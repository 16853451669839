import React, {Component} from 'react'
import bwipjs from 'bwip-js';
import Papa from "papaparse";
import validbarcode from "barcode-validator";
import TextLayer from "./layers/TextLayer"
import Draw from "./layers/Draw"
import DrawLabel from "./labelGraphics/drawLabel";
import BarcodeLayer from "./layers/BarcodeLayer";
import ImageLayer from "./layers/ImageLayer";
import ShapeLayer from "./layers/ShapeLayer";
import Data_Var from "./layers/DataVariableLayer";
import Data_Excel from "./layers/Data_Excel";
import {connect} from "react-redux";
import {Delete_Objects, LH1, LH2, LH3, LH4, LH5, RH1, RH2, RH3, TOOLBAR, TOOLBARICONS_ON} from "../actions";
import './layers/activator_style.css';
import Draggable from 'react-draggable';
import NewFile_Popup from "../popup/NewFile";
import OpenFile_Popup from "../popup/OpenFile";
import DateStamp_Popup from '../popup/DateStamp'
import CopyPastePopup from "../popup/CopyPastePopup";
import ViewCSV from "../popup/ViewCSV";
import DimError from "../popup/DimenError";
import CSVLoad from "../popup/CSVLoad";
import BarcodeError from "../popup/BarcodeError";
import {Circle, Ellipse, Layer, Line, Rect, Stage} from "react-konva";
import doc_d from '../assets/nav_Images/btn_Document_Disabled.png'
import doc_e from '../assets/nav_Images/btn_Document_Enabled.png'
import txt_d from '../assets/nav_Images/btn_Text_Disabled.png'
import txt_e from '../assets/nav_Images/btn_Text_Enabled_.png'
import bar_d from '../assets/nav_Images/btn_Barcode_Disabled.png'
import bar_e from '../assets/nav_Images/btn_Barcode_Enabled.png'
import img_d from '../assets/nav_Images/btn_Image_Disabled.png'
import img_e from '../assets/nav_Images/btn_Image_Enabled.png'
import shp_d from '../assets/nav_Images/btn_Shapes_Disabled.png'
import shp_e from '../assets/nav_Images/btn_Shapes_Enabled.png'
import data_var_d from '../assets/nav_Images/btn_DataVariable_Disabled.png'
import data_var_e from '../assets/nav_Images/btn_DataVariable_Enabled.png';
import data_excel_d from '../assets/nav_Images/btn_DataExcel_Disabled.png'
import data_excel_e from '../assets/nav_Images/btn_DataExcel_Enabled.png'
import data_excel_d_new from '../assets/Tab_Images/Data_Excel/btn_DataTab_disabled-8.png'
import data_excel_e_new from '../assets/Tab_Images/Data_Excel/btn_DataTab_enabled-8.png'

import ReactTooltip from "react-tooltip";
import '../components/layers/toolTip_Style.css';
import Toolbar from '../../src/components/Toolbar'
import {getDrawProps} from './Helper/helperFunctions';
import {css} from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import QuestionPrint from "../popup/QuestionPrint";
import Settings from "../popup/Settings";
import TransformNotice from "../popup/TransformNotice";
import ViewCSVQBP from "../popup/ViewCSVQBP";
import CsvDataReset from "../popup/CsvDataReset";
import language_Options from "../assets/translations/translator";
import CsvAppendSave_Popup from "../popup/CsvAppendSave";
import CsvResetSave_Popup from "../popup/CsvResetSave";
import CsvResetInfo from "../popup/CsvResetInfo";


var Barcode = require('react-barcode');
var QRCode = require('qrcode.react');
var jsdiff = require('diff');

const font_styles = ['Arial','Caviar','Caudex','Arimo','PtSans','SourceSansPro','Lato','Amiri','Almendra'];

const options_XDIM_200DPI = [
    { label: 'XS',value: 1 },
    { label: 'S',value: 2 },
    { label: 'M',value: 3 },
    { label: 'L',value: 4 },
    { label: 'XL',value: 5 },
    { label: 'XXL',value: 6 },
];
const options_XDIM_300DPI = [
    { label: 'XS',value: 1 },
    { label: 'S',value: 2 },
    { label: 'M',value: 3 },
    { label: 'L',value: 4 },
    { label: 'XL',value: 5 },
    { label: 'XXL',value: 6 },

];


class Activator extends Component{
    constructor(props) {
        super(props);

        this.state = {activatorOn: false, dimensions: {},
            csvDataResetPopup:false,
            csvPrintData:[],
            csvQTYArr:[],
            bcdValidateError:false,
            csvResetInfo: false,
            xPosError:false,
            dbDataLabel:'',
            csvPopup:false,
            dbEnableStatus:false,
            csvFileData:[],
            csvFileDataTable:[],
            csvFileDataTableQBP:[],
            viewCSVPopup:false,
            viewCSVQBPPopup:false,
            csvFileName:'',
            csvReadRow:false,
            truncateText: false,
            limitColumns: false,
            qInputSortArray:[],
            questionPrintLock:false,
            modalClose:false,
            loadLanguage: language_Options.English,
            loadLanguageLabel:'English',
            settingsPopup:false,
            loadPreviousReprint:[],
            loadImageName:'Select Image',
            transparentStatus:false,
            shapeNature:'',
            imageLoaded: false,
            imageSrcCode: '',
            fileLoading:false,
            transformPopup: false,
            transformStatus:90,
            multilineWidth:0,
            multilineHeight:1,
            copyDataStatus:false,
            disableCopyData: false,
            copyPasteStatus: false,
            copyData:[],
            dbDataValue:[],
            copyDataLabel:'',
            toolBarCopy:{},
            label_dim: {
                width: '', height: '', fileName:'Untitled File',actualWidth: '',actualHeight: '',
                leftMargin: '0', rightMargin: '0', label_across: '1', labelCreate: false,
                startLabel: false, label_down: '0',pageNumber:1,
            },
            addToList:[],
            zoom: false,
            width_text: '150px',
            text: [],
            activeDrags: 0,
            deltaPosition: {
                x: 0, y: 0
            },
            chosenTextIndex: -1,
            showText : '',
            bold: true,
            italic: false,
            underline: false,
            lineThrough: false,
            flipText: false,
            fontSize: 6,
            fontFamily: 'Arimo',
            fontFamilyIndex: 9,
            backgroundColor: '#FFFFFF',
            textColor: '#000000',
            barcodeLineColor:'#000000',
            barcodeBackgroundColor:'#FFFFFF',
            textAlign: 'left',
            leftAlign: true,
            centerAlign: false,
            rightAlign: false,
            multiline: false,
            onMouseOver: false,
            pinText: 'Untitled Text',
            textLocked: false,
            prefix: '',
            suffix: '',
            textXPos:'',
            textYPos:'',
            textNoPrint: false,
            questionPrintText:'',
            objectPosition: {
                x: 0, y: 0, width: 0, height: 0, right: 0
            },
            objectPosition_barcodeText: {
                x: 0, y: 0, width: 0, height: 0, right: 0
            },
            font_Scaling: 1,
            chosenBarcodeIndex: -1,
            barcodeType: 'CODE128',
            barcodeTypeValue: 1,
            barcodeValue:'',
            barcodeDPI: '200 dpi',
            barcodeDPIValue: 1,
            barcodepinText: 'Untitled Barcode',
            barcodeHeight:'',
            barcodeDPIXDIM: 'XS',
            barcodeDPIXDIM_Value: 1,
            barcodeDPI_Options: options_XDIM_200DPI,
            barcodeDPI_Options_Value: 1,
            qrCode: false,
            barcodeLocked:false,
            barcodeNoPrint: false,
            barcodeXPos:'',
            barcodeYPos:'',
            checkDigit: false,
            barcodeObject: {
                barcode: [], barcodeText: []
            },
            image_array: [],
            shape_array:[],
            chosenShapeIndex: -1,
            shapeXPos:'',
            shapeYPos:'',
            shapeLocked:false,
            shapeNoPrint: false,
            shapepinText: 'Untitled Shape',
            shapeWidth:'',
            shapeHeight:'',
            shapeName:'',
            shapeStretch: false,
            img_dim: {
                width: 0, height: 0,
            },
            chosenImageIndex: -1,
            imageXPos:'',
            imageYPos:'',
            objectPosition_Image: {
                x: 0, y: 0, width: 0, height: 0, right: 0
            },
            imageLocked:false,
            imageNoPrint: false,
            imagepinText: 'Untitled Image',
            imageWidth:'',
            imageHeight:'',
            imageName:'',
            imageStretch: false,
            popupNewStatus: false,
            csvAppendSave: false,
            csvResetLoadData: [],
            csvResetLoadFilename: '',
            csvResetSave: false,
            popupOpenStatus:false,
            fileArray:'',
            shapeType:false,
            shapeStrokeWidth: 0,
            shapeBorderColor:'#000000',
            shapeFillColor:'#FFFFFF',
            win_Width: window.innerWidth,
            paraWidth:false,
            paraHeight:false,
            textQuestion:false,
            textIncrement:false,
            dateStampOn: false,
            dateStampPopup:false,
            loadGrid: false,
            questionPrint:false,
            questionText:[],
            questionBtx:[],
            userSelectedColumn:'',
            csvFileDataTableQBPMap: {},
        };

        // This line is important!
        this.activatorClick = this.activatorClick.bind(this);
        this.LH1_Click = this.LH1_Click.bind(this);
        this.LH2_Click = this.LH2_Click.bind(this);
        this.LH2_ClickBtx = this.LH2_ClickBtx.bind(this);

        this.LH3_Click = this.LH3_Click.bind(this);
        this.LH4_Click = this.LH4_Click.bind(this);
        this.LH5_Click = this.LH5_Click.bind(this);
        this.toolClick = this.toolClick.bind(this);
        this.RH1_Click = this.RH1_Click.bind(this);
        this.RH2_Click = this.RH2_Click.bind(this);
        this.RH3_Click = this.RH3_Click.bind(this);

    }

    componentDidUpdate(prevProps) {

        if (this.props.delete_On) {
            if (this.state.chosenTextIndex >= 0) {

                if (this.state.text[this.state.chosenTextIndex].copyChildIndex.length > 0 ){
                    let fg = this.state.text[this.state.chosenTextIndex]
                    for (let i =0;i < fg.copyChildIndex.length;i++){
                        this.state.text[fg.copyChildIndex[i]].copyDataLabel = 'Copy data from Field'
                        this.state.text[fg.copyChildIndex[i]].copyDataStatus = false

                    }
                }

                if (this.state.text[this.state.chosenTextIndex].copyDataStatus){
                    for (let i =0; i < this.state.text.length;i++){
                        if (i !== this.state.chosenTextIndex) {
                            let rg = this.state.text[i]
                            if (rg.copyChildIndex.includes(this.state.chosenTextIndex)) {

                                for (let j = 0; j < rg.copyChildIndex.length; j++) {

                                    if (rg.copyChildIndex[j] === this.state.chosenTextIndex) {
                                        this.state.text[i].copyChildIndex.splice(j, 1)
                                    }

                                }
                            }
                        }
                    }

                }




                if (document.getElementById('text'+this.state.chosenTextIndex)){
                        let rb = document.getElementById('text'+this.state.chosenTextIndex)
                        this.state.text[this.state.chosenTextIndex].deleted = true
                        rb.remove()
                    this.setState((state, props) => ({
                        chosenTextIndex: -1,
                        showText:'',
                    }))
                }

                this.props.dispatch(Delete_Objects());

            }
            if (this.state.chosenBarcodeIndex >= 0) {

                if (document.getElementById('btext'+this.state.chosenBarcodeIndex)){

                    if (this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex].copyChildIndex.length > 0 ){
                        let fg = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex]
                        for (let i =0;i < fg.copyChildIndex.length;i++){
                            this.state.barcodeObject.barcodeText[fg.copyChildIndex[i]].copyDataLabel = 'Copy data from Field'
                            this.state.barcodeObject.barcodeText[fg.copyChildIndex[i]].copyDataStatus = false

                        }
                    }

                    if (this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex].copyDataStatus){
                        for (let i =0; i < this.state.barcodeObject.barcodeText.length;i++){
                            if (i !== this.state.chosenBarcodeIndex) {
                                let rg = this.state.barcodeObject.barcodeText[i]
                                if (rg.copyChildIndex.includes(this.state.chosenBarcodeIndex)) {

                                    for (let j = 0; j < rg.copyChildIndex.length; j++) {

                                        if (rg.copyChildIndex[j] === this.state.chosenBarcodeIndex) {
                                            this.state.barcodeObject.barcodeText[i].copyChildIndex.splice(j, 1)
                                        }

                                    }
                                }
                            }
                        }

                    }

                    let rb = document.getElementById('btext'+this.state.chosenBarcodeIndex)
                    this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex].deleted = true
                    // rb.remove()
                    rb.style.display = 'none';

                    let rbb = document.getElementById('bcd'+this.state.chosenBarcodeIndex)
                    this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex].deleted = true
                    rbb.style.display = 'none';
                    this.setState((state, props) => ({
                        chosenBarcodeIndex: -1,
                        showText:'',
                    }))
                }
                this.props.dispatch(Delete_Objects());


            }
            if (this.state.chosenImageIndex >= 0) {


                if (document.getElementById('img'+this.state.chosenImageIndex)){
                    let rb = document.getElementById('img'+this.state.chosenImageIndex)
                    this.state.image_array[this.state.chosenImageIndex].deleted = true
                    rb.remove()

                    this.setState((state, props) => ({
                        chosenImageIndex: -1,
                    }));
                }

                this.props.dispatch(Delete_Objects());

            }
            if (this.state.chosenShapeIndex >= 0) {

                if (document.getElementById('shape'+this.state.chosenShapeIndex)){
                    let rb = document.getElementById('shape'+this.state.chosenShapeIndex)
                    this.state.shape_array[this.state.chosenShapeIndex].deleted = true
                    rb.remove()

                    this.setState((state, props) => ({
                        chosenShapeIndex: -1,
                    }));
                }

                this.props.dispatch(Delete_Objects());

            }


        }
        if (this.state.text.length > 0 || this.state.barcodeObject.barcode.length > 0 || this.state.image_array.length > 0 ||
            this.state.shape_array.length > 0 || this.state.barcodeObject.barcodeText.length > 0 ){
            if (!this.props.toolIcons_bool){
                this.props.dispatch(TOOLBARICONS_ON());

            }
        }

        if (!this.state.text.length && !this.state.barcodeObject.barcode.length && !this.state.image_array.length &&
            !this.state.shape_array.length && !this.state.barcodeObject.barcodeText.length){
            if (this.props.toolIcons_bool){
                this.props.dispatch(TOOLBARICONS_ON());
            }
        }
    }
    onUpMouseUpAll(){
        clearInterval(this.Upallinterval);
    }

    upMoveAll() {
            this.Upallinterval = setInterval(() => {
                 this.state.text.map((fx2,index)=>{
                     if (!fx2.lock){
                     fx2.top -= 1
                     this.state.text.splice(index, 1, fx2)

                     let cx = this.state.text;
                     this.setState({
                         text: cx,
                     })
                     }
                 })


                this.state.barcodeObject.barcodeText.map((fx2,index)=> {
                    if (!fx2.lock) {
                        fx2.top -= 1
                        this.state.barcodeObject.barcodeText.splice(index, 1, fx2)

                        let cx = this.state.barcodeObject.barcodeText;
                        this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcodeText: cx,
                            }
                        })
                    }
                })

                this.state.barcodeObject.barcode.map((fx2,index)=> {
                    if (!fx2.lock) {
                        fx2.top -= 1
                        this.state.barcodeObject.barcode.splice(index, 1, fx2)

                        let cx = this.state.barcodeObject.barcode;
                        this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcode: cx,
                            }
                        })
                    }
                })
                this.state.image_array.map((fx2,index)=>{
                    if (!fx2.lock) {
                        fx2.top -= 1
                        this.state.image_array.splice(index, 1, fx2)

                        let cx = this.state.image_array;
                        this.setState({
                            image_array: cx,
                        })
                    }
                })
            }, 100);

    }
    onDownMouseUpAll(){
        clearInterval(this.Downallinterval);
    }
    downMoveAll() {
            this.Downallinterval = setInterval(() => {
                this.state.text.map((fx2,index)=>{
                    if (!fx2.lock) {
                        fx2.top += 1
                        this.state.text.splice(index, 1, fx2)

                        let cx = this.state.text;
                        this.setState({
                            text: cx,
                        })
                    }
                })

                this.state.barcodeObject.barcodeText.map((fx2,index)=> {
                    if (!fx2.lock) {
                        fx2.top += 1
                        this.state.barcodeObject.barcodeText.splice(index, 1, fx2)

                        let cx = this.state.barcodeObject.barcodeText;
                        this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcodeText: cx,
                            }
                        })
                    }
                })

                this.state.barcodeObject.barcode.map((fx2,index)=> {
                    if (!fx2.lock) {
                        fx2.top += 1
                        this.state.barcodeObject.barcode.splice(index, 1, fx2)

                        let cx = this.state.barcodeObject.barcode;
                        this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcode: cx,
                            }
                        })
                    }
                })
                this.state.image_array.map((fx2,index)=>{
                    if (!fx2.lock) {
                        fx2.top += 1
                        this.state.image_array.splice(index, 1, fx2)

                        let cx = this.state.image_array;
                        this.setState({
                            image_array: cx,
                        })
                    }
                })
            }, 100);

    }
    onLeftMouseUpAll(){
        clearInterval(this.Leftallinterval);
    }
    leftMoveAll() {
            this.Leftallinterval = setInterval(() => {
                this.state.text.map((fx2,index)=>{
                    if (!fx2.lock) {
                        if (fx2.textAlign === 'left') {
                            fx2.left -= 1;
                        }
                        if (fx2.textAlign === 'center') {
                            fx2.left -= 1;
                            fx2.right += 1;
                        }
                        if (fx2.textAlign === 'right') {
                            fx2.right += 1;
                        }
                        this.state.text.splice(index, 1, fx2)

                        let cx = this.state.text;
                        this.setState({
                            text: cx,
                        })
                    }
                })

                this.state.barcodeObject.barcodeText.map((fx2,index)=> {
                    if (!fx2.lock) {
                        if (fx2.textAlign === 'left') {
                            fx2.left -= 1;
                        }
                        if (fx2.textAlign === 'center') {
                            fx2.left -= 1;
                            fx2.right += 1;
                        }
                        if (fx2.textAlign === 'right') {
                            fx2.right += 1;
                        }
                        this.state.barcodeObject.barcodeText.splice(index, 1, fx2)

                        let cx = this.state.barcodeObject.barcodeText;
                        this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcodeText: cx,
                            }
                        })
                    }
                })

                this.state.barcodeObject.barcode.map((fx2,index)=> {
                    if (!fx2.lock) {
                        fx2.left -= 1;
                        this.state.barcodeObject.barcode.splice(index, 1, fx2)

                        let cx = this.state.barcodeObject.barcode;
                        this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcode: cx,
                            }
                        })
                    }
                })
                this.state.image_array.map((fx2,index)=>{
                    if (!fx2.lock) {
                        fx2.left -= 1;
                        this.state.image_array.splice(index, 1, fx2)

                        let cx = this.state.image_array;
                        this.setState({
                            image_array: cx,
                        })
                    }
                })
            }, 100);

    }

    onRightMouseUpAll(){
        clearInterval(this.Rightallinterval);
    }
    rightMoveAll() {
            this.Rightallinterval = setInterval(() => {
                this.state.text.map((fx2,index)=>{
                    if (!fx2.lock) {
                        if (fx2.textAlign === 'left') {
                            fx2.left += 1;
                        }
                        if (fx2.textAlign === 'center') {
                            fx2.left += 1;
                            fx2.right -= 1;
                        }
                        if (fx2.textAlign === 'right') {
                            fx2.right -= 1;
                        }
                        this.state.text.splice(index, 1, fx2)

                        let cx = this.state.text;
                        this.setState({
                            text: cx,
                        })
                    }
                })

                this.state.barcodeObject.barcodeText.map((fx2,index)=> {
                    if (!fx2.lock) {
                        if (fx2.textAlign === 'left') {
                            fx2.left += 1;
                        }
                        if (fx2.textAlign === 'center') {
                            fx2.left += 1;
                            fx2.right -= 1;
                        }
                        if (fx2.textAlign === 'right') {
                            fx2.right -= 1;
                        }
                        this.state.barcodeObject.barcodeText.splice(index, 1, fx2)

                        let cx = this.state.barcodeObject.barcodeText;
                        this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcodeText: cx,
                            }
                        })
                    }
                })

                this.state.barcodeObject.barcode.map((fx2,index)=> {
                    if (!fx2.lock) {
                        fx2.left += 1;
                        this.state.barcodeObject.barcode.splice(index, 1, fx2)

                        let cx = this.state.barcodeObject.barcode;
                        this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcode: cx,
                            }
                        })
                    }
                })
                this.state.image_array.map((fx2,index)=>{
                    if (!fx2.lock) {
                        fx2.left += 1;
                        this.state.image_array.splice(index, 1, fx2)

                        let cx = this.state.image_array;
                        this.setState({
                            image_array: cx,
                        })
                    }
                })
            }, 100);

    }

    textStart = (item,index, s) => {


         let decoration_style = () => this.checkDecoration(item);

         let rsz_dz = 11.057374338624339 / this.state.label_dim.width;

         let ratio_dz = this.props.label_Width * rsz_dz;

         let scale_f = ratio_dz / 32.724609375;

         let bx = item.fontSize / item.scalingRatio;

         let gx = bx * scale_f;

         let brd = false

         let t_left = 0


         var width = require('text-width');
         let wt = 'normal';
         var w = width(item.prefix + item.text + item.suffix, {
             family: item.fontFamily,
             size: gx,
             weight: wt
         });
         if (item.oldWidth === window.innerWidth) {
             if (item.textAlign === 'left' || item.textAlign === 'center') {

                 let ranger = item.left + item.deltaPositionX - this.props.label_X - item.oldWidth * 0.25;
                 t_left = item.left + item.deltaPositionX


                 let resizer = (this.state.label_dim.width / this.props.label_Width);
                 let x_pos_real = ranger * resizer
                 item.x = (Math.round(x_pos_real * 100) / 100).toFixed(1)
             }
             if (item.textAlign === 'right' && !item.multiline) {
                 if (document.getElementById('txtx' + index)) {
                     let x_width = document.getElementById('txtx' + index).offsetWidth / item.oldWidth
                     w = window.innerWidth * x_width
                 }

                 let ry = window.innerWidth - item.right - w;
                 t_left = ry + item.deltaPositionX

                 let x_range = ry + item.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;

                 let x_resize = (this.state.label_dim.width / this.props.label_Width);
                 let x_pos_real = x_range * x_resize
                 item.x = (Math.round(x_pos_real * 100) / 100).toFixed(1)


             }


             let y_ranger = item.top + item.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

             let y_resizer = (this.state.label_dim.height / this.props.label_Height);
             let y_pos_real = y_ranger * y_resizer
             item.y = (Math.round(y_pos_real * 100) / 100).toFixed(1)


             let rf = item.fontSize;
             let rf_mm = (Number(rf) * this.state.label_dim.height) / this.props.label_Height
             item.fontSizeMM = rf_mm / 0.352778;

         }

         if (item.oldWidth !== window.innerWidth) {
             this.resetFocusAll()

             let x_pos = 0
             let y_pos = 0

             if (item.multiline) {
                 item.width = (item.width * window.innerWidth) / item.oldWidth
             }


             if (item.textAlign === 'left') {
                 if (item.x === 0) {
                     let ranger = item.left + item.deltaPositionX - this.props.label_X - item.oldWidth * 0.25;
                     let resizer = (this.state.label_dim.width / this.props.label_Width);
                     let x_pos_real = ranger * resizer
                     x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                     item.x = x_pos
                 } else {

                     x_pos = Number(item.x)

                 }

                 let x_resize = (this.state.label_dim.width / this.props.label_Width);

                 let x_range = x_pos / x_resize
                 item.oldWidth = window.innerWidth
                 item.left = x_range - item.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;

             }
             if (item.textAlign === 'center') {


                 let x_resize = (this.state.label_dim.width / this.props.label_Width);
                 let x_range = item.x / x_resize
                 if (document.getElementById('txtx' + index)) {
                     let x_width = document.getElementById('txtx' + index).offsetWidth / item.oldWidth

                     item.oldWidth = window.innerWidth
                     item.width = window.innerWidth * x_width

                     item.left = x_range - item.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                     item.right = window.innerWidth - (item.left + item.width)
                 }

             }


             if (item.textAlign === 'right') {
                 if (document.getElementById('txtx' + index)) {
                     let x_width = document.getElementById('txtx' + index).offsetWidth / item.oldWidth
                     let itw = window.innerWidth * x_width

                     let x_resize = (this.state.label_dim.width / this.props.label_Width);
                     let x_range = item.x / x_resize

                     let t_left = x_range - item.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                     item.right = window.innerWidth - (t_left + itw)
                     item.oldWidth = window.innerWidth
                 }

             }


             if (item.y === 0) {

                 let y_ranger = item.top + item.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

                 let y_resizer = (this.state.label_dim.height / this.props.label_Height);
                 let y_pos_real = y_ranger * y_resizer
                 y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                 item.y = y_pos
             } else {
                 y_pos = Number(item.y)
             }


             let y_resize = (this.state.label_dim.height / this.props.label_Height);
             let y_range = y_pos / y_resize;

             item.top = y_range - item.deltaPositionY + this.props.label_Y + window.innerWidth * 0.095;


             let gx_mm = Number(item.fontSizeMM) * 0.352778
             item.fontSize = (this.props.label_Height * gx_mm) / this.state.label_dim.height
         }

         if (document.getElementById('text' + index)) {
             let hx = document.getElementById('text' + index).offsetHeight + item.top + item.deltaPositionY
             let h_y = item.top + item.deltaPositionY
             let lb_h = this.props.label_Y + this.props.label_Height + window.innerWidth * 0.095
             let lb_y = this.props.label_Y + window.innerWidth * 0.095

             let lb_x = this.props.label_X + window.innerWidth * 0.25
             let wx = document.getElementById('text' + index).offsetWidth + t_left


             if (lb_h < hx || h_y < lb_y || t_left < lb_x || wx > (lb_x + this.props.label_Width)) {

                 brd = true
             }
             if (item.textAlign === 'center') {
                 if (lb_h < (hx + 2.5)) {
                     brd = true
                 }
             }
         }
         let txr = document.getElementById('text' + index) ? document.getElementById('text' + index) : null

             return (
                 <Draggable
                     key={'text' + index + item.resetTransform}
                     bounds={{
                         left: (txr !== null && txr ? -(txr.offsetLeft - window.innerWidth * 0.25) : -this.props.label_X),
                         top: (txr !== null && txr  ? (window.innerWidth * 0.095 - item.top) : (-this.props.label_Y - this.props.label_Height)),
                         right: txr !== null && txr  ? window.innerWidth * 0.75 - txr.offsetLeft - txr.offsetWidth : window.innerWidth * 0.5 - this.props.label_X,
                         bottom: txr !== null && txr  ? window.innerHeight * 0.9 - item.top : (window.innerHeight * 0.9) - (window.innerWidth * 0.095 + this.props.label_Y + this.props.label_Height)
                     }}

                     onDrag={this.handleDrag} onStart={this.onStart} onStop={this.onStop}
                     enableUserSelectHack={false}
                     disabled={item.lock || (item.transform !== null && item.transform !== 'rotate(0deg)')}>
                     <div
                         style={{
                             right: item.right,
                             left: item.left,
                             position: 'absolute',
                             fontSize: item.fontSize + 'px',
                             top: item.top,
                             fontFamily: item.fontFamily,
                             textDecoration: decoration_style(),
                             textAlign: item.textAlign,
                             whiteSpace: item.multiline ? 'pre-wrap' : 'nowrap',
                             border: item.focus && brd ? '0.03px solid red' : item.focus && !item.outlineSelected && item.textAlign !== 'center' ? '0.02px solid #0DA4EB' : item.focus && item.multiline ? '0.02px solid #0DA4EB' : 'none',
                             borderRadius: '0px',
                             display: item.multiline ? null : 'flex',
                             // display:'inline-block',
                             justifyContent: "center",
                             // outline:'#0DA4EB auto 0.01px',
                             outline: item.focus && !item.outlineSelected && item.textAlign === 'center' ? item.text.length === 0 ? 'none' : '#0DA4EB auto 0.01px' : item.focus && item.outlineSelected && !item.multiline ? brd ? 'red auto 0.01px' : '#0DA4EB auto 0.02px' : brd ? 'red auto 0.01px' : 'none',
                             zIndex: item.zIndex,
                             alignItems: "center",
                             color: item.textColor,
                             width: item.width,
                             backgroundColor: item.backgroundColor,
                             lineHeight: item.multiline ? item.multilineHeight : 1,
                             transform: item.transform,
                             'WebkitTransform': item.transform,
                             'MozTransform': item.transform,
                             'msTransform': item.transform,
                             transformOrigin: '0 0',
                             outlineOffset: '1px'
                         }}

                         id={'text' + index}
                         spellCheck={false}

                         className="parent" key={'text' + index}
                         suppressContentEditableWarning={true}
                         contentEditable={!item.dateStamp && !item.copyDataStatus}

                         onFocus={(e) => this.onTextFocus(e, index)}
                         onBlur={(e) => this.offTextFocusBlur(e, index)}
                         onMouseMove={(e) => this.offTextFocus(e, index)}
                         onMouseUp={(e) => this.offTextFocus(e, index)}
                         onKeyUp={(e) => this.handleChange(e, index)}
                         onKeyDown={(e) => this.handleChangeKeyDown(e, index)}
                         onDoubleClick={(e) => this.handleDoubleClick(e, index)}

                         onMouseLeave={() => this.checkIncrement()}

                         onClick={(e) => this.clickText(e, index)}
                         tabIndex="0">
                         <div id={'txtx' + index}
                              suppressContentEditableWarning={true}>{item.prefix + item.text + item.suffix}</div>
                     </div>
                 </Draggable>
             )
    };

    onStart = (e) => {
        if (this.props.Left_State !== "LH2"){
            this.props.dispatch(LH2());
        }
        if (this.props.Right_State !== "RH1"){
            this.props.dispatch(RH1());

        }
        if (this.state.chosenTextIndex >= 0 && e.currentTarget.id !== this.state.chosenTextIndex) {
            let fx1 = this.state.text[this.state.chosenTextIndex];
            fx1.focus = false;
            let fxFont = fx1.fontFamily;

            if (fxFont.includes('Bold') || fxFont.includes('BoldItalic')){
                const d = fxFont.indexOf('Bold');
                fxFont = fxFont.slice(0, d);
            }
            else {
                if (fxFont.includes('Italic')){
                    const dx = fxFont.indexOf('Italic');
                    fxFont = fxFont.slice(0, dx);
                }
            }
            const fontStyleChecker = (font_styles.indexOf(fxFont) > -1);

            if (!fontStyleChecker){
                this.setState({bold:false, italic:false}, ()=> {
                    fx1.bold = false;
                    fx1.italic = false;
                });
            }
            this.setState({fontFamily: fxFont}, ()=>{
            })
            if (fx1.textAlign === 'center'){
                fx1.outlineSelected = true;
            }

            this.state.text.splice(this.state.chosenTextIndex, 1, fx1);
            let cx1 = this.state.text;
            this.setState({
                text: cx1,
            })

        }
        let p = e.currentTarget.id.substring(4,e.currentTarget.id.length)

        this.setState({
            chosenTextIndex: Number(p)
        })
        e.target.style.cursor = 'move';

    };
    onStartBarcodeText = (e) => {
        if (this.props.Left_State !== "LH2"){
            this.props.dispatch(LH2());
        }
        if (this.props.Right_State !== "RH1"){
            this.props.dispatch(RH1());

        }
        if (this.state.chosenBarcodeIndex >= 0 && e.currentTarget.id !== this.state.chosenBarcodeIndex) {

            let fx1 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            fx1.focus = false;
            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx1);
            let cx1 = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcode: cx1,
                }
            })

            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if (fx2) {
                fx2.focus = false;
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx2 = this.state.barcodeObject.barcodeText;

                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: cx2,
                    }
                })
            }
        }
        let p = e.currentTarget.id.substring(5,e.currentTarget.id.length)
        this.setState({
            chosenBarcodeIndex: Number(p)
        })
        e.target.style.cursor = 'move';

    };

    onStop = (e) => {

        e.target.style.cursor = 'move';



    };

    handleDrag = (e, ui) => {

        if (this.state.chosenTextIndex >= 0) {

            let fx2 = this.state.text[this.state.chosenTextIndex];
            fx2.deltaPositionX = fx2.deltaPositionX + ui.deltaX;
            fx2.deltaPositionY = fx2.deltaPositionY + ui.deltaY

            fx2.xPos = fx2.deltaPositionX + ui.deltaX;
            fx2.yPos = fx2.deltaPositionY + ui.deltaY;

            let x_range = '';

            if (fx2.textAlign === 'left' || fx2.textAlign === 'center'){
                x_range = fx2.left +fx2.deltaPositionX + ui.deltaX - this.props.label_X-  window.innerWidth * 0.25;

            }
            if (fx2.textAlign === 'right'){
                let ry = window.innerWidth -fx2.right - this.state.objectPosition.width;
                x_range = ry +fx2.deltaPositionX + ui.deltaX - this.props.label_X -  window.innerWidth * 0.25;
            }




            let x_resize = (this.state.label_dim.width /this.props.label_Width);
            let x_pos_real = x_range * x_resize
            let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

            let y_range = fx2.top + fx2.deltaPositionY + ui.deltaY - this.props.label_Y -  window.innerWidth* 0.095;
            let y_resize = this.state.label_dim.height /this.props.label_Height ;
            let y_pos_real = y_range * y_resize
            let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)





            this.setState({

                textXPos: x_pos,
                textYPos: y_pos,
            });
            fx2.focus = true;



            this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })


        }


    };
    onHandleDragQText = (e, ui,m) => {

            let fx2 = this.state.text[m];
            let gbx = fx2.qTextdeltaX*window.innerWidth/100
            let gbxx = gbx + ui.deltaX;
            fx2.qTextdeltaX = (gbxx/window.innerWidth*100).toFixed(2)

            let gby = fx2.qTextdeltaY*window.innerWidth/100
            let gbyy = gby + ui.deltaY;
            fx2.qTextdeltaY = (gbyy/window.innerWidth*100).toFixed(2)
            fx2.firstLoad = false

            this.state.text.splice(m, 1, fx2);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })

    }
    onHandleDragQInputText  = (e, ui,m) => {

        let fx2 = this.state.text[m];
        let gbx = fx2.qInputdeltaX*window.innerWidth/100
        let gbxx = gbx + ui.deltaX;
        fx2.qInputdeltaX = (gbxx/window.innerWidth*100).toFixed(2)

        let gby = fx2.qInputdeltaY*window.innerWidth/100
        let gbyy = gby + ui.deltaY;
        fx2.qInputdeltaY = (gbyy/window.innerWidth*100).toFixed(2)
        fx2.firstLoad = false

        this.state.text.splice(m, 1, fx2);
        let cx = this.state.text;
        this.setState({
            text: cx,
        })

    }

    onHandleDragQBText = (e, ui,m) => {

        let fx2 = this.state.barcodeObject.barcodeText[m];
        let gbx = fx2.qTextdeltaX*window.innerWidth/100
        let gbxx = gbx + ui.deltaX;
        fx2.qTextdeltaX = (gbxx/window.innerWidth*100).toFixed(2)
        fx2.firstLoad = false

        let gby = fx2.qTextdeltaY*window.innerWidth/100
        let gbyy = gby + ui.deltaY;
        fx2.qTextdeltaY = (gbyy/window.innerWidth*100).toFixed(2)

        this.state.barcodeObject.barcodeText.splice(m, 1, fx2);
        let cx = this.state.barcodeObject.barcodeText;
        this.setState({
            barcodeObject:{
                ...this.state.barcodeObject,
                barcodeText: cx,
            }
        })

    }
    onHandleDragQInputBText  = (e, ui,m) => {
        let fx2 = this.state.barcodeObject.barcodeText[m];
        let gbx = fx2.qInputdeltaX*window.innerWidth/100
        let gbxx = gbx + ui.deltaX;
        fx2.qInputdeltaX = (gbxx/window.innerWidth*100).toFixed(2)
        fx2.firstLoad = false

        let gby = fx2.qInputdeltaY*window.innerWidth/100
        let gbyy = gby + ui.deltaY;
        fx2.qInputdeltaY = (gbyy/window.innerWidth*100).toFixed(2)

        this.state.barcodeObject.barcodeText.splice(m, 1, fx2);
        let cx = this.state.barcodeObject.barcodeText;
        this.setState({
            barcodeObject:{
                ...this.state.barcodeObject,
                barcodeText: cx,
            }
        })
    }

     handleDragBarcode = (e, ui) => {


        if (this.state.chosenBarcodeIndex >= 0) {
            if (this.props.Left_State !== "LH3") {
                this.props.dispatch(LH3());

            }
            let fx2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            fx2.deltaPositionX = fx2.deltaPositionX + ui.deltaX;
            fx2.deltaPositionY = fx2.deltaPositionY + ui.deltaY

            fx2.xPos = fx2.deltaPositionX + ui.deltaX;
            fx2.yPos = fx2.deltaPositionY + ui.deltaY;

            let x_range = '';

                x_range = fx2.left +fx2.deltaPositionX + ui.deltaX - this.props.label_X-  window.innerWidth * 0.25+1;

            let x_resize = (this.state.label_dim.width /this.props.label_Width);
            let x_pos_real = x_range * x_resize
            let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

            let y_range = fx2.top + fx2.deltaPositionY + ui.deltaY - this.props.label_Y -  window.innerWidth* 0.095;
            let y_resize = this.state.label_dim.height /this.props.label_Height ;
            let y_pos_real = y_range * y_resize
            let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)


            this.setState({
                barcodeXPos: x_pos,
                barcodeYPos: y_pos,
            });
            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcode: cx,
                }
            })
        }

    };
    handleDragBarcodeText = (e, ui) => {

        if (this.state.chosenBarcodeIndex >= 0) {
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

            fx2.deltaPositionX = fx2.deltaPositionX + ui.deltaX;
            fx2.deltaPositionY = fx2.deltaPositionY + ui.deltaY

            fx2.xPos = fx2.deltaPositionX + ui.deltaX;
            fx2.yPos = fx2.deltaPositionY + ui.deltaY;

            let x_range = '';

            if (fx2.textAlign === 'left' || fx2.textAlign === 'center') {
                x_range = fx2.left + fx2.deltaPositionX + ui.deltaX - this.props.label_X - window.innerWidth * 0.25;

            }
            if (fx2.textAlign === 'right') {
                let ry = window.innerWidth - fx2.right - this.state.objectPosition.width;
                x_range = ry + fx2.deltaPositionX + ui.deltaX - this.props.label_X - window.innerWidth * 0.25;
            }

            let x_resize = (this.state.label_dim.width / this.props.label_Width);
            let x_pos_real = x_range * x_resize
            let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

            let y_range = fx2.top + fx2.deltaPositionY + ui.deltaY - this.props.label_Y - window.innerWidth * 0.095;
            let y_resize = this.state.label_dim.height / this.props.label_Height;
            let y_pos_real = y_range * y_resize
            let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)

            this.setState({

                textXPos: x_pos,
                textYPos: y_pos,
            });
            fx2.focus = true;

            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcodeText;

            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcodeText: cx,

                }
            })
        }

    };

    barcodeStart = (item,index, s) => {


        let brd = false

        if (item.oldWidth === window.innerWidth){

            item.barHeightMM = (item.barHeight * this.state.label_dim.height/this.props.label_Height).toFixed(2)

            let x_range = item.left +item.deltaPositionX - this.props.label_X -  window.innerWidth * 0.25;
            let x_resize = (this.state.label_dim.width /this.props.label_Width);
            let x_pos_real = x_range * x_resize
            item.x = (Math.round(x_pos_real * 100) / 100).toFixed(1)

            let y_range = item.top+item.deltaPositionY-this.props.label_Y-window.innerWidth * 0.095;

            let y_resize = (this.state.label_dim.height /this.props.label_Height);
            let y_pos_real = y_range * y_resize
            item.y  = (Math.round(y_pos_real * 100) / 100).toFixed(1)



        }
        if (item.oldWidth !== window.innerWidth) {

            this.resetFocusAll()

            let x_pos = 0
            let y_pos = 0
            let bh = 0

            if (item.x === 0) {
                let x_range = item.left +item.deltaPositionX - this.props.label_X -  window.innerWidth * 0.25;
                let x_resize = (this.state.label_dim.width /this.props.label_Width);
                let x_pos_real = x_range * x_resize
                x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                item.x = x_pos

            }
            else {
                x_pos = item.x

            }
            let x_resize = (this.state.label_dim.width / this.props.label_Width);
            let x_range = x_pos / x_resize
            item.left = x_range - item.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;

            if (item.y === 0) {
                let y_range = item.top+item.deltaPositionY-this.props.label_Y-window.innerWidth * 0.095;

                let y_resize = (this.state.label_dim.height /this.props.label_Height);
                let y_pos_real = y_range * y_resize
                y_pos  = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                item.y = y_pos
            }
            else {
                y_pos = Number(item.y)

            }
            let y_resize = (this.state.label_dim.height /this.props.label_Height);
            let y_range = y_pos/ y_resize;

            item.top = Number(y_range  - item.deltaPositionY + this.props.label_Y +  window.innerWidth* 0.095);
            if (item.barHeightMM === 0){
                bh = (item.barHeight * this.state.label_dim.height/this.props.label_Height).toFixed(2)
                item.barHeightMM = bh

            }
            else {
                bh = item.barHeightMM

            }

            item.barHeight = Number((this.props.label_Height * Number(bh)) / this.state.label_dim.height)
            item.barWidth = ((this.props.label_Width * 0.13)/this.state.label_dim.width)

            item.oldWidth = window.innerWidth
        }

        if (document.getElementById('bcd'+index)){


            let hx = document.getElementById('bcd'+index).offsetHeight+item.top+item.deltaPositionY
            let h_y = item.top+item.deltaPositionY
            let lb_h = this.props.label_Y +this.props.label_Height +  window.innerWidth* 0.095
            let lb_y = this.props.label_Y +  window.innerWidth* 0.095

            let lb_x = this.props.label_X + window.innerWidth * 0.25

            let h_x = item.left+item.deltaPositionX
            let wx = document.getElementById('bcd'+index).offsetWidth+item.left+item.deltaPositionX


            if (hx >lb_h || h_y < lb_y || h_x < lb_x || wx > (lb_x+this.props.label_Width)){
                brd = true
            }
        }

        let txr = document.getElementById('bcd'+index) ? document.getElementById('bcd'+index): null



        return (
            <Draggable key={'bcd'+index+item.resetTransform}
             bounds={{left:(txr !== null ? -(txr.offsetLeft-window.innerWidth*0.25):-this.props.label_X),
             top: (txr !== null ? (window.innerWidth * 0.095-item.top) :(-this.props.label_Y-this.props.label_Height)),
             right: txr !== null ? window.innerWidth*0.75-txr.offsetLeft-txr.offsetWidth: window.innerWidth*0.5-this.props.label_X,
             bottom: txr !== null ? window.innerHeight*0.9-item.top:(window.innerHeight*0.9)-(window.innerWidth * 0.095+this.props.label_Y+this.props.label_Height)}}

                       onDrag={this.handleDragBarcode} onStart={this.onStartBarcode} onStop={this.onStopBarcode}
                enableUserSelectHack={false}  indexValue={item.index}
                       disabled={item.lock || (item.transform !== null && item.transform !== 'rotate(0deg)')}
              >
                <div
                    style={{zIndex: item.zIndex,position:'absolute',border: item.focus && brd ? '1px solid red' : item.focus ? '1px solid #0DA4EB':'none',left: item.left,
                    // height: item.barHeight ,
                        top: item.top,
                        backgroundColor:item.backgroundColor,
                        opacity:  item.emptyPrint ? 0:1,
                        transform: item.transform,
                        display:'flex',
                        padding:'1.8px',

                    'WebkitTransform': item.transform,
                    'MozTransform': item.transform,
                    'msTransform': item.transform,
                    transformOrigin:'0 0'
                }} id={'bcd'+index}
                     onClick={(e)=>this.clickBarcode(e, index)}
                >
                    {
                        item.value.length > 0 ?
                            item.qrCode ?
                                <QRCode value={item.value} size={item.barHeight} bgColor={item.backgroundColor}
                                        fgColor={item.color}/>
                                :
                                item.barcodeType === 'EAN13' && item.value.length === 13 && validbarcode(item.value) ||
                                item.barcodeType === 'EAN8' && item.value.length === 8 && validbarcode(item.value) ||
                                (item.barcodeType !== 'EAN13' && item.barcodeType !== 'EAN8') ?

                                    <Barcode value={item.value} format={item.barcodeType} background={item.backgroundColor}
                                         lineColor={item.color} flat={true} margin={0}
                                         width={item.barWidth}  displayValue={false} height={item.barHeight}/> :

                                    <Barcode value={'123456'} format={'CODE128'} background={item.backgroundColor}
                                             lineColor={item.color} margin={0}
                                             width={item.barWidth}  displayValue={false} height={item.barHeight}/>
                        :
                            null
                    }

                </div>
            </Draggable>
        )
    };
    isNumeric = (str)=> {
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }
   getWidthByXdimAndDPI = (dpi, xdim) => {
        if (dpi === "200 dpi") {
            const width200DPI = {
                XS: 0.35,
                S: 0.7,
                M: 1.05,
                L: 1.4,
                XL: 1.85,
                XXL: 2.1,
            };
            return width200DPI[xdim];
        }
        if (dpi === "300 dpi") {
            const width300DPI = {
                XS: 0.24,
                S: 0.48,
                M: 0.72,
                L: 0.96,
                XL: 1.2,
                XXL: 1.44,
            };
            return width300DPI[xdim];
        }
    };
    getBarWidth = (btype,val,xdim,dpi) =>{
        let bW = this.getWidthByXdimAndDPI(dpi, xdim)



            let tp = btype

            if (tp.includes('code128')) {
                tp = 'code128'
            }
            if (tp === 'upc') {
                tp = 'upca'
            }
            var x = document.createElement("CANVAS");

            let canvas;

            try {
                canvas = bwipjs.toCanvas(x, {
                    bcid: tp,       // Barcode type
                    text: val,    // Text to encode
                    scale: 1,               // 3x scaling factor
                    height: 10,              // Bar height, in millimeters
                    includetext: false,            // Show human-readable text
                })


            } catch (err) {
            }

            if (canvas) {
                let rd = (canvas.width * bW) * 0.352778
                let ry = (this.props.label_Width * rd) / this.state.label_dim.width
                return ry


            }


        return null

}
    getBarWidthLoad = (btype,val,xdim,gc_w,dpi) =>{
        let bW = this.getWidthByXdimAndDPI(dpi, xdim)

        let tp = btype

            if (tp.includes('code128')) {
                tp = 'code128'
            }
            if (tp === 'upc') {
                tp = 'upca'
            }
            var x = document.createElement("CANVAS");

            let canvas;

            try {
                canvas = bwipjs.toCanvas(x, {
                    bcid: tp,       // Barcode type
                    text: val,    // Text to encode
                    scale: 1,               // 3x scaling factor
                    height: 10,              // Bar height, in millimeters
                    includetext: false,            // Show human-readable text
                })


            } catch (err) {
            }

            if (canvas) {
                let rd = (canvas.width * bW) * 0.352778
                let ry = (gc_w * rd) / this.state.label_dim.width
                return ry



        }

        return null

    }
    onStartBarcode = (e) => {
        if (this.props.Left_State !== "LH3"){
            this.props.dispatch(LH3());

        }
        if (this.state.chosenBarcodeIndex >= 0 && e.currentTarget.id !== this.state.chosenBarcodeIndex) {

            let fx1 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

            fx1.focus = false;

            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx1);
            let cx1 = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcode: cx1,

                }
            })

            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if(fx2){
            fx2.focus = false;

            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx2 = this.state.barcodeObject.barcodeText;

            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcodeText: cx2,

                }
            })
        }
        }
        let p = e.currentTarget.id.substring(3,e.currentTarget.id.length)
        this.setState({
            chosenBarcodeIndex: Number(p)
        })

        e.target.style.cursor = 'movee';

    };

    onStopBarcode = (e) => {

        e.target.style.cursor = 'move';
    };
    onStartImage = (e) => {
        if (this.props.Left_State !== "LH4") {
            this.props.dispatch(LH4());
        }
        let p = e.currentTarget.id.substring(3,e.currentTarget.id.length)
        this.setState({
            chosenImageIndex: Number(p)
        })

        e.target.style.cursor = 'move';
    }
    onStopImage = (e) => {

        e.target.style.cursor = 'move';
    };
    handleDragImage = (e, ui) => {

        if (this.state.chosenImageIndex >= 0) {
            if (this.props.Left_State !== "LH4") {
                this.props.dispatch(LH4());

            }
            let fx2 = this.state.image_array[this.state.chosenImageIndex];
            fx2.deltaPositionX = fx2.deltaPositionX + ui.deltaX;
            fx2.deltaPositionY = fx2.deltaPositionY + ui.deltaY

            fx2.xPos = fx2.deltaPositionX + ui.deltaX;
            fx2.yPos = fx2.deltaPositionY + ui.deltaY;

            let x_range = '';

            x_range = fx2.left + fx2.deltaPositionX + ui.deltaX - this.props.label_X - window.innerWidth * 0.25;

            let x_resize = (this.state.label_dim.width / this.props.label_Width);
            let x_pos_real = x_range * x_resize
            let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

            let y_range = fx2.top+fx2.deltaPositionY + ui.deltaY-((window.innerWidth * 0.095)+this.props.label_Y);

            let y_resize = this.state.label_dim.height / this.props.label_Height;
            let y_pos_real = y_range * y_resize
            let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)


            this.setState({
                imageXPos: x_pos,
                imageYPos: y_pos,
            });
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fx2);
            let cx = this.state.image_array;
            this.setState({
                image_array: cx,

            })
        }
    }
    onStartShape = (e) => {
        if (this.props.Left_State !== "LH5") {
            this.props.dispatch(LH5());
        }
        let p = e.currentTarget.id.substring(5,e.currentTarget.id.length)
        this.setState({
            chosenShapeIndex: Number(p)
        })

        e.target.style.cursor = 'move';
    }
    onStopShape = (e) => {

        e.target.style.cursor = 'move';
    };
    handleDragShape = (e, ui) => {

        if (this.state.chosenShapeIndex >= 0) {
            if (this.props.Left_State !== "LH5") {
                this.props.dispatch(LH5());

            }
            let fx2 = this.state.shape_array[this.state.chosenShapeIndex];
            fx2.deltaPositionX = fx2.deltaPositionX + ui.deltaX;
            fx2.deltaPositionY = fx2.deltaPositionY + ui.deltaY

            fx2.xPos = fx2.deltaPositionX + ui.deltaX;
            fx2.yPos = fx2.deltaPositionY + ui.deltaY;

            let x_range = '';

            x_range = fx2.left + fx2.deltaPositionX + ui.deltaX - this.props.label_X - window.innerWidth * 0.25;

            let x_resize = (this.state.label_dim.width / this.props.label_Width);
            let x_pos_real = x_range * x_resize
            let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

            let y_range = fx2.top+fx2.deltaPositionY + ui.deltaY-((window.innerWidth * 0.095)+this.props.label_Y);

            let y_resize = this.state.label_dim.height / this.props.label_Height;
            let y_pos_real = y_range * y_resize
            let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)


            this.setState({
                shapeXPos: x_pos,
                shapeYPos: y_pos,
            });
            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx2);
            let cx = this.state.shape_array;
            this.setState({
                shape_array: cx,

            })
        }
    }


    barcodetextStart = (item,index, s) => {


        let decoration_style = ()=>this.checkDecoration(item);

        let rsz_dz = 11.057374338624339 / this.state.label_dim.width ;

        let ratio_dz = this.props.label_Width * rsz_dz;

        let scale_f = ratio_dz/ 32.724609375;

        let bx = item.fontSize / item.scalingRatio;

        let gx = bx * scale_f;

        let brd = false

        let t_left = 0

        var width = require('text-width');
        let wt = 'normal';
        var w = width(item.prefix + item.text + item.suffix, {
            family: item.fontFamily,
            size: gx,
            weight: wt
        });
        if (item.oldWidth === window.innerWidth){
            if (item.textAlign === 'left' || item.textAlign === 'center') {

                let ranger = item.left + item.deltaPositionX - this.props.label_X - item.oldWidth * 0.25;

                t_left = item.left+item.deltaPositionX


                let resizer = (this.state.label_dim.width / this.props.label_Width);
                let x_pos_real = ranger * resizer
                item.x = (Math.round(x_pos_real * 100) / 100).toFixed(1)
            }
            if (item.textAlign === 'right' && !item.multiline){
                // let ry = window.innerWidth - item.right - w;
                // t_left = ry+item.deltaPositionX
                // let x_range = ry + item.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                //
                // let x_resize = (this.state.label_dim.width / this.props.label_Width);
                // let x_pos_real = x_range * x_resize
                // item.x = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                if (document.getElementById('btxtx'+index)){
                    let x_width = document.getElementById('btxtx'+index).offsetWidth  / item.oldWidth
                    w = window.innerWidth *x_width
                }

                let ry = window.innerWidth - item.right - w;
                t_left = ry+item.deltaPositionX

                let x_range = ry + item.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;

                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_pos_real = x_range * x_resize
                item.x = (Math.round(x_pos_real * 100) / 100).toFixed(1)


            }


            let y_ranger = item.top + item.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

            let y_resizer = (this.state.label_dim.height / this.props.label_Height);
            let y_pos_real = y_ranger * y_resizer
            item.y = (Math.round(y_pos_real * 100) / 100).toFixed(1)

            let rf = item.fontSize;
            let rf_mm = (Number(rf) * this.state.label_dim.height)/this.props.label_Height
            item.fontSizeMM = rf_mm /  0.352778;

        }

        if (item.oldWidth !== window.innerWidth){
            this.resetFocusAll()

            let x_pos = 0
            let y_pos = 0



            if (item.textAlign === 'left') {
                if (item.x === 0) {
                    let ranger = item.left + item.deltaPositionX - this.props.label_X - item.oldWidth * 0.25;
                    let resizer = (this.state.label_dim.width / this.props.label_Width);
                    let x_pos_real = ranger * resizer
                    x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                    item.x = x_pos
                }
                else {
                    x_pos = item.x

                }

                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_range = x_pos / x_resize
                item.oldWidth = window.innerWidth
                item.left = x_range - item.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;

            }
            if (item.textAlign === 'center') {

                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_range = item.x / x_resize
                if (document.getElementById('btxtx' + index)) {

                let x_width = document.getElementById('btxtx' + index).offsetWidth / item.oldWidth
                item.oldWidth = window.innerWidth
                item.width = window.innerWidth * x_width

                item.left = x_range - item.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                item.right = window.innerWidth - (item.left + item.width)
            }}

            if (item.textAlign === 'right') {
                if (document.getElementById('btxtx'+index)) {
                    let x_width = document.getElementById('btxtx' + index).offsetWidth / item.oldWidth
                    let itw = window.innerWidth * x_width

                    let x_resize = (this.state.label_dim.width / this.props.label_Width);
                    let x_range = item.x / x_resize

                    let t_left = x_range - item.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                    item.right = window.innerWidth - (t_left + itw)
                    item.oldWidth = window.innerWidth
                }
            }

            if (item.y === 0) {

                let y_ranger = item.top + item.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

                let y_resizer = (this.state.label_dim.height / this.props.label_Height);
                let y_pos_real = y_ranger * y_resizer
                y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                item.y = y_pos
            }
            else {
                y_pos = item.y
            }

            let y_resize = (this.state.label_dim.height /this.props.label_Height);
            let y_range = y_pos/ y_resize;

            item.top = y_range  - item.deltaPositionY + this.props.label_Y +  window.innerWidth* 0.095;

            let gx_mm = Number(item.fontSizeMM) * 0.352778
            item.fontSize = (this.props.label_Height * gx_mm)/this.state.label_dim.height


        }

        if (document.getElementById('btext'+index)){
            let hx = document.getElementById('btext'+index).offsetHeight+item.top+item.deltaPositionY
            let h_y = item.top+item.deltaPositionY

            let lb_h = this.props.label_Y +this.props.label_Height +  window.innerWidth* 0.095
            let lb_y = this.props.label_Y +  window.innerWidth* 0.095

            let lb_x = this.props.label_X + window.innerWidth * 0.25
            let wx = document.getElementById('btext'+index).offsetWidth+t_left


            if (lb_h < hx || h_y < lb_y || t_left < lb_x || wx > (lb_x+this.props.label_Width)){
                brd = true
            }
        }
        let txr = document.getElementById('btx'+index) ? document.getElementById('btx'+index): null

        return (
            <Draggable
                key={'btx'+index+item.resetTransform}
                bounds={{left:(txr !== null ? -(txr.offsetLeft-window.innerWidth*0.25):-this.props.label_X),
                    top: (txr !== null ? (window.innerWidth * 0.095-item.top) :(-this.props.label_Y-this.props.label_Height)),
                    right: txr !== null ? window.innerWidth*0.75-txr.offsetLeft-txr.offsetWidth: window.innerWidth*0.5-this.props.label_X,
                    bottom: txr !== null ? window.innerHeight*0.9-item.top:(window.innerHeight*0.9)-(window.innerWidth * 0.095+this.props.label_Y+this.props.label_Height)}}

                onDrag={this.handleDragBarcodeText} onStart={this.onStartBarcodeText} onStop={this.onStop}
                enableUserSelectHack={false}
                disabled={item.lock || (item.transform !== null && item.transform !== 'rotate(0deg)')}
            >
                <div id={'btext'+index}
                    style={{right: item.right, left: item.left,position:'absolute', fontSize: item.fontSize+'px' , top: item.top,
                        fontFamily: item.fontFamily,textDecoration: decoration_style(), textAlign: item.textAlign,
                        whiteSpace:item.multiline? null:'nowrap',
                        border: item.focus && brd ? '0.03px solid red' : item.focus && !item.outlineSelected ? '0.02px solid #0DA4EB': 'none',
                        borderRadius: '0px',
                        display: item.multiline? null:'flex',justifyContent: "center",
                        // outline: item.focus && item.outlineSelected  ? '#0DA4EB auto 0.02px':'none',
                        outline: item.focus && !item.outlineSelected && item.textAlign === 'center' ? item.text.length === 0 ? 'none' : '#0DA4EB auto 0.01px':item.focus && item.outlineSelected && !item.multiline  ? brd ? 'red auto 0.01px' : '#0DA4EB auto 0.02px': brd ? 'red auto 0.01px': 'none',

                        zIndex: item.zIndex, alignItems: "center",
                        color: item.textColor,
                        width: item.width,
                        backgroundColor:item.backgroundColor,
                        lineHeight:item.multilineHeight,
                        transform: item.transform,
                        'WebkitTransform': item.transform,
                        'MozTransform': item.transform,
                        'msTransform': item.transform,
                        transformOrigin:'0 0'
                    }}
                    spellCheck={false}

                    className="parent" key={'btext'+index}
                    suppressContentEditableWarning={true}
                    contentEditable={!item.dateStamp && !item.copyDataStatus}

                    onFocus={(e)=>this.onbarcodeTextFocus(e, index)}
                    onBlur={(e)=>this.offbarcodeTextFocusBlur(e,index)}
                    onMouseMove={(e)=>this.offbarcodeTextFocus(e,index)}
                    onMouseUp={(e)=>this.offbarcodeTextFocus(e,index)}
                    onKeyUp={(e)=>this.handleChangebarcodeText(e,index)}
                    onKeyDown={(e)=>this.handleChangeKeyDownbarcodeText(e,index)}
                    onMouseLeave={()=>this.checkIncrement()}

                    onClick={(e)=>this.clickbarcodeText(e, index)}
                    tabIndex="0">
                    <div id={'btxtx'+index}  suppressContentEditableWarning={true} >{item.prefix+item.text+item.suffix}</div>
                </div>
            </Draggable>
        )
    };
    imageStart = (item,index, s) => {

        let brd = false

        if (item.oldWidth === window.innerWidth){

            let ranger = item.left + item.deltaPositionX - this.props.label_X - item.oldWidth * 0.25;


            let resizer = (this.state.label_dim.width / this.props.label_Width);
            let x_pos_real = ranger * resizer
            item.x = (Math.round(x_pos_real * 100) / 100).toFixed(1)


            let y_ranger = item.top + item.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

            let y_resizer = (this.state.label_dim.height / this.props.label_Height);
            let y_pos_real = y_ranger * y_resizer
            item.y = (Math.round(y_pos_real * 100) / 100).toFixed(1)


        }
        if (item.oldWidth !== window.innerWidth) {
            this.resetFocusAll()
            let x_pos = 0
            let y_pos = 0



            // if (item.x === 0) {
            //     let ranger = item.left + item.deltaPositionX - this.props.label_X - item.oldWidth * 0.25;
            //     let resizer = (this.state.label_dim.width / this.props.label_Width);
            //     let x_pos_real = ranger * resizer
            //     x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
            //     item.x = x_pos
            // }
            // else {
                x_pos = item.x
            //
            // }
            //
            if (!this.state.fileLoading){
                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_range = x_pos / x_resize
                item.left = x_range - item.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;

            }
            // if (item.y === 0) {
            //
            //     let y_ranger = item.top + item.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;
            //
            //     let y_resizer = (this.state.label_dim.height / this.props.label_Height);
            //     let y_pos_real = y_ranger * y_resizer
            //     y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
            //     item.y = y_pos
            // }
            // else {
                y_pos = item.y
            // }
            //
            //
            if (!this.state.fileLoading) {
                let y_resize = (this.state.label_dim.height /this.props.label_Height);
                let y_range = y_pos/ y_resize;
                item.top = y_range - item.deltaPositionY + this.props.label_Y + window.innerWidth * 0.095;
            }
            //
            // if (item.rszWidth === 0){
            //     // item.rszWidth = item.width/window.innerWidth
                item.rszWidth = item.width/item.oldWidth
            // }
            // if (item.rszHeight === 0){
            //     // item.rszHeight = item.height/window.innerWidth
                item.rszHeight = item.height/item.oldWidth
            // }

            if (this.state.fileLoading){
                if (!item.stretch){
                    item.width = item.rszWidth*window.innerWidth
                    item.height = item.rszHeight*window.innerWidth

                }

            }
            else {

                item.height = item.rszHeight*window.innerWidth
                 item.width = item.rszWidth*window.innerWidth
            }


            item.oldWidth = window.innerWidth


        }
        if (document.getElementById('img'+index)){
            let hx = document.getElementById('img'+index).offsetHeight+item.top+item.deltaPositionY
            let h_y = item.top+item.deltaPositionY
            let lb_h = this.props.label_Y +this.props.label_Height +  window.innerWidth* 0.095
            let lb_y = this.props.label_Y +  window.innerWidth* 0.095

            let lb_x = this.props.label_X + window.innerWidth * 0.25

            let h_x = item.left+item.deltaPositionX
            let wx = document.getElementById('img'+index).offsetWidth+item.left+item.deltaPositionX


            if (hx >lb_h || h_y < lb_y || h_x < lb_x || wx > (lb_x+this.props.label_Width)){
                brd = true
            }
        }
        let txr = document.getElementById('img'+index) ? document.getElementById('img'+index): null

        return (
            <Draggable key={'img'+index+item.resetTransform}
                bounds={{left:(txr !== null ? -(txr.offsetLeft-window.innerWidth*0.25):-this.props.label_X),
                top: (txr !== null ? (window.innerWidth * 0.095-item.top) :(-this.props.label_Y-this.props.label_Height)),
                right: txr !== null ? window.innerWidth*0.75-txr.offsetLeft-txr.offsetWidth: window.innerWidth*0.5-this.props.label_X,
                bottom: txr !== null ? window.innerHeight*0.9-item.top:(window.innerHeight*0.9)-(window.innerWidth * 0.095+this.props.label_Y+this.props.label_Height)}}

                       onDrag={this.handleDragImage} onStart={this.onStartImage} onStop={this.onStopImage}
                enableUserSelectHack={false}  indexValue={item.index}
                disabled={item.lock || (item.transform !== null && item.transform !== 'rotate(0deg)')}
            >
                <div id={'img'+index}
                    style={{zIndex: item.zIndex,position:'absolute',left: item.left,
                    height: null  ,top:item.top,border: item.focus && brd ? '0.03px solid red' : item.focus ? '0.02px solid #0DA4EB': 'none',
                    transform: item.transform,
                    'WebkitTransform': item.transform,
                    'MozTransform': item.transform,
                    'msTransform': item.transform,
                    transformOrigin:'0 0'}}
                    onClick={(e)=>this.clickImage(e, index)}>

                    <img
                        onLoad={(e)=>item.width === null && item.height === null ? this.onImgLoad(e,index,item): null}
                        src={item.src} alt="upload Image"
                        width={item.width}
                         height={item.height}

                    />

                </div>
            </Draggable>
        )
    };
    shapeStart = (item,index, s) => {

        let brd = false
        let stg_width = item.width+(2*item.strokeWidth)
        let stg_height = item.height+(2*item.strokeWidth)
        if (item.type === 'circle' && !item.stretch){
            stg_width = item.radius *2+2*item.strokeWidth
            stg_height = item.radius *2+2*item.strokeWidth
        }
        if (item.type === 'circle' && item.stretch){
            stg_width = item.radiusX *2+2*item.strokeWidth
            stg_height = item.radiusY *2+2*item.strokeWidth
        }
        if (item.oldWidth === window.innerWidth) {
            let x_range = item.left + item.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
            let x_resize = (this.state.label_dim.width / this.props.label_Width);
            let x_pos_real = x_range * x_resize
            item.x = (Math.round(x_pos_real * 100) / 100).toFixed(1)


            let y_range = item.top + item.deltaPositionY - ((window.innerWidth * 0.095) + this.props.label_Y);
            let y_resize = (this.state.label_dim.height / this.props.label_Height);
            let y_pos_real = y_range * y_resize
            item.y = (Math.round(y_pos_real * 100) / 100).toFixed(1)

            item.rszWidth = (item.width * this.state.label_dim.width/this.props.label_Width).toFixed(1)

            item.rszHeight = (item.height * this.state.label_dim.height/this.props.label_Height).toFixed(1)
        }
        if (item.oldWidth !== window.innerWidth) {
            this.resetFocusAll()

            let x_pos = 0
            let y_pos = 0
            let xw = 0
            let yh = 0

            if (item.x === 0){
                let x_range = item.left + item.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_pos_real = x_range * x_resize
                item.x = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                x_pos = item.x
            }else {
                x_pos = item.x

            }
            let x_resize = (this.state.label_dim.width / this.props.label_Width);
            let x_range = x_pos / x_resize
           item.left = x_range - item.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;

            if (item.y === 0){
                let y_range = item.top + item.deltaPositionY - ((window.innerWidth * 0.095) + this.props.label_Y);
                let y_resize = (this.state.label_dim.height / this.props.label_Height);
                let y_pos_real = y_range * y_resize
                item.y = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                y_pos = item.y
            }else {

                y_pos = item.y

            }
            let y_resize = (this.state.label_dim.height /this.props.label_Height);
            let y_range =y_pos/ y_resize;
            item.top = y_range  - item.deltaPositionY + (window.innerWidth * 0.095)+this.props.label_Y;

            if (item.rszWidth === 0){
                item.rszWidth = (item.width * this.state.label_dim.width/this.props.label_Width).toFixed(1)
                xw = item.rszWidth

            }else{
                 xw = item.rszWidth
            }
            if (item.rszHeight === 0){

               item.rszHeight = (item.height * this.state.label_dim.height/this.props.label_Height).toFixed(1)
                yh = item.rszHeight
            }
            else{
                yh = item.rszHeight
            }

            if (item.type === 'line'){
                item.width  = xw * this.props.label_Height/this.state.label_dim.height;
            }
            if (item.type === 'square'){
                if (item.stretch){
                    item.width  = xw * this.props.label_Width/this.state.label_dim.width;
                    item.height  = yh * this.props.label_Height/this.state.label_dim.height;

                }
                else {
                    item.width  = xw * this.props.label_Width/this.state.label_dim.width;
                    item.height = xw * this.props.label_Width/this.state.label_dim.width;

                }

            }
            if (item.type === 'circle'){
                if (item.stretch){
                    item.radiusX  = xw * this.props.label_Width/this.state.label_dim.width/2;
                    item.width = (xw * this.props.label_Width/this.state.label_dim.width/2) * 2;
                    item.radiusY  = yh * this.props.label_Height/this.state.label_dim.height/2;
                    item.height = (yh * this.props.label_Height/this.state.label_dim.height/2) * 2;

                }
                else {
                    item.radius  = xw * this.props.label_Width/this.state.label_dim.width/2;
                    item.width = (xw * this.props.label_Width/this.state.label_dim.width/2) * 2;
                    item.height = (xw * this.props.label_Width/this.state.label_dim.width/2) * 2;

                }

            }


            item.oldWidth = window.innerWidth

        }
        if (document.getElementById('shape'+index)){
            let hx = document.getElementById('shape'+index).offsetHeight+item.top+item.deltaPositionY
            let h_y = item.top+item.deltaPositionY
            let lb_h = this.props.label_Y +this.props.label_Height +  window.innerWidth* 0.095
            let lb_y = this.props.label_Y +  window.innerWidth* 0.095

            let lb_x = this.props.label_X + window.innerWidth * 0.25

            let h_x = item.left+item.deltaPositionX
            let wx = document.getElementById('shape'+index).offsetWidth+item.left+item.deltaPositionX


            if (hx >lb_h || h_y < lb_y || h_x < lb_x || wx > (lb_x+this.props.label_Width)){
                brd = true
            }
        }
        let txr = document.getElementById('shp'+index) ? document.getElementById('shp'+index): null

        return (
            <Draggable key={'shp'+index+item.resetTransform}
             bounds={{left:(txr !== null ? -(txr.offsetLeft-window.innerWidth*0.25):-this.props.label_X),
             top: (txr !== null ? (window.innerWidth * 0.095-item.top) :(-this.props.label_Y-this.props.label_Height)),
             right: txr !== null ? window.innerWidth*0.75-txr.offsetLeft-txr.offsetWidth: window.innerWidth*0.5-this.props.label_X,
             bottom: txr !== null ? window.innerHeight*0.9-item.top:(window.innerHeight*0.9)-(window.innerWidth * 0.095+this.props.label_Y+this.props.label_Height)}}

                       onDrag={this.handleDragShape} onStart={this.onStartShape} onStop={this.onStopShape}
                enableUserSelectHack={false}  indexValue={item.index}
                disabled={item.lock || (item.transform !== null && item.transform !== 'rotate(0deg)')}
            >
                <div style={{zIndex: 1,position:'absolute',left: item.left,
                    height: item.type === 'line' ? item.strokeWidth*0.5 : null  ,top:item.top,border: item.focus && brd ? '0.03px solid red'   :item.focus ? '0.02px solid #0DA4EB': 'none',
                    borderRadius: item.type === 'circle' && !item.stretch? '50%': 0,
                    transform: item.transform,
                    'WebkitTransform': item.transform,
                    'MozTransform': item.transform,
                    'msTransform': item.transform,
                    transformOrigin:'0 0'}} id={'shape'+index} onClick={(e)=>this.clickShape(e, index)}>

                    <Stage width={item.type === 'line' ? item.width : stg_width} height={item.type === 'line' ? item.strokeWidth : stg_height} >
                        <Layer>
                            { item.type === 'square'?
                                <Rect
                                    x={item.strokeWidth/2}
                                    y={item.strokeWidth/2}
                                    width={item.width}
                                    height={item.height}
                                    fill={item.fill}
                                    stroke={item.stroke}
                                    strokeWidth={item.strokeWidth}
                                /> : null
                            }
                            { item.type === 'circle' && !item.stretch?

                                <Circle x={item.radius+(item.strokeWidth/2)} y={item.radius+(item.strokeWidth/2)}
                                        fill={item.fill}
                                        radius={item.radius} stroke={item.stroke} strokeWidth={item.strokeWidth} /> : null

                            }
                            { item.type === 'circle' && item.stretch?

                                <Ellipse x={item.radiusX+(item.strokeWidth/2)} y={item.radiusY+(item.strokeWidth/2)}
                                        radiusX={item.radiusX}  radiusY={item.radiusY} stroke={item.stroke} strokeWidth={item.strokeWidth} /> : null

                            }
                            { item.type === 'line'?
                                <Line
                                    x={0}
                                    y={0}
                                    points={[0, 0, item.width,0]}
                                    height={item.strokeWidth}

                                    stroke= {item.stroke}
                                    strokeWidth={item.strokeWidth}

                                /> : null
                            }
                        </Layer>
                    </Stage>

                </div>
            </Draggable>
        )
    };
    onImgLoad({target:img},i,item) {
        let fx_img = this.state.image_array[i];

        fx_img.naturalWidth = img.naturalWidth;
        fx_img.naturalHeight = img.naturalHeight;
        let aspect = img.naturalWidth / img.naturalHeight;
        if (img.naturalWidth <= this.props.label_Width && img.naturalHeight <= this.props.label_Height ){
            aspect = img.naturalWidth / img.naturalHeight;
            fx_img.aspectRatio = aspect;
            fx_img.width = (img.naturalWidth-2).toFixed(1);
            fx_img.height = (img.naturalHeight-2).toFixed(1);
            this.setState({img_dim:{height:img.naturalHeight-2,
                    width:img.naturalWidth-2}});
        }
        else {

            if (img.naturalWidth > img.naturalHeight) {

                aspect = img.naturalWidth / img.naturalHeight;
                fx_img.aspectRatio = aspect
                if (this.props.label_Width < this.props.label_Height) {

                    fx_img.width = (this.props.label_Width - 2).toFixed(1);
                    fx_img.height = (this.props.label_Width / aspect - 2).toFixed(1);
                    this.setState({
                        img_dim: {
                            height: this.props.label_Width / aspect - 2,
                            width: this.props.label_Width - 2
                        }
                    });
                }
                if (this.props.label_Width > this.props.label_Height) {

                    fx_img.width = (this.props.label_Height * aspect- 2).toFixed(1);
                    fx_img.height = (this.props.label_Height - 2).toFixed(1);
                    this.setState({
                        img_dim: {
                            height: this.props.label_Height - 2,
                            width: this.props.label_Height  * aspect- 2
                        }
                    });
                }
                if (this.props.label_Width === this.props.label_Height) {

                    fx_img.width = (this.props.label_Width - 2).toFixed(1);
                    fx_img.height = (this.props.label_Width/ aspect - 2).toFixed(1);
                    this.setState({
                        img_dim: {
                            height: this.props.label_Width/ aspect - 2,
                            width: this.props.label_Width - 2
                        }
                    });
                }


            }
            if (img.naturalWidth < img.naturalHeight) {

                aspect = img.naturalHeight / img.naturalWidth;
                fx_img.aspectRatio = aspect
                if (this.props.label_Width > this.props.label_Height) {
                    fx_img.width = (this.props.label_Height / aspect - 2).toFixed(1);
                    fx_img.height = (this.props.label_Height - 2).toFixed(1);
                    this.setState({
                        img_dim: {
                            height: this.props.label_Height - 2,
                            width: this.props.label_Height / aspect - 2
                        }
                    });
                }

                if (this.props.label_Width < this.props.label_Height) {
                    fx_img.width = (this.props.label_Width  - 2).toFixed(1);
                    fx_img.height = (this.props.label_Width * aspect- 2).toFixed(1);
                    this.setState({
                        img_dim: {
                            height: this.props.label_Width * aspect- 2,
                            width: this.props.label_Width  - 2
                        }
                    });
                }
                if (this.props.label_Width === this.props.label_Height) {
                    fx_img.width = (this.props.label_Width  - 2).toFixed(1);
                    fx_img.height = (this.props.label_Width * aspect- 2).toFixed(1);
                    this.setState({
                        img_dim: {
                            height: this.props.label_Width * aspect- 2,
                            width: this.props.label_Width  - 2
                        }
                    });
                }

                }
            if (img.naturalWidth === img.naturalHeight) {

                aspect = img.naturalHeight / img.naturalWidth;
                if (this.props.label_Width > this.props.label_Height  || this.props.label_Height === this.props.label_Width) {

                    fx_img.width = (this.props.label_Height-2).toFixed(1);
                    fx_img.height = (this.props.label_Height - 2).toFixed(1);
                    fx_img.aspectRatio = aspect
                    this.setState({
                        img_dim: {
                            height: this.props.label_Height- 2,
                            width: this.props.label_Height - 2
                        }
                    });
                }
                if (this.props.label_Width < this.props.label_Height) {

                    fx_img.width = (this.props.label_Width - 2).toFixed(1);
                    fx_img.height = (this.props.label_Width - 2).toFixed(1);
                    fx_img.aspectRatio = aspect
                    this.setState({
                        img_dim: {
                            height: this.props.label_Width - 2,
                            width: this.props.label_Width - 2
                        }
                    });
                }
            }
        }
        this.state.image_array.splice(i, 1, fx_img);
        let cx = this.state.image_array;
        this.setState({
            image_array: cx,
        })
    }


    onClickedSelectedText = () =>{
        if (this.state.chosenTextIndex >= 0) {

            let fx2 = this.state.text[this.state.chosenTextIndex];

            if ((fx2.transform !== null && fx2.transform !== 'rotate(0deg)')){
                    this.onLeftTextAlignChange()
                }
            if (fx2.text === '(EMPTY)'){
                fx2.text = ''
                this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                let cx = this.state.text;
                this.setState({
                    text: cx,
                    showText:'',
                })

            }
            // if (fx2.textAlign === 'center') {
            //     if (fx2.right === null){
            //         let dxx = document.getElementById('text'+this.state.chosenTextIndex)
            //         if(dxx){
            //             fx2.right = window.innerWidth - (dxx.offsetLeft+dxx.offsetWidth);
            //         }
            //
            //     }
            //     if (fx2.outlineSelected) {
            //         fx2.outlineSelected = false;
            //     }
            //
            //     this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
            //     let cx = this.state.text;
            //     this.setState({
            //         text: cx,
            //     })
            // }
        }
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            // if (fx2.textAlign === 'right' && fx2.right === null && fx2.width === null){
            //     this.onRightTextAlignChangeRotation()
            //
            // }
            if ((fx2.transform !== null && fx2.transform !== 'rotate(0deg)')){
                this.onLeftTextAlignChange()
            }
            if (fx2.text === '(EMPTY)'){
                fx2.text = ''
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject:{
                        ...this.state.barcodeObject,
                        barcodeText: cx,
                    },
                    barcodeValue:'',
                })

            }
            // if (fx2.textAlign === 'center') {
            //     if (fx2.right === null){
            //         let dxx = document.getElementById('btext'+this.state.chosenBarcodeIndex)
            //         if(dxx){
            //             fx2.right = window.innerWidth - (dxx.offsetLeft+dxx.offsetWidth);
            //         }
            //
            //     }
            //     if (fx2.outlineSelected) {
            //         fx2.outlineSelected = false;
            //     }
            //
            //     this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
            //     let cx = this.state.barcodeObject.barcodeText;
            //     this.setState({
            //         barcodeObject:{
            //             ...this.state.barcodeObject,
            //             barcodeText: cx,
            //         }
            //     })
            // }
        }
    };
    onKeyUpPrefixText = (e) =>{
        if (this.state.chosenTextIndex >= 0) {

            let fx = this.state.text[this.state.chosenTextIndex];

            this.setState({prefix: e.target.value})
            fx.prefix = e.target.value;
            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })
        }
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

            this.setState({prefix: e.target.value})
            fx.prefix = e.target.value;
            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                }
            })
        }

    };
    onKeyUpSuffixText = (e) =>{
        if (this.state.chosenTextIndex >= 0) {

            let fx = this.state.text[this.state.chosenTextIndex];

            this.setState({suffix: e.target.value})
            fx.suffix = e.target.value;
            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })
        }
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

            this.setState({suffix: e.target.value})
            fx.suffix = e.target.value;
            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                }
            })
        }
    };

    onKeyUpSelectedText = (e) =>{
        if (this.state.chosenTextIndex >= 0) {
            let x = e.target.value
            let fx = this.state.text[this.state.chosenTextIndex];
            this.setState({showText: x})
            fx.text = e.target.value;
            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let x = e.target.value
            let fx = this.state.text[this.state.chosenBarcodeIndex];
            this.setState({showText: x})
            fx.text = e.target.value;
            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                }
            })
        }
    };
    onKeyDownPrefixText = (e, i) => {
        if (this.state.chosenTextIndex >= 0) {
            let fx = this.state.text[this.state.chosenTextIndex];
            // if (fx.textAlign === 'center') {
            //     var width = require('text-width');
            //     let wt = 'normal';
            //     if (fx.bold) {
            //         wt = 'bold';
            //     }
            //     var w = width(e.key.toString(), {
            //         family: fx.fontFamily,
            //         size: fx.fontSize,
            //         weight: wt
            //     });
            //     console.log('key width: ',w)
            //
            //     fx.left -= w / 2;
            //     fx.right -= w / 2;
            //
            //     if (fx.outlineSelected) {
            //         fx.outlineSelected = false;
            //     }
            //     this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            //     let cx = this.state.text;
            //     this.setState({
            //         text: cx,
            //     })
            // }
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            // if (fx.textAlign === 'center') {
            //     var width = require('text-width');
            //     let wt = 'normal';
            //     if (fx.bold) {
            //         wt = 'bold';
            //     }
            //     var w = width(e.key.toString(), {
            //         family: fx.fontFamily,
            //         size: fx.fontSize,
            //         weight: wt
            //     });
            //
            //     fx.left -= w / 2;
            //     fx.right -= w / 2;
            //
            //     if (fx.outlineSelected) {
            //         fx.outlineSelected = false;
            //     }
            //     this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            //     let cx = this.state.barcodeObject.barcodeText;
            //     this.setState({
            //         barcodeObject:{
            //             ...this.state.barcodeObject,
            //             barcodeText: cx,
            //         }
            //     })
            // }
        }

    };
    onKeyDownSuffixText = (e, i) => {
        if (this.state.chosenTextIndex >= 0) {
            let fx = this.state.text[this.state.chosenTextIndex];
            // if (fx.textAlign === 'center') {
            //     var width = require('text-width');
            //     let wt = 'normal';
            //     if (fx.bold) {
            //         wt = 'bold';
            //     }
            //     var w = width(e.key.toString(), {
            //         family: fx.fontFamily,
            //         size: fx.fontSize,
            //         weight: wt
            //     });
            //
            //     fx.left -= w / 2;
            //     fx.right -= w / 2;
            //
            //     if (fx.outlineSelected) {
            //         fx.outlineSelected = false;
            //     }
            //     this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            //     let cx = this.state.text;
            //     this.setState({
            //         text: cx,
            //     })
            // }
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            // if (fx.textAlign === 'center') {
            //     var width = require('text-width');
            //     let wt = 'normal';
            //     if (fx.bold) {
            //         wt = 'bold';
            //     }
            //     var w = width(e.key.toString(), {
            //         family: fx.fontFamily,
            //         size: fx.fontSize,
            //         weight: wt
            //     });
            //
            //     fx.left -= w / 2;
            //     fx.right -= w / 2;
            //
            //     if (fx.outlineSelected) {
            //         fx.outlineSelected = false;
            //     }
            //     this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            //     let cx = this.state.barcodeObject.barcodeText;
            //     this.setState({
            //         barcodeObject:{
            //             ...this.state.barcodeObject,
            //             barcodeText: cx,
            //         }
            //     })
            // }
        }

    };
    onKeyDownSelectedBarcodeText = (e, i) => {
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx1 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

        }
    }

    onKeyDownSelectedText = (e, i) => {
        if (this.state.chosenTextIndex >= 0) {

            let fx = this.state.text[this.state.chosenTextIndex];
            if (fx.textAlign === 'center') {
                var width = require('text-width');
                let wt = 'normal';
                if (fx.bold) {
                    wt = 'bold';
                }
                var w = width(e.key.toString(), {
                    family: fx.fontFamily,
                    size: fx.fontSize,
                    weight: wt
                });
                let dx = document.getElementById('text'+i)
                if (!fx.multiline && dx){
                    fx.left = dx.offsetLeft;
                    fx.right = window.innerWidth - (dx.offsetLeft+dx.offsetWidth);
                }

                if (fx.outlineSelected) {
                    fx.outlineSelected = false;
                }
                this.state.text.splice(this.state.chosenTextIndex, 1, fx);
                let cx = this.state.text;
                this.setState({
                    text: cx,
                })
            }
        }

        if (this.state.chosenBarcodeIndex >= 0) {
            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if (fx.textAlign === 'center') {
                var width = require('text-width');
                let wt = 'normal';
                if (fx.bold) {
                    wt = 'bold';
                }
                var w = width(e.key.toString(), {
                    family: fx.fontFamily,
                    size: fx.fontSize,
                    weight: wt
                });
                fx.left -= w / 2;
                fx.right -= w / 2;
                if (fx.outlineSelected) {
                    fx.outlineSelected = false;
                }
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
                let cx = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject:{
                        ...this.state.barcodeObject,
                        barcodeText: cx,
                    }
                })
            }
        }

    };
    handleChange = (e, i) => {

        if (e.keyCode === 13){
            e.preventDefault();
            return false;
        }
        else {

            let dx = e.currentTarget.textContent;
            let fx = this.state.text[i]

            let tb = [...this.state.text]
            for (let t = 0; t < fx.copyChildIndex.length; t++) {
                let tv = tb[fx.copyChildIndex[t]]
                tv.text = e.currentTarget.textContent

                this.setState({
                    text: tb,
                })
            }


            var diff = jsdiff.diffChars(this.state.prefix + this.state.showText + this.state.suffix, dx);
            let xl = diff[0].count;
            if (this.state.prefix.length > 0 || this.state.suffix.length > 0) {

                if (this.state.prefix.length > xl) {
                    this.setState({prefix: dx.substring(0, this.state.prefix.length + 1)})
                } else {


                    if (this.state.prefix.length + this.state.showText.length < xl) {
                        this.setState({suffix: dx.substring(this.state.prefix.length + this.state.showText.length, dx.length)})
                    } else {

                        if (this.state.prefix.length < xl < this.state.prefix.length + this.state.showText.length || (this.state.prefix.length < xl && xl === (this.state.prefix.length + this.state.showText.length))) {
                            this.setState({showText: dx.substring(this.state.prefix.length, dx.length - this.state.suffix.length)})

                        }
                    }

                }
            } else {
                this.setState({
                    showText: e.currentTarget.textContent
                })
            }


            if (dx.includes('(') && dx.includes('E') && dx.includes('M') && dx.includes('P') && dx.includes('T') && dx.includes('Y') && dx.includes(')')) {
                let a = dx.replace('(', '')
                let b = a.replace('E', '')
                let c = b.replace('M', '')
                let d = c.replace('P', '')
                let e = d.replace('T', '')
                let f = e.replace('Y', '')
                let gv = f.replace(')', '')
                fx.text = gv

                this.state.text.splice(i, 1, fx);
                let cx = this.state.text;
                this.setState({
                    text: cx
                }, () => {
                    var el = document.getElementById("txtx" + i)
                    var range = document.createRange()
                    var sel = window.getSelection()
                    if (el && fx.text.length > 0) {
                        range.setStart(el.childNodes[0], 1)
                        range.collapse(true)
                        sel.removeAllRanges()
                        sel.addRange(range)
                    }
                })


            }


            this.setState({chosenTextIndex: i});

            var width = require('text-width');
            let wt = 'normal';
            if (fx.bold) {
                wt = 'bold';
            }
            var w = width(e.currentTarget.textContent, {
                family: fx.fontFamily,
                size: fx.fontSize,
                weight: wt
            });

            let ddx = document.getElementById('text'+i)
            if (ddx && fx.textAlign === 'center'){

                fx.outlineSelected = true
                let rg =  window.innerWidth - ((ddx.offsetLeft+ddx.offsetWidth))
                fx.left = ddx.offsetLeft;
                fx.right = rg;
                fx.width = null

                if (w+10 < ddx.offsetWidth){
                    fx.width = w
                    fx.left = ddx.offsetLeft+ddx.offsetWidth/2- (w/2)
                }
            }

            const rect = e.currentTarget.getBoundingClientRect();
            this.setState({
                objectPosition: {
                    ...this.state.objectPosition,
                    x: rect.x,
                    y: rect.y,
                    width: rect.width,
                    right: rect.right,
                    left: rect.left,
                }
            });
        }

    };
    handleChangebarcodeText = (e, i) => {

        if (e.keyCode === 13){
            e.preventDefault();
            return false;
        }
        else {

            let dx = e.currentTarget.textContent;

            if (dx.length > 0 && dx !== '(EMPTY)'){
                let fGH = this.state.barcodeObject.barcode[i]
                if (fGH.emptyPrint){
                    fGH.emptyPrint = false

                    this.setState({  barcodeObject: {
                            ...this.state.barcodeObject,
                            barcode: fGH,
                        }})

                }

            }

            var isnum = /^\d+$/.test(dx);
            let fb2 = this.state.barcodeObject.barcode[i];

            if (dx.length > 0) {


                // if  (((fb2.barcodeType === 'CODE128A'|| fb2.barcodeType === 'CODE128C'||
                //     fb2.barcodeType === 'MSI10'|| fb2.barcodeType === 'MSI11') && isnum)
                //     || fb2.barcodeType === 'CODE128B'|| fb2.barcodeType === 'CODE39'
                //     || fb2.barcodeType === 'pharmacode'|| fb2.barcodeType === 'EAN8'
                //     || fb2.barcodeType === 'EAN13'|| fb2.barcodeType === 'UPC'
                //     || fb2.barcodeType === 'QR CODE'){

                let fx = this.state.barcodeObject.barcodeText[i]

                let tb = [...this.state.barcodeObject.barcodeText]
                let tbb = [...this.state.barcodeObject.barcode]

                for (let t = 0; t < fx.copyChildIndex.length; t++) {
                    let tv = tb[fx.copyChildIndex[t]]
                    let tvb = tbb[fx.copyChildIndex[t]]

                    tv.text = e.currentTarget.textContent
                    tvb.value = dx


                    this.setState({
                        barcodeObject: {
                            ...this.state.barcodeObject,
                            barcode: tvb,
                            barcodeText: tb,
                        }
                    })
                }

                this.setState({barcodeValue: dx})


                var diff = jsdiff.diffChars(this.state.prefix + this.state.showText + this.state.suffix, dx);
                let xl = diff[0].count;
                if (this.state.prefix.length > 0 || this.state.suffix.length > 0) {

                    if (this.state.prefix.length > xl) {
                        this.setState({prefix: dx.substring(0, this.state.prefix.length + 1)})
                    } else {


                        if (this.state.prefix.length + this.state.showText.length < xl) {
                            this.setState({suffix: dx.substring(this.state.prefix.length + this.state.showText.length, dx.length)})
                        } else {

                            if (this.state.prefix.length < xl < this.state.prefix.length + this.state.showText.length || (this.state.prefix.length < xl && xl === (this.state.prefix.length + this.state.showText.length))) {
                                this.setState({showText: dx.substring(this.state.prefix.length, dx.length - this.state.suffix.length)})

                            }
                        }

                    }
                } else {
                    this.setState({
                        showText: e.currentTarget.textContent
                    })
                }

                    fb2.value = dx
                    if (document.getElementById('bcd' + this.state.chosenBarcodeIndex)) {
                        let bz = Number(document.getElementById('bcd' + this.state.chosenBarcodeIndex).clientWidth)

                        let bw = Number(this.getBarWidth(fb2.barcodeType.toLowerCase(), fb2.value, fb2.barcodeXDIM,fb2.barcodeDPI))
                        if (Math.ceil(bz) !== Math.ceil(bw)) {
                            const trueWidth = parseInt(bz, 10);
                            const scale = bw / trueWidth;
                            if (scale > 0 && fb2.barWidth > 0){

                                fb2.barWidth = (fb2.barWidth * scale);

                            }


                        }
                    }

                this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2);
                    let cx1 = this.state.barcodeObject.barcode;
                    this.setState({
                        barcodeObject: {
                            ...this.state.barcodeObject,
                            barcode: cx1,
                        }
                    }, () => {
                        if (document.getElementById('bcd' + this.state.chosenBarcodeIndex)) {
                            let fb2x = this.state.barcodeObject.barcode[i];

                            let bz = Number(document.getElementById('bcd' + this.state.chosenBarcodeIndex).clientWidth)
                            let bw = Number(this.getBarWidth(fb2x.barcodeType.toLowerCase(), fb2x.value, fb2x.barcodeXDIM,fb2.barcodeDPI))
                            if (Math.ceil(bz) !== Math.ceil(bw)) {
                                const trueWidth = parseInt(bz, 10);
                                const scale = bw / trueWidth;
                                if (scale > 0 && fb2.barWidth > 0) {
                                    fb2x.barWidth = (fb2x.barWidth * scale);
                                }
                                this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2x);
                                let cx1 = this.state.barcodeObject.barcode;
                                this.setState({
                                    barcodeObject: {
                                        ...this.state.barcodeObject,
                                        barcode: cx1,
                                    }
                                })
                            }
                        }
                    })


                if (dx.includes('(') && dx.includes('E') && dx.includes('M') && dx.includes('P') && dx.includes('T') && dx.includes('Y') && dx.includes(')')) {
                    let a = dx.replace('(', '')
                    let b = a.replace('E', '')
                    let c = b.replace('M', '')
                    let d = c.replace('P', '')
                    let e = d.replace('T', '')
                    let f = e.replace('Y', '')
                    let gv = f.replace(')', '')
                    fx.text = gv

                    this.state.barcodeObject.barcode.splice(i, 1, fb2);
                    let cx1 = this.state.barcodeObject.barcode;

                    this.state.barcodeObject.barcodeText.splice(i, 1, fx);
                    let cx = this.state.barcodeObject.barcodeText;
                    this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcodeText: cx,
                                barcode: cx1,
                            }
                        },
                        () => {
                            var el = document.getElementById("btxtx" + i)
                            var range = document.createRange()
                            var sel = window.getSelection()
                            if (el && fx.text.length > 0) {
                                range.setStart(el.childNodes[0], 1)
                                range.collapse(true)
                                sel.removeAllRanges()
                                sel.addRange(range)
                            }
                        })


                }


                this.setState({chosenBarcodeIndex: i});

                var width = require('text-width');
                let wt = 'normal';
                if (fx.bold) {
                    wt = 'bold';
                }
                var w = width(e.currentTarget.textContent, {
                    family: fx.fontFamily,
                    size: fx.fontSize,
                    weight: wt
                });

                let ddx = document.getElementById('btext'+i)
                if (ddx && fx.textAlign === 'center'){

                    fx.outlineSelected = true
                    let rg =  window.innerWidth - ((ddx.offsetLeft+ddx.offsetWidth))
                    fx.left = ddx.offsetLeft;
                    fx.right = rg;
                    fx.width = null

                    if (w+10 < ddx.offsetWidth){
                        fx.width = w
                        fx.left = ddx.offsetLeft+ddx.offsetWidth/2- (w/2)
                    }
                }


                const rect = e.currentTarget.getBoundingClientRect();
                this.setState({
                    objectPosition_barcodeText: {
                        ...this.state.objectPosition_barcodeText,
                        x: rect.x,
                        y: rect.y,
                        width: rect.width,
                        right: rect.right,
                        left: rect.left,
                    }
                });
                // }
                // else{
                //     let fb2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                //     fb2.text = dx.replace(/\D/g,'');
                //     e.currentTarget.textContent = dx.replace(/\D/g,'')
                //
                //     this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fb2);
                //     let cx1 = this.state.barcodeObject.barcodeText;
                //     this.setState({
                //         barcodeObject:{
                //             ...this.state.barcodeObject,
                //             barcodeText: cx1,
                //         }
                //     },()=>alert('Invalid Input'))
                //
                // }

            }
        }




    };
    handleChangeKeyDown = (e, i) => {

        if (e.keyCode === 13){
            e.preventDefault();
            return false;
        }
        else {

            let fx = this.state.text[i];


            const rect = e.currentTarget.getBoundingClientRect();
            this.setState({
                objectPosition: {
                    ...this.state.objectPosition,
                    x: rect.x,
                    y: rect.y,
                    width: rect.width,
                    right: rect.right,
                    left: rect.left,
                }
            });

            if (fx.textAlign === 'center') {
                fx.outlineSelected = true;

                if (fx.right === null) {
                    fx.textAlign = 'center';
                    fx.left = this.state.objectPosition.left - fx.deltaPositionX;
                    fx.right = window.innerWidth - this.state.objectPosition.right + fx.deltaPositionX;
                    fx.width = null
                }

                this.state.text.splice(this.state.chosenTextIndex, 1, fx);
                let cx = this.state.text;
                this.setState({
                    text: cx,
                })
            }
        }
    };
    handleChangeKeyDownbarcodeText = (e, i) => {
        if (e.keyCode === 13){
            e.preventDefault();
            return false;
        }
        else {
            let dx = e.currentTarget.textContent;

            if (this.state.barcodeValue && this.state.chosenBarcodeIndex >= 0) {
                var isnum = /^\d+$/.test(dx);
                let fb2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

                if (dx.length > 0) {


                    if (((fb2.barcodeType === 'CODE128A' || fb2.barcodeType === 'CODE128C' || fb2.barcodeType === 'CODE128' ||
                        fb2.barcodeType === 'MSI10' || fb2.barcodeType === 'MSI11') && isnum)
                        || fb2.barcodeType === 'CODE128B' || fb2.barcodeType === 'CODE39'
                        || fb2.barcodeType === 'pharmacode' || fb2.barcodeType === 'EAN8'
                        || fb2.barcodeType === 'EAN13' || fb2.barcodeType === 'UPC'
                        || fb2.barcodeType === 'QR CODE') {
                        let fx = this.state.barcodeObject.barcodeText[i];

                        const rect = e.currentTarget.getBoundingClientRect();
                        this.setState({
                            objectPosition_barcodeText: {
                                ...this.state.objectPosition_barcodeText,
                                x: rect.x,
                                y: rect.y,
                                width: rect.width,
                                right: rect.right,
                                left: rect.left,
                            }
                        });

                        if (fx.textAlign === 'center') {
                            fx.outlineSelected = true;

                            if (fx.right === null) {
                                fx.textAlign = 'center';
                                fx.left = this.state.objectPosition_barcodeText.left - fx.deltaPositionX;
                                fx.right = window.innerWidth - this.state.objectPosition_barcodeText.right + fx.deltaPositionX;
                                fx.width = null
                            }

                            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
                            let cx = this.state.barcodeObject.barcodeText;
                            this.setState({
                                barcodeObject: {
                                    ...this.state.barcodeObject,
                                    barcodeText: cx,
                                }
                            })
                        }
                    }

                }
            }
        }
    };

    onTextFocus = (e,i) => {
        if (this.state.chosenTextIndex >= 0 && i !== this.state.chosenTextIndex) {

            let fx1 = this.state.text[this.state.chosenTextIndex];
            fx1.focus = false;

            this.state.text.splice(this.state.chosenTextIndex, 1, fx1);

            let cx1 = this.state.text;

            this.setState({
                text: cx1,
            },()=>{
                this.setState({chosenTextIndex: i})

            })

        }
        this.setState({chosenTextIndex: i});

        const rect1 = e.currentTarget.getBoundingClientRect();
        if (this.state.objectPosition.height === 0) {
            this.setState({
                objectPosition: {
                    ...this.state.objectPosition,
                    x: rect1.x,
                    y: rect1.y,
                    width: rect1.width,
                    right: rect1.right,
                    left: rect1.left,
                    height: rect1.height,

                }
            });
        }
        else {
            this.setState({
                objectPosition: {
                    ...this.state.objectPosition,
                    x: rect1.x,
                    y: rect1.y,
                    width: rect1.width,
                    right: rect1.right,
                    left: rect1.left,

                }
            });
        }

    };
    onbarcodeTextFocus = (e,i) => {

        if (this.state.chosenBarcodeIndex >= 0 && i !== this.state.chosenBarcodeIndex) {

            let fx1 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            fx1.focus = false;

            let fx2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            fx2.focus = false;

            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx1);
            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2);

            let cx1 = this.state.barcodeObject.barcodeText;
            let cx2 = this.state.barcodeObject.barcode;

            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx1,
                    barcode:cx2,
                }
            },()=>{
                this.setState({chosenBarcodeIndex: i})

            })

        }
        this.setState({chosenBarcodeIndex: i});

        const rect1 = e.currentTarget.getBoundingClientRect();
        if (this.state.objectPosition_barcodeText.height === 0) {
            this.setState({
                objectPosition_barcodeText: {
                    ...this.state.objectPosition_barcodeText,
                    x: rect1.x,
                    y: rect1.y,
                    width: rect1.width,
                    right: rect1.right,
                    left: rect1.left,
                    height: rect1.height,

                }
            });
        }
        else {
            this.setState({
                objectPosition_barcodeText: {
                    ...this.state.objectPosition_barcodeText,
                    x: rect1.x,
                    y: rect1.y,
                    width: rect1.width,
                    right: rect1.right,
                    left: rect1.left,

                }
            });
        }

    };
    offTextFocus = (e, i) => {

        let fx = this.state.text[i];

        if ( i === this.state.chosenTextIndex && fx.prefix.length > 0 && fx.suffix.length > 0){

            let f = e.currentTarget.textContent;
            if (this.state.prefix.length > 0 && fx.prefix !== this.state.prefix){
                fx.prefix = this.state.prefix;

            }
            if (this.state.suffix.length > 0 && fx.suffix !== this.state.suffix){
                fx.suffix = this.state.suffix;

            }

            let fx_text = f.substring(fx.prefix.length, f.length-fx.suffix.length);
            if (fx.text !== fx_text){
                fx.text = fx_text;
            }

        }
        this.state.text.splice(i, 1, fx);
        let cx = this.state.text;
        this.setState({
            text: cx
        }, ()=>{
        })

    };
    offbarcodeTextFocus = (e, i) => {

        let fx = this.state.barcodeObject.barcodeText[i];

        if ( i === this.state.chosenBarcodeIndex  && fx.prefix.length > 0 && fx.suffix.length > 0){

            let f = e.currentTarget.textContent;
            if (this.state.prefix.length > 0 && fx.prefix !== this.state.prefix){
                fx.prefix = this.state.prefix;

            }
            if (this.state.suffix.length > 0 && fx.suffix !== this.state.suffix){
                fx.suffix = this.state.suffix;

            }

            let fx_text = f.substring(fx.prefix.length, f.length-fx.suffix.length);
            if (fx.text !== fx_text){
                fx.text = fx_text;
            }

        }
        this.state.barcodeObject.barcodeText.splice(i, 1, fx);
        let cx = this.state.barcodeObject.barcodeText;
        this.setState({
            barcodeObject:{
                ...this.state.barcodeObject,
                barcodeText: cx,
            }
        }
        , ()=>{
        })
    };
    offTextFocusBlur = (e, i) => {

        const rect = e.currentTarget.getBoundingClientRect();

        let fx = this.state.text[i];
        var width = require('text-width');
        let wt = 'normal';
        if (fx.bold){
            wt = 'bold';
        }
        if (e.currentTarget.textContent.length === 0){
            let fg = this.state.text[i]
            fg.text = '(EMPTY)'
            this.setState({showText:'(EMPTY)'})

        }
        var w = width(e.currentTarget.textContent, {
            family: fx.fontFamily,
            size: fx.fontSize,
            weight: wt,

        });

        let wx = w - rect.width;

        if ( i === this.state.chosenTextIndex && (fx.prefix.length > 0 || fx.suffix.length > 0)){
            let f = e.currentTarget.textContent;
            if (this.state.prefix.length > 0 && fx.prefix !== this.state.prefix){
                fx.prefix = this.state.prefix;

            }
            if (this.state.suffix.length > 0 && fx.suffix !== this.state.suffix){
                fx.suffix = this.state.suffix;

            }
            let fx_text = f.substring(fx.prefix.length, f.length-fx.suffix.length);
            if (fx.text !== fx_text){
                fx.text = fx_text;
            }
        }
        if ( i === this.state.chosenTextIndex && (fx.prefix.length === 0 && fx.suffix.length === 0) &&
            e.currentTarget.textContent.length > 0){

            fx.text = e.currentTarget.textContent;

        }
        if (fx.textAlign === 'center' && wx < -10 && !fx.multiline) {
            let z = rect.width-w

            fx.outlineSelected = false;
            fx.width = w;
            let t_rz = ((fx.left + z / 2)*100) / window.innerWidth

            fx.left = t_rz
            this.setState({
                objectPosition: {
                    ...this.state.objectPosition,
                    x: this.state.objectPosition.x+z/2,
                    left: this.state.objectPosition.x + z/2,
                    right: this.state.objectPosition.right - z/2,
                    width: w

                }
            });
        }

        if (fx.textAlign === 'center' && wx > 0 && !fx.multiline){
            fx.outlineSelected = false;
            let rz = (fx.left - wx / 2)/window.innerWidth
            fx.width = w;
            fx.left = window.innerWidth*rz;
            this.setState({
                objectPosition: {
                    ...this.state.objectPosition,
                    x: this.state.objectPosition.x-wx/2,
                    left: this.state.objectPosition.x - wx/2,
                    right: this.state.objectPosition.right + wx/2,
                    width: w

                }
            });

        }

        let tb = [...this.state.text]
        for (let t = 0 ; t < fx.copyChildIndex.length; t++){
            let tv = tb[fx.copyChildIndex[t]]
            let hh = document.getElementById('text'+fx.copyChildIndex[t])

            let rect1 = hh.getBoundingClientRect();
            let wxx = w - rect1.width;

            if (tv.textAlign === 'center' && wxx < -10 && !tv.multiline) {
                let zx = rect1.width-w
                tv.width = w;
                tv.left = tv.left + zx / 2;
            }
            if (tv.textAlign === 'center' && wxx > 0 && !tv.multiline){
                tv.width = w;
                tv.left = tv.left - wxx / 2;
            }
            this.setState({
                text: tb,
            })
        }

        this.state.text.splice(i, 1, fx);
        let cx = this.state.text;
        this.setState({
            text: cx
        }, ()=>{

            if (document.getElementById('text'+i).textContent !== fx.prefix+this.state.showText+fx.suffix && this.state.showText !== null){
                document.getElementById('text'+i).textContent = this.state.showText

            }
            else {
                if (this.state.showText !== this.state.text[i].text && this.state.showText !== null) {

                    let bx = [...this.state.text]
                    bx[i].text = this.state.showText
                    this.setState({text: bx})
                    this.forceUpdate()

                }
            }

        })

    };
    offbarcodeTextFocusBlur = (e, i) => {
        const rect = e.currentTarget.getBoundingClientRect();

        let fx = this.state.barcodeObject.barcodeText[i];
        let gb = this.state.barcodeObject.barcode[i];

        var width = require('text-width');
        let wt = 'normal';
        if (fx.bold) {
            wt = 'bold';
        }
        if (e.currentTarget.textContent.length === 0) {
            let fg = this.state.barcodeObject.barcodeText[i]
            fg.text = '(EMPTY)'
            gb.emptyPrint = true
            this.setState({  barcodeObject: {
                    ...this.state.barcodeObject,
                    barcode: gb,
                },
                showText: '(EMPTY)'})}

        var w = width(e.currentTarget.textContent, {
            family: fx.fontFamily,
            size: fx.fontSize,
            weight: wt,

        });

        let wx = w - rect.width;

        if (i === this.state.chosenBarcodeIndex && (fx.prefix.length > 0 || fx.suffix.length > 0)) {
            let f = e.currentTarget.textContent;
            if (this.state.prefix.length > 0 && fx.prefix !== this.state.prefix) {
                fx.prefix = this.state.prefix;

            }
            if (this.state.suffix.length > 0 && fx.suffix !== this.state.suffix) {
                fx.suffix = this.state.suffix;

            }
            let fx_text = f.substring(fx.prefix.length, f.length - fx.suffix.length);
            if (fx.text !== fx_text) {
                fx.text = fx_text;

            }
        }
        if (i === this.state.chosenBarcodeIndex && (fx.prefix.length === 0 && fx.suffix.length === 0) &&
            e.currentTarget.textContent.length > 0) {

            fx.text = e.currentTarget.textContent;
        }

        if (fx.textAlign === 'center' && wx < -10 && !fx.multiline) {
            let z = rect.width - w

            fx.outlineSelected = false;
            fx.width = w;

            fx.left = fx.left + z / 2;
            this.setState({
                objectPosition_barcodeText: {
                    ...this.state.objectPosition_barcodeText,
                    x: this.state.objectPosition_barcodeText.x + z / 2,
                    left: this.state.objectPosition_barcodeText.x + z / 2,
                    right: this.state.objectPosition_barcodeText.right - z / 2,
                    width: w

                }
            });
        }

        if (fx.textAlign === 'center' && wx > 0 && !fx.multiline) {
            fx.outlineSelected = false;
            fx.width = w;

            fx.left = fx.left - wx / 2;
            this.setState({
                objectPosition_barcodeText: {
                    ...this.state.objectPosition_barcodeText,
                    x: this.state.objectPosition_barcodeText.x - wx / 2,
                    left: this.state.objectPosition_barcodeText.x - wx / 2,
                    right: this.state.objectPosition_barcodeText.right + wx / 2,
                    width: w

                }
            });

        }

        let tb = [...this.state.barcodeObject.barcodeText]

        for (let t = 0; t < fx.copyChildIndex.length; t++) {
            let tv = tb[fx.copyChildIndex[t]]
            let hh = document.getElementById('btxt' + fx.copyChildIndex[t])
            if (!hh) return;
            let rect1 = hh.getBoundingClientRect();
            let wxx = w - rect1.width;

            if (tv.textAlign === 'center' && wxx < -10 && !tv.multiline) {
                let zx = rect1.width - w
                tv.width = w;
                tv.left = tv.left + zx / 2;
            }
            if (tv.textAlign === 'center' && wxx > 0 && !tv.multiline) {
                tv.width = w;
                tv.left = tv.left - wxx / 2;
            }

            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcodeText: tb,
                }
            })
        }

        this.state.barcodeObject.barcodeText.splice(i, 1, fx);
        let cx = this.state.barcodeObject.barcodeText;
        this.setState({
            barcodeObject: {
                ...this.state.barcodeObject,
                barcodeText: cx,
            }
        }, () => {

            if (document.getElementById('btext' + i).textContent !== fx.prefix+this.state.showText+fx.suffix && this.state.showText !== null) {

                document.getElementById('btext' + i).textContent = this.state.showText

            } else {
                if (this.state.showText !== this.state.barcodeObject.barcodeText[i].text && this.state.showText !== null) {

                    let bx = [...this.state.barcodeObject.barcodeText]
                    let bc = [...this.state.barcodeObject.barcode]

                    bx[i].text = this.state.showText
                    bc[i].value = this.state.showText
                    document.getElementById('btext' + i).textContent = this.state.showText
                    this.setState({
                        barcodeObject: {
                            ...this.state.barcodeObject,
                            barcode: bc,
                            barcodeText: bx,

                        }
                    })
                    this.forceUpdate()
                }


            }


        });
    }

    toolClick() {
        this.props.dispatch(TOOLBAR());
    }

    activatorClick() {
        this.setState({activatorOn: ! this.state.activatorOn});
    }
    createLabel() {
        if (this.state.label_dim.width && this.state.label_dim.height) {
            this.setState({
                label_dim:{
                    ...this.state.label_dim,
                    startLabel: true,

                }
            });
        }
    }
    LH1_Click(){
        this.props.dispatch(LH1());
    }
    LH2_Click(){
        if (this.state.label_dim.startLabel && this.state.label_dim.width.toString().length > 0 && this.state.label_dim.height.toString().length > 0){
            this.props.dispatch(LH2());
        }
    }
    LH2_ClickBtx(){
        if (this.state.label_dim.startLabel && this.state.label_dim.width.toString().length > 0 && this.state.label_dim.height.toString().length > 0){
            document.getElementById('btx'+this.state.chosenBarcodeIndex).click()
            // this.props.dispatch(LH2());
        }
    }
    LH3_Click(){
        if (this.state.label_dim.startLabel && this.state.label_dim.width.toString().length > 0 && this.state.label_dim.height.toString().length > 0) {
            this.props.dispatch(LH3());
        }
    }
    LH4_Click(){
        if (this.state.label_dim.startLabel && this.state.label_dim.width.toString().length > 0 && this.state.label_dim.height.toString().length > 0) {
            this.props.dispatch(LH4());
            this.setState({
                zoom: true
            })
        }
    }
    LH5_Click(){
        if (this.state.label_dim.startLabel && this.state.label_dim.width.toString().length > 0 && this.state.label_dim.height.toString().length > 0) {

            this.props.dispatch(LH5());
        }
    }
    RH1_Click(){
        this.props.dispatch(RH1());
    }
    RH2_Click(){
        if (this.state.label_dim.startLabel && this.state.label_dim.width.toString().length > 0 && this.state.label_dim.height.toString().length > 0){
            this.props.dispatch(RH2());
        }
    }
    RH3_Click(){
        if (this.state.label_dim.startLabel && this.state.label_dim.width.toString().length > 0 && this.state.label_dim.height.toString().length > 0) {
            this.props.dispatch(RH3());
        }
    }

    handleDoubleClick = (e,i) => {
        let fx = this.state.text[i];
        fx.focus = true;
        if (fx.dateStamp) {
            this.setState({
                dateStampPopup: true
            })
        }
    }

    getXposObject = () =>{
        if (this.state.chosenTextIndex >= 0) {
            let fx = this.state.text[this.state.chosenTextIndex];
            let x_pos = 0
            if (fx.textAlign === 'left') {
                this.setState({leftAlign: true, centerAlign: false, rightAlign: false})

                let x_range = fx.left + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;

                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_pos_real = x_range * x_resize

                x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                return x_pos
            }
            if (fx.textAlign === 'center') {
                fx.outlineSelected = true;
                let dxx = document.getElementById('txtx' + this.state.chosenTextIndex)

                if (dxx) {
                    this.setState({leftAlign: false, centerAlign: true, rightAlign: false})


                    let x_range = fx.left + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25 + dxx.offsetLeft;

                    let x_resize = (this.state.label_dim.width / this.props.label_Width);
                    let x_pos_real = x_range * x_resize
                    x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                    return x_pos
                }
            }
            if (fx.textAlign === 'right') {
                let dxx = document.getElementById('txtx' + this.state.chosenTextIndex)
                if (dxx) {
                    this.setState({leftAlign: false, centerAlign: false, rightAlign: true})

                    let ry = window.innerWidth - fx.right - dxx.offsetWidth;
                    let x_range = ry + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;

                    let x_resize = (this.state.label_dim.width / this.props.label_Width);
                    let x_pos_real = x_range * x_resize
                    x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                    return x_pos
                }
            }
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            let x_pos = 0
            if (fx.textAlign === 'left') {
                this.setState({leftAlign: true, centerAlign: false, rightAlign: false})

                let x_range = fx.left + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;

                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_pos_real = x_range * x_resize

                x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                return x_pos
            }
            if (fx.textAlign === 'center') {
                fx.outlineSelected = true;
                let dxx = document.getElementById('btxtx' + this.state.chosenBarcodeIndex)

                if (dxx) {
                    this.setState({leftAlign: false, centerAlign: true, rightAlign: false})


                    let x_range = fx.left + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25 + dxx.offsetLeft;

                    let x_resize = (this.state.label_dim.width / this.props.label_Width);
                    let x_pos_real = x_range * x_resize
                    x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                    return x_pos
                }
            }
            if (fx.textAlign === 'right') {
                let dxx = document.getElementById('btxtx' + this.state.chosenBarcodeIndex)
                if (dxx) {
                    this.setState({leftAlign: false, centerAlign: false, rightAlign: true})

                    let ry = window.innerWidth - fx.right - dxx.offsetWidth;
                    let x_range = ry + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;

                    let x_resize = (this.state.label_dim.width / this.props.label_Width);
                    let x_pos_real = x_range * x_resize
                    x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                    return x_pos
                }
            }
        }

    }
    getYposObject = () =>{
        if (this.state.chosenTextIndex >= 0) {
            let fx = this.state.text[this.state.chosenTextIndex];

            let y_range = fx.top + fx.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

            let y_resize = (this.state.label_dim.height / this.props.label_Height);
            let y_pos_real = y_range * y_resize
            let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
            return y_pos
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

            let y_range = fx.top + fx.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

            let y_resize = (this.state.label_dim.height / this.props.label_Height);
            let y_pos_real = y_range * y_resize
            let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
            return y_pos
        }

    }
    getBarcodeXposObject = () =>{
        let fb = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

        let x_range = fb.left +fb.deltaPositionX - this.props.label_X -  window.innerWidth * 0.25;


        let x_resize = (this.state.label_dim.width /this.props.label_Width);
        let x_pos_real = x_range * x_resize
        return (Math.round(x_pos_real * 100) / 100).toFixed(1)

    }
    getBarcodeYposObject = () =>{
        let fb = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

        let y_range = fb.top+fb.deltaPositionY-this.props.label_Y-window.innerWidth * 0.095;

        let y_resize = (this.state.label_dim.height /this.props.label_Height);
        let y_pos_real = y_range * y_resize
        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
        return y_pos

    }
    getImageXposObject = ()=>{
        if (this.state.chosenImageIndex >= 0) {
            let fb = this.state.image_array[this.state.chosenImageIndex];

            let x_range = fb.left + fb.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;

            let x_resize = (this.state.label_dim.width / this.props.label_Width);
            let x_pos_real = x_range * x_resize
            return (Math.round(x_pos_real * 100) / 100).toFixed(1);
        }
        if (this.state.chosenShapeIndex >= 0) {
            let fb = this.state.shape_array[this.state.chosenShapeIndex];

            let x_range = fb.left +fb.deltaPositionX - this.props.label_X -  window.innerWidth * 0.25;

            let x_resize = (this.state.label_dim.width /this.props.label_Width);
            let x_pos_real = x_range * x_resize
            return (Math.round(x_pos_real * 100) / 100).toFixed(1);
        }
    }
    getImageYposObject = ()=>{
        if (this.state.chosenImageIndex >= 0) {
            let fb = this.state.image_array[this.state.chosenImageIndex];

            let y_range = fb.top + fb.deltaPositionY - ((window.innerWidth * 0.095) + this.props.label_Y);

            let y_resize = (this.state.label_dim.height / this.props.label_Height);
            let y_pos_real = y_range * y_resize
            return (Math.round(y_pos_real * 100) / 100).toFixed(1);
        }
        if (this.state.chosenShapeIndex >= 0) {
            let fb = this.state.shape_array[this.state.chosenShapeIndex];
            let y_range = fb.top+fb.deltaPositionY-((window.innerWidth * 0.095)+this.props.label_Y);

            let y_resize = (this.state.label_dim.height /this.props.label_Height);
            let y_pos_real = y_range * y_resize
            return (Math.round(y_pos_real * 100) / 100).toFixed(1)
        }

    }

    clickText=  (e, i) => {

            if (this.props.Left_State !== "LH2") {
                this.props.dispatch(LH2());

            }
            if (this.props.Right_State !== "RH1") {
                this.props.dispatch(RH1());

            }

            const rect1 = e.currentTarget.getBoundingClientRect();

            if (this.state.objectPosition.height === 0) {

                this.setState({
                    objectPosition: {
                        ...this.state.objectPosition,
                        x: rect1.x,
                        y: rect1.y,
                        width: rect1.width,
                        right: rect1.right,
                        left: rect1.left,
                        height: rect1.height,

                    }
                });
            } else {
                this.setState({
                    objectPosition: {
                        ...this.state.objectPosition,
                        x: rect1.x,
                        y: rect1.y,
                        width: rect1.width,
                        right: rect1.right,
                        left: rect1.left,

                    }
                });
            }
            if (this.state.chosenBarcodeIndex >= 0) {
                let fx1 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
                fx1.focus = false;
                this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx1);
                let cx1 = this.state.barcodeObject.barcode;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcode: cx1,
                    },
                    chosenBarcodeIndex: -1,
                    barcodeValue: null,
                })

                let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                if (fx2) {
                    fx2.focus = false;
                    this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                    let cx2 = this.state.barcodeObject.barcodeText;

                    this.setState({
                        barcodeObject: {
                            ...this.state.barcodeObject,
                            barcodeText: cx2,

                        },
                        chosenBarcodeIndex: -1,
                        barcodeValue: null,
                    })
                }
            }
            if (this.state.chosenImageIndex >= 0) {
                let fb = this.state.image_array[this.state.chosenImageIndex];
                fb.focus = false;
                this.state.image_array.splice(this.state.chosenImageIndex, 1, fb);
                let cx1 = this.state.image_array;

                this.setState({
                    chosenImageIndex: -1,
                    image_array: cx1,
                })
            }
            if (this.state.chosenShapeIndex >= 0) {
                let fb = this.state.shape_array[this.state.chosenShapeIndex];
                fb.focus = false;
                this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);
                let cx1 = this.state.shape_array;

                this.setState({
                    chosenShapeIndex: -1,
                    shape_array: cx1,
                })
            }
            if (this.state.chosenTextIndex >= 0 && i !== this.state.chosenTextIndex) {

                let fx1 = this.state.text[this.state.chosenTextIndex];
                fx1.focus = false;
                let fxFont = fx1.fontFamily;
                if (fxFont.includes('Bold') || fxFont.includes('BoldItalic')) {
                    const d = fxFont.indexOf('Bold');
                    fxFont = fxFont.slice(0, d);
                } else {
                    if (fxFont.includes('Italic')) {
                        const dx = fxFont.indexOf('Italic');
                        fxFont = fxFont.slice(0, dx);

                    }

                }
                const fontStyleChecker = (font_styles.indexOf(fxFont) > -1);

                if (!fontStyleChecker) {
                    this.setState({bold: false, italic: false}, () => {
                        fx1.bold = false;
                        fx1.italic = false;
                    });
                }
                this.setState({fontFamily: fxFont}, () => {
                })
                if (fx1.textAlign === 'center') {
                    fx1.outlineSelected = true;

                }


                this.state.text.splice(this.state.chosenTextIndex, 1, fx1);

                let cx1 = this.state.text;

                this.setState({
                    text: cx1,
                })

            }

            let x = e.currentTarget.textContent;


            let fx = this.state.text[i];


            fx.focus = true;
            if (fx.textAlign === 'left' && fx.left === null) {

                fx.left = window.innerWidth * 0.25 + fx.deltaPositionX;
                fx.top = window.outerHeight * 0.18 + fx.deltaPositionY;
                fx.width = null;
                fx.outlineSelected = false;
                fx.right = null;

            }
            if (fx.textAlign === 'right' && fx.right === null && fx.width === null){
                this.onRightTextAlignChange()

            }


            let fxFont = fx.fontFamily;

            if (fxFont.includes('Bold') || fxFont.includes('BoldItalic')) {
                const d = fxFont.indexOf('Bold');
                fxFont = fxFont.slice(0, d);
            } else {
                if (fxFont.includes('Italic')) {
                    const dx = fxFont.indexOf('Italic');
                    fxFont = fxFont.slice(0, dx);

                }

            }
            const fontStyleChecker = (font_styles.indexOf(fxFont) > -1);

            if (!fontStyleChecker) {
                this.setState({bold: false, italic: false}, () => {
                    fx.bold = false;
                    fx.italic = false;
                });
            }

            if (fx.textAlign === 'left') {

                this.setState({leftAlign: true, centerAlign: false, rightAlign: false})

                let x_range = fx.left + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;

                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_pos_real = x_range * x_resize
                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                this.setState({
                    textXPos: x_pos
                })
            }
            if (fx.textAlign === 'center') {
                fx.outlineSelected = true;
                let dxx = document.getElementById('txtx'+i)

                if (dxx) {
                    this.setState({leftAlign: false, centerAlign: true, rightAlign: false})

                    let x_range = fx.left + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25 + dxx.offsetLeft;

                    let x_resize = (this.state.label_dim.width / this.props.label_Width);

                    let x_pos_real = x_range * x_resize
                    let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                    this.setState({
                        textXPos: x_pos
                    })
                }
            }
            if (fx.textAlign === 'right') {
                let dxx = document.getElementById('txtx'+i)
                if (dxx){
                    this.setState({leftAlign: false, centerAlign: false, rightAlign: true})

                    let ry = window.innerWidth - fx.right - dxx.offsetWidth;
                    let x_range = ry + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;

                    let x_resize = (this.state.label_dim.width / this.props.label_Width);
                    let x_pos_real = x_range * x_resize
                    let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                    this.setState({
                        textXPos: x_pos
                    })
                }
            }
            let y_range = fx.top + fx.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

            let y_resize = (this.state.label_dim.height / this.props.label_Height);
            let y_pos_real = y_range * y_resize
            let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
            this.setState({
                textYPos: y_pos
            })

            if (fx.multiline) {
                this.setState({multiline: true})
                    let tx_multimm = (fx.width * this.state.label_dim.width/this.props.label_Width).toFixed(1)
                    this.setState({multilineWidth:tx_multimm,multilineHeight:fx.multilineHeight})
            }
            else {
                this.setState({multiline: false})

            }



            // let bx = fx.fontSize / fx.scalingRatio;

            let rf = fx.fontSize;
            let rf_mm = (Number(rf) * this.state.label_dim.height)/this.props.label_Height
            let bx = rf_mm /  0.352778;


        if (fx.copyDataStatus === true && fx.copyDataLabel === 'Copy data from Field'){
                fx.copyDataStatus = false

            }
            if (fx.copyChildIndex.length > 0){

                for (let b =0;b < fx.copyChildIndex.length; b++){

                    if (fx.copyChildIndex[b] >= this.state.text.length){
                        fx.copyChildIndex.splice(b,1)
                    }else{

                        let rx = this.state.text[fx.copyChildIndex[b]]

                        if (rx.copyDataStatus && (rx.copyDataLabel === fx.pinText)){
                        }
                        else{
                            fx.copyChildIndex.splice(b,1)
                        }
                    }


                }
                if (fx.copyChildIndex.length > 0) {
                    fx.copyDataStatus = false
                    this.setState({disableCopyData: true})
                }
                else {
                    this.setState({disableCopyData: false})

                }

            }else{

                this.setState({disableCopyData: false})

            }


            let rt = []

            for (var g = 0 ; g  < this.state.text.length; g ++){
                if (g !== i){
                    rt.push(  {
                        label: this.state.text[g].pinText,
                        value: 't'+g,
                        data: false,
                    },)
                }

            }

            if (fx.transformRotation > 0){
                this.setState({transformPopup: true})
            }
            if (fx.text === '(EMPTY)' && fx.prefix === '' && fx.suffix === ''){
                fx.text = ''
            }

            this.setState({
                bold: fx.bold,
                dbDataLabel:fx.dbConnectData,
                italic: fx.italic,
                transparentStatus:fx.backgroundColor === 'transparent',
                lineThrough: fx.lineThrough,
                textColor: fx.textColor,
                textLocked: fx.lock,
                textNoPrint: fx.noPrint,
                underline: fx.underline,
                fontSize: Math.round(bx),
                fontFamily: fxFont,
                backgroundColor: fx.backgroundColor,
                pinText: fx.pinText,
                paraWidth: fx.paraWidth,
                paraHeight: fx.paraHeight,
                textQuestion: fx.question,
                textIncrement:fx.increment,
                dateStampOn: fx.dateStamp,
                questionPrintText:fx.questionText,
                copyDataLabel: fx.copyDataLabel,
                copyDataStatus: fx.copyDataStatus,
                transformStatus:fx.transformValue,
                copyData:rt,
                dbEnableStatus:fx.dbConnectData.length > 0

            });

        this.state.text.splice(i, 1, fx);
            let cx = this.state.text;

            if (x.substring(fx.prefix.length, x.length - fx.suffix.length) === '(EMPTY)'){
                this.setState({
                    showText:''
                })
            }
            else{
                this.setState({
                    showText: x.substring(fx.prefix.length, x.length - fx.suffix.length),
                })
            }

            this.setState({
                chosenTextIndex: i, prefix: fx.prefix, suffix: fx.suffix,
            });


            this.setState({text: cx},()=>this.clicktextCopyStatus())



    };

    clickBarcode=  (e, i) => {

        if (this.props.Left_State !== "LH3") {
            this.props.dispatch(LH3());

        }
        if (this.state.chosenTextIndex >= 0){
            let fx1 = this.state.text[this.state.chosenTextIndex];
            fx1.focus = false;

            this.state.text.splice(this.state.chosenTextIndex, 1, fx1);

            let cx1 = this.state.text;

            this.setState({
                text: cx1,
                chosenTextIndex: -1,
                showText:null,
                textXPos: null,
                textYPos: null,
            })
        }
        if (this.state.chosenImageIndex >= 0) {
            let fb = this.state.image_array[this.state.chosenImageIndex];
            fb.focus = false;
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fb);
            let cx1 = this.state.image_array;

            this.setState({
                chosenImageIndex: -1,
                image_array: cx1,
            })
        }
        if (this.state.chosenShapeIndex >= 0) {
            let fb = this.state.shape_array[this.state.chosenShapeIndex];
            fb.focus = false;
            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);
            let cx1 = this.state.shape_array;

            this.setState({
                chosenShapeIndex: -1,
                shape_array: cx1,
            })
        }
        if (this.state.chosenBarcodeIndex >= 0 && i !== this.state.chosenBarcodeIndex) {

            let fx1 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            fx1.focus = false;

            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx1);

            let cx1 = this.state.barcodeObject.barcode;

            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcode: cx1,

                }
            })
        }
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if(fx2){
            if (fx2.focus) {


                fx2.focus = false;

                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);

                let cx2 = this.state.barcodeObject.barcodeText;

                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: cx2,

                    }
                })
            }
        }
        }
        let fb = this.state.barcodeObject.barcode[i];
        let fx1 = this.state.barcodeObject.barcodeText[i];

        fb.focus = true;


        let x = (fb.barHeight * this.state.label_dim.height/this.props.label_Height).toFixed(2)

        let x_range = fb.left +fb.deltaPositionX - this.props.label_X -  window.innerWidth * 0.25;


        let x_resize = (this.state.label_dim.width /this.props.label_Width);
        let x_pos_real = x_range * x_resize
        let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
        let y_range = fb.top+fb.deltaPositionY-this.props.label_Y-window.innerWidth * 0.095;

        let y_resize = (this.state.label_dim.height /this.props.label_Height);
        let y_pos_real = y_range * y_resize
        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)

        if (fb.barcodeType === 'QR CODE' && !this.state.qrCode){
            this.setState({
                qrCode: true,
            })

        }
        if (fb.barcodeType !== 'QR CODE' && this.state.qrCode){
            this.setState({
                qrCode: false,
            })

        }
        let fbType = fb.barcodeType
        if(fbType === 'UPC'){
            fbType = 'UPC(A)'
        }
        if (fbType === 'UPCE'){
            fbType = 'UPC(E)'
        }
        if (fb.transformRotation > 0){
            this.setState({transformPopup: true})
        }

        this.setState({
            chosenBarcodeIndex: i,
            barcodeType: fbType,
            barcodeHeight: x,
            transparentStatus:fb.backgroundColor === 'transparent',
            showText:'',
            barcodeDPI: fb.barcodeDPI,
            barcodeDPIXDIM: fb.barcodeXDIM,
            barcodeXPos: x_pos,
            barcodeYPos: y_pos,
            barcodeLocked: fb.lock,
            checkDigit: fb.checkDigit,
            // barcodeValue: fb.value,
            barcodeValue: fx1.text,
            barcodepinText:fb.pinText,
            barcodeNoPrint:fb.noPrint,
            transformStatus:fb.transformValue,
            copyDataStatus: false,
        })


    }
    clickbarcodeText =  (e, i) =>{
        if (this.props.Left_State !== "LH2"){
            this.props.dispatch(LH2());
        }
        if (this.props.Right_State !== "RH1"){
            this.props.dispatch(RH1());

        }
        const rect1 = e.currentTarget.getBoundingClientRect();

        if (this.state.objectPosition_barcodeText.height === 0) {

            this.setState({
                objectPosition_barcodeText: {
                    ...this.state.objectPosition_barcodeText,
                    x: rect1.x,
                    y: rect1.y,
                    width: rect1.width,
                    right: rect1.right,
                    left: rect1.left,
                    height: rect1.height,

                }
            });
        }
        else {
            this.setState({
                objectPosition_barcodeText: {
                    ...this.state.objectPosition_barcodeText,
                    x: rect1.x,
                    y: rect1.y,
                    width: rect1.width,
                    right: rect1.right,
                    left: rect1.left,

                }
            });
        }
        if (this.state.chosenTextIndex >= 0){
            let fx1 = this.state.text[this.state.chosenTextIndex];
            fx1.focus = false;

            this.state.text.splice(this.state.chosenTextIndex, 1, fx1);

            let cx1 = this.state.text;

            this.setState({
                text: cx1,
                chosenTextIndex: -1,
                showText:null,
                textXPos: null,
                textYPos: null,
            })
        }
        if (this.state.chosenImageIndex >= 0) {
            let fb = this.state.image_array[this.state.chosenImageIndex];
            fb.focus = false;
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fb);
            let cx1 = this.state.image_array;

            this.setState({
                chosenImageIndex: -1,
                image_array: cx1,
            })

        }
        if (this.state.chosenShapeIndex >= 0) {
            let fb = this.state.shape_array[this.state.chosenShapeIndex];
            fb.focus = false;
            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);
            let cx1 = this.state.shape_array;

            this.setState({
                chosenShapeIndex: -1,
                shape_array: cx1,
            })
        }

        if (this.state.chosenBarcodeIndex >= 0 && i !== this.state.chosenBarcodeIndex) {
            let fx2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            fx2.focus = false;
            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx2 = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcode: cx2,
                }
            })

            let fx1 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if(fx1) {
                fx1.focus = false;
                let fxFont = fx1.fontFamily;

                if (fxFont.includes('Bold') || fxFont.includes('BoldItalic')) {
                    const d = fxFont.indexOf('Bold');
                    fxFont = fxFont.slice(0, d);
                } else {
                    if (fxFont.includes('Italic')) {
                        const dx = fxFont.indexOf('Italic');
                        fxFont = fxFont.slice(0, dx);

                    }

                }
                const fontStyleChecker = (font_styles.indexOf(fxFont) > -1);

                if (!fontStyleChecker) {
                    this.setState({bold: false, italic: false}, () => {
                        fx1.bold = false;
                        fx1.italic = false;
                    });
                }
                this.setState({fontFamily: fxFont}, () => {
                })
                if (fx1.textAlign === 'center') {
                    fx1.outlineSelected = true;
                }
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx1);

                let cx1 = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: cx1,
                    }
                })
            }
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
                fx2.focus = false;
                this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx2 = this.state.barcodeObject.barcode;

                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcode: cx2,

                    }
                })

        }

        let x = e.currentTarget.textContent;

        let fx = this.state.barcodeObject.barcodeText[i];
        let fb = this.state.barcodeObject.barcode[i];

        fx.focus = true;

        if (fx.textAlign === 'left' && fx.left === null){
            fx.left = window.innerWidth * 0.25  + fx.deltaPositionX;
            fx.top = window.outerHeight * 0.18 + fx.deltaPositionY;
            fx.width = null;
            fx.outlineSelected = false;
            fx.right = null;

        }

        let fxFont = fx.fontFamily;

        if (fxFont.includes('Bold') || fxFont.includes('BoldItalic')){
            const d = fxFont.indexOf('Bold');
            fxFont = fxFont.slice(0, d);
        }
        else {
            if (fxFont.includes('Italic')){
                const dx = fxFont.indexOf('Italic');
                fxFont = fxFont.slice(0, dx);

            }

        }
        const fontStyleChecker = (font_styles.indexOf(fxFont) > -1);

        if (!fontStyleChecker){
            this.setState({bold:false, italic:false}, ()=> {
                fx.bold = false;
                fx.italic = false;
            });
        }

        if (fx.textAlign === 'left'){
            this.setState({leftAlign: true, centerAlign: false, rightAlign: false})

            let x_range = fx.left +fx.deltaPositionX - this.props.label_X -  window.innerWidth * 0.25;


            let x_resize = (this.state.label_dim.width /this.props.label_Width);
            let x_pos_real = x_range * x_resize
            let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

            this.setState({
                textXPos: x_pos
            })
        }
        if (fx.textAlign === 'center'){
            fx.outlineSelected = true;
            let dxx = document.getElementById('btxtx'+i)
            if (dxx) {


                this.setState({leftAlign: false, centerAlign: true, rightAlign: false})

                let x_range = fx.left + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25 + dxx.offsetLeft;

                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_pos_real = x_range * x_resize
                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                this.setState({
                    textXPos: x_pos
                })
            }
        }
        if (fx.textAlign === 'right'){
            let dxx = document.getElementById('btext'+i)

            if (dxx){
                this.setState({leftAlign: false, centerAlign: false, rightAlign: true})

                let ry = window.innerWidth - fx.right - dxx.offsetWidth;
                let x_range = ry + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;

                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_pos_real = x_range * x_resize
                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                this.setState({
                    textXPos: x_pos
                })
            }
        }
        let y_range = fx.top+fx.deltaPositionY-this.props.label_Y-window.innerWidth * 0.095;

        let y_resize = (this.state.label_dim.height /this.props.label_Height);
        let y_pos_real = y_range * y_resize
        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
        this.setState({
            textYPos: y_pos
        })

        if (fx.multiline){
            this.setState({multiline: true})
            let tx_multimm = (fx.width * this.state.label_dim.width/this.props.label_Width).toFixed(1)
            this.setState({multilineWidth:tx_multimm, multilineHeight: fx.multilineHeight})
        }
        else {
            this.setState({multiline: false})

        }
        let rt = []
        for (let g = 0 ; g  < this.state.barcodeObject.barcodeText.length; g ++) {
            if (g !== i) {
                rt.push({
                    label: this.state.barcodeObject.barcodeText[g].pinText,
                    value: 'b' + g,
                    data: false,
                },)
            }
        }

        if (fx.text === '(EMPTY)' && fx.prefix === '' && fx.suffix === ''){
            fx.text = ''
        }


        // let bx = fx.fontSize / fx.scalingRatio;
        let rf = fx.fontSize;
        let rf_mm = (Number(rf) * this.state.label_dim.height)/this.props.label_Height
        let bx = rf_mm /  0.352778;

        if (fx.copyDataStatus === true && fx.copyDataLabel === 'Copy data from Field'){
            fx.copyDataStatus = false

        }
        if (fx.copyChildIndex.length > 0){

            for (let b =0;b < fx.copyChildIndex.length; b++){

                let rx = this.state.barcodeObject.barcodeText[fx.copyChildIndex[b]]

                if (rx.copyDataStatus && (rx.copyDataLabel === fx.pinText)){
                }
                else{
                    fx.copyChildIndex.splice(b,1)
                }
            }
            if (fx.copyChildIndex.length > 0) {
                fx.copyDataStatus = false
                this.setState({disableCopyData: true})
            }
            else {
                this.setState({disableCopyData: false})

            }

        }else{

            this.setState({disableCopyData: false})

        }


        if (fx.transformRotation > 0){
            this.setState({transformPopup: true})
        }

        this.setState({bold: fx.bold, italic: fx.italic, lineThrough: fx.lineThrough,transparentStatus:fx.backgroundColor === 'transparent',
            textColor: fx.textColor,textLocked: fx.lock,textNoPrint:fx.noPrint,dbDataLabel:fx.dbConnectData,

            copyDataLabel:fx.copyDataLabel, copyDataStatus: fx.copyDataStatus,transformStatus:fx.transformValue,copyData:rt,
            underline: fx.underline,fontSize: Math.round(bx) , fontFamily: fxFont, backgroundColor: fx.backgroundColor, pinText: fx.pinText, paraWidth:fx.paraWidth,
            paraHeight:fx.paraHeight,barcodeValue:fx.text,textQuestion:fx.question,dateStampOn:fx.dateStamp, textIncrement:fx.increment,questionPrintText:fx.questionText,
        });

        this.state.barcodeObject.barcodeText.splice(i, 1, fx);
        let cx = this.state.barcodeObject.barcodeText;

        if (x.substring(fx.prefix.length, x.length - fx.suffix.length) === '(EMPTY)'){
            this.setState({
                showText:''
            })
        }
        else{
            this.setState({
                showText: x.substring(fx.prefix.length, x.length - fx.suffix.length),
            })
        }
        this.setState({chosenBarcodeIndex: i,
            prefix: fx.prefix, suffix: fx.suffix});

        this.setState({
            barcodeObject:{
                ...this.state.barcodeObject,
                barcodeText: cx,
            }},()=>this.clicktextCopyStatus())
    };
    clickShape =  (e, i) => {
        if (this.props.Left_State !== "LH5") {
            this.props.dispatch(LH5());
        }
        if (this.state.chosenImageIndex >= 0) {
            this.setState({
                chosenImageIndex: -1,
            })
        }


        if (this.state.chosenTextIndex >= 0){
            let fx1 = this.state.text[this.state.chosenTextIndex];
            fx1.focus = false;

            this.state.text.splice(this.state.chosenTextIndex, 1, fx1);

            let cx1 = this.state.text;

            this.setState({
                text: cx1,
                chosenTextIndex: -1,
                showText:null,
                textXPos: null,
                textYPos: null,
            })
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx1 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            fx1.focus = false;
            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx1);
            let cx1 = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcode: cx1,
                },
                chosenBarcodeIndex: -1,
                barcodeValue: null,
            })

            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if(fx2){
                fx2.focus = false;
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx2 = this.state.barcodeObject.barcodeText;

                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: cx2,

                    },
                    chosenBarcodeIndex: -1,
                    barcodeValue: null,
                })
            }
        }
        if (this.state.chosenShapeIndex >= 0) {

            this.state.shape_array.map((item)=>{
                item.focus = false
            })
            let cx1 = this.state.shape_array;

            this.setState({
                chosenShapeIndex: -1,
                shape_array: cx1,
            })

        }


        let fb = this.state.shape_array[i];
        fb.focus = true;
        let x = (fb.width * this.state.label_dim.width/this.props.label_Width).toFixed(1)

        let y = (fb.height * this.state.label_dim.height/this.props.label_Height).toFixed(1)

        if (!fb.stretch){
            y = x;
        }



        let str_width = fb.strokeWidth
        if (fb.type === 'line'){
            let rf = fb.strokeWidth;
            let rf_mm = (Number(rf) * this.state.label_dim.height)/this.props.label_Height
            str_width = Math.round(rf_mm /  0.352778);

            this.setState({
                shapeType: true
            })
        }
        else {
            this.setState({
                shapeType: false
            })
        }


        let x_range = fb.left +fb.deltaPositionX - this.props.label_X -  window.innerWidth * 0.25;

        let x_resize = (this.state.label_dim.width /this.props.label_Width);
        let x_pos_real = x_range * x_resize
        let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)


        let y_range = fb.top+fb.deltaPositionY-((window.innerWidth * 0.095)+this.props.label_Y);

        let y_resize = (this.state.label_dim.height /this.props.label_Height);
        let y_pos_real = y_range * y_resize
        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)

        this.setState({
            shapeXPos: x_pos,
            shapeYPos: y_pos,
            shapeWidth: x,
            shapeHeight: y,
            shapepinText: fb.pinText,
            chosenShapeIndex: i,
            shapeLocked:fb.lock,
            shapeStretch: fb.stretch,
            shapeNoPrint:fb.noPrint,
            shapeStrokeWidth: str_width,
            shapeBorderColor:fb.stroke,
            shapeFillColor:fb.fill,
            copyDataStatus: false,

        })


    }
    clickImage =  (e, i) => {
        if (this.props.Left_State !== "LH4"){
            this.props.dispatch(LH4());
        }
        if (this.state.chosenShapeIndex >= 0) {
            let fb = this.state.shape_array[this.state.chosenShapeIndex];
            fb.focus = false;
            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);
            let cx1 = this.state.shape_array;

            this.setState({
                chosenShapeIndex: -1,
                shape_array: cx1,
            })
        }
        if (this.state.chosenTextIndex >= 0){
            let fx1 = this.state.text[this.state.chosenTextIndex];
            fx1.focus = false;

            this.state.text.splice(this.state.chosenTextIndex, 1, fx1);

            let cx1 = this.state.text;

            this.setState({
                text: cx1,
                chosenTextIndex: -1,
                showText:null,
                textXPos: null,
                textYPos: null,
            })
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx1 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            fx1.focus = false;
            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx1);
            let cx1 = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcode: cx1,
                },
                chosenBarcodeIndex: -1,
                barcodeValue: null,
            })

            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if(fx2){
                fx2.focus = false;
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx2 = this.state.barcodeObject.barcodeText;

                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: cx2,

                    },
                    chosenBarcodeIndex: -1,
                    barcodeValue: null,
                })
            }
        }

        if (this.state.chosenImageIndex >= 0) {

            this.state.image_array.map((item)=>{
                item.focus = false
            })
            let cx1 = this.state.image_array;

            this.setState({
                chosenImageIndex: -1,
                image_array: cx1,
            })

        }

        let fb = this.state.image_array[i];
        fb.focus = true;

        let x_range = fb.left +fb.deltaPositionX - this.props.label_X -  window.innerWidth * 0.25;

        let x_resize = (this.state.label_dim.width /this.props.label_Width);
        let x_pos_real = x_range * x_resize
        let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)


        let y_range = fb.top+fb.deltaPositionY-((window.innerWidth * 0.095)+this.props.label_Y);



        let y_resize = (this.state.label_dim.height /this.props.label_Height);
        let y_pos_real = y_range * y_resize
        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)

        let img_w = (fb.width * this.state.label_dim.width/this.props.label_Width).toFixed(2)

        let img_h = (fb.height * this.state.label_dim.height/this.props.label_Height).toFixed(2)


        this.setState({
            imageXPos: x_pos,
            imageYPos: y_pos,
            imageWidth: img_w,
            imageHeight: img_h,
            imagepinText: fb.pinText,
            chosenImageIndex: i,
            imageLocked:fb.lock,
            imageStretch: fb.stretch,
            imageNoPrint:fb.noPrint,
            copyDataStatus: false,
        })


    }
    onChangeStretch(){
        if(this.state.chosenImageIndex >= 0){
            let fb = this.state.image_array[this.state.chosenImageIndex];
            fb.stretch = true;
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fb);
            let cx1 = this.state.image_array;
            this.setState({
                image_array: cx1,
                imageStretch: true,
            })
        }
        if(this.state.chosenShapeIndex >= 0){
            let fb = this.state.shape_array[this.state.chosenShapeIndex];
            fb.stretch = true;
            if (fb.type === 'circle'){
                fb.radiusX = fb.radius
                fb.radiusY = fb.radius
                fb.width = fb.radius*2
                fb.height = fb.radius*2
            }
            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);
            let cx1 = this.state.shape_array;
            this.setState({
                shape_array: cx1,
                shapeStretch: true,
            })
        }

    }
    onChangeAspectRatio(){
        if(this.state.chosenImageIndex >= 0){
            let fb = this.state.image_array[this.state.chosenImageIndex];
            fb.stretch = false;
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fb);
            let cx1 = this.state.image_array;
            this.setState({
                image_array: cx1,
                imageStretch: false,
            })
        }
        if(this.state.chosenShapeIndex >= 0){
            let fb = this.state.shape_array[this.state.chosenShapeIndex];
            fb.stretch = false;
            if (fb.type === 'circle'){
                fb.radius = fb.radiusX


                this.setState({
                    shapeHeight: this.state.imageWidth
                })

            }
            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);
            let cx1 = this.state.shape_array;
            this.setState({
                shape_array: cx1,
                shapeStretch: false,
            })
        }
    }

    onChangeShapeThickness(e){

        if (this.state.chosenShapeIndex >= 0 ) {
                // this.setState({
                //     shapeStrokeWidth:e.target.value
                // })


            if (e.target.value >= 1) {
                let fb = this.state.shape_array[this.state.chosenShapeIndex];
                // fb.strokeWidth = e.target.value
                let gx_mm = Number(e.target.value) * 0.352778
                fb.strokeWidth = (this.props.label_Height * gx_mm)/this.state.label_dim.height

                this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);

                let cx1 = this.state.shape_array;
                this.setState({
                    shape_array: cx1,
                    shapeStrokeWidth: fb.strokeWidth,
                })
            }
        }

    }
    onChangeShapeThicknessPress(e){

        if (this.state.chosenShapeIndex >= 0 ) {
            // if (e < 1){
            //     this.setState({
            //         shapeStrokeWidth:1
            //     })
            //
            // }
            if (e >= 1) {
                let fb = this.state.shape_array[this.state.chosenShapeIndex];
                let gx_mm = Number(e) * 0.352778
                fb.strokeWidth = (this.props.label_Height * gx_mm)/this.state.label_dim.height
                // fb.strokeWidth = e.toFixed(0)
                this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);

                let cx1 = this.state.shape_array;
                this.setState({
                    shape_array: cx1,
                    shapeStrokeWidth: e.toFixed(0),
                })
            }
        }

    }

    onnumberImageWidth(e) {

        if (this.state.chosenImageIndex >= 0) {
            let img_w = (e.target.value * this.props.label_Width / this.state.label_dim.width)

            // let img_h = (fb.height * this.state.label_dim.height/this.props.label_Height).toFixed(0)
            this.setState({
                imageWidth: e.target.value,

            })
            let fb = this.state.image_array[this.state.chosenImageIndex];
            let bx = fb.naturalWidth/fb.naturalHeight
            if (fb.stretch) {
                fb.width = img_w;
                this.setState({
                    img_dim: {
                        ...this.state.img_dim,
                        width: e.target.value,
                    },
                    imageWidth: e.target.value,
                })
            } else {
                fb.width = img_w;
                fb.height = img_w/bx
                this.setState({
                    img_dim: {
                        ...this.state.img_dim,
                        width: e.target.value,
                        height: e.target.value/bx,
                    },
                    imageWidth: e.target.value,
                    imageHeight:e.target.value/bx,
                })
            }
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fb);
            let cx1 = this.state.image_array;
            this.setState({
                image_array: cx1,
            })

        }
        if (this.state.chosenShapeIndex >= 0) {
            this.setState({
                shapeWidth: e.target.value,

            })
        }
        if (this.state.chosenShapeIndex >= 0 && e.target.value > 0) {
            let fb = this.state.shape_array[this.state.chosenShapeIndex];
            if (fb.type === 'line'){
                fb.width  = e.target.value * this.props.label_Height/this.state.label_dim.height;
                this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);
            }
            if (fb.type === 'square'){
                if (fb.stretch){
                    fb.width  = e.target.value * this.props.label_Width/this.state.label_dim.width;

                }
                else {
                    fb.width  = e.target.value * this.props.label_Width/this.state.label_dim.width;
                    fb.height = e.target.value * this.props.label_Width/this.state.label_dim.width;

                    this.setState({
                        shapeHeight: e.target.value,
                    })

                }

            }
            if (fb.type === 'circle'){
                if (fb.stretch){
                    fb.radiusX  = e.target.value * this.props.label_Width/this.state.label_dim.width/2;
                    fb.width = (e.target.value * this.props.label_Width/this.state.label_dim.width/2) * 2;

                }
                else {
                    fb.radius  = e.target.value * this.props.label_Width/this.state.label_dim.width/2;
                    fb.width = (e.target.value * this.props.label_Width/this.state.label_dim.width/2) * 2;
                    fb.height = (e.target.value * this.props.label_Width/this.state.label_dim.width/2) * 2;

                    this.setState({
                        shapeHeight: e.target.value,
                    })

                }

            }
            let cx1 = this.state.shape_array;
            this.setState({
                shape_array: cx1,
                shapeWidth:e.target.value,
            })
        }
        }


    onnumberImageHeight(e) {


        if (this.state.chosenImageIndex >= 0) {
            let img_h = (e.target.value * this.props.label_Height / this.state.label_dim.height)

            // let img_h = (fb.height * this.state.label_dim.height/this.props.label_Height).toFixed(0)
            this.setState({
                imageHeight: e.target.value,
            })
            let fb = this.state.image_array[this.state.chosenImageIndex];
            let bx = fb.naturalWidth/fb.naturalHeight
            if (fb.stretch) {
                fb.height = img_h;
                this.setState({
                    img_dim: {
                        ...this.state.img_dim,
                        height: e.target.value,
                    },
                    imageHeight: e.target.value,
                })
            } else {
                fb.height = img_h;
                fb.width = img_h * bx
                this.setState({
                    img_dim: {
                        ...this.state.img_dim,
                        width: e.target.value * bx,
                        height: e.target.value,
                    },
                    imageWidth: (e.target.value * bx).toFixed(1),
                    imageHeight:e.target.value,
                })
            }
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fb);
            let cx1 = this.state.image_array;
            this.setState({
                image_array: cx1,
            })

        }
        if (this.state.chosenShapeIndex >= 0){
            this.setState({
                shapeHeight: e.target.value,
            })
        }

        if (this.state.chosenShapeIndex >= 0 && e.target.value > 0) {
            let fb = this.state.shape_array[this.state.chosenShapeIndex];

            if (fb.type === 'square'){
                if (fb.stretch){
                    fb.height  = e.target.value * this.props.label_Height/this.state.label_dim.height;

                }
                else {
                    fb.width  = e.target.value * this.props.label_Height/this.state.label_dim.height;
                    fb.height = e.target.value * this.props.label_Height/this.state.label_dim.height;

                    this.setState({
                        shapeWidth: e.target.value,
                    })

                }

            }
            if (fb.type === 'circle'){
                if (fb.stretch){
                    fb.radiusY  = e.target.value * this.props.label_Height/this.state.label_dim.height/2;
                    fb.height = (e.target.value * this.props.label_Height/this.state.label_dim.height/2) * 2;

                }
                else {
                    fb.radius  = e.target.value * this.props.label_Height/this.state.label_dim.height/2;
                    fb.width = (e.target.value * this.props.label_Height/this.state.label_dim.height/2) * 2;
                    fb.height = (e.target.value * this.props.label_Height/this.state.label_dim.height/2) * 2;

                    this.setState({
                        shapeWidth: e.target.value,
                    })

                }

            }
            let cx1 = this.state.shape_array;
            this.setState({
                shape_array: cx1,
                shapeHeight:e.target.value,
            })
        }
    }

    onnumberImageWidthPressMouseUp(){
        clearInterval(this.ImageWidthMouseinterval);

    }
    onnumberImageWidthPressMouseDown() {
        if (this.state.chosenImageIndex >= 0) {
            this.ImageWidthMouseinterval = setInterval(() => {
            let fb = this.state.image_array[this.state.chosenImageIndex];
            let bx = fb.naturalWidth/fb.naturalHeight
            if (fb.stretch) {
                let img_w = ((fb.width * this.state.label_dim.width/this.props.label_Width)+1)
                fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width
                this.setState({
                    img_dim: {
                        ...this.state.img_dim,
                        width: fb.width,
                    },
                    imageWidth: img_w,
                })
            } else {
                fb.width = Number(fb.width)
                fb.height = Number(fb.height)

                let img_w = ((fb.width * this.state.label_dim.width/this.props.label_Width)+1).toFixed(1)
                let bx = fb.naturalWidth/fb.naturalHeight

                let img_h = (Number(img_w)/bx).toFixed(1)

                fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width
                fb.height = Number(img_h)*this.props.label_Height/this.state.label_dim.height



                this.setState({
                    img_dim: {
                        ...this.state.img_dim,
                        width: fb.width,
                        height: fb.height,
                    },
                    imageWidth: img_w,
                    imageHeight: img_h,
                })
            }
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fb);
            let cx1 = this.state.image_array;
            this.setState({
                image_array: cx1,
            })
            }, 100);

        }
        if (this.state.chosenShapeIndex >= 0) {
            this.ImageWidthMouseinterval = setInterval(() => {
            let fb = this.state.shape_array[this.state.chosenShapeIndex];
            if (fb.type === 'line'){
                fb.width = Number(fb.width)
                let img_w = ((fb.width * this.state.label_dim.width/this.props.label_Width)+1).toFixed(1)
                fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width
                this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);
                this.setState({
                    shapeWidth: img_w,

                })
            }
            if (fb.type === 'square'){
                if (fb.stretch){
                    // fb.width  = fb.width+1;
                    fb.width = Number(fb.width)
                    let img_w = ((fb.width * this.state.label_dim.width/this.props.label_Width)+1).toFixed(1)
                    fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width
                    this.setState({
                        shapeWidth: img_w,

                    })
                }
                else {

                    fb.width = Number(fb.width)
                    fb.height = Number(fb.height)

                    let img_w = ((fb.width * this.state.label_dim.width/this.props.label_Width)+1).toFixed(1)

                    fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width
                    fb.height = Number(img_w)*this.props.label_Height/this.state.label_dim.height

                    this.setState({
                        shapeHeight: img_w,
                        shapeWidth: img_w,

                    })

                }

            }
            if (fb.type === 'circle'){
                if (fb.stretch){

                    fb.width = Number(fb.width)

                    let img_w = ((fb.width * this.state.label_dim.width/this.props.label_Width)+1).toFixed(1)

                    fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width

                    fb.radiusX  = (fb.width/2)+1;

                    this.setState({
                        shapeWidth: img_w,

                    })

                }
                else {

                    fb.width = Number(fb.width)
                    fb.height = Number(fb.height)

                    let img_w = ((fb.width * this.state.label_dim.width/this.props.label_Width)+1).toFixed(1)

                    fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width
                    fb.height = Number(img_w)*this.props.label_Height/this.state.label_dim.height

                    fb.radius  = (fb.width/2)+1;

                    this.setState({
                        shapeHeight: img_w,
                        shapeWidth: img_w,

                    })

                }

            }
            let cx1 = this.state.shape_array;
            this.setState({
                shape_array: cx1,
            })
            }, 100);
        }
    }
    onnumberImageWidthPressMouseUPLess(){
        clearInterval(this.ImageWidthMouseintervalLess);

    }
    onnumberImageWidthPressMouseDownLess() {
        if (this.state.chosenImageIndex >= 0) {
            this.ImageWidthMouseintervalLess = setInterval(() => {
                let fb = this.state.image_array[this.state.chosenImageIndex];
                let bx = fb.naturalWidth/fb.naturalHeight
                if (fb.stretch) {
                    let img_w = ((fb.width * this.state.label_dim.width/this.props.label_Width)-1)
                    fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width

                    this.setState({
                        img_dim: {
                            ...this.state.img_dim,
                            width: fb.width,
                        },
                        imageWidth: img_w,
                    })
                } else {
                    fb.width = Number(fb.width)
                    fb.height = Number(fb.height)

                    let img_w = ((fb.width * this.state.label_dim.width/this.props.label_Width)-1).toFixed(1)
                    let bx = fb.naturalWidth/fb.naturalHeight

                    let img_h = (Number(img_w)/bx).toFixed(1)


                    fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width
                    fb.height = Number(img_h)*this.props.label_Height/this.state.label_dim.height

                    this.setState({
                        img_dim: {
                            ...this.state.img_dim,
                            width: fb.width,
                            height: fb.height,
                        },
                        imageWidth: img_w,
                        imageHeight: img_h,
                    })

                }
                this.state.image_array.splice(this.state.chosenImageIndex, 1, fb);
                let cx1 = this.state.image_array;
                this.setState({
                    image_array: cx1,
                })
            }, 100);
        }
        if (this.state.chosenShapeIndex >= 0) {
            this.ImageWidthMouseintervalLess = setInterval(() => {
                let fb = this.state.shape_array[this.state.chosenShapeIndex];
                if (fb.type === 'line'){
                    fb.width = Number(fb.width)

                    let img_w = ((fb.width * this.state.label_dim.width/this.props.label_Width)-1).toFixed(1)

                    fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width
                    this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);

                    this.setState({
                        shapeWidth: img_w,
                    })
                }
                if (fb.type === 'square'){
                    if (fb.stretch){
                        fb.width = Number(fb.width)

                        let img_w = ((fb.width * this.state.label_dim.width/this.props.label_Width)-1).toFixed(1)

                        fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width

                        this.setState({
                            shapeWidth: img_w,
                        })

                    }
                    else {
                        fb.width = Number(fb.width)
                        fb.height = Number(fb.height)

                        let img_w = ((fb.width * this.state.label_dim.width/this.props.label_Width)-1).toFixed(1)

                        fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width
                        fb.height = Number(img_w)*this.props.label_Height/this.state.label_dim.height

                        this.setState({
                            shapeHeight: img_w,
                            shapeWidth: img_w,

                        })

                    }

                }
                if (fb.type === 'circle'){
                    if (fb.stretch){
                        fb.width = Number(fb.width)

                        let img_w = ((fb.width * this.state.label_dim.width/this.props.label_Width)-1).toFixed(1)

                        fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width
                        fb.radiusX  = (fb.width/2)-1;


                        this.setState({
                            shapeWidth: img_w,
                        })

                    }
                    else {

                        fb.width = Number(fb.width)
                        fb.height = Number(fb.height)

                        let img_w = ((fb.width * this.state.label_dim.width/this.props.label_Width)-1).toFixed(1)

                        fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width
                        fb.height = Number(img_w)*this.props.label_Height/this.state.label_dim.height

                        fb.radius  = (fb.width/2)-1;


                        this.setState({
                            shapeHeight: img_w,
                            shapeWidth: img_w,

                        })

                    }

                }
                let cx1 = this.state.shape_array;
                this.setState({
                    shape_array: cx1,
                })
            }, 100);
        }
    }
    onnumberImageHeightPressMouseUp(){
        clearInterval(this.ImageHeightMouseinterval);

    }
    onnumberImageHeightPressMouseDown() {
        if (this.state.chosenImageIndex >= 0) {
            this.ImageHeightMouseinterval = setInterval(() => {
            let fb = this.state.image_array[this.state.chosenImageIndex];

                if (fb.stretch) {
                let img_h = ((fb.height * this.state.label_dim.height/this.props.label_Height)+1)
                fb.height = Number(img_h)*this.props.label_Height/this.state.label_dim.height

                    this.setState({
                    img_dim: {
                        ...this.state.img_dim,
                        height: fb.height,
                    },
                    imageHeight: img_h,
                })
            } else {
                fb.width = Number(fb.width)
                fb.height = Number(fb.height)

                let img_h = ((fb.height * this.state.label_dim.height/this.props.label_Height)+1).toFixed(1)
                let bx = fb.naturalWidth/fb.naturalHeight
                 let img_w = (Number(img_h)*bx).toFixed(1)


                fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width
                fb.height = Number(img_h)*this.props.label_Height/this.state.label_dim.height

                    this.setState({
                    img_dim: {
                        ...this.state.img_dim,
                        width: (fb.width) ,
                        height: fb.height,
                    },
                    imageWidth: img_w,
                    imageHeight:img_h,
                })
            }
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fb);
            let cx1 = this.state.image_array;
            this.setState({
                image_array: cx1,
            })

        }, 100);
        }

        if (this.state.chosenShapeIndex >= 0) {
            this.ImageHeightMouseinterval = setInterval(() => {
            let fb = this.state.shape_array[this.state.chosenShapeIndex];

            if (fb.type === 'square'){
                if (fb.stretch){
                    fb.height = Number(fb.height)

                    let img_h = ((fb.height * this.state.label_dim.height/this.props.label_Height)+1).toFixed(1)
                    fb.height = Number(img_h)*this.props.label_Height/this.state.label_dim.height

                    this.setState({
                        shapeHeight: img_h,
                    })

                }
                else {
                    fb.width = Number(fb.width)
                    fb.height = Number(fb.height)

                    let img_h = ((fb.height * this.state.label_dim.height/this.props.label_Height)+1).toFixed(1)

                    fb.width = (Number(img_h)*this.props.label_Width)/this.state.label_dim.width
                    fb.height = Number(img_h)*this.props.label_Height/this.state.label_dim.height

                    this.setState({
                        shapeWidth: img_h,
                        shapeHeight: img_h,
                    })

                }

            }
            if (fb.type === 'circle'){

                if (fb.stretch){
                    fb.height = Number(fb.height)

                    let img_h = ((fb.height * this.state.label_dim.height/this.props.label_Height)+1).toFixed(1)
                    fb.height = Number(img_h)*this.props.label_Height/this.state.label_dim.height
                    fb.radiusY  = (fb.height)/2+1

                    this.setState({
                        shapeHeight: img_h,
                    })

                }
                else {
                    fb.width = Number(fb.width)
                    fb.height = Number(fb.height)

                    let img_h = ((fb.height * this.state.label_dim.height/this.props.label_Height)+1).toFixed(1)

                    fb.width = (Number(img_h)*this.props.label_Width)/this.state.label_dim.width
                    fb.height = Number(img_h)*this.props.label_Height/this.state.label_dim.height
                    fb.radius  = (fb.width/2)+1

                    this.setState({
                        shapeWidth: img_h,
                        shapeHeight: img_h,
                    })

                }

            }
            let cx1 = this.state.shape_array;
            this.setState({
                shape_array: cx1,
            })
            }, 100);
        }

    }

    onnumberImageHeightPressMouseUPLess(){
        clearInterval(this.ImageHeightMouseintervalLess);

    }
    onnumberImageHeightPressMouseDownLess() {
        if (this.state.chosenImageIndex >= 0) {
            this.ImageHeightMouseintervalLess = setInterval(() => {
                let fb = this.state.image_array[this.state.chosenImageIndex];
                if (fb.stretch) {

                    let img_h = ((fb.height * this.state.label_dim.height/this.props.label_Height)-1)
                    fb.height = Number(img_h)*this.props.label_Height/this.state.label_dim.height
                    this.setState({
                        img_dim: {
                            ...this.state.img_dim,
                            height: fb.height,
                        },
                        imageHeight: img_h,
                    })
                } else {
                    fb.width = Number(fb.width)
                    fb.height = Number(fb.height)

                    let img_h = ((fb.height * this.state.label_dim.height/this.props.label_Height)-1).toFixed(1)
                    let bx = fb.naturalWidth/fb.naturalHeight
                    let img_w = (Number(img_h)*bx).toFixed(1)

                    fb.width = (Number(img_w)*this.props.label_Width)/this.state.label_dim.width
                    fb.height = Number(img_h)*this.props.label_Height/this.state.label_dim.height

                    this.setState({
                        img_dim: {
                            ...this.state.img_dim,
                            width: (fb.width),
                            height: fb.height,
                        },
                        imageWidth: img_w,
                        imageHeight:img_h,
                    })
                }
                this.state.image_array.splice(this.state.chosenImageIndex, 1, fb);
                let cx1 = this.state.image_array;
                this.setState({
                    image_array: cx1,
                })

            }, 100);
        }

        if (this.state.chosenShapeIndex >= 0) {
            this.ImageHeightMouseintervalLess = setInterval(() => {
                let fb = this.state.shape_array[this.state.chosenShapeIndex];

                if (fb.type === 'square'){

                    if (fb.stretch){
                        fb.height = Number(fb.height)

                        let img_h = ((fb.height * this.state.label_dim.height/this.props.label_Height)-1).toFixed(1)

                        fb.height = Number(img_h)*this.props.label_Height/this.state.label_dim.height

                        this.setState({
                            shapeHeight: img_h,
                        })
                    }
                    else {
                        fb.width = Number(fb.width)
                        fb.height = Number(fb.height)

                        let img_h = ((fb.height * this.state.label_dim.height/this.props.label_Height)-1).toFixed(1)

                        fb.width = (Number(img_h)*this.props.label_Width)/this.state.label_dim.width
                        fb.height = Number(img_h)*this.props.label_Height/this.state.label_dim.height

                        this.setState({
                            shapeWidth:img_h,
                            shapeHeight: img_h,
                        })

                    }

                }
                if (fb.type === 'circle'){

                    if (fb.stretch){
                        fb.height = Number(fb.height)

                        let img_h = ((fb.height * this.state.label_dim.height/this.props.label_Height)-1).toFixed(1)
                        fb.height = Number(img_h)*this.props.label_Height/this.state.label_dim.height
                        fb.radiusY  = (fb.height/2)-1;

                        this.setState({
                            shapeHeight: img_h,
                        })

                    }
                    else {
                        fb.width = Number(fb.width)
                        fb.height = Number(fb.height)

                        let img_h = ((fb.height * this.state.label_dim.height/this.props.label_Height)-1).toFixed(1)

                        fb.width = (Number(img_h)*this.props.label_Width)/this.state.label_dim.width
                        fb.height = Number(img_h)*this.props.label_Height/this.state.label_dim.height

                        fb.radius  = (fb.width/2)-1;

                        this.setState({
                            shapeWidth:img_h,
                            shapeHeight: img_h,
                        })

                    }

                }
                let cx1 = this.state.shape_array;
                this.setState({
                    shape_array: cx1,
                })
            }, 100);
        }

    }

    changeWidth (e){
        if (isNaN(e.target.value) === false){
            this.setState({
                label_dim: {
                    ...this.state.label_dim,
                    width: e.target.value
                },
            })
        }

    }
    changeHeight (e){
        if (isNaN(e.target.value) === false) {
            this.setState({
                label_dim:{
                    ...this.state.label_dim,
                    height: e.target.value,
                }
            })
        }

        }

    changelAcross (e){
        if (isNaN(e.target.value) === false) {
            this.setState({
                label_dim: {
                    ...this.state.label_dim,
                    label_across: e.target.value
                }
            })
        }
    }
    changelmar (e){
        if (isNaN(e.target.value) === false) {
            this.setState({
                label_dim: {
                    ...this.state.label_dim,
                    leftMargin: e.target.value,
                }
            })

        }
    }
    changermar (e){
        if (isNaN(e.target.value) === false) {
            this.setState({
                label_dim: {
                    ...this.state.label_dim,
                    rightMargin: e.target.value
                }
            })
        }
    }
    changePrefixText (e){
        if (this.state.chosenTextIndex >= 0) {

            let fx = this.state.text[this.state.chosenTextIndex];

            this.setState({prefix: e.target.value})
            fx.prefix = e.target.value;
            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })
        }
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

            this.setState({prefix: e.target.value})
            fx.prefix = e.target.value;
            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                }
            })

            let fb2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

            fb2.value = e.target.value+fx.text+fx.suffix

            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2);
            let cx1 = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcode: cx1,
                }
            })
        }

    }
    textXPosOnChange (e){
        if (this.state.chosenTextIndex >= 0 && Number(e.target.value) <= Number(this.state.label_dim.width)) {

            let fx2 = this.state.text[this.state.chosenTextIndex];

            this.setState({textXPos: e.target.value})

            if (fx2.textAlign === 'left') {

                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_range = e.target.value / x_resize
                fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                let cx = this.state.text;
                this.setState({
                    text: cx,
                })
            }
            if (fx2.textAlign === 'center') {

                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_range = e.target.value / x_resize

                fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                fx2.right = window.innerWidth - (fx2.left+this.state.objectPosition.width)

                this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                let cx = this.state.text;
                this.setState({
                    text: cx,
                })
            }
            if (fx2.textAlign === 'right') {

                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_range = e.target.value / x_resize

                let t_left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                fx2.right = window.innerWidth - (t_left+this.state.objectPosition.width)

                this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                let cx = this.state.text;
                this.setState({
                    text: cx,
                })
            }
        } else {
            if (Number(e.target.value) > Number(this.state.label_dim.width)){
                this.setState({xPosError:true})

            }
        }
        if (this.state.chosenBarcodeIndex >= 0 && Number(e.target.value) <= Number(this.state.label_dim.width)) {

            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

            this.setState({textXPos: e.target.value})

            if (fx2.textAlign === 'left') {

                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_range = e.target.value / x_resize
                fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject:{
                        ...this.state.barcodeObject,
                        barcodeText: cx,
                    }
                })
            }
            if (fx2.textAlign === 'center') {

                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_range = e.target.value / x_resize

                fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                fx2.right = window.innerWidth - (fx2.left+this.state.objectPosition_barcodeText.width)

                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject:{
                        ...this.state.barcodeObject,
                        barcodeText: cx,
                    }
                })
            }
            if (fx2.textAlign === 'right') {

                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_range = e.target.value / x_resize

                let t_left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                fx2.right = window.innerWidth - (t_left+this.state.objectPosition_barcodeText.width)

                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject:{
                        ...this.state.barcodeObject,
                        barcodeText: cx,
                    }
                })
            }
        } else {
            if (Number(e.target.value) > Number(this.state.label_dim.width)){
                this.setState({xPosError:true})

            }
        }

    }
    textYPosOnChange (e){
        if (this.state.chosenTextIndex >= 0 && Number(e.target.value) <= Number(this.state.label_dim.height)) {

            let fx2 = this.state.text[this.state.chosenTextIndex];

            this.setState({textYPos: e.target.value})

            let y_resize = (this.state.label_dim.height /this.props.label_Height);
            let y_range = e.target.value/ y_resize;

            fx2.top = y_range  - fx2.deltaPositionY + this.props.label_Y +  window.innerWidth* 0.095;

            this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })
        } else {
            if (Number(e.target.value) > Number(this.state.label_dim.height)){
                this.setState({xPosError:true})

            }
        }
        if (this.state.chosenBarcodeIndex >= 0 && Number(e.target.value) <= Number(this.state.label_dim.height)) {

            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

            this.setState({textYPos: e.target.value})

            let y_resize = (this.state.label_dim.height /this.props.label_Height);
            let y_range = e.target.value/ y_resize;

            fx2.top = y_range  - fx2.deltaPositionY + this.props.label_Y +  window.innerWidth* 0.095;


            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                }
            })
        } else {
            if (Number(e.target.value) > Number(this.state.label_dim.height)){
                this.setState({xPosError:true})

            }
        }

    }
    barcodeXPosOnChange (e) {
        if (this.state.chosenBarcodeIndex >= 0 && this.state.barcodeValue && Number(e.target.value) <= Number(this.state.label_dim.width)) {

            let fx2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

            this.setState({barcodeXPos: e.target.value})


            let x_resize = (this.state.label_dim.width / this.props.label_Width);
            let x_range = e.target.value / x_resize
            fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcode: cx,
                }

            })

        }else {
            if (Number(e.target.value) > Number(this.state.label_dim.width)){
                this.setState({xPosError:true})

            }
        }
    }
    barcodeYPosOnChange (e){
        if (this.state.chosenBarcodeIndex >= 0 && this.state.barcodeValue && Number(e.target.value) <= Number(this.state.label_dim.height)) {

            let fx2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

            this.setState({barcodeYPos: e.target.value})

            let y_resize = (this.state.label_dim.height /this.props.label_Height);
            let y_range = e.target.value/ y_resize;

            fx2.top = y_range  - fx2.deltaPositionY + this.props.label_Y +  window.innerWidth* 0.095;


            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcode: cx,

                }
            })
        }else {
            if (Number(e.target.value) > Number(this.state.label_dim.height)){
                this.setState({xPosError:true})

            }
        }

    }
    imageXPosOnChange (e) {


        if (this.state.chosenImageIndex >= 0 && Number(e.target.value) <= Number(this.state.label_dim.width)) {
            let fx2 = this.state.image_array[this.state.chosenImageIndex];
            this.setState({imageXPos: e.target.value})
            let x_resize = (this.state.label_dim.width / this.props.label_Width);
            let x_range = e.target.value / x_resize
            fx2.left = Math.round(x_range) - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fx2);
            let cx = this.state.image_array;
            this.setState({
                image_array: cx,
            })
        }else {
            if (Number(e.target.value) > Number(this.state.label_dim.width)){
                this.setState({xPosError:true})

            }
        }

        if (this.state.chosenShapeIndex >= 0 && Number(e.target.value) <= Number(this.state.label_dim.width)) {
            let fx2 = this.state.shape_array[this.state.chosenShapeIndex];
            this.setState({shapeXPos: e.target.value})
            let x_resize = (this.state.label_dim.width / this.props.label_Width);
            let x_range = e.target.value / x_resize
            fx2.left = Math.round(x_range) - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx2);
            let cx = this.state.shape_array;
            this.setState({
                shape_array: cx,
            })
        }else {
            if (Number(e.target.value) > Number(this.state.label_dim.width)){
                this.setState({xPosError:true})

            }
        }

    }
    imageYPosOnChange (e) {
        if (this.state.chosenImageIndex >= 0 && Number(e.target.value) <= Number(this.state.label_dim.height)) {
            let fx2 = this.state.image_array[this.state.chosenImageIndex];
            this.setState({imageYPos: e.target.value})
            let y_resize = (this.state.label_dim.height /this.props.label_Height);
            let y_range = e.target.value/ y_resize;
            fx2.top = y_range  - fx2.deltaPositionY + (window.innerWidth * 0.095)+this.props.label_Y;
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fx2);
            let cx = this.state.image_array;
            this.setState({
                image_array: cx,
            })
        }else {
            if (Number(e.target.value) > Number(this.state.label_dim.height)){
                this.setState({xPosError:true})

            }
        }
        if (this.state.chosenShapeIndex >= 0 && Number(e.target.value) <= Number(this.state.label_dim.height)) {
            let fx2 = this.state.shape_array[this.state.chosenShapeIndex];
            this.setState({shapeYPos: e.target.value})
            let y_resize = (this.state.label_dim.height /this.props.label_Height);
            let y_range = e.target.value/ y_resize;
            fx2.top = y_range  - fx2.deltaPositionY + (window.innerWidth * 0.095)+this.props.label_Y;
            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx2);
            let cx = this.state.shape_array;
            this.setState({
                shape_array: cx,
            })
        }else {
            if (Number(e.target.value) > Number(this.state.label_dim.height)){
                this.setState({xPosError:true})

            }
        }


    }
    onImageLeftMouseUp11(){
        clearInterval(this.ImageLeftinterval);

    }


    onImageLeftMouseDown11() {
        if (this.state.chosenImageIndex >= 0) {
            let fx2 = this.state.image_array[this.state.chosenImageIndex];
            if (!fx2.lock) {
                this.ImageLeftinterval = setInterval(() => {
                        let rx = Number(this.getImageXposObject())-0.1

                        let x_resize = (this.state.label_dim.width / this.props.label_Width);
                        let x_range = rx / x_resize
                        fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;

                        let x_range1 = fx2.left + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25 ;
                        let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                        let x_pos_real = x_range1 * x_resize1
                        let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                        this.setState({
                            imageXPos: x_pos
                        })
                        this.state.image_array.splice(this.state.chosenImageIndex, 1, fx2)
                        let cx = this.state.image_array;
                        this.setState({
                            image_array:cx,
                        })
                    }
                    , 50);
            }
        }
        if (this.state.chosenShapeIndex >= 0) {
            let fx2 = this.state.shape_array[this.state.chosenShapeIndex];
            if (!fx2.lock) {
                this.ImageLeftinterval = setInterval(() => {
                        let rx = Number(this.getImageXposObject())-0.1

                        let x_resize = (this.state.label_dim.width / this.props.label_Width);
                        let x_range = rx / x_resize
                        fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;

                        let x_range1 = fx2.left + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25 ;
                        let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                        let x_pos_real = x_range1 * x_resize1
                        let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                        this.setState({
                            shapeXPos: x_pos
                        })
                        this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx2)
                        let cx = this.state.shape_array;
                        this.setState({
                            shape_array:cx,
                        })
                    }
                    , 50);
            }
        }
    }
    onImageRightMouseUp11(){
        clearInterval(this.ImageRightinterval);

    }


    onImageRightMouseDown11() {
        if (this.state.chosenImageIndex >= 0) {
            let fx2 = this.state.image_array[this.state.chosenImageIndex];
            if (!fx2.lock) {
                this.ImageRightinterval = setInterval(() => {
                        let rx = Number(this.getImageXposObject())+0.1

                        let x_resize = (this.state.label_dim.width / this.props.label_Width);
                        let x_range = rx / x_resize
                        fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;

                        let x_range1 = fx2.left + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25 ;
                        let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                        let x_pos_real = x_range1 * x_resize1
                        let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                        this.setState({
                            imageXPos: x_pos
                        })
                        this.state.image_array.splice(this.state.chosenImageIndex, 1, fx2)
                        let cx = this.state.image_array;
                        this.setState({
                            image_array:cx,
                        })
                    }
                    , 50);
            }
        }
        if (this.state.chosenShapeIndex >= 0) {
            let fx2 = this.state.shape_array[this.state.chosenShapeIndex];
            if (!fx2.lock) {
                this.ImageRightinterval = setInterval(() => {
                        let rx = Number(this.getImageXposObject())+0.1

                        let x_resize = (this.state.label_dim.width / this.props.label_Width);
                        let x_range = rx / x_resize
                        fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;

                        let x_range1 = fx2.left + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25 ;
                        let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                        let x_pos_real = x_range1 * x_resize1
                        let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                        this.setState({
                            shapeXPos: x_pos
                        })
                        this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx2)
                        let cx = this.state.shape_array;
                        this.setState({
                            shape_array:cx,
                        })
                    }
                    , 50);
            }
        }
    }
    onImageUpMouseUp11(){
        clearInterval(this.ImageUpinterval);

    }


    onImageUpMouseDown11() {
        if (this.state.chosenImageIndex >= 0) {
            let fx2 = this.state.image_array[this.state.chosenImageIndex];
            if (!fx2.lock) {
                this.ImageUpinterval = setInterval(() => {
                        let rx = Number(this.getImageYposObject())-0.1

                        let y_resize = (this.state.label_dim.height /this.props.label_Height);
                        let y_range = rx/ y_resize;
                        fx2.top = y_range  - fx2.deltaPositionY + (window.innerWidth * 0.095)+this.props.label_Y;

                        let y_range1 = fx2.top + fx2.deltaPositionY - this.props.label_Y -(window.innerWidth * 0.095);
                        let y_resize1 = (this.state.label_dim.height / this.props.label_Height);
                        let y_pos_real = y_range1 * y_resize1
                        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                        this.setState({
                            imageYPos: y_pos
                        })
                        this.state.image_array.splice(this.state.chosenImageIndex, 1, fx2)
                        let cx = this.state.image_array;
                        this.setState({
                            image_array:cx,
                        })
                    }
                    , 50);
            }
        }
        if (this.state.chosenShapeIndex >= 0) {
            let fx2 = this.state.shape_array[this.state.chosenShapeIndex];
            if (!fx2.lock) {
                this.ImageUpinterval = setInterval(() => {
                        let rx = Number(this.getImageYposObject())-0.1

                        let y_resize = (this.state.label_dim.height /this.props.label_Height);
                        let y_range = rx/ y_resize;
                        fx2.top = y_range  - fx2.deltaPositionY + (window.innerWidth * 0.095)+this.props.label_Y;

                        let y_range1 = fx2.top + fx2.deltaPositionY - this.props.label_Y -(window.innerWidth * 0.095);
                        let y_resize1 = (this.state.label_dim.height / this.props.label_Height);
                        let y_pos_real = y_range1 * y_resize1
                        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                        this.setState({
                            shapeYPos: y_pos
                        })
                        this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx2)
                        let cx = this.state.shape_array;
                        this.setState({
                            shape_array:cx,
                        })
                    }
                    , 50);
            }
        }
    }
    onImageDownMouseUp11(){
        clearInterval(this.ImageDowninterval);

    }


    onImageDownMouseDown11() {
        if (this.state.chosenImageIndex >= 0) {
            let fx2 = this.state.image_array[this.state.chosenImageIndex];
            if (!fx2.lock) {
                this.ImageDowninterval = setInterval(() => {
                        let rx = Number(this.getImageYposObject())+0.1

                        let y_resize = (this.state.label_dim.height /this.props.label_Height);
                        let y_range = rx/ y_resize;
                        fx2.top = y_range  - fx2.deltaPositionY + (window.innerWidth * 0.095)+this.props.label_Y;

                        let y_range1 = fx2.top + fx2.deltaPositionY - this.props.label_Y -(window.innerWidth * 0.095);
                        let y_resize1 = (this.state.label_dim.height / this.props.label_Height);
                        let y_pos_real = y_range1 * y_resize1
                        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                        this.setState({
                            imageYPos: y_pos
                        })
                        this.state.image_array.splice(this.state.chosenImageIndex, 1, fx2)
                        let cx = this.state.image_array
                        this.setState({
                            image_array:cx,
                        })
                    }
                    , 50);
            }
        }
        if (this.state.chosenShapeIndex >= 0) {
            let fx2 = this.state.shape_array[this.state.chosenShapeIndex];
            if (!fx2.lock) {
                this.ImageDowninterval = setInterval(() => {
                        let rx = Number(this.getImageYposObject())+0.1

                        let y_resize = (this.state.label_dim.height /this.props.label_Height);
                        let y_range = rx/ y_resize;
                        fx2.top = y_range  - fx2.deltaPositionY + (window.innerWidth * 0.095)+this.props.label_Y;

                        let y_range1 = fx2.top + fx2.deltaPositionY - this.props.label_Y -(window.innerWidth * 0.095);
                        let y_resize1 = (this.state.label_dim.height / this.props.label_Height);
                        let y_pos_real = y_range1 * y_resize1
                        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                        this.setState({
                            shapeYPos: y_pos
                        })
                        this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx2)
                        let cx = this.state.shape_array
                        this.setState({
                            shape_array:cx,
                        })
                    }
                    , 50);
            }
        }
    }


    changeSuffixText (e){
        if (this.state.chosenTextIndex >= 0) {

            let fx = this.state.text[this.state.chosenTextIndex];

            this.setState({suffix: e.target.value})
            fx.suffix = e.target.value;
            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })
        }
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

            this.setState({suffix: e.target.value})
            fx.suffix = e.target.value;
            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                }
            })
            let fb2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

            fb2.value = fx.prefix+fx.text+e.target.value

            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2);
            let cx1 = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcode: cx1,
                }
            })
        }

    }
    changeSelectedText (e){

        if (this.state.chosenTextIndex >= 0) {
            let dx = document.getElementById('text'+this.state.chosenTextIndex)

            let fx = this.state.text[this.state.chosenTextIndex];
            this.setState({showText: e.target.value})
            var width = require('text-width');
            let wt = 'normal';
            if (fx.bold) {
                wt = 'bold';
            }
            var w = width(e.target.value, {
                family: fx.fontFamily,
                size: fx.fontSize,
                weight: wt
            });
            // if (dx && fx.textAlign === 'center'){
            //     fx.outlineSelected = true
            //     let rg =  window.innerWidth - ((dx.offsetLeft+dx.offsetWidth))
            //     fx.left = dx.offsetLeft;
            //     fx.right = rg;
            //     fx.width = null
            //
            //     if (w+10 < dx.offsetWidth){
            //         fx.width = w
            //         fx.left += dx.offsetWidth/2 - (w/2)
            //
            //     }
            //
            //
            // }
            fx.text = e.target.value;
            let tb = [...this.state.text]
            for (let t = 0 ; t < fx.copyChildIndex.length; t++){
                let tv = tb[fx.copyChildIndex[t]]
                tv.text = e.target.value

                this.setState({
                    text: tb,
                })
            }

            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })
        }
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            let fb2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

            this.setState({showText: e.target.value})
            fx.text = e.target.value;
            let tb = [...this.state.barcodeObject.barcodeText]
            for (let t = 0 ; t < fx.copyChildIndex.length; t++){
                let tv = tb[fx.copyChildIndex[t]]
                tv.text = e.target.value

                this.setState({
                    barcodeObject:{
                        ...this.state.barcodeObject,
                        barcodeText: tb,
                    }
                })
            }

            this.setState({barcodeValue: e.target.value})
            fb2.value = e.target.value
            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2);
            let cx1 = this.state.barcodeObject.barcode;

            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                    barcode: cx1,
                }
            })
        }

    }

    changeSelectedPinText (e){
        if (this.state.chosenTextIndex >= 0) {

            let fx = this.state.text[this.state.chosenTextIndex];
            this.setState({pinText: e.target.value})
            fx.pinText = e.target.value;

            let g = [...this.state.text]

            for (let j = 0; j < fx.copyChildIndex; j++){
                if(g[fx.copyChildIndex[j]]){
                    g[fx.copyChildIndex[j]].copyDataLabel = e.target.value
                }

            }
            this.setState({
                text: g
            })


            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })

        }
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            this.setState({pinText: e.target.value})
            fx.pinText = e.target.value;

            let g = [...this.state.barcodeObject.barcodeText]

            for (let j = 0; j < fx.copyChildIndex; j++){
                if(g[fx.copyChildIndex[j]]) {
                    g[fx.copyChildIndex[j]].copyDataLabel = e.target.value
                }
            }
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: g,
                }
            })

            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                }
            })

        }

    }
    checkDecoration(item){
        let underline = item.underline;
        let linethrough = item.lineThrough;
        if (underline && linethrough){
            return 'line-through underline'

        }
        if (!underline && linethrough){
            return 'line-through'

        }
        if (underline && !linethrough){
            return 'underline'

        }
        else {
            return null;
        }


    }
    onMultilineWidth (){
        if(this.state.chosenTextIndex >= 0) {
            if (this.state.showText) {
                let fx2 = this.state.text[this.state.chosenTextIndex];
                if (fx2.multiline) {
                    var width = require('text-width');
                    let wt = 'normal';
                    var w = width(fx2.prefix + fx2.text + fx2.suffix, {
                        family: fx2.fontFamily,
                        size: fx2.fontSize,
                        weight: wt
                    });
                    var st = w - this.state.objectPosition.width;
                    this.setState({
                        objectPosition: {
                            ...this.state.objectPosition,
                            right: this.state.objectPosition.right + st + 2,
                            width: w + 2
                        }
                    })

                    let dxx = document.getElementById('text'+this.state.chosenTextIndex)
                    if(dxx){
                        fx2.multiline = false;
                        this.setState({multiline: false})
                        if (fx2.textAlign === 'left') {
                            fx2.textAlign = 'left';
                            fx2.left = dxx.offsetLeft;
                            fx2.width = null;
                            fx2.outlineSelected = false;
                            fx2.right = null;
                        }
                        if (fx2.textAlign === 'right') {
                            fx2.textAlign = 'right';
                            fx2.width = null;
                            fx2.right = window.innerWidth - ((dxx.offsetLeft+dxx.offsetWidth));
                            fx2.left = null;
                            fx2.outlineSelected = false;
                        }
                        if (fx2.textAlign === 'center') {
                            fx2.outlineSelected = false;
                            fx2.left = dxx.offsetLeft;
                            fx2.right = null;
                            fx2.outlineSelected = false;
                            fx2.width = null
                        }
                        fx2.multilineFontSize = 0
                        fx2.multilineFirst = ''
                        fx2.multilineFontFamily = ''
                        this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                        let cx = this.state.text;
                        this.setState({
                            text: cx,
                        })

                    }

                } else {
                    fx2.multiline = true;
                    fx2.multilineReset = null
                    fx2.width = document.getElementById('text'+this.state.chosenTextIndex).offsetWidth
                    let rf = fx2.fontSize;
                    let rf_mm = (Number(rf) * this.state.label_dim.height)/this.props.label_Height

                    fx2.multilineFontSize = rf_mm /  0.352778
                    fx2.multilineFirst = fx2.prefix+fx2.text+fx2.suffix
                    fx2.multilineFontFamily = fx2.fontFamily

                    let tx_multimm = (this.state.objectPosition.width * this.state.label_dim.width/this.props.label_Width).toFixed(1)

                    this.setState({multiline: true,multilineWidth:tx_multimm,multilineHeight:1.2})
                    this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                    let cx = this.state.text;
                    this.setState({
                        text: cx,
                    })
                }
            }
        }
        if(this.state.chosenBarcodeIndex >= 0) {
            if (this.state.showText) {
                let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                if (fx2.multiline) {
                    var width = require('text-width');
                    let wt = 'normal';
                    var w = width(fx2.prefix + fx2.text + fx2.suffix, {
                        family: fx2.fontFamily,
                        size: fx2.fontSize,
                        weight: wt
                    });
                    var st = w - this.state.objectPosition_barcodeText.width;
                    this.setState({
                        objectPosition_barcodeText: {
                            ...this.state.objectPosition_barcodeText,
                            right: this.state.objectPosition_barcodeText.right + st + 2,
                            width: w + 2
                        }
                    })
                    let dxx = document.getElementById('btext'+this.state.chosenBarcodeIndex)
                    if(dxx){

                        fx2.multiline = false;
                        this.setState({multiline: false})
                        if (fx2.textAlign === 'left') {
                            fx2.textAlign = 'left';
                            fx2.left = dxx.offsetLeft;
                            fx2.width = null;
                            fx2.outlineSelected = false;
                            fx2.right = null;
                        }
                        if (fx2.textAlign === 'right') {
                            fx2.textAlign = 'right';
                            fx2.width = null;
                            fx2.right = window.innerWidth - ((dxx.offsetLeft+dxx.offsetWidth));
                            fx2.left = null;
                            fx2.outlineSelected = false;
                        }
                        if (fx2.textAlign === 'center') {
                            fx2.outlineSelected = false;
                            fx2.left = dxx.offsetLeft;
                            fx2.right = null
                            fx2.width = null
                        }
                        fx2.multilineFontSize = 0
                        fx2.multilineFirst = ''
                        fx2.multilineFontFamily = ''
                        this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                        let cx = this.state.barcodeObject.barcodeText;
                        this.setState({
                            barcodeObject:{
                                ...this.state.barcodeObject,
                                barcodeText: cx,
                            }
                        })

                    }

                } else {
                    fx2.multiline = true;
                    fx2.multilineReset = null
                    var element = document.getElementById('btext'+this.state.chosenBarcodeIndex);
                    fx2.width = element.offsetWidth
                    let rf = fx2.fontSize;
                    let rf_mm = (Number(rf) * this.state.label_dim.height)/this.props.label_Height

                    fx2.multilineFontSize = rf_mm /  0.352778
                    fx2.multilineFirst = fx2.prefix+fx2.text+fx2.suffix
                    fx2.multilineFontFamily = fx2.fontFamily

                    let tx_multimm = (this.state.objectPosition_barcodeText.width * this.state.label_dim.width/this.props.label_Width).toFixed(1)

                    this.setState({multiline: true,multilineWidth:tx_multimm,multilineHeight:1})
                    this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                    let cx = this.state.barcodeObject.barcodeText;
                    this.setState({
                        barcodeObject:{
                            ...this.state.barcodeObject,
                            barcodeText: cx,
                        }
                    })
                }
            }
        }
    }
    onBoldChange(){
        if(this.state.chosenTextIndex >= 0) {
            if (this.state.showText) {
                let fx2 = this.state.text[this.state.chosenTextIndex];
                let fxFont = fx2.fontFamily;


                if (fxFont.includes('Bold') || fxFont.includes('BoldItalic')) {
                    const d = fxFont.indexOf('Bold');
                    fxFont = fxFont.slice(0, d);
                } else {
                    if (fxFont.includes('Italic')) {
                        const dx = fxFont.indexOf('Italic');
                        fxFont = fxFont.slice(0, dx);
                    }
                }
                const fontStyleChecker = (font_styles.indexOf(fxFont) > -1);
                if (fontStyleChecker) {
                    fx2.bold = !fx2.bold;
                    this.setState({bold: fx2.bold});
                    if (fx2.bold) {
                        if (!fx2.fontFamily.includes('Bold')) {

                            if (fx2.fontFamily.includes('Italic')) {
                                const d = fx2.fontFamily.indexOf('Italic');
                                fx2.fontFamily = fx2.fontFamily.slice(0, d) + 'Bold' + fx2.fontFamily.slice(d, fx2.fontFamily.length)

                            } else {
                                fx2.fontFamily = fx2.fontFamily + 'Bold'
                            }
                        }
                    }
                    if (!fx2.bold) {
                        if (fx2.fontFamily.includes('Bold')) {
                            const d = fx2.fontFamily.indexOf('Bold');

                            fx2.fontFamily = fx2.fontFamily.slice(0, d) + fx2.fontFamily.slice(d + 4, fx2.fontFamily.length)
                        }
                    }
                    if (fx2.textAlign === 'center') {
                        fx2.outlineSelected = false;
                        fx2.right = null
                        fx2.width = null
                    }
                    this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                    let cx = this.state.text;
                    this.setState({
                        text: cx,
                    })

                    var width = require('text-width');
                    let wt = 'normal';

                    if (fx2.bold) {
                        wt = 'bold';
                    }
                    var w = width(fx2.prefix + fx2.text + fx2.suffix, {
                        family: fx2.fontFamily,
                        size: fx2.fontSize,
                        weight: wt
                    });
                    let wx = this.state.objectPosition.width - w
                    if (fx2.textAlign === 'left') {
                        this.setState({
                            objectPosition: {
                                ...this.state.objectPosition,
                                right: this.state.objectPosition.right - wx,
                                width: w
                            }
                        })
                    }
                    if (fx2.textAlign === 'right') {
                        this.setState({
                            objectPosition: {
                                ...this.state.objectPosition,
                                left: this.state.objectPosition.left - wx,
                                width: w
                            }
                        })
                    }
                    if (fx2.textAlign === 'center') {
                        this.setState({
                            objectPosition: {
                                ...this.state.objectPosition,
                                left: this.state.objectPosition.left - wx / 2,
                                right: this.state.objectPosition.right - wx / 2,
                                width: w
                            }
                        })
                    }

                }
            } else {
                const fontStyleChecker = (font_styles.indexOf(this.state.fontFamily) > -1);
                if (fontStyleChecker) {
                    this.setState(prevState => ({
                        bold: !prevState.bold
                    }));
                }
            }
        }
        if(this.state.chosenBarcodeIndex >= 0) {
            if (this.state.showText) {
                let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                let fxFont = fx2.fontFamily;

                if (fxFont.includes('Bold') || fxFont.includes('BoldItalic')) {
                    const d = fxFont.indexOf('Bold');
                    fxFont = fxFont.slice(0, d);
                } else {
                    if (fxFont.includes('Italic')) {
                        const dx = fxFont.indexOf('Italic');
                        fxFont = fxFont.slice(0, dx);
                    }
                }
                const fontStyleChecker = (font_styles.indexOf(fxFont) > -1);
                if (fontStyleChecker) {
                    fx2.bold = !fx2.bold;
                    this.setState({bold: fx2.bold});
                    if (fx2.bold) {
                        if (!fx2.fontFamily.includes('Bold')) {

                            if (fx2.fontFamily.includes('Italic')) {
                                const d = fx2.fontFamily.indexOf('Italic');
                                fx2.fontFamily = fx2.fontFamily.slice(0, d) + 'Bold' + fx2.fontFamily.slice(d, fx2.fontFamily.length)

                            } else {
                                fx2.fontFamily = fx2.fontFamily + 'Bold'
                            }
                        }
                    }
                    if (!fx2.bold) {
                        if (fx2.fontFamily.includes('Bold')) {
                            const d = fx2.fontFamily.indexOf('Bold');

                            fx2.fontFamily = fx2.fontFamily.slice(0, d) + fx2.fontFamily.slice(d + 4, fx2.fontFamily.length)
                        }
                    }
                    if (fx2.textAlign === 'center') {
                        fx2.outlineSelected = false;
                        fx2.right = null
                        fx2.width = null
                    }
                    var width = require('text-width');
                    let wt = 'normal';

                    if (fx2.bold) {
                        wt = 'bold';
                    }
                    var w = width(fx2.prefix + fx2.text + fx2.suffix, {
                        family: fx2.fontFamily,
                        size: fx2.fontSize,
                        weight: wt
                    });
                    let wx = this.state.objectPosition_barcodeText.width - w
                    if (fx2.textAlign === 'left') {
                        this.setState({
                            objectPosition_barcodeText: {
                                ...this.state.objectPosition_barcodeText,
                                right: this.state.objectPosition_barcodeText.right - wx,
                                width: w
                            }
                        })
                    }
                    if (fx2.textAlign === 'right') {
                        this.setState({
                            objectPosition_barcodeText: {
                                ...this.state.objectPosition_barcodeText,
                                left: this.state.objectPosition_barcodeText.left - wx,
                                width: w
                            }
                        })
                    }
                    if (fx2.textAlign === 'center') {
                        this.setState({
                            objectPosition_barcodeText: {
                                ...this.state.objectPosition_barcodeText,
                                left: this.state.objectPosition_barcodeText.left - wx / 2,
                                right: this.state.objectPosition_barcodeText.right - wx / 2,
                                width: w
                            }
                        })
                    }
                    this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                    let cx = this.state.barcodeObject.barcodeText;
                    this.setState({
                        barcodeObject:{
                            ...this.state.barcodeObject,
                            barcodeText: cx,
                        }
                    })
                }
            } else {
                const fontStyleChecker = (font_styles.indexOf(this.state.fontFamily) > -1);
                if (fontStyleChecker) {
                    this.setState(prevState => ({
                        bold: !prevState.bold
                    }));
                }
            }
        }

    }
    onItalicChange(){
        if(this.state.chosenTextIndex >= 0) {
            if (this.state.showText) {
                let fx2 = this.state.text[this.state.chosenTextIndex];
                let fxFont = fx2.fontFamily;
                if (fxFont.includes('Bold') || fxFont.includes('BoldItalic')) {
                    const d = fxFont.indexOf('Bold');
                    fxFont = fxFont.slice(0, d);
                } else {
                    if (fxFont.includes('Italic')) {
                        const dx = fxFont.indexOf('Italic');
                        fxFont = fxFont.slice(0, dx);
                    }
                }
                const fontStyleChecker = (font_styles.indexOf(fxFont) > -1);
                if (fontStyleChecker) {
                    fx2.italic = !fx2.italic;
                    this.setState({italic: fx2.italic});
                    if (fx2.italic) {
                        if (!fx2.fontFamily.includes('Italic')) {
                            fx2.fontFamily = fx2.fontFamily + 'Italic'
                        }
                    }
                    if (!fx2.italic) {
                        if (fx2.fontFamily.includes('Italic')) {
                            const d = fx2.fontFamily.indexOf('Italic');
                            fx2.fontFamily = fx2.fontFamily.slice(0, d)
                        }
                    }
                    if (fx2.textAlign === 'center') {
                        fx2.outlineSelected = false;
                        fx2.right = null
                        fx2.width = null
                    }
                    this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                    let cx = this.state.text;
                    this.setState({
                        text: cx,
                    })

                    var width = require('text-width');
                    let wt = 'normal';
                    if (fx2.bold) {
                        wt = 'bold';
                    }
                    var w = width(fx2.text, {
                        family: fx2.fontFamily,
                        size: fx2.fontSize,
                        weight: wt
                    });
                    let wx = this.state.objectPosition.width - w
                    if (fx2.textAlign === 'left') {
                        this.setState({
                            objectPosition: {
                                ...this.state.objectPosition,
                                right: this.state.objectPosition.right - wx,
                                width: w
                            }
                        })
                    }
                    if (fx2.textAlign === 'right') {
                        this.setState({
                            objectPosition: {
                                ...this.state.objectPosition,
                                left: this.state.objectPosition.left - wx,
                                width: w
                            }
                        })
                    }
                    if (fx2.textAlign === 'center') {
                        this.setState({
                            objectPosition: {
                                ...this.state.objectPosition,
                                left: this.state.objectPosition.left - wx / 2,
                                right: this.state.objectPosition.right - wx / 2,
                                width: w
                            }
                        })
                    }

                }

            } else {
                const fontStyleChecker = (font_styles.indexOf(this.state.fontFamily) > -1);
                if (fontStyleChecker) {
                    this.setState(prevState => ({
                        italic: !prevState.italic
                    }));
                }
            }
        }
        if(this.state.chosenBarcodeIndex >= 0) {
            if (this.state.showText) {
                let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                let fxFont = fx2.fontFamily;
                if (fxFont.includes('Bold') || fxFont.includes('BoldItalic')) {
                    const d = fxFont.indexOf('Bold');
                    fxFont = fxFont.slice(0, d);
                } else {
                    if (fxFont.includes('Italic')) {
                        const dx = fxFont.indexOf('Italic');
                        fxFont = fxFont.slice(0, dx);
                    }
                }
                const fontStyleChecker = (font_styles.indexOf(fxFont) > -1);
                if (fontStyleChecker) {
                    fx2.italic = !fx2.italic;
                    this.setState({italic: fx2.italic});
                    if (fx2.italic) {
                        if (!fx2.fontFamily.includes('Italic')) {
                            fx2.fontFamily = fx2.fontFamily + 'Italic'
                        }
                    }
                    if (!fx2.italic) {
                        if (fx2.fontFamily.includes('Italic')) {
                            const d = fx2.fontFamily.indexOf('Italic');
                            fx2.fontFamily = fx2.fontFamily.slice(0, d)
                        }
                    }
                    if (fx2.textAlign === 'center') {
                        fx2.outlineSelected = false;
                        fx2.right = null
                        fx2.width = null
                    }
                    var width = require('text-width');
                    let wt = 'normal';
                    if (fx2.bold) {
                        wt = 'bold';
                    }
                    var w = width(fx2.text, {
                        family: fx2.fontFamily,
                        size: fx2.fontSize,
                        weight: wt
                    });
                    let wx = this.state.objectPosition_barcodeText.width - w
                    if (fx2.textAlign === 'left') {
                        this.setState({
                            objectPosition_barcodeText: {
                                ...this.state.objectPosition_barcodeText,
                                right: this.state.objectPosition_barcodeText.right - wx,
                                width: w
                            }
                        })
                    }
                    if (fx2.textAlign === 'right') {
                        this.setState({
                            objectPosition_barcodeText: {
                                ...this.state.objectPosition_barcodeText,
                                left: this.state.objectPosition_barcodeText.left - wx,
                                width: w
                            }
                        })
                    }
                    if (fx2.textAlign === 'center') {
                        this.setState({
                            objectPosition_barcodeText: {
                                ...this.state.objectPosition_barcodeText,
                                left: this.state.objectPosition_barcodeText.left - wx / 2,
                                right: this.state.objectPosition_barcodeText.right - wx / 2,
                                width: w
                            }
                        })
                    }
                    this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                    let cx = this.state.barcodeObject.barcodeText;
                    this.setState({
                        barcodeObject:{
                            ...this.state.barcodeObject,
                            barcodeText: cx,
                        }
                    })
                }
            } else {
                const fontStyleChecker = (font_styles.indexOf(this.state.fontFamily) > -1);
                if (fontStyleChecker) {
                    this.setState(prevState => ({
                        italic: !prevState.italic
                    }));
                }
            }
        }

    }
    onlineThroughChange(){
        if(this.state.chosenTextIndex >= 0) {
            if (this.state.showText) {
                let fx2 = this.state.text[this.state.chosenTextIndex];
                let fxFont = fx2.fontFamily;
                if (fxFont.includes('Bold') || fxFont.includes('BoldItalic')) {
                    const d = fxFont.indexOf('Bold');
                    fxFont = fxFont.slice(0, d);
                } else {
                    if (fxFont.includes('Italic')) {
                        const dx = fxFont.indexOf('Italic');
                        fxFont = fxFont.slice(0, dx);
                    }
                }
                const fontStyleChecker = (font_styles.indexOf(fxFont) > -1);
                if (fontStyleChecker) {
                    fx2.lineThrough = !fx2.lineThrough;
                    if (fx2.lineThrough) {
                        this.setState({lineThrough: fx2.lineThrough});
                    }
                    if (!fx2.lineThrough) {
                        this.setState({lineThrough: fx2.lineThrough});
                    }
                    this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                    let cx = this.state.text;
                    this.setState({
                        text: cx,
                    })
                }
            } else {
                const fontStyleChecker = (font_styles.indexOf(this.state.fontFamily) > -1);
                if (fontStyleChecker) {
                    this.setState(prevState => ({
                        lineThrough: !prevState.lineThrough
                    }));
                }
            }
        }
        if(this.state.chosenBarcodeIndex >= 0) {
            if (this.state.showText) {
                let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                let fxFont = fx2.fontFamily;
                if (fxFont.includes('Bold') || fxFont.includes('BoldItalic')) {
                    const d = fxFont.indexOf('Bold');
                    fxFont = fxFont.slice(0, d);
                } else {
                    if (fxFont.includes('Italic')) {
                        const dx = fxFont.indexOf('Italic');
                        fxFont = fxFont.slice(0, dx);
                    }
                }
                const fontStyleChecker = (font_styles.indexOf(fxFont) > -1);
                if (fontStyleChecker) {
                    fx2.lineThrough = !fx2.lineThrough;
                    if (fx2.lineThrough) {
                        this.setState({lineThrough: fx2.lineThrough});
                    }
                    if (!fx2.lineThrough) {
                        this.setState({lineThrough: fx2.lineThrough});
                    }
                    this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                    let cx = this.state.barcodeObject.barcodeText;
                    this.setState({
                        barcodeObject:{
                            ...this.state.barcodeObject,
                            barcodeText: cx,
                        }
                    })
                }
            } else {
                const fontStyleChecker = (font_styles.indexOf(this.state.fontFamily) > -1);
                if (fontStyleChecker) {
                    this.setState(prevState => ({
                        lineThrough: !prevState.lineThrough
                    }));
                }
            }
        }


    }
    onUnderlineChange(){
        if(this.state.chosenTextIndex >= 0) {
            if (this.state.showText) {
                let fx2 = this.state.text[this.state.chosenTextIndex];
                fx2.underline = !fx2.underline;
                if (fx2.underline) {
                    this.setState({underline: fx2.underline});
                }
                if (!fx2.underline) {
                    this.setState({underline: fx2.underline});
                }
                this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                let cx = this.state.text;
                this.setState({
                    text: cx,
                })
            } else {
                this.setState(prevState => ({
                    underline: !prevState.underline
                }));
            }
        }
        if(this.state.chosenBarcodeIndex >= 0) {
            if (this.state.showText) {
                let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                fx2.underline = !fx2.underline;
                if (fx2.underline) {
                    this.setState({underline: fx2.underline});
                }
                if (!fx2.underline) {
                    this.setState({underline: fx2.underline});
                }
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject:{
                        ...this.state.barcodeObject,
                        barcodeText: cx,
                    }
                })
            } else {
                this.setState(prevState => ({
                    underline: !prevState.underline
                }));
            }
        }

    }
    onLockChange(){
        if (this.state.showText && this.state.chosenTextIndex >= 0){
            let fx2 = this.state.text[this.state.chosenTextIndex];
            fx2.lock = !fx2.lock;

            this.setState({textLocked: fx2.lock})

            this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })
        }
        if (this.state.showText && this.state.chosenBarcodeIndex >= 0){
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            fx2.lock = !fx2.lock;

            this.setState({textLocked: fx2.lock})

            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                }
            })
        }

    }

    onNoPrintChange(){
        if (this.state.showText && this.state.chosenTextIndex >= 0){
            let fx2 = this.state.text[this.state.chosenTextIndex];
            fx2.noPrint = !fx2.noPrint

            this.setState({textNoPrint: fx2.noPrint})

            this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })
        }
        if (this.state.showText && this.state.chosenBarcodeIndex >= 0){
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            fx2.noPrint = !fx2.noPrint;

            this.setState({textNoPrint: fx2.noPrint})

            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                }
            })
        }

    }
    onTransparencyBarcodeChange(){
        if (this.state.chosenBarcodeIndex >= 0){
            let fx2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            if (fx2.backgroundColor !== 'transparent'){
                this.setState({transparentStatus: true,barcodeBackgroundColor:'transparent'})
                fx2.backgroundColor = 'transparent'
                this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx = this.state.barcodeObject.barcode;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcode: cx,
                    }
                })
            } else {
                this.setState({transparentStatus: false,barcodeBackgroundColor:'#FFFFFF'})
                fx2.backgroundColor = '#FFFFFF'
                this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx = this.state.barcodeObject.barcode;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcode: cx,
                    }
                })
            }
        }
    }

        onTransparencyChange(){
        if (this.state.showText && this.state.chosenTextIndex >= 0){

            let fx2 = this.state.text[this.state.chosenTextIndex];
            if (fx2.backgroundColor !== 'transparent'){
                this.setState({transparentStatus: true,backgroundColor:'transparent'})
                fx2.backgroundColor = 'transparent'
                this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                let cx = this.state.text;
                this.setState({
                    text: cx,
                })
            } else {
                this.setState({transparentStatus: false,backgroundColor:'#FFFFFF'})
                fx2.backgroundColor = '#FFFFFF'
                this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                let cx = this.state.text;
                this.setState({
                    text: cx,
                })
            }

        }
        if (this.state.showText && this.state.chosenBarcodeIndex >= 0){
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if (fx2.backgroundColor !== 'transparent'){
                this.setState({transparentStatus: true,backgroundColor:'transparent'})
                fx2.backgroundColor = 'transparent'
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: cx,
                    }
                })
              }
            else {
                this.setState({transparentStatus: false,backgroundColor:'#FFFFFF'})
                fx2.backgroundColor = '#FFFFFF'
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: cx,
                    }
                })
            }
        }

    }
    onParaWidthChange(){
        if (this.state.showText && this.state.chosenTextIndex >= 0){
            let fx2 = this.state.text[this.state.chosenTextIndex];
            fx2.paraWidth = !fx2.paraWidth

            this.setState({paraWidth: fx2.paraWidth})

            this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })
        }
        if (this.state.showText && this.state.chosenBarcodeIndex >= 0){
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            fx2.paraWidth = !fx2.paraWidth;

            this.setState({paraWidth: fx2.paraWidth})

            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                }
            })
        }

    }
    onParaHeightChange(){
        if (this.state.showText && this.state.chosenTextIndex >= 0){
            let fx2 = this.state.text[this.state.chosenTextIndex];
            fx2.paraHeight = !fx2.paraHeight

            this.setState({paraHeight: fx2.paraHeight})

            this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })
        }
        if (this.state.showText && this.state.chosenBarcodeIndex >= 0){
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            fx2.paraHeight = !fx2.paraHeight;

            this.setState({paraHeight: fx2.paraHeight})

            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                }
            })
        }

    }
    onChangeCheckDigit(){
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            // fx2.checkDigit = !fx2.checkDigit;
            // if(fx2.checkDigit && (fx2.backgroundColor === 'UPC' || fx2.backgroundColor === 'EAN8' || fx2.backgroundColor === 'EAN13')){
            //
            //     if (fx2.value.length === 12 && fx2.barcodeType === 'UPC' && Barcoder.validate(fx2.value) ||
            //         fx2.value.length === 13 && fx2.barcodeType === 'EAN13' && Barcoder.validate(fx2.value) ||
            //         fx2.value.length === 8 && fx2.barcodeType === 'EAN8' && Barcoder.validate(fx2.value)){
            //
            //         alert('validated succcessfully')
            //     }
            //     else{
            //         alert('validation failed')
            //
            //         fx2.checkDigit = false
            //     }
            //     }

            // this.setState({checkDigit: fx2.checkDigit})

            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcode: cx,

                }
           })
            }
        }
    onBarcodeLockChange(){
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            fx2.lock = !fx2.lock;

            this.setState({barcodeLocked: fx2.lock})

            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcode: cx,

                }
            })
        }
    }
    onBarcodeNoPrintChange(){
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            fx2.noPrint = !fx2.noPrint;

            this.setState({barcodeNoPrint: fx2.noPrint})

            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcode: cx,

                }
            })
        }
    }
    onImageLockChange(){
        if (this.state.chosenImageIndex >= 0) {
            let fx2 = this.state.image_array[this.state.chosenImageIndex];
            fx2.lock = !fx2.lock;
            this.setState({imageLocked: fx2.lock})
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fx2);
            let cx = this.state.image_array;
            this.setState({
               image_array:cx,
            })
        }
        if (this.state.chosenShapeIndex >= 0) {
            let fx2 = this.state.shape_array[this.state.chosenShapeIndex];
            fx2.lock = !fx2.lock;
            this.setState({shapeLocked: fx2.lock})
            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx2);
            let cx = this.state.shape_array;
            this.setState({
                shape_array:cx,
            })
        }
    }
    onImageNoPrintChange(){
        if (this.state.chosenImageIndex >= 0) {
            let fx2 = this.state.image_array[this.state.chosenImageIndex];
            fx2.noPrint = !fx2.noPrint;
            this.setState({imageNoPrint: fx2.noPrint})
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fx2);
            let cx = this.state.image_array;
            this.setState({
                image_array:cx,
            })
        }
        if (this.state.chosenShapeIndex >= 0) {
            let fx2 = this.state.shape_array[this.state.chosenShapeIndex];
            fx2.noPrint = !fx2.noPrint;
            this.setState({shapeNoPrint: fx2.noPrint})
            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx2);
            let cx = this.state.shape_array;
            this.setState({
                shape_array:cx,
            })
        }
    }
    onFlipChange(){
        if (this.state.showText && this.state.chosenTextIndex >= 0){
            let fx2 = this.state.text[this.state.chosenTextIndex];
            fx2.flipText = !fx2.flipText;
            if (fx2.flipText) {
                this.setState({flipText: fx2.flipText});
                fx2.backgroundColor = '#000000';
                fx2.textColor = '#FFFFFF'
            }
            if (!fx2.flipText){
                this.setState({flipText: fx2.flipText});
                fx2.backgroundColor = '#FFFFFF';
                fx2.textColor = '#000000'
            }
            this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })
        }
        if (this.state.showText && this.state.chosenBarcodeIndex >= 0){
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            fx2.flipText = !fx2.flipText;
            if (fx2.flipText) {
                this.setState({flipText: fx2.flipText});
                fx2.backgroundColor = '#000000';
                fx2.textColor = '#FFFFFF'
            }
            if (!fx2.flipText){
                this.setState({flipText: fx2.flipText});
                fx2.backgroundColor = '#FFFFFF';
                fx2.textColor = '#000000'
            }
            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,

                }
            })
        }

    }
    onBarcodeBackgroundColorChangeHandler(colors) {
        if (this.state.barcodeValue) {
            this.setState({barcodeBackgroundColor:  colors.color})
            let fb2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
                fb2.backgroundColor = colors.color
                this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2)

                let cx = this.state.barcodeObject.barcode;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcode: cx,

                    }
                })
        }
    }
    onBarcodeBackgroundColorChangeHandler(colors) {
        if (this.state.barcodeValue) {
            this.setState({barcodeBackgroundColor:  colors.color})
            let fb2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            this.setState({backgroundColor: colors.color,transparentStatus: false});

            fb2.backgroundColor = colors.color
            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2)

            let cx = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcode: cx,

                }
            })
        }
    }
    onBarcodeLineColorChangeHandler(colors) {
        if (this.state.barcodeValue) {
            this.setState({barcodeLineColor:  colors.color})
            let fb2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            fb2.color = colors.color
            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2)

            let cx = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcode: cx,

                }
            })
        }
    }

    onBackgroundColorChangeHandler(colors){
        if (this.state.showText && this.state.chosenTextIndex >= 0){
            let fx2 = this.state.text[this.state.chosenTextIndex];

            this.setState({flipText: false, backgroundColor: colors.color,transparentStatus: false});
            fx2.backgroundColor = colors.color;
            this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
            let cx = this.state.text;
            this.setState({
                text:cx
            })
        }
        if (this.state.showText && this.state.chosenBarcodeIndex >= 0){
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            this.setState({flipText: false, backgroundColor: colors.color,transparentStatus: false});
            fx2.backgroundColor = colors.color;
            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,

                }
            })
        }
    }
    onChangeShapeBorderColor(colors){
        if (this.state.chosenShapeIndex >= 0 ) {
            let fb = this.state.shape_array[this.state.chosenShapeIndex];
            fb.stroke = colors.color;

            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);

            let cx1 = this.state.shape_array;
            this.setState({
                shape_array: cx1,
                shapeBorderColor:colors.color,
            })
        }


        }
    onChangeShapeFillColor(colors){
        if (this.state.chosenShapeIndex >= 0 ) {
            let fb = this.state.shape_array[this.state.chosenShapeIndex];
            fb.fill = colors.color;

            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);

            let cx1 = this.state.shape_array;
            this.setState({
                shape_array: cx1,
                shapeFillColor:colors.color,

            })
        }
    }

    onTextColorChangeHandler(colors){
        if (this.state.showText && this.state.chosenTextIndex >= 0){
            let fx2 = this.state.text[this.state.chosenTextIndex];
            this.setState({flipText: false, textColor: colors.color});
            fx2.textColor = colors.color;
            this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })
        }
        if (this.state.showText && this.state.chosenBarcodeIndex >= 0){
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            this.setState({flipText: false, textColor: colors.color});
            fx2.textColor = colors.color;
            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                }
            })
        }
    }

    onLeftMouseUp11(){
        clearInterval(this.Leftinterval);

    }


    onLeftMouseDown11() {
        if (this.state.chosenTextIndex >= 0) {
            let fx2 = this.state.text[this.state.chosenTextIndex];
            if (this.state.showText && !fx2.lock) {
                this.Leftinterval = setInterval(() => {
                        let rx = Number(this.getXposObject())-0.1

                        if (fx2.textAlign === 'left') {

                            let x_resize = (this.state.label_dim.width / this.props.label_Width);
                            let x_range = rx / x_resize
                            fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                            let x_range1 = fx2.left + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                            let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                            let x_pos_real = x_range1 * x_resize1
                            let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                            this.setState({
                                textXPos: x_pos,
                                objectPosition: {
                                    ...this.state.objectPosition,
                                    left: fx2.left,
                                    right: this.state.objectPosition.width+fx2.left,
                                }
                            })
                        }
                        if (fx2.textAlign === 'center') {
                            fx2.outlineSelected = false;
                            let dxx = document.getElementById('txtx'+this.state.chosenTextIndex)

                            if (dxx) {

                                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                                let x_range = rx / x_resize

                                let bcheck = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25

                                if (bcheck > fx2.left){
                                    let bs = bcheck - fx2.left
                                    fx2.left = fx2.left-bs

                                } else {
                                    fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;

                                }

                                fx2.right = window.innerWidth - (fx2.left + dxx.offsetWidth)

                                let x_range1 = fx2.left + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                                let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                                let x_pos_real = x_range1 * x_resize1

                                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                                this.setState({
                                    textXPos: x_pos,
                                    objectPosition: {
                                        ...this.state.objectPosition,
                                        left: fx2.left,
                                        right: this.state.objectPosition.width+fx2.left,
                                    }
                                })
                            }
                        }
                        if (fx2.textAlign === 'right') {
                            let dxx = document.getElementById('txtx'+this.state.chosenTextIndex)

                            if (dxx) {
                                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                                let x_range = rx / x_resize

                                let t_left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                                fx2.right = window.innerWidth - (t_left + dxx.offsetWidth)

                                let ry = window.innerWidth - fx2.right - dxx.offsetWidth;
                                let x_range1 = ry + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                                let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                                let x_pos_real = x_range1 * x_resize1
                                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                                this.setState({
                                    textXPos: x_pos,
                                    objectPosition: {
                                        ...this.state.objectPosition,
                                        left: t_left,
                                        right: t_left + dxx.offsetWidth,
                                    }
                                })
                            }
                        }
                        this.state.text.splice(this.state.chosenTextIndex, 1, fx2)

                        let cx = this.state.text;
                        this.setState({
                            text: cx,
                        })
                    }
                    , 50);
            }
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if (this.state.showText && !fx2.lock) {
                this.Leftinterval = setInterval(() => {
                        let rx = Number(this.getXposObject())-0.1

                        if (fx2.textAlign === 'left') {
                            let x_resize = (this.state.label_dim.width / this.props.label_Width);
                            let x_range = rx / x_resize
                            fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                            let x_range1 = fx2.left + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                            let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                            let x_pos_real = x_range1 * x_resize1
                            let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                            this.setState({
                                textXPos: x_pos,
                                objectPosition_barcodeText: {
                                    ...this.state.objectPosition_barcodeText,
                                    left: fx2.left,
                                    right: this.state.objectPosition_barcodeText.width+fx2.left,
                                }
                            })
                        }
                        if (fx2.textAlign === 'center') {
                            fx2.outlineSelected = false;
                            let dxx = document.getElementById('btxtx' + this.state.chosenBarcodeIndex)

                            if (dxx) {
                                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                                let x_range = rx / x_resize

                                fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                                fx2.right = window.innerWidth - (fx2.left + dxx.offsetWidth)

                                let x_range1 = fx2.left + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                                let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                                let x_pos_real = x_range1 * x_resize1

                                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                                this.setState({
                                    textXPos: x_pos,
                                    objectPosition_barcodeText: {
                                        ...this.state.objectPosition_barcodeText,
                                        left: fx2.left,
                                        right: this.state.objectPosition_barcodeText.width + fx2.left,
                                    }
                                })
                            }
                        }
                        if (fx2.textAlign === 'right') {
                            let dxx = document.getElementById('btxtx' + this.state.chosenBarcodeIndex)

                            if (dxx) {
                                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                                let x_range = rx / x_resize

                                let t_left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                                fx2.right = window.innerWidth - (t_left + dxx.offsetWidth)

                                let ry = window.innerWidth - fx2.right - dxx.offsetWidth;
                                let x_range1 = ry + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                                let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                                let x_pos_real = x_range1 * x_resize1
                                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                                this.setState({
                                    textXPos: x_pos,
                                    objectPosition_barcodeText: {
                                        ...this.state.objectPosition_barcodeText,
                                        left: t_left,
                                        right: t_left + dxx.offsetWidth,
                                    }
                                })
                            }
                        }
                        this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2)

                        let cx = this.state.barcodeObject.barcodeText;
                        this.setState({
                            barcodeObject:{
                                ...this.state.barcodeObject,
                                barcodeText: cx,
                            }
                        })
                    }
                    , 50);
            }
        }
    }
    onRightMouseUp11(){
        clearInterval(this.Rightinterval);

    }

    onRightMouseDown11() {
        if (this.state.chosenTextIndex >= 0) {
            let fx2 = this.state.text[this.state.chosenTextIndex];
            if (this.state.showText && !fx2.lock) {
                this.Rightinterval = setInterval(() => {

                        let rx = Number(this.getXposObject())+0.1

                        if (fx2.textAlign === 'left') {
                            let x_resize = (this.state.label_dim.width / this.props.label_Width);
                            let x_range = rx / x_resize
                            fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                            let x_range1 = fx2.left + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                            let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                            let x_pos_real = x_range1 * x_resize1
                            let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                            this.setState({
                                textXPos: x_pos,
                                objectPosition: {
                                    ...this.state.objectPosition,
                                    left: fx2.left,
                                    right: this.state.objectPosition.width+fx2.left,
                                }
                            })
                        }
                        if (fx2.textAlign === 'center') {
                            fx2.outlineSelected = false;
                            let dxx = document.getElementById('txtx'+this.state.chosenTextIndex)

                            if (dxx) {
                                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                                let x_range = rx / x_resize

                                fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                                fx2.right = window.innerWidth - (fx2.left + dxx.offsetWidth)

                                let x_range1 = fx2.left + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                                let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                                let x_pos_real = x_range1 * x_resize1

                                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                                this.setState({
                                    textXPos: x_pos,
                                    objectPosition: {
                                        ...this.state.objectPosition,
                                        left: fx2.left,
                                        right: this.state.objectPosition.width+fx2.left,
                                    }
                                })
                            }
                        }
                        if (fx2.textAlign === 'right') {
                            let dxx = document.getElementById('txtx'+this.state.chosenTextIndex)

                            if (dxx) {
                                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                                let x_range = rx / x_resize

                                let t_left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                                fx2.right = window.innerWidth - (t_left + dxx.offsetWidth)

                                let ry = window.innerWidth - fx2.right - dxx.offsetWidth;
                                let x_range1 = ry + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                                let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                                let x_pos_real = x_range1 * x_resize1
                                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                                this.setState({
                                    textXPos: x_pos,
                                    objectPosition: {
                                        ...this.state.objectPosition,
                                        left: t_left,
                                        right: t_left + dxx.offsetWidth,
                                    }
                                })
                            }
                        }
                        this.state.text.splice(this.state.chosenTextIndex, 1, fx2)

                        let cx = this.state.text;
                        this.setState({
                            text: cx,
                        })

                    }
                    , 50);
            }
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if (this.state.showText && !fx2.lock) {
                this.Rightinterval = setInterval(() => {
                        let rx = Number(this.getXposObject())+0.1

                        if (fx2.textAlign === 'left') {
                            let x_resize = (this.state.label_dim.width / this.props.label_Width);
                            let x_range = rx / x_resize
                            fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                            let x_range1 = fx2.left + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                            let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                            let x_pos_real = x_range1 * x_resize1
                            let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                            this.setState({
                                textXPos: x_pos,
                                objectPosition_barcodeText: {
                                    ...this.state.objectPosition_barcodeText,
                                    left: fx2.left,
                                    right: this.state.objectPosition_barcodeText.width+fx2.left,
                                }
                            })
                        }
                        if (fx2.textAlign === 'center') {
                            fx2.outlineSelected = false;
                            let dxx = document.getElementById('btxtx' + this.state.chosenBarcodeIndex)

                            if (dxx) {
                                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                                let x_range = rx / x_resize

                                fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                                fx2.right = window.innerWidth - (fx2.left + dxx.offsetWidth)

                                let x_range1 = fx2.left + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                                let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                                let x_pos_real = x_range1 * x_resize1

                                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                                this.setState({
                                    textXPos: x_pos,
                                    objectPosition_barcodeText: {
                                        ...this.state.objectPosition_barcodeText,
                                        left: fx2.left,
                                        right: this.state.objectPosition_barcodeText.width + fx2.left,
                                    }
                                })
                            }
                        }
                        if (fx2.textAlign === 'right') {
                            let dxx = document.getElementById('btxtx' + this.state.chosenBarcodeIndex)

                            if (dxx) {
                                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                                let x_range = rx / x_resize

                                let t_left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                                fx2.right = window.innerWidth - (t_left + dxx.offsetWidth)

                                let ry = window.innerWidth - fx2.right - dxx.offsetWidth;
                                let x_range1 = ry + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                                let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                                let x_pos_real = x_range1 * x_resize1
                                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                                this.setState({
                                    textXPos: x_pos,
                                    objectPosition_barcodeText: {
                                        ...this.state.objectPosition_barcodeText,
                                        left: t_left,
                                        right: t_left + dxx.offsetWidth,
                                    }
                                })
                            }
                        }
                        this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2)

                        let cx = this.state.barcodeObject.barcodeText;
                        this.setState({
                            barcodeObject:{
                                ...this.state.barcodeObject,
                                barcodeText: cx,
                            }
                        })
                    }
                    , 50);
            }
        }
    }
    onUpMouseUp11(){
        clearInterval(this.Upinterval);


    }

    onUpMouseDown11() {
        if (this.state.chosenTextIndex >= 0) {
            let fx2 = this.state.text[this.state.chosenTextIndex];
            if (this.state.showText && !fx2.lock) {
                this.Upinterval = setInterval(() => {
                        let rx = Number(this.getYposObject())-0.1

                        if (fx2.textAlign === 'center') {
                            fx2.outlineSelected = false;
                        }

                        let y_resize = (this.state.label_dim.height /this.props.label_Height);
                        let y_range = rx/ y_resize;

                        fx2.top = y_range  - fx2.deltaPositionY + this.props.label_Y +  window.innerWidth* 0.095;


                        let y_range1 = fx2.top + fx2.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095 ;
                        let y_resize1 = (this.state.label_dim.height / this.props.label_Height);
                        let y_pos_real = y_range1 * y_resize1
                        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                        this.setState({
                            textYPos: y_pos
                        })
                        this.state.text.splice(this.state.chosenTextIndex, 1, fx2)

                        let cx = this.state.text;
                        this.setState({
                            text: cx,
                        })
                    }
                    , 50);
            }
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if (this.state.showText && !fx2.lock) {
                this.Upinterval = setInterval(() => {
                        let rx = Number(this.getYposObject())-0.1

                        if (fx2.textAlign === 'center') {
                            fx2.outlineSelected = false;
                        }

                        let y_resize = (this.state.label_dim.height /this.props.label_Height);
                        let y_range = rx/ y_resize;

                        fx2.top = y_range  - fx2.deltaPositionY + this.props.label_Y +  window.innerWidth* 0.095;


                        let y_range1 = fx2.top + fx2.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095 ;
                        let y_resize1 = (this.state.label_dim.height / this.props.label_Height);
                        let y_pos_real = y_range1 * y_resize1
                        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                        this.setState({
                            textYPos: y_pos
                        })
                        this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2)

                        let cx = this.state.barcodeObject.barcodeText;
                        this.setState({
                            barcodeObject:{
                                ...this.state.barcodeObject,
                                barcodeText: cx,
                            }
                        })
                    }
                    , 50);
            }
        }
    }
    onDownMouseUp11(){
        clearInterval(this.Downinterval);

    }

    onDownMouseDown11() {
        if (this.state.chosenTextIndex >= 0) {
            let fx2 = this.state.text[this.state.chosenTextIndex];
            if (this.state.showText && !fx2.lock) {
                this.Downinterval = setInterval(() => {
                        let rx = Number(this.getYposObject())+0.1

                        if (fx2.textAlign === 'center') {
                            fx2.outlineSelected = false;
                        }

                        let y_resize = (this.state.label_dim.height /this.props.label_Height);
                        let y_range = rx/ y_resize;

                        fx2.top = y_range  - fx2.deltaPositionY + this.props.label_Y +  window.innerWidth* 0.095;


                        let y_range1 = fx2.top + fx2.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095 ;
                        let y_resize1 = (this.state.label_dim.height / this.props.label_Height);
                        let y_pos_real = y_range1 * y_resize1
                        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                        this.setState({
                            textYPos: y_pos
                        })
                        this.state.text.splice(this.state.chosenTextIndex, 1, fx2)

                        let cx = this.state.text;
                        this.setState({
                            text: cx,
                        })
                    }
                    , 50);
            }
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if (this.state.showText && !fx2.lock) {
                this.Downinterval = setInterval(() => {
                        let rx = Number(this.getYposObject())+0.1

                        if (fx2.textAlign === 'center') {
                            fx2.outlineSelected = false;
                        }

                        let y_resize = (this.state.label_dim.height /this.props.label_Height);
                        let y_range = rx/ y_resize;

                        fx2.top = y_range  - fx2.deltaPositionY + this.props.label_Y +  window.innerWidth* 0.095;


                        let y_range1 = fx2.top + fx2.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095 ;
                        let y_resize1 = (this.state.label_dim.height / this.props.label_Height);
                        let y_pos_real = y_range1 * y_resize1
                        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                        this.setState({
                            textYPos: y_pos
                        })
                        this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2)

                        let cx = this.state.barcodeObject.barcodeText;
                        this.setState({
                            barcodeObject:{
                                ...this.state.barcodeObject,
                                barcodeText: cx,
                            }
                        })
                    }
                    , 50);
            }
        }
    }
    onBarcodeLeftMouseUp11(){
        clearInterval(this.BarcodeLeftinterval);

    }


    onBarcodeLeftMouseDown11() {
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            if (this.state.barcodeValue && !fx2.lock) {
                this.BarcodeLeftinterval = setInterval(() => {
                        let rx = Number(this.getBarcodeXposObject())-0.1

                        let x_resize = (this.state.label_dim.width / this.props.label_Width);
                        let x_range = rx / x_resize
                        fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;


                        let x_range1 = fx2.left + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;


                        let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                        let x_pos_real = x_range1 * x_resize1
                        let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                        this.setState({
                            barcodeXPos: x_pos
                        })


                        this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2)

                        let cx = this.state.barcodeObject.barcode;
                        this.setState({
                            barcodeObject:{
                                ...this.state.barcodeObject,
                                barcode: cx,

                            }
                        })
                    }
                    , 50);
            }
        }
    }
    onBarcodeRightMouseUp11(){
        clearInterval(this.BarcodeRightinterval);

    }


    onBarcodeRightMouseDown11() {
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            if (this.state.barcodeValue && !fx2.lock) {
                this.BarcodeRightinterval = setInterval(() => {

                        let rx = Number(this.getBarcodeXposObject())+0.1

                        let x_resize = (this.state.label_dim.width / this.props.label_Width);
                        let x_range = rx / x_resize
                        fx2.left = x_range - fx2.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;


                        let x_range1 = fx2.left + fx2.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;


                        let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                        let x_pos_real = x_range1 * x_resize1
                        let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                        this.setState({
                            barcodeXPos: x_pos
                        })


                        this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2)

                        let cx = this.state.barcodeObject.barcode;
                        this.setState({
                            barcodeObject:{
                                ...this.state.barcodeObject,
                                barcode: cx,
                            }
                        })
                    }
                    , 50);
            }
        }
    }
    onBarcodeUpMouseUp11(){
        clearInterval(this.BarcodeUpinterval);

    }


    onBarcodeUpMouseDown11() {
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            if (this.state.barcodeValue && !fx2.lock) {
                this.BarcodeUpinterval = setInterval(() => {
                        let rx = Number(this.getBarcodeYposObject())-0.1

                        let y_resize = (this.state.label_dim.height /this.props.label_Height);
                        let y_range = rx/ y_resize;

                        fx2.top = y_range  - fx2.deltaPositionY + this.props.label_Y +  window.innerWidth* 0.095;

                        let y_range1 = fx2.top + fx2.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

                        let y_resize1 = (this.state.label_dim.height / this.props.label_Height);
                        let y_pos_real = y_range1 * y_resize1
                        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                        this.setState({
                            barcodeYPos: y_pos
                        })
                        this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2)

                        let cx = this.state.barcodeObject.barcode;
                        this.setState({
                            barcodeObject:{
                                ...this.state.barcodeObject,
                                barcode: cx,

                            }
                        })
                    }
                    , 50);
            }
        }
    }
    onBarcodeDownMouseUp11(){
        clearInterval(this.BarcodeDowninterval);

    }


    onBarcodeDownMouseDown11() {
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            if (this.state.barcodeValue && !fx2.lock) {
                this.BarcodeDowninterval = setInterval(() => {
                        let rx = Number(this.getBarcodeYposObject())+0.1

                        let y_resize = (this.state.label_dim.height /this.props.label_Height);
                        let y_range = rx/ y_resize;

                        fx2.top = y_range  - fx2.deltaPositionY + this.props.label_Y +  window.innerWidth* 0.095;



                        let y_range1 = fx2.top + fx2.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

                        let y_resize1 = (this.state.label_dim.height / this.props.label_Height);
                        let y_pos_real = y_range1 * y_resize1
                        let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                        this.setState({
                            barcodeYPos: y_pos
                        })
                        this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx2)

                        let cx = this.state.barcodeObject.barcode;
                        this.setState({
                            barcodeObject:{
                                ...this.state.barcodeObject,
                                barcode: cx,
                            }
                        })
                    }
                    , 50);
            }
        }
    }

    onLeftTextAlignChange(){
        if (this.state.showText && this.state.chosenTextIndex >= 0) {
            let fx2 = this.state.text[this.state.chosenTextIndex];

            if (fx2.textAlign !== 'left' && !fx2.multiline) {
                let dxx = document.getElementById('text'+this.state.chosenTextIndex)
                if (dxx) {
                    this.setState({textAlign: 'left', leftAlign: true, centerAlign: false, rightAlign: false})
                    fx2.textAlign = 'left';
                    fx2.left = dxx.offsetLeft;
                    fx2.width = null;
                    fx2.outlineSelected = false;
                    fx2.right = null;
                    this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                    let cx = this.state.text;
                    this.setState({
                        text: cx,
                    })
                }

            }
            if (fx2.textAlign !== 'left' && fx2.multiline) {
                this.setState({textAlign: 'left', leftAlign: true, centerAlign: false, rightAlign: false})
                fx2.textAlign = 'left';
                fx2.left = this.state.objectPosition.left  -fx2.deltaPositionX;
                fx2.outlineSelected = false;
                this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                let cx = this.state.text;
                this.setState({
                    text: cx,
                })
            }
        }
        if (this.state.showText && this.state.chosenBarcodeIndex >= 0) {
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if (fx2.textAlign !== 'left' && !fx2.multiline) {
                let dxx = document.getElementById('btext'+this.state.chosenBarcodeIndex)
                if (dxx) {

                    this.setState({textAlign: 'left', leftAlign: true, centerAlign: false, rightAlign: false})
                    fx2.textAlign = 'left';
                    fx2.left = dxx.offsetLeft;
                    fx2.width = null;
                    fx2.outlineSelected = false;
                    fx2.right = null;
                    this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                    let cx = this.state.barcodeObject.barcodeText;
                    this.setState({
                        barcodeObject:{
                            ...this.state.barcodeObject,
                            barcodeText: cx,
                        }
                    })

                }

            }
            if (fx2.textAlign !== 'left' && fx2.multiline) {
                this.setState({textAlign: 'left', leftAlign: true, centerAlign: false, rightAlign: false})
                fx2.textAlign = 'left';
                fx2.left = this.state.objectPosition_barcodeText.left  -fx2.deltaPositionX;
                fx2.outlineSelected = false;
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject:{
                        ...this.state.barcodeObject,
                        barcodeText: cx,
                    }
                })
            }
        }

    }
    onCenterTextAlignChange(){
        if (this.state.showText && this.state.chosenTextIndex >= 0) {
            let fx2 = this.state.text[this.state.chosenTextIndex];
            if ((fx2.transform === null || fx2.transform === 'rotate(0deg)')) {

                if (fx2.textAlign !== 'center' && !fx2.multiline) {
                    let dxx = document.getElementById('text' + this.state.chosenTextIndex)
                    if (dxx) {
                        this.setState({textAlign: 'center', leftAlign: false, centerAlign: true, rightAlign: false})
                        fx2.textAlign = 'center';
                        let rg = window.innerWidth - ((dxx.offsetLeft + dxx.offsetWidth))
                        fx2.left = dxx.offsetLeft;
                        fx2.right = rg;
                        fx2.width = null
                        this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                        let cx = this.state.text;
                        this.setState({
                            text: cx,
                        })
                    }

                }
                if (fx2.textAlign !== 'center' && fx2.multiline) {
                    this.setState({textAlign: 'center', leftAlign: false, centerAlign: true, rightAlign: false})
                    fx2.textAlign = 'center';
                    fx2.left = this.state.objectPosition.left - fx2.deltaPositionX;
                    fx2.right = window.innerWidth - this.state.objectPosition.right + fx2.deltaPositionX;
                    // fx2.width = null
                    this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                    let cx = this.state.text;
                    this.setState({
                        text: cx,
                    })
                }
            }
        }
        if (this.state.showText && this.state.chosenBarcodeIndex >= 0) {
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if ((fx2.transform === null || fx2.transform === 'rotate(0deg)')) {

                if (fx2.textAlign !== 'center' && !fx2.multiline) {
                    let dxx = document.getElementById('btext' + this.state.chosenBarcodeIndex)
                    if (dxx) {

                        this.setState({textAlign: 'center', leftAlign: false, centerAlign: true, rightAlign: false})
                        fx2.textAlign = 'center';
                        let rg = window.innerWidth - ((dxx.offsetLeft + dxx.offsetWidth))
                        fx2.left = dxx.offsetLeft;
                        fx2.right = rg;
                        fx2.width = null
                        this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                        let cx = this.state.barcodeObject.barcodeText;
                        this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcodeText: cx,
                            }
                        })
                    }

                }
                if (fx2.textAlign !== 'center' && fx2.multiline) {
                    this.setState({textAlign: 'center', leftAlign: false, centerAlign: true, rightAlign: false})
                    fx2.textAlign = 'center';
                    fx2.left = this.state.objectPosition_barcodeText.left - fx2.deltaPositionX;
                    fx2.right = window.innerWidth - this.state.objectPosition_barcodeText.right + fx2.deltaPositionX;
                    // fx2.width = null
                    this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                    let cx = this.state.barcodeObject.barcodeText;
                    this.setState({
                        barcodeObject: {
                            ...this.state.barcodeObject,
                            barcodeText: cx,
                        }
                    })
                }
            }
        }

    }
    onRightTextAlignChange(){
        if (this.state.showText && this.state.chosenTextIndex >= 0) {
            let fx2 = this.state.text[this.state.chosenTextIndex];
            if ((fx2.transform === null || fx2.transform === 'rotate(0deg)')) {
                if (fx2.textAlign !== 'right' && !fx2.multiline) {
                    let dxx = document.getElementById('text' + this.state.chosenTextIndex)
                    if (dxx) {
                        this.setState({textAlign: 'right', leftAlign: false, centerAlign: false, rightAlign: true})
                        fx2.textAlign = 'right';
                        fx2.width = null;
                        let rg = window.innerWidth - ((dxx.offsetLeft + dxx.offsetWidth))
                        fx2.right = rg;
                        fx2.left = null;
                        fx2.outlineSelected = false;
                        this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                        let cx = this.state.text;
                        this.setState({
                            text: cx,
                        })
                    }

                }
                if (fx2.textAlign !== 'right' && fx2.multiline) {
                    this.setState({textAlign: 'right', leftAlign: false, centerAlign: false, rightAlign: true})
                    fx2.textAlign = 'right';
                    // fx2.width = null;
                    fx2.right = window.innerWidth - this.state.objectPosition.right + fx2.deltaPositionX;
                    // fx2.left = null;
                    fx2.outlineSelected = false;
                    this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                    let cx = this.state.text;
                    this.setState({
                        text: cx,
                    })
                }
            }
        }
        if (this.state.showText && this.state.chosenBarcodeIndex >= 0) {
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if ((fx2.transform === null || fx2.transform === 'rotate(0deg)')) {

                if (fx2.textAlign !== 'right' && !fx2.multiline) {
                    let dxx = document.getElementById('btext' + this.state.chosenBarcodeIndex)
                    if (dxx) {

                        this.setState({textAlign: 'right', leftAlign: false, centerAlign: false, rightAlign: true})
                        fx2.textAlign = 'right';
                        fx2.width = null;
                        fx2.right = window.innerWidth - ((dxx.offsetLeft + dxx.offsetWidth));
                        fx2.left = null;
                        fx2.outlineSelected = false;
                        this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                        let cx = this.state.barcodeObject.barcodeText;
                        this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcodeText: cx,
                            }
                        })

                    }

                }
                if (fx2.textAlign !== 'right' && fx2.multiline) {
                    this.setState({textAlign: 'right', leftAlign: false, centerAlign: false, rightAlign: true})
                    fx2.textAlign = 'right';
                    // fx2.width = null;
                    fx2.right = window.innerWidth - this.state.objectPosition_barcodeText.right + fx2.deltaPositionX;
                    // fx2.left = null;
                    fx2.outlineSelected = false;
                    this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                    let cx = this.state.barcodeObject.barcodeText;
                    this.setState({
                        barcodeObject: {
                            ...this.state.barcodeObject,
                            barcodeText: cx,
                        }
                    })
                }
            }
        }
    }


    fontSizeOnChange(e){
        let num = e.target.value
        this.setState({fontSize: num})
        if (!isNaN(num)) {

            if (this.state.chosenTextIndex >= 0 && num > 0) {
                if (this.state.showText && num !== null) {
                    let fx2 = this.state.text[this.state.chosenTextIndex];
                    let gx_mm = Number(num) * 0.352778
                    fx2.fontSize = (this.props.label_Height * gx_mm)/this.state.label_dim.height
                    this.setState({fontSize: num})
                    if (fx2.textAlign === 'center' && !fx2.multiline) {
                        fx2.outlineSelected = false;
                        fx2.right = null
                        fx2.width = null
                    }
                    var width = require('text-width');
                    let wt = 'normal';
                    if (fx2.bold) {
                        wt = 'bold';
                    }
                    var w = width(fx2.text, {
                        family: fx2.fontFamily,
                        size: fx2.fontSize,
                        weight: wt
                    });
                    let wx = this.state.objectPosition.width - w
                    if (fx2.textAlign === 'left') {
                        this.setState({
                            objectPosition: {
                                ...this.state.objectPosition,
                                right: this.state.objectPosition.right - wx,
                                width: w
                            }
                        })
                    }
                    if (fx2.textAlign === 'right') {
                        this.setState({
                            objectPosition: {
                                ...this.state.objectPosition,
                                left: this.state.objectPosition.left - wx,
                                width: w
                            }
                        })
                    }
                    if (fx2.textAlign === 'center') {
                        this.setState({
                            objectPosition: {
                                ...this.state.objectPosition,
                                left: this.state.objectPosition.left - wx / 2,
                                right: this.state.objectPosition.right - wx / 2,
                                width: w
                            }
                        })
                    }
                    this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                    let cx = this.state.text;
                    this.setState({
                        text: cx,
                    })
                } else {
                    this.setState({fontSize: num})
                }
            }
            if (this.state.chosenBarcodeIndex >= 0 && num > 0) {
                if (this.state.showText && num !== null) {
                    let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                    let gx_mm = Number(num) * 0.352778
                    fx2.fontSize = (this.props.label_Height * gx_mm)/this.state.label_dim.height
                    this.setState({fontSize: num})
                    if (fx2.textAlign === 'center' && !fx2.multiline) {
                        fx2.outlineSelected = false;
                        fx2.right = null
                        fx2.width = null
                    }
                    var width = require('text-width');
                    let wt = 'normal';
                    if (fx2.bold) {
                        wt = 'bold';
                    }
                    var w = width(fx2.text, {
                        family: fx2.fontFamily,
                        size: fx2.fontSize,
                        weight: wt
                    });
                    let wx = this.state.objectPosition_barcodeText.width - w
                    if (fx2.textAlign === 'left') {
                        this.setState({
                            objectPosition_barcodeText: {
                                ...this.state.objectPosition_barcodeText,
                                right: this.state.objectPosition_barcodeText.right - wx,
                                width: w
                            }
                        })
                    }
                    if (fx2.textAlign === 'right') {
                        this.setState({
                            objectPosition_barcodeText: {
                                ...this.state.objectPosition_barcodeText,
                                left: this.state.objectPosition_barcodeText.left - wx,
                                width: w
                            }
                        })
                    }
                    if (fx2.textAlign === 'center') {
                        this.setState({
                            objectPosition_barcodeText: {
                                ...this.state.objectPosition_barcodeText,
                                left: this.state.objectPosition_barcodeText.left - wx / 2,
                                right: this.state.objectPosition_barcodeText.right - wx / 2,
                                width: w
                            }
                        })
                    }
                    this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                    let cx = this.state.barcodeObject.barcodeText;
                    this.setState({
                        barcodeObject: {
                            ...this.state.barcodeObject,
                            barcodeText: cx,
                        }
                    })
                } else {
                    this.setState({fontSize: num})
                }
            }
        }

    }

    onnumberInputChange(num){
        if( this.state.chosenTextIndex >= 0 && num > 0) {
            if (this.state.showText && num !== null) {
                let fx2 = this.state.text[this.state.chosenTextIndex];
                let gx_mm = Number(num) * 0.352778
                fx2.fontSize = (this.props.label_Height * gx_mm)/this.state.label_dim.height
                this.setState({fontSize: num})
                if (fx2.textAlign === 'center' && !fx2.multiline) {
                    fx2.outlineSelected = false;
                    fx2.right = null
                    fx2.width = null
                }
                var width = require('text-width');
                let wt = 'normal';
                if (fx2.bold) {
                    wt = 'bold';
                }
                var w = width(fx2.text, {
                    family: fx2.fontFamily,
                    size: fx2.fontSize,
                    weight: wt
                });
                let wx = this.state.objectPosition.width - w
                if (fx2.textAlign === 'left') {
                    this.setState({
                        objectPosition: {
                            ...this.state.objectPosition,
                            right: this.state.objectPosition.right - wx,
                            width: w
                        }
                    })
                }
                if (fx2.textAlign === 'right') {
                    this.setState({
                        objectPosition: {
                            ...this.state.objectPosition,
                            left: this.state.objectPosition.left - wx,
                            width: w
                        }
                    })
                }
                if (fx2.textAlign === 'center') {
                    this.setState({
                        objectPosition: {
                            ...this.state.objectPosition,
                            left: this.state.objectPosition.left - wx / 2,
                            right: this.state.objectPosition.right - wx / 2,
                            width: w
                        }
                    })
                }
                this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                let cx = this.state.text;
                this.setState({
                    text: cx,
                })
            } else {
                this.setState({fontSize: num})
            }
        }
        if (this.state.chosenBarcodeIndex >= 0  && num > 0) {
            if (this.state.showText && num !== null) {
                let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                let gx_mm = Number(num) * 0.352778
                fx2.fontSize = (this.props.label_Height * gx_mm)/this.state.label_dim.height
                this.setState({fontSize: num})
                if (fx2.textAlign === 'center' && !fx2.multiline) {
                    fx2.outlineSelected = false;
                    fx2.right = null
                    fx2.width = null
                }
                var width = require('text-width');
                let wt = 'normal';
                if (fx2.bold) {
                    wt = 'bold';
                }
                var w = width(fx2.text, {
                    family: fx2.fontFamily,
                    size: fx2.fontSize,
                    weight: wt
                });
                let wx = this.state.objectPosition_barcodeText.width - w
                if (fx2.textAlign === 'left') {
                    this.setState({
                        objectPosition_barcodeText: {
                            ...this.state.objectPosition_barcodeText,
                            right: this.state.objectPosition_barcodeText.right - wx,
                            width: w
                        }
                    })
                }
                if (fx2.textAlign === 'right') {
                    this.setState({
                        objectPosition_barcodeText: {
                            ...this.state.objectPosition_barcodeText,
                            left: this.state.objectPosition_barcodeText.left - wx,
                            width: w
                        }
                    })
                }
                if (fx2.textAlign === 'center') {
                    this.setState({
                        objectPosition_barcodeText: {
                            ...this.state.objectPosition_barcodeText,
                            left: this.state.objectPosition_barcodeText.left - wx / 2,
                            right: this.state.objectPosition_barcodeText.right - wx / 2,
                            width: w
                        }
                    })
                }
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject:{
                        ...this.state.barcodeObject,
                        barcodeText: cx,
                    }
                })
            } else {
                this.setState({fontSize: num})
            }
        }
    }

    onfontChange(f){
        if( this.state.chosenTextIndex >= 0) {
            if (this.state.showText) {
                let fx2 = this.state.text[this.state.chosenTextIndex];
                let ffm = f.label
                const fontStyleChecker = (font_styles.indexOf(f.label) > -1);
                if (fontStyleChecker) {
                    if (fx2.bold && fx2.italic) {
                        ffm = ffm + 'BoldItalic'

                    }
                    if (fx2.bold && !fx2.italic) {
                        ffm = ffm + 'Bold'
                    }
                    if (!fx2.bold && fx2.italic) {
                        ffm = ffm + 'Italic'
                    }


                }

                if (!fontStyleChecker) {
                    this.setState({bold: false, italic: false}, () => {
                        fx2.bold = false;
                        fx2.italic = false;
                    })
                }
                fx2.fontFamily = ffm;

                if (fx2.textAlign === 'center' && !fx2.multiline) {
                    fx2.outlineSelected = false;
                    fx2.right = null
                    fx2.width = null
                }
                var width = require('text-width');
                let wt = 'normal';
                if (fx2.bold) {
                    wt = 'bold';
                }
                var w = width(fx2.text, {
                    family: fx2.fontFamily,
                    size: fx2.fontSize,
                    weight: wt
                });
                let wx = this.state.objectPosition.width - w
                if (fx2.textAlign === 'left') {
                    this.setState({
                        objectPosition: {
                            ...this.state.objectPosition,
                            right: this.state.objectPosition.right - wx,
                            width: w
                        }
                    })
                }
                if (fx2.textAlign === 'right') {
                    this.setState({
                        objectPosition: {
                            ...this.state.objectPosition,
                            left: this.state.objectPosition.left - wx,
                            width: w
                        }
                    })
                }
                if (fx2.textAlign === 'center') {
                    this.setState({
                        objectPosition: {
                            ...this.state.objectPosition,
                            left: this.state.objectPosition.left - wx / 2,
                            right: this.state.objectPosition.right - wx / 2,
                            width: w
                        }
                    })
                }
                this.setState({fontFamily: f.label, fontFamilyIndex: f.value});
                this.state.text.splice(this.state.chosenTextIndex, 1, fx2);
                let cx = this.state.text;
                this.setState({
                    text: cx,
                },()=>this.forceUpdate())
            } else {
                const fontStyleChecker = (font_styles.indexOf(f.label) > -1);
                if (!fontStyleChecker) {
                    this.setState({bold: false, italic: false})
                }
                this.setState({fontFamily: f.label, fontFamilyIndex: f.value});
            }
        }
        if( this.state.chosenBarcodeIndex >= 0) {
            if (this.state.showText) {
                let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                let ffm = f.label
                const fontStyleChecker = (font_styles.indexOf(f.label) > -1);


                if (fontStyleChecker) {
                    if (fx2.bold && fx2.italic) {
                        ffm = ffm + 'BoldItalic'

                    }
                    if (fx2.bold && !fx2.italic) {
                        ffm = ffm + 'Bold'
                    }
                    if (!fx2.bold && fx2.italic) {
                        ffm = ffm + 'Italic'
                    }


                }

                if (!fontStyleChecker) {
                    this.setState({bold: false, italic: false}, () => {
                        fx2.bold = false;
                        fx2.italic = false;
                    })
                }
                fx2.fontFamily = ffm;

                if (fx2.textAlign === 'center' && !fx2.multiline) {
                    fx2.outlineSelected = false;
                    fx2.right = null
                    fx2.width = null
                }
                var width = require('text-width');
                let wt = 'normal';
                if (fx2.bold) {
                    wt = 'bold';
                }
                var w = width(fx2.text, {
                    family: fx2.fontFamily,
                    size: fx2.fontSize,
                    weight: wt
                });
                let wx = this.state.objectPosition_barcodeText.width - w
                if (fx2.textAlign === 'left') {
                    this.setState({
                        objectPosition_barcodeText: {
                            ...this.state.objectPosition_barcodeText,
                            right: this.state.objectPosition_barcodeText.right - wx,
                            width: w
                        }
                    })
                }
                if (fx2.textAlign === 'right') {
                    this.setState({
                        objectPosition_barcodeText: {
                            ...this.state.objectPosition_barcodeText,
                            left: this.state.objectPosition_barcodeText.left - wx,
                            width: w
                        }
                    })
                }
                if (fx2.textAlign === 'center') {
                    this.setState({
                        objectPosition_barcodeText: {
                            ...this.state.objectPosition_barcodeText,
                            left: this.state.objectPosition_barcodeText.left - wx / 2,
                            right: this.state.objectPosition_barcodeText.right - wx / 2,
                            width: w
                        }
                    })
                }
                this.setState({fontFamily: f.label, fontFamilyIndex: f.value});
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject:{
                        ...this.state.barcodeObject,
                        barcodeText: cx,
                    }
                })
            } else {
                const fontStyleChecker = (font_styles.indexOf(f.label) > -1);
                if (!fontStyleChecker) {
                    this.setState({bold: false, italic: false})
                }
                this.setState({fontFamily: f.label, fontFamilyIndex: f.value});
            }
        }
    }
    addToText (){
        this.resetFocusAll()
        let d = this.props.scale_factor * parseInt(this.state.label_dim.width_text,10);
        let dx = d.toString()+'px';
        this.setState({
            deltaPosition: {
                x: 0,
                y: 0,
            }
        });
        let rsz_dz = 11.057374338624339 / this.state.label_dim.width ;

        let ratio_dz = this.props.label_Width * rsz_dz;

        let scale_f = ratio_dz/ 32.724609375;

        let bld = false
        let itl = false


        let ffm = this.state.fontFamily;

        if (this.state.bold && this.state.italic){
            ffm = this.state.fontFamily+'BoldItalic'
            bld = true
            itl = true
        }
        if (this.state.bold && !this.state.italic){
            ffm = this.state.fontFamily+'Bold'
            bld = true
        }
        if (!this.state.bold && this.state.italic){
            ffm = this.state.fontFamily+'Italic'
            itl = true
        }

        let ffsz = this.state.fontSize;


        if (this.state.chosenTextIndex >= 0) {
            let fx1 = this.state.text[this.state.chosenTextIndex];
            let rf = fx1.fontSize;
            let rf_mm = (Number(rf) * this.state.label_dim.height)/this.props.label_Height
            ffsz = rf_mm /  0.352778;
        }
        let gx = ffsz;
        // let up_y = 1.33 * gx;
        let gx_mm = Number(gx) * 0.352778
        gx = (this.props.label_Height * gx_mm)/this.state.label_dim.height
        let up_y = gx_mm

        this.setState({width_text: dx,backgroundColor:'transparent'})
        this.setState({ text: [...this.state.text, {text:'SampleText', focus: false, flipText: false, textAlign: 'left',
                top: (window.innerWidth * 0.095)+this.props.label_Y,noPrint:false,
                overflow:'hidden', left: this.props.label_X+window.innerWidth * 0.25, right: null, deltaPositionX : 0, transform: null, multiline: false,
                deltaPositionY : 0,fontFamily: ffm,fontSize: gx,fontSizeMM:0, lineThrough:false, bold: bld, italic: itl, underline: false,
                backgroundColor:'transparent', textColor: '#000000', resetTransform: null, outlineSelected: false,csvQTY:0,
                pinText: this.state.text.length > 0 ?'Untitled Text'+' ('+this.state.text.length+')': 'Untitled Text',width: null,
                lock:false, suffix:'', prefix: '',xPos:0,yPos:0,scalingRatio: scale_f,paraWidth:false,paraHeight:false,qWidth:40,qWidthNow:40,
                copyDataLabel:'Copy data from Field',copyDataStatus:false,copyChildIndex:[],multilineHeight:1.2,widthMM:0,
                question:false,dateStamp:false,dateStampFormat:'',x:0,y:0,oldWidth:window.innerWidth,questionText:'DATA',
                qTextdeltaX:0,qTextdeltaY:0,qInputdeltaX:0,qInputdeltaY:0,dbEnable:false,dbConnectData:'',dbConnectDataIndex:'',
                transformValue:90,transformRotation:0,zIndex:2,multilineFontSize:gx,multilineFirst:'',multilineFontFamily:ffm,multilineReset:null,
                deleted:false, firstLoad: true
                }]})


    }
    convertHex(hexCode,opacity){
        var hex = hexCode.replace('#','');

        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        var r = parseInt(hex.substring(0,2), 16),
            g = parseInt(hex.substring(2,4), 16),
            b = parseInt(hex.substring(4,6), 16);

        return 'rgba('+r+','+g+','+b+','+opacity/100+')';
    }

    addToBarcode (){
        this.resetFocusAll()
        let bar_Height = (this.props.label_Height * 2.7)/this.state.label_dim.height
        let bar_Width = ((this.props.label_Width * 0.13)/this.state.label_dim.width)

        this.setState({
            deltaPosition: {
                x: 0,
                y: 0,
            }
        });
        let rsz_dz = 11.057374338624339 / this.state.label_dim.width ;

        let ratio_dz = this.props.label_Width * rsz_dz;

        let scale_f = ratio_dz/ 32.724609375;


        let ffm = this.state.fontFamily;
        let bld = false
        let itl = false

        if (this.state.bold && this.state.italic){
            ffm = this.state.fontFamily+'BoldItalic'
            bld = true
            itl = true
        }
        if (this.state.bold && !this.state.italic){
            ffm = this.state.fontFamily+'Bold'
            bld = true
        }
        if (!this.state.bold && this.state.italic){
            ffm = this.state.fontFamily+'Italic'
            itl = true
        }


        let ffsz = this.state.fontSize;

        if (this.state.chosenBarcodeIndex >= 0) {
            let fx1 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if(fx1){
                let rf = fx1.fontSize;
                let rf_mm = (Number(rf) * this.state.label_dim.height)/this.props.label_Height
                ffsz = rf_mm /  0.352778;
            }


        }

        let gx = ffsz;
        let gx_mm = Number(gx) * 0.352778
        gx = (this.props.label_Height * gx_mm)/this.state.label_dim.height
        let up_y = gx_mm

        let barTop = (window.innerWidth * 0.095)+this.props.label_Y

        let qrOn = false

        let bcd_value = '123456'

        if (this.state.barcodeType === 'EAN8'){
            bcd_value = '96385074'
        }
        if (this.state.barcodeType === 'EAN13'){
            bcd_value = '9780201379624'
        }
        if (this.state.barcodeType === 'UPC(A)'){
            bcd_value = '123456789999'
        }
        if (this.state.barcodeType === 'UPC(E)'){
            bcd_value = '00123457'
        }
        if (this.state.barcodeType === 'pharmacode'){
            bcd_value = '1234'
        }
        if (this.state.barcodeType === 'MSI10'){
            bcd_value = '1234'
        }
        if (this.state.barcodeType === 'MSI11'){
            bcd_value = '1234'
        }
        if (this.state.barcodeType === 'QR CODE'){
            qrOn = true
            bar_Height =  this.props.label_Height/2
            this.setState({
                qrCode: true
            })
        }
        let bType = this.state.barcodeType
        if (bType === 'UPC(A)'){
            bType = 'UPC'
        }
        if (bType === 'UPC(E)'){
            bType = 'UPCE'
        }
        this.setState({ barcodeObject: {barcode: [...this.state.barcodeObject.barcode, {barcodeType:bType,barcodeDPI: '200 dpi',value: bcd_value,
                barWidth:bar_Width, barHeight: bar_Height,qrCode: qrOn,barcodeXDIM:'XS',deltaPositionX : 0,emptyPrint:false,csvQTY:0,
        color:'#000000',backgroundColor:'transparent',barHeightMM:0,oldWidth:window.innerWidth,zIndex:2,resetTransform: null,transform: null,
                deltaPositionY : 0,xPos:0,yPos:0, left: this.props.label_X+window.innerWidth * 0.25,lock:false,transformValue:90,transformRotation:0,
                top: barTop, focus: false,checkDigit: false, pinText:this.state.barcodeObject.barcode.length > 0 ?'Untitled Barcode'+' ('+this.state.barcodeObject.barcode.length+')': 'Untitled Barcode', noPrint:false, x:0,y:0,deleted:false,}],
                barcodeText: [...this.state.barcodeObject.barcodeText, {text: bcd_value, focus: false, flipText: false, textAlign: 'left', top: barTop+bar_Height+up_y/2,paraWidth:false,paraHeight:false,question:false,
                overflow:'hidden', left: this.props.label_X+window.innerWidth * 0.25, right: null, deltaPositionX : 0, transform: null, multiline: false,qWidth:40,qWidthNow:40,
                deltaPositionY : 0,fontFamily: ffm,fontSize: gx,fontSizeMM:0, lineThrough:false, bold: bld, italic: itl, underline: false,questionText:'DATA',
                qTextdeltaX:0,qTextdeltaY:0,qInputdeltaX:0,qInputdeltaY:0, copyDataLabel:'Copy data from Field',copyDataStatus:false,
                backgroundColor:'transparent', textColor: '#000000', resetTransform: null, outlineSelected: false, pinText:this.state.barcodeObject.barcodeText.length > 0 ?'Untitled BarcodeText'+' ('+this.state.barcodeObject.barcodeText.length+')': 'Untitled BarcodeText',width: null,
                lock:false, suffix:'', prefix: '',xPos:0,yPos:0,scalingRatio: scale_f,noPrint:false,dateStamp:false,dateStampFormat:'', x:0,y:0,zIndex:2,
                oldWidth:window.innerWidth,copyChildIndex:[],multilineHeight:1.2,widthMM:0,transformValue:90,transformRotation:0,multilineFontSize:gx,multilineFirst:'',
                multilineFontFamily:ffm,multilineReset: null,dbConnectData:'',csvQTY:0,dbConnectDataIndex:'',deleted:false, firstLoad: true }]}
        },()=>{
            if (document.getElementById('bcd' + (this.state.barcodeObject.barcode.length-1))) {

                let fb2 = this.state.barcodeObject.barcode[(this.state.barcodeObject.barcode.length-1)]

                let bz = Number(document.getElementById('bcd' + (this.state.barcodeObject.barcode.length-1)).clientWidth)
                let bw = Number(this.getBarWidth(bType.toLowerCase(), bcd_value, fb2.barcodeXDIM,fb2.barcodeDPI))

                if (Math.ceil(bz) !== Math.ceil(bw)) {
                    const trueWidth = parseInt(bz, 10);
                    const scale = bw / trueWidth;
                    fb2.barWidth = (fb2.barWidth * scale);
                    this.state.barcodeObject.barcode.splice((this.state.barcodeObject.barcode.length-1), 1, fb2);
                    let cx1 = this.state.barcodeObject.barcode;
                    this.setState({
                        barcodeObject:{
                            ...this.state.barcodeObject,
                            barcode: cx1,
                        }
                    })

                }
            }
        })

    }
    onBarcodeTypeChange(f){

        if (this.state.chosenBarcodeIndex >= 0) {

            let fb2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            if (fb2.focus) {

                if (f.label === 'QR CODE') {
                    fb2.qrCode = true;
                    this.setState({
                        qrCode: true,
                    })
                    let fb_bfr = fb2.barHeight;
                    fb2.barHeight = this.props.label_Height / 6
                    let tx = fb2.barHeight - fb_bfr;
                    // fb2.deltaPositionY += tx;
                    fb2.barcodeType = f.label;
                    this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2);
                    let cx1 = this.state.barcodeObject.barcode;
                    let x = fb2.barHeight * this.state.label_dim.height / this.props.label_Height
                    this.setState({
                        barcodeObject: {
                            ...this.state.barcodeObject,
                            barcode: cx1,
                        },
                    })
                    this.setState({
                        barcodeType: f.label,
                        barcodeTypeValue: f.value,
                        barcodeHeight: x,
                    })
                } else {

                    if ((f.label === 'EAN8' && fb2.value.toString().length !== 8) || (f.label === 'EAN13' && fb2.value.toString().length !== 13)) {
                        let fbv = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                        if (f.label === 'EAN8') {
                            fb2.value = '96385074'
                            fbv.text = '96385074'
                        }
                        if (f.label === 'EAN13') {
                            fb2.value = '9780201379624'
                            fbv.text = '9780201379624'
                        }

                        this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fbv);
                        let cx1 = this.state.barcodeObject.barcodeText;
                        this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcodeText: cx1,
                            }
                        })


                    }

                    // if (fb2.value.length !== 8 && f.label === 'EAN8') {
                    //     alert('EAN-8 codes must contain 8 numeric digits')
                    //
                    // }
                    // if (fb2.value.length === 8 && f.label === 'EAN8' && !Barcoder.validate(fb2.value)) {
                    //     alert('Invalid checksum digit for EAN8')
                    //
                    // }
                    // if (fb2.value.length !== 13 && f.label === 'EAN13') {
                    //     alert('EAN-13 code values must contain 12 digits (without checksum digit)')
                    //
                    // }
                    // if (fb2.value.length === 13 && f.label === 'EAN13' && !Barcoder.validate(fb2.value)) {
                    //     alert('Invalid checksum digit for EAN13')
                    //
                    // }
                    // if (fb2.value.length !== 12 && f.label === 'UPC') {
                    //     alert('UPC-A code values must contain 11 digits (without checksum digit)')
                    //
                    // }
                    // if (fb2.value.length === 12 && f.label === 'UPC' && !Barcoder.validate(fb2.value)) {
                    //     alert('Invalid checksum digit for UPC-A')
                    //
                    // }

                    // if (fb2.value.length === 12 && f.label === 'UPC' && Barcoder.validate(fb2.value) ||
                    //     fb2.value.length === 13 && f.label === 'EAN13' && Barcoder.validate(fb2.value) ||
                    //     fb2.value.length === 8 && f.label === 'EAN8' && Barcoder.validate(fb2.value) ||
                    //     (f.label !== 'EAN8' && f.label !== 'EAN13' && f.label !== 'UPC' && fb2.value.length > 0)) {


                    if (fb2.qrCode) {
                        let fb_qr = fb2.barHeight;
                        fb2.barHeight = (this.props.label_Height * 2.7) / this.state.label_dim.height
                        fb2.barWidth = ((this.props.label_Width * 0.13) / this.state.label_dim.width)

                        let tx1 = fb_qr - fb2.barHeight;
                        fb2.deltaPositionY -= tx1;

                    }
                    fb2.qrCode = false;
                    this.setState({
                        qrCode: false,
                    })

                    fb2.barcodeType = f.label;
                    let x = fb2.barHeight * this.state.label_dim.height / this.props.label_Height


                    this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2);
                    let cx1 = this.state.barcodeObject.barcode;
                    this.setState({
                        barcodeObject: {
                            ...this.state.barcodeObject,
                            barcode: cx1,
                        }
                    })

                    this.setState({
                        barcodeType: f.label,
                        barcodeTypeValue: f.value,
                        barcodeHeight: x,

                    })

                    // }
                }
            } else {
                this.setState({
                    barcodeType: f.label,

                })
            }

        }
        else{
            this.setState({
                barcodeType: f.label,

            })
        }
    }
    onBarcodeDPIChange(f){
        if (this.state.chosenBarcodeIndex > -1){
            let fb2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            fb2.barcodeDPI = f.label;

            this.setState({
                barcodeDPI: f.label,
                barcodeDPIValue: f.value,
            })
            if (f.label !== '200 dpi'){
                this.setState({
                    barcodeDPI_Options: options_XDIM_300DPI,
                    barcodeDPIXDIM: 'XS',
                })
                fb2.barcodeXDIM = 'XS';

                // fb2.barWidth = (this.props.label_Width * 0.08)/this.state.label_dim.width

            }
            if (f.label === '200 dpi'){

                this.setState({
                    barcodeDPI_Options: options_XDIM_200DPI,
                    barcodeDPIXDIM: 'XS',
                })
                fb2.barcodeXDIM = 'XS';
                // fb2.barWidth = (this.props.label_Width * 0.13)/this.state.label_dim.width

            }
            if (document.getElementById('bcd' + this.state.chosenBarcodeIndex) &&  fb2.barcodeType === 'CODE128' && fb2.value.length % 2 === 0 && this.isNumeric(fb2.value)) {
                let bz = Number(document.getElementById('bcd' + this.state.chosenBarcodeIndex).clientWidth)
                let bw = Number(this.getBarWidth(fb2.barcodeType.toLowerCase(), fb2.value,fb2.barcodeXDIM,fb2.barcodeDPI))
                if (Math.ceil(bz) !== Math.ceil(bw)) {
                    const trueWidth = parseInt(bz, 10);
                    const scale = bw / trueWidth;
                    fb2.barWidth = (fb2.barWidth * scale);

                }
            }


            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2);
            let cx1 = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcode: cx1,
                }
            })
        }
    }
    onBarcodeDPI_XDIM_Change(f){
        this.setState({
            barcodeDPIXDIM: f.label,
            barcodeDPIXDIM_Value:f.value,
        })
        let fb2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
        fb2.barcodeXDIM = f.label;
        if (document.getElementById('bcd' + this.state.chosenBarcodeIndex)) {
            let bz = Number(document.getElementById('bcd' + this.state.chosenBarcodeIndex).clientWidth)
            let bw = Number(this.getBarWidth(fb2.barcodeType.toLowerCase(), fb2.value, fb2.barcodeXDIM,fb2.barcodeDPI))
            if (Math.ceil(bz) !== Math.ceil(bw)) {
                const trueWidth = parseInt(bz, 10);
                const scale = bw / trueWidth;
                fb2.barWidth = (fb2.barWidth * scale);

            }
        }
        // fb2.barWidth = (this.props.label_Width * f.label)/this.state.label_dim.width

        this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2);
        let cx1 = this.state.barcodeObject.barcode;
        this.setState({
            barcodeObject:{
                ...this.state.barcodeObject,
                barcode: cx1,

            }
        })

    }
    onbarcodeHeightChangePress(e){
        if(this.state.barcodeValue) {
            let rx = e.toFixed(2)
            if (Number(rx) > 2.0) {
                this.setState({barcodeHeight: rx})
                let fb2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
                if (e > 0) {

                    if (fb2.qrCode) {

                        fb2.barHeight = ((this.props.label_Height * e) / this.state.label_dim.height)
                        this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2);
                        let cx1 = this.state.barcodeObject.barcode;
                        this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcode: cx1,
                            },
                        })
                    } else {
                        fb2.barHeight = ((this.props.label_Height * e) / this.state.label_dim.height)

                        this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2);
                        let cx1 = this.state.barcodeObject.barcode;
                        this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcode: cx1,
                            }
                        })
                    }
                }
            }

        }

    }

    onbarcodeHeightChange(e){
            if(this.state.barcodeValue) {
                this.setState({barcodeHeight: e.target.value})
                if (Number(e.target.value) > 2.0) {
                    let fb2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
                    if (e.target.value.length > 0) {
                        this.setState({barcodeHeight: e.target.value})

                        fb2.barHeight = ((this.props.label_Height * e.target.value) / this.state.label_dim.height)

                        this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2);
                        let cx1 = this.state.barcodeObject.barcode;
                        this.setState({
                            barcodeObject: {
                                ...this.state.barcodeObject,
                                barcode: cx1,
                            }
                        })
                    }

                }
            }

    }
    onchangebarcodeValue(e){
        if(this.state.chosenBarcodeIndex >= 0) {

            var isnum = /^\d+$/.test(e.target.value);
            let fb2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            let fb1 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];


                // if  (((fb2.barcodeType === 'CODE128A'|| fb2.barcodeType === 'CODE128C'||
                //     fb2.barcodeType === 'MSI10'|| fb2.barcodeType === 'MSI11') && isnum)
                //     || fb2.barcodeType === 'CODE128B'|| fb2.barcodeType === 'CODE39'
                //     || fb2.barcodeType === 'pharmacode'|| fb2.barcodeType === 'EAN8'
                //     || fb2.barcodeType === 'EAN13'|| fb2.barcodeType === 'UPC'
                //     || fb2.barcodeType === 'QR CODE') {
            if (e.target.value.length > 0){
                this.setState({barcodeValue: e.target.value})
                fb2.value = fb1.prefix + e.target.value + fb1.suffix

                if (fb2.emptyPrint){
                    fb2.emptyPrint = false
                }

            } else {
                this.setState({barcodeValue: '(EMPTY)'})

            }


                if (document.getElementById('bcd' + this.state.chosenBarcodeIndex)) {
                    let bz = Number(document.getElementById('bcd' + this.state.chosenBarcodeIndex).clientWidth)
                    let bw = Number(this.getBarWidth(fb2.barcodeType.toLowerCase(), fb2.value,fb2.barcodeXDIM,fb2.barcodeDPI))
                    if (Math.ceil(bz) !== Math.ceil(bw)) {
                        const trueWidth = parseInt(bz, 10);
                        const scale = bw / trueWidth;
                        if (scale >0 && fb2.barWidth > 0){
                            fb2.barWidth = (fb2.barWidth * scale);

                        }


                    }
                }

                this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2);
                let cx1 = this.state.barcodeObject.barcode;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcode: cx1,
                    }
                }, () => {
                    if (document.getElementById('bcd' + this.state.chosenBarcodeIndex)) {
                        let fb2x = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

                        let bz = Number(document.getElementById('bcd' + this.state.chosenBarcodeIndex).clientWidth)
                        let bw = Number(this.getBarWidth(fb2x.barcodeType.toLowerCase(), fb2x.value, fb2x.barcodeXDIM,fb2.barcodeDPI))
                        if (Math.ceil(bz) !== Math.ceil(bw)) {
                            const trueWidth = parseInt(bz, 10);
                            const scale = bw / trueWidth;
                            if (scale >0 && fb2.barWidth > 0) {

                                fb2x.barWidth = (fb2x.barWidth * scale);
                            }
                            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fb2x);
                            let cx1 = this.state.barcodeObject.barcode;
                            this.setState({
                                barcodeObject: {
                                    ...this.state.barcodeObject,
                                    barcode: cx1,
                                }
                            })



                        }
                    }
                })


                    if(fb1 && this.state.chosenBarcodeIndex >= 0){


                        let dx = document.getElementById('btext'+this.state.chosenBarcodeIndex)

                        let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                        this.setState({showText: e.target.value})
                        var width = require('text-width');
                        let wt = 'normal';
                        if (fx.bold) {
                            wt = 'bold';
                        }
                        var w = width(e.target.value, {
                            family: fx.fontFamily,
                            size: fx.fontSize,
                            weight: wt
                        });
                        // if (dx && fx.textAlign === 'center'){
                        //     fx.outlineSelected = true
                        //     let rg =  window.innerWidth - ((dx.offsetLeft+dx.offsetWidth))
                        //     fx.left = dx.offsetLeft;
                        //     fx.right = rg;
                        //     fx.width = null
                        //
                        //     if (w+10 < dx.offsetWidth){
                        //         fx.width = w
                        //         fx.left += dx.offsetWidth/2 - (w/2)
                        //     }
                        // }

                        fb1.text = e.target.value;

                        let tb = [...this.state.barcodeObject.barcodeText]
                        let tbb = [...this.state.barcodeObject.barcode]

                        for (let t = 0 ; t < fb1.copyChildIndex.length; t++){
                            let tv = tb[fb1.copyChildIndex[t]]
                            let tvb = tbb[fb1.copyChildIndex[t]]
                            tv.text = e.target.value
                            tvb.value = tv.prefix+e.target.value+tv.suffix


                            this.setState({
                                barcodeObject:{
                                    ...this.state.barcodeObject,
                                    barcode: tvb,
                                    barcodeText: tb,
                                }
                            })
                        }
                    this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fb1);
                    let cx2 = this.state.barcodeObject.barcodeText;
                    this.setState({
                        barcodeObject: {
                            ...this.state.barcodeObject,
                            barcodeText: cx2,
                        }
                    })
                }

        }

    }

    changeSelectedBarcodePinText (e){
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            this.setState({barcodepinText:e.target.value})
            fx.pinText = e.target.value;
            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx);

            let cx = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcode: cx,

                }
            })

        }
    }
    changeSelectedImagePinText (e){
        if (this.state.chosenImageIndex >= 0) {
            let fx = this.state.image_array[this.state.chosenImageIndex];
            this.setState({imagepinText:e.target.value})
            fx.pinText = e.target.value;
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fx);
            let cx = this.state.image_array;
            this.setState({
              image_array: cx,
            })
        }
        if (this.state.chosenShapeIndex >= 0) {
            let fx = this.state.shape_array[this.state.chosenShapeIndex];
            this.setState({shapepinText:e.target.value})
            fx.pinText = e.target.value;
            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx);
            let cx = this.state.shape_array;
            this.setState({
                shape_array: cx,
            })
        }
    }

    addtoImage(){
        this.resetFocusAll()
        this.setState({ image_array: [...this.state.image_array, {src:this.state.imageSrcCode, focus: false,
                top: (window.innerWidth * 0.095)+this.props.label_Y,
                left: this.props.label_X+window.innerWidth * 0.25, right: null, deltaPositionX : 0,
                imageName:'select image...',noPrint:false,x:0,y:0,oldWidth:window.innerWidth,
                rszWidth:0,rszHeight: 0, widthMM:0,heightMM:0,
                deltaPositionY : 0, pinText:this.state.image_array.length > 0 ?'Untitled Image'+' ('+this.state.image_array.length+')': 'Untitled Image',width: null, aspect_ratio:'', height:null,
                lock:false, xPos:0,yPos:0, naturalWidth:'',naturalHeight:'',stretch:false,
                zIndex:1,resetTransform: null,transform: null,
                transformValue:90,transformRotation:0,deleted:false,}]},()=>{
            this.setState({
                imageSrcCode: '',
                imageLoaded: false,
                loadImageName:'Select Image'
            })
        })

    }
    addtoShapeNature(e){
        this.setState({shapeNature: e.currentTarget.id})
    }
    addtoShape(){

       this.resetFocusAll()
        let shape_Width = this.props.label_Width/4
        let shape_Height = this.props.label_Width/4
        let str_width = 1


        let shapeTop = (window.innerWidth * 0.095)+this.props.label_Y

        if (this.state.shapeNature === 'line'){
            shape_Width = this.props.label_Width/2
            shapeTop = (window.innerWidth* 0.095)+this.props.label_Y+this.props.label_Height+window.innerWidth*0.025
            shape_Height = null;
            let gx = 1;
            let gx_mm = Number(gx) * 0.352778
            str_width = (this.props.label_Height * gx_mm)/this.state.label_dim.height
        }

        this.setState({ shape_array: [...this.state.shape_array, {type:this.state.shapeNature,focus: false,
                top: shapeTop,fill:'white',radiusX:'',radiusY:'',x:0,y:0, widthMM:0,heightMM:0,
                left: this.props.label_X+window.innerWidth * 0.25, right: null, deltaPositionX : 0,
                rszWidth:0,rszHeight:0,
                shapeName:'select shape...',noPrint:false,radius:shape_Width/2,stroke:'black',strokeWidth:str_width,
                deltaPositionY : 0, pinText: this.state.shape_array.length > 0 ?'Untitled Shape'+' ('+this.state.shape_array.length+')': 'Untitled Shape',
                width: shape_Width, aspect_ratio:'', height:shape_Height,
                lock:false, xPos:0,yPos:0, naturalWidth:'',naturalHeight:'',stretch:false,oldWidth:window.innerWidth,
                zIndex:1,resetTransform: null,transform: null,
                transformValue:90,transformRotation:0,deleted:false,}]},()=>{
            this.setState({shapeNature: ''})

        })
    }


    widthBlur (){
        this.setState({
            label_dim: {
                ...this.state.label_dim,
                actualWidth: this.state.label_dim.width - this.state.label_dim.leftMargin - this.state.label_dim.rightMargin
            }
        })
    }
    onNewFile() {
        this.setState({
                popupNewStatus: false,
            },()=>{
                this.setState({
                    activatorOn: false, dimensions: {},
                    shapeNature:'',
                    imageLoaded: false,
                    imageSrcCode: '',
                    fileLoading:false,
                    transformPopup: false,
                    transformStatus:90,
                    multilineWidth:0,
                    multilineHeight:1,
                    copyDataStatus:false,
                    disableCopyData: false,
                    copyPasteStatus: false,
                    copyData:[],
                    copyDataLabel:'',
                    toolBarCopy:{},
                    label_dim: {
                        width: '', height: '', fileName:'Untitled File',actualWidth: '',actualHeight: '',
                        leftMargin: '0', rightMargin: '0', label_across: '1', labelCreate: false,
                        startLabel: false, label_down: '0',pageNumber:1,
                    },
                    addToList:[],
                    zoom: false,
                    width_text: '150px',
                    text: [],
                    activeDrags: 0,
                    deltaPosition: {
                        x: 0, y: 0
                    },
                    chosenTextIndex: -1,
                    showText : '',
                    bold: true,
                    italic: false,
                    underline: false,
                    lineThrough: false,
                    flipText: false,
                    fontSize: 6,
                    fontFamily: 'Arimo',
                    fontFamilyIndex: 9,
                    backgroundColor: 'white',
                    textColor: 'black',
                    barcodeLineColor:'black',
                    barcodeBackgroundColor:'white',
                    textAlign: 'left',
                    leftAlign: true,
                    centerAlign: false,
                    rightAlign: false,
                    multiline: false,
                    onMouseOver: false,
                    pinText: 'Untitled Text',
                    textLocked: false,
                    prefix: '',
                    suffix: '',
                    textXPos:'',
                    textYPos:'',
                    textNoPrint: false,
                    questionPrintText:'',
                    objectPosition: {
                        x: 0, y: 0, width: 0, height: 0, right: 0
                    },
                    objectPosition_barcodeText: {
                        x: 0, y: 0, width: 0, height: 0, right: 0
                    },
                    font_Scaling: 1,
                    chosenBarcodeIndex: -1,
                    barcodeType: 'CODE128',
                    barcodeTypeValue: 1,
                    barcodeValue:'',
                    barcodeDPI: '200 dpi',
                    barcodeDPIValue: 1,
                    barcodepinText: 'Untitled Barcode',
                    barcodeHeight:'',
                    barcodeDPIXDIM: 'XS',
                    barcodeDPIXDIM_Value: 1,
                    barcodeDPI_Options: options_XDIM_200DPI,
                    barcodeDPI_Options_Value: 1,
                    qrCode: false,
                    barcodeLocked:false,
                    barcodeNoPrint: false,
                    barcodeXPos:'',
                    barcodeYPos:'',
                    checkDigit: false,
                    barcodeObject: {
                        barcode: [], barcodeText: []
                    },
                    image_array: [],
                    shape_array:[],
                    chosenShapeIndex: -1,
                    shapeXPos:'',
                    shapeYPos:'',
                    shapeLocked:false,
                    shapeNoPrint: false,
                    shapepinText: 'Untitled Shape',
                    shapeWidth:'',
                    shapeHeight:'',
                    shapeName:'',
                    shapeStretch: false,
                    img_dim: {
                        width: 0, height: 0,
                    },
                    chosenImageIndex: -1,
                    imageXPos:'',
                    imageYPos:'',
                    objectPosition_Image: {
                        x: 0, y: 0, width: 0, height: 0, right: 0
                    },
                    imageLocked:false,
                    imageNoPrint: false,
                    imagepinText: 'Untitled Image',
                    imageWidth:'',
                    imageHeight:'',
                    imageName:'',
                    imageStretch: false,
                    popupNewStatus: false,
                    popupOpenStatus:false,
                    fileArray:'',
                    shapeType:false,
                    shapeStrokeWidth: 0,
                    shapeBorderColor:'#000000',
                    shapeFillColor:'#FFFFFF',
                    win_Width: window.innerWidth,
                    paraWidth:false,
                    paraHeight:false,
                    textQuestion:false,
                    textIncrement:false,
                    dateStampOn: false,
                    dateStampPopup:false,
                    loadGrid: false,
                    questionPrint:false,
                    questionText:[],
                    questionBtx:[],
                })

        })

    }
    onSaveNew(){
        this.setState({
            popupNewStatus: false,
        },()=>{
            this.onSaveFile()
            this.onNewFile()
        })




    }
    onNewPopupDecider(){
        if (this.state.label_dim.width && this.state.label_dim.height) {
            this.setState({
                popupNewStatus: true,
            })
        }

    }
    onOpenPopupDecider(){
        if (this.state.label_dim.width && this.state.label_dim.height) {
            this.setState({
                popupOpenStatus: true,
            })
        }

    }


        onSaveFile() {
            if (this.state.chosenTextIndex >= 0){
                let fx1 = this.state.text[this.state.chosenTextIndex];
                fx1.focus = false;

                this.state.text.splice(this.state.chosenTextIndex, 1, fx1);

                let cx1 = this.state.text;

                this.setState({
                    text: cx1,
                    chosenTextIndex: -1,
                    showText:null,
                    textXPos: null,
                    textYPos: null,
                })
            }
            if (this.state.chosenBarcodeIndex >= 0) {
                let fx1 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
                fx1.focus = false;
                this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx1);
                let cx1 = this.state.barcodeObject.barcode;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcode: cx1,
                    },
                    chosenBarcodeIndex: -1,
                    barcodeValue: null,
                })

                let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                if (fx2) {
                    fx2.focus = false;
                    this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                    let cx2 = this.state.barcodeObject.barcodeText;

                    this.setState({
                        barcodeObject: {
                            ...this.state.barcodeObject,
                            barcodeText: cx2,

                        },
                        chosenBarcodeIndex: -1,
                        barcodeValue: null,
                    })
                }
            }

            if (this.state.chosenImageIndex >= 0) {
                let fb = this.state.image_array[this.state.chosenImageIndex];
                fb.focus = false;
                this.state.image_array.splice(this.state.chosenImageIndex, 1, fb);
                let cx1 = this.state.image_array;

                this.setState({
                    chosenImageIndex: -1,
                    image_array: cx1,
                })
            }
            if (this.state.chosenShapeIndex >= 0) {
                let fb = this.state.shape_array[this.state.chosenShapeIndex];
                fb.focus = false;
                this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);
                let cx1 = this.state.shape_array;

                this.setState({
                    chosenShapeIndex: -1,
                    shape_array: cx1,
                })
            }
        var FileSaver = require('file-saver');




          this.state.text.map((fx,index)=> {

              if (!fx.deleted){
              let y_range = fx.top + fx.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

              let y_resize = (this.state.label_dim.height / this.props.label_Height);
              let y_pos_real = y_range * y_resize
              let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
              fx.y = Number(y_pos)

              // let bx = Number(fx.fontSize) / Number(fx.scalingRatio);
              // fx.fontSizeMM = Number(bx)

              let rf = fx.fontSize;
              let rf_mm = (Number(rf) * this.state.label_dim.height) / this.props.label_Height
              fx.fontSizeMM = rf_mm / 0.352778;


              let fl = fx.left
              let fdx = fx.deltaPositionX
              let fr = fx.right

              if (fx.textAlign === 'left') {

                  let x_range = fl + fdx - this.props.label_X - window.innerWidth * 0.25;
                  let x_resize = (this.state.label_dim.width / this.props.label_Width);
                  let x_pos_real = x_range * x_resize
                  let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                  fx.x = Number(x_pos)


              }
              if (fx.textAlign === 'center') {

                  let dxx = document.getElementById('txtx' + index)

                  let x_range = fl + fdx - this.props.label_X - window.innerWidth * 0.25 + dxx.offsetLeft;
                  let x_resize = (this.state.label_dim.width / this.props.label_Width);
                  let x_pos_real = x_range * x_resize
                  let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                  fx.x = Number(x_pos)

                  let x_resize1 = (this.state.label_dim.width / this.props.label_Width);
                  let x_range1 = Number(x_pos) / x_resize1

                  fx.left = x_range1 - fx.deltaPositionX + this.props.label_X + window.innerWidth * 0.25;
                  fx.right = window.innerWidth - (fx.left + dxx.offsetWidth)

              }
              if (fx.textAlign === 'right') {

                  let ry = window.innerWidth - fr - this.state.objectPosition.width;
                  let x_range = ry + fdx - this.props.label_X - window.innerWidth * 0.25;
                  let x_resize = (this.state.label_dim.width / this.props.label_Width);
                  let x_pos_real = x_range * x_resize
                  let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                  fx.x = Number(x_pos)


                  // let ry = window.innerWidth - fr - this.state.objectPosition.width;
                  // let x_range = ry + fdx - this.props.label_X - window.innerWidth * 0.25;
                  // let x_resize = (this.state.label_dim.width / this.props.label_Width);
                  // let x_pos_real = x_range * x_resize
                  // let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                  // fx.x = Number(x_pos)

              }
          }

        })


            this.state.barcodeObject.barcode.map((fb,index)=> {
                if (!fb.deleted){
                let x_range = fb.left + fb.deltaPositionX - this.props.label_X - window.innerWidth * 0.25 + 1;

                let bbh = (fb.barHeight * this.state.label_dim.height / this.props.label_Height).toFixed(2)
                fb.barHeightMM = bbh


                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_pos_real = x_range * x_resize
                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                fb.x = Number(x_pos)
                let y_range = fb.top + fb.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

                let y_resize = (this.state.label_dim.height / this.props.label_Height);
                let y_pos_real = y_range * y_resize
                let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                fb.y = Number(y_pos)
            }

            })
            this.state.barcodeObject.barcodeText.map((fx,index)=>{
                if (!fx.deleted) {
                    let dxx = document.getElementById('btxtx' + index)

                    if (fx.textAlign === 'left') {
                        let x_range = fx.left + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                        let x_resize = (this.state.label_dim.width / this.props.label_Width);
                        let x_pos_real = x_range * x_resize
                        let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                        fx.x = Number(x_pos)
                    }
                    if (fx.textAlign === 'center') {
                        if (dxx) {
                            let x_range = fx.left + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25 + dxx.offsetLeft;
                            let x_resize = (this.state.label_dim.width / this.props.label_Width);
                            let x_pos_real = x_range * x_resize
                            let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                            fx.x = Number(x_pos)
                        }
                    }
                    if (fx.textAlign === 'right') {
                        let ry = window.innerWidth - fx.right - this.state.objectPosition_barcodeText.width;
                        let x_range = ry + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                        let x_resize = (this.state.label_dim.width / this.props.label_Width);
                        let x_pos_real = x_range * x_resize
                        let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                        fx.x = Number(x_pos)

                    }
                    let y_range = fx.top + fx.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;
                    let y_resize = (this.state.label_dim.height / this.props.label_Height);
                    let y_pos_real = y_range * y_resize
                    let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                    fx.y = Number(y_pos)
                    // let bx = Number(fx.fontSize) / Number(fx.scalingRatio);
                    // fx.fontSizeMM = Number(bx)
                    let rf = fx.fontSize;
                    let rf_mm = (Number(rf) * this.state.label_dim.height) / this.props.label_Height
                    fx.fontSizeMM = rf_mm / 0.352778;
                }
            })

            this.state.image_array.map((fb,index)=>{

                if (!fb.deleted) {

                    let x_range = fb.left + fb.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                    let x_resize = (this.state.label_dim.width / this.props.label_Width);
                    let x_pos_real = x_range * x_resize
                    let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                    fb.x = Number(x_pos)

                    let img_width = (this.state.label_dim.width * fb.width) / this.props.label_Width
                    fb.widthMM = (img_width).toFixed(2)
                    let img_height = (this.state.label_dim.height * fb.height) / this.props.label_Height
                    fb.heightMM = (img_height).toFixed(2)


                    let y_range = fb.top + fb.deltaPositionY - ((window.innerWidth * 0.095) + this.props.label_Y);
                    let y_resize = (this.state.label_dim.height / this.props.label_Height);
                    let y_pos_real = y_range * y_resize
                    let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                    fb.y = Number(y_pos)

                }

            })

            this.state.shape_array.map((fb,index)=>{
                if (!fb.deleted) {
                    let x_range = fb.left + fb.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                    let x_resize = (this.state.label_dim.width / this.props.label_Width);
                    let x_pos_real = x_range * x_resize
                    let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                    fb.x = Number(x_pos)

                    let img_width = (this.state.label_dim.width * fb.width) / this.props.label_Width
                    fb.widthMM = img_width

                    let img_height = (this.state.label_dim.height * fb.height) / this.props.label_Height
                    fb.heightMM = img_height

                    let y_range = fb.top + fb.deltaPositionY - ((window.innerWidth * 0.095) + this.props.label_Y);
                    let y_resize = (this.state.label_dim.height / this.props.label_Height);
                    let y_pos_real = y_range * y_resize
                    let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                    fb.y = Number(y_pos)
                }

            })


                var data = {label: this.state.label_dim,language:this.state.loadLanguageLabel,
                    text: this.state.text.filter(item => item.deleted !== true),
                    barcodeObject:{barcode:this.state.barcodeObject.barcode.filter(item => item.deleted !== true),
                        barcodeText:this.state.barcodeObject.barcodeText.filter(item => item.deleted !== true)},
                    image_array:this.state.image_array.filter(item => item.deleted !== true),
                    shape_array:this.state.shape_array.filter(item => item.deleted !== true),
                    questionLock:this.state.questionPrintLock,fileLoadedRow:this.state.csvReadRow,truncateText:this.state.truncateText,
                    limitColumns: this.state.limitColumns,
                    csvFileData: this.state.csvFileData,csvFileName:this.state.csvFileName,csvQTYArray:this.state.csvQTYArr,
                    userSelectedColumn: this.state.userSelectedColumn};
                    var json = JSON.stringify(data);
                    var file = new File([json], this.state.label_dim.fileName+".zet", {type: "application/json"});
                    // FileSaver.saveAs(file);
                    if (window.confirm(`Do you want to replace the file ${this.state.label_dim.fileName}.zet?`)) {
                        FileSaver.saveAs(file);
                    } else {
                        console.log("File save canceled.");
                    }

    }
    onSaveOpen(){
        this.setState({
            popupOpenStatus: false,
        })
        this.onSaveFile()
        this.onFileOpen()
    }
    onFileOpen(){
        this.setState({
            popupOpenStatus: false,
        },()=>{

            this.setState({
                activatorOn: false, dimensions: {},
                shapeNature:'',
                imageLoaded: false,
                imageSrcCode: '',
                fileLoading:false,
                transformPopup: false,
                transformStatus:90,
                multilineWidth:0,
                multilineHeight:1,
                copyDataStatus:false,
                disableCopyData: false,
                copyPasteStatus: false,
                copyData:[],
                copyDataLabel:'',
                toolBarCopy:{},
                label_dim: {
                    width: '', height: '', fileName:'Untitled File',actualWidth: '',actualHeight: '',
                    leftMargin: '0', rightMargin: '0', label_across: '1', labelCreate: false,
                    startLabel: false, label_down: '0',pageNumber:1,
                },
                addToList:[],
                zoom: false,
                width_text: '150px',
                text: [],
                activeDrags: 0,
                deltaPosition: {
                    x: 0, y: 0
                },
                chosenTextIndex: -1,
                showText : '',
                bold: true,
                italic: false,
                underline: false,
                lineThrough: false,
                flipText: false,
                fontSize: 6,
                fontFamily: 'Arimo',
                fontFamilyIndex: 9,
                backgroundColor: 'white',
                textColor: 'black',
                barcodeLineColor:'black',
                barcodeBackgroundColor:'white',
                textAlign: 'left',
                leftAlign: true,
                centerAlign: false,
                rightAlign: false,
                multiline: false,
                onMouseOver: false,
                pinText: 'Untitled Text',
                textLocked: false,
                prefix: '',
                suffix: '',
                textXPos:'',
                textYPos:'',
                textNoPrint: false,
                questionPrintText:'',
                objectPosition: {
                    x: 0, y: 0, width: 0, height: 0, right: 0
                },
                objectPosition_barcodeText: {
                    x: 0, y: 0, width: 0, height: 0, right: 0
                },
                font_Scaling: 1,
                chosenBarcodeIndex: -1,
                barcodeType: 'CODE128',
                barcodeTypeValue: 1,
                barcodeValue:'',
                barcodeDPI: '200 dpi',
                barcodeDPIValue: 1,
                barcodepinText: 'Untitled Barcode',
                barcodeHeight:'',
                barcodeDPIXDIM: 'XS',
                barcodeDPIXDIM_Value: 1,
                barcodeDPI_Options: options_XDIM_200DPI,
                barcodeDPI_Options_Value: 1,
                qrCode: false,
                barcodeLocked:false,
                barcodeNoPrint: false,
                barcodeXPos:'',
                barcodeYPos:'',
                checkDigit: false,
                barcodeObject: {
                    barcode: [], barcodeText: []
                },
                image_array: [],
                shape_array:[],
                chosenShapeIndex: -1,
                shapeXPos:'',
                shapeYPos:'',
                shapeLocked:false,
                shapeNoPrint: false,
                shapepinText: 'Untitled Shape',
                shapeWidth:'',
                shapeHeight:'',
                shapeName:'',
                shapeStretch: false,
                img_dim: {
                    width: 0, height: 0,
                },
                chosenImageIndex: -1,
                imageXPos:'',
                imageYPos:'',
                objectPosition_Image: {
                    x: 0, y: 0, width: 0, height: 0, right: 0
                },
                imageLocked:false,
                imageNoPrint: false,
                imagepinText: 'Untitled Image',
                imageWidth:'',
                imageHeight:'',
                imageName:'',
                imageStretch: false,
                popupNewStatus: false,
                popupOpenStatus:false,
                fileArray:'',
                shapeType:false,
                shapeStrokeWidth: 0,
                shapeBorderColor:'#000000',
                shapeFillColor:'#FFFFFF',
                win_Width: window.innerWidth,
                paraWidth:false,
                paraHeight:false,
                textQuestion:false,
                textIncrement:false,
                dateStampOn: false,
                dateStampPopup:false,
                loadGrid: false,
                questionPrint:false,
                questionText:[],
                questionBtx:[],

            })

        })

        if (this.state.fileArray.name.includes('.zet')) {
            this.setState({
                fileLoading: true
            },()=>{
                this.loadNewFile(this.state.fileArray)
            })


        }
        else {
            this.loadOldFile(this.state.fileArray)

        }


    }

    onOpenFile(){
        var File  = document.querySelector('input[type=file]').files[0];

        if (this.state.label_dim.width && this.state.label_dim.height) {
            this.setState({
                fileArray: File
            })
            this.onOpenPopupDecider()

        }
        else {
            if (File.name.includes('.zet')) {
                this.setState({
                    fileLoading: true
                },()=>{
                    this.loadNewFile(File)
                })
            } else {
                this.loadOldFile(File)
            }
        }
    }
    loadNewFile(f){
        const blob = new Blob([f], {type: "application/json"});

        const fr = new FileReader();

        fr.addEventListener("load", e => {
            let gc = JSON.parse(fr.result)

            let lbx_val = getDrawProps(Number(gc.label.width), Number(gc.label.height),Number(this.props.scale_factor))

            let rsz_dz = 11.057374338624339 / Number(gc.label.width) ;

            let ratio_dz = lbx_val[2] * rsz_dz;

            let scale_f = ratio_dz/ 32.724609375;


            gc.label.fileName = f.name.substring(0,f.name.toString().length-4)

            if (!gc.language){

                gc.language = 'English'

            }


            for (let i = 0; i < gc.text.length; i++) {
                let fx2 = gc.text[i]

                let bx = Number(fx2.fontSize) / Number(fx2.scalingRatio);

                // let gx = bx * scale_f;
                //
                fx2.scalingRatio = scale_f;
                // fx2.fontSize = gx;

                let gx_mm = Number(fx2.fontSizeMM) * 0.352778
                fx2.fontSize = (Number(lbx_val[3]) * gx_mm)/Number(gc.label.height)

                var width = require('text-width');
                let wt = 'normal';
                if (fx2.bold) {
                    wt = 'bold';
                }
                var w = width(fx2.text, {
                    family: fx2.fontFamily,
                    size: fx2.fontSize,
                    weight: wt
                });
                let tx = Number(gc.label.width)/Number(fx2.x)
                let rw = (lbx_val[2] /tx )

                let t = Number(gc.label.height)/Number(fx2.y)
                let rx = (lbx_val[3] /t )

                if (fx2.textAlign === 'left'){
                    fx2.left =  lbx_val[0] + window.innerWidth * 0.25+rw;

                }
                if (fx2.dateStamp === true){
                    fx2.question = false

                }
                if (fx2.textAlign === 'center'){
                    let vx =  lbx_val[0] + window.innerWidth * 0.25+rw;

                    fx2.left  = vx
                    fx2.width = w

                }
                if (fx2.textAlign === 'right'){
                    let gy = (fx2.deltaPositionX*window.innerWidth)/fx2.oldWidth
                    let rx = (fx2.right*window.innerWidth)/fx2.oldWidth
                    fx2.right = rx - gy

                }
                if (fx2.multiline){

                    if (fx2.multilineReset === null){
                        fx2.width = (Number(fx2.widthMM) * lbx_val[2])/gc.label.width


                    }else {
                        fx2.width = (Number(fx2.multilineReset) * lbx_val[2])/gc.label.width
                    }
                }


                fx2.top = lbx_val[1] +  window.innerWidth* 0.095 + rx
                fx2.deltaPositionX = 0
                fx2.deltaPositionY = 0
                fx2.oldWidth = window.innerWidth

                if (!fx2.copyChildIndex){
                    fx2.copyChildIndex = []
                }
                if (!fx2.copyDataStatus){
                    fx2.copyDataStatus = false

                }
                if (!fx2.copyDataLabel){
                    fx2.copyDataLabel = 'Copy data from Field'
                }
                if (!fx2.multilineHeight){
                    fx2.multilineHeight = 1
                }
                if (!fx2.widthMM) {
                    fx2.widthMM = 0
                }
                if (!fx2.transformValue){
                    fx2.transformValue = 90
                }
                if (!fx2.transformRotation){
                    fx2.transformRotation = 0
                }
                if (!fx2.zIndex){
                    fx2.zIndex = 2
                }
                if (!fx2.multilineFontSize){
                    fx2.multilineFontSize = 0
                }
                if (!fx2.multilineFirst){
                    fx2.multilineFirst = ''
                }
                if (!fx2.multilineFontFamily){
                    fx2.multilineFontFamily = ''
                }
                if (!fx2.multilineReset){
                    fx2.multilineReset = null
                }
                if (!fx2.dbEnable){
                    fx2.dbEnable = false
                }
                if (!fx2.dbConnectData){
                    fx2.dbConnectData = ''
                }
                if (!fx2.dbConnectDataIndex){
                    fx2.dbConnectDataIndex = ''
                }
                if (!fx2.qTextdeltaX && !fx2.qTextdeltaY){
                    fx2.qTextdeltaX = 0
                    fx2.qTextdeltaY = 0
                }
                if (fx2.questionText === 'Question Before Print'){
                    fx2.questionText = 'DATA'
                }
                if (!fx2.csvQTY){
                    fx2.csvQTY = 0
                }
                if (!fx2.qInputdeltaX && !fx2.qInputdeltaY){
                    fx2.qInputdeltaX = 0
                    fx2.qInputdeltaY = 0
                } else {
                    if (Number.isNaN(Number(fx2.qInputdeltaX))){
                        fx2.qInputdeltaX = 0
                    }
                    if (Number.isNaN(Number(fx2.qInputdeltaY))){
                        fx2.qInputdeltaY = 0
                    }
                }
                if (!fx2.deleted){
                    fx2.deleted = false
                }
                if (!fx2.firstLoad && fx2.firstLoad !== false) {
                    fx2.firstLoad = true;
                }

                if (fx2.qTextdeltaX && fx2.qTextdeltaY && fx2.firstLoad){
                    fx2.qTextdeltaX = 0
                    fx2.qTextdeltaY = 0
                }
                if (fx2.qInputdeltaX && fx2.qInputdeltaY && fx2.firstLoad){
                    fx2.qInputdeltaX = 0
                    fx2.qInputdeltaY = 0
                }
            }

            for (let i = 0; i < gc.text.length; i++) {
                let fx = gc.text[i]
                if (fx.copyChildIndex){
                    if (fx.copyChildIndex.length > 0){
                        for (let j = 0; j < fx.copyChildIndex.length; j++) {
                            if (gc.text[fx.copyChildIndex[j]] !== undefined){
                                if (gc.text[fx.copyChildIndex[j]].copyDataLabel !== fx.pinText){
                                    gc.text[fx.copyChildIndex[j]].copyDataLabel = fx.pinText
                                }
                            }

                        }
                    }
                }
                if(fx.copyDataStatus){

                    if (fx.copyDataStatus === true){
                        let crcCheck = false
                        for (let k = 0; k < gc.text.length; k++) {
                            if (k !== i) {
                                let gx = gc.text[k]
                                if (gx.copyChildIndex.length > 0 && gx.copyChildIndex.includes(i)) {
                                    let mn = gx.copyChildIndex.indexOf(i)
                                    if (mn > -1){
                                        crcCheck = true
                                    }
                                }
                            }
                        }
                        if(crcCheck === false){
                            let hj = false
                            for (let n = 0; n < gc.text.length; n++) {
                                let nx = gc.text[n]
                                if (nx.copyChildIndex.length > 0){
                                    if (nx.pinText === fx.copyDataLabel){
                                        nx.copyChildIndex.push(i)
                                        hj = true
                                    }

                                }
                            }

                            if (hj === false){
                                fx.copyDataLabel = false
                                fx.copyDataLabel = 'Copy data from Field'
                            }
                        }
                    }

                }

            }


            for (let i = 0; i < gc.barcodeObject.barcode.length; i++) {

                let fx2 = gc.barcodeObject.barcode[i]
                let t = Number(gc.label.height)/Number(fx2.y)
                let rx = (lbx_val[3] /t )
                let tx = Number(gc.label.width)/Number(fx2.x)
                let rw = (lbx_val[2] /tx )
                fx2.barHeight = ((lbx_val[3] * Number(fx2.barHeightMM)) / Number(gc.label.height))

                if (fx2.barcodeType === 'CODE128A' || fx2.barcodeType === 'CODE128B' || fx2.barcodeType === 'CODE128C'){
                    fx2.barcodeType = 'CODE128'

                }
                if (fx2.barcodeDPI !== '200 dpi' && fx2.barcodeDPI !== '300 dpi' && fx2.barcodeDPI !== '600 dpi'
                && fx2.barcodeDPI !== '1200 dpi' ){
                    fx2.barcodeDPI = '200 dpi'
                }
                if (fx2.barcodeDPI === '300 dpi'){

                    if(fx2.barcodeXDIM === '0.08'){
                        fx2.barcodeXDIM = 'XS'
                    }
                    if(fx2.barcodeXDIM === '0.17'){
                        fx2.barcodeXDIM = 'S'
                    }
                    if(fx2.barcodeXDIM === '0.25'){
                        fx2.barcodeXDIM = 'M'
                    }
                    if(fx2.barcodeXDIM === '0.34'){
                        fx2.barcodeXDIM = 'L'
                    }
                    if(fx2.barcodeXDIM === '0.42'){
                        fx2.barcodeXDIM = 'XL'
                    }
                    if(fx2.barcodeXDIM === '0.51' || fx2.barcodeXDIM === '0.59' || fx2.barcodeXDIM === '0.68'
                    || fx2.barcodeXDIM === '0.76'){
                        fx2.barcodeXDIM = 'XXL'
                    }
                }
                if (fx2.barcodeDPI === '200 dpi'){
                    if(fx2.barcodeXDIM === '0.13'){
                        fx2.barcodeXDIM = 'XS'
                    }
                    if(fx2.barcodeXDIM === '0.25'){
                        fx2.barcodeXDIM = 'S'
                    }
                    if(fx2.barcodeXDIM === '0.38'){
                        fx2.barcodeXDIM = 'M'
                    }
                    if(fx2.barcodeXDIM === '0.50'){
                        fx2.barcodeXDIM = 'L'
                    }
                    if(fx2.barcodeXDIM === '0.63'){
                        fx2.barcodeXDIM = 'XL'
                    }
                    if(fx2.barcodeXDIM === '0.76'){
                        fx2.barcodeXDIM = 'XXL'
                    }

                }

                if (fx2.barcodeDPI === '300 dpi'){

                    if(fx2.barcodeXDIM === 0.08){
                        fx2.barcodeXDIM = 'XS'
                    }
                    if(fx2.barcodeXDIM === 0.17){
                        fx2.barcodeXDIM = 'S'
                    }
                    if(fx2.barcodeXDIM === 0.25){
                        fx2.barcodeXDIM = 'M'
                    }
                    if(fx2.barcodeXDIM === 0.34){
                        fx2.barcodeXDIM = 'L'
                    }
                    if(fx2.barcodeXDIM === 0.42){
                        fx2.barcodeXDIM = 'XL'
                    }
                    if(fx2.barcodeXDIM === 0.51 || fx2.barcodeXDIM === 0.59 || fx2.barcodeXDIM === 0.68
                        || fx2.barcodeXDIM === 0.76){
                        fx2.barcodeXDIM = 'XXL'
                    }
                }
                if (fx2.barcodeDPI === '200 dpi'){
                    if(fx2.barcodeXDIM === 0.13){
                        fx2.barcodeXDIM = 'XS'
                    }
                    if(fx2.barcodeXDIM === 0.25){
                        fx2.barcodeXDIM = 'S'
                    }
                    if(fx2.barcodeXDIM === 0.38){
                        fx2.barcodeXDIM = 'M'
                    }
                    if(fx2.barcodeXDIM === 0.50){
                        fx2.barcodeXDIM = 'L'
                    }
                    if(fx2.barcodeXDIM === 0.63){
                        fx2.barcodeXDIM = 'XL'
                    }
                    if(fx2.barcodeXDIM === 0.76){
                        fx2.barcodeXDIM = 'XXL'
                    }

                }

                fx2.left =  lbx_val[0] + window.innerWidth * 0.25+rw;
                fx2.top = lbx_val[1] +  window.innerWidth* 0.095 + rx
                fx2.deltaPositionX = 0
                fx2.deltaPositionY = 0
                fx2.oldWidth = window.innerWidth
                if (fx2.dateStamp === true){
                    fx2.question = false

                }
                if (!fx2.transformValue){
                    fx2.transformValue = 90
                }
                if (!fx2.transformRotation){
                    fx2.transformRotation = 0
                }
                if (!fx2.zIndex){
                    fx2.zIndex = 2
                }
                if (!fx2.resetTransform){
                    fx2.resetTransform = null
                }
                if (!fx2.transform){
                    fx2.transform = null
                }
                if (!fx2.barScale){
                    fx2.barScale = '1'
                }
                if (!fx2.csvQTY){
                    fx2.csvQTY = 0
                }
                if (!fx2.bWidth){
                    fx2.bWidth = '10'
                }
                if (!fx2.emptyPrint){
                    fx2.emptyPrint = false
                }
                if (!fx2.csvQTY){
                    fx2.csvQTY = 0
                }
                if (!fx2.deleted){
                    fx2.deleted = false
                }
            }
            for (let i = 0; i < gc.barcodeObject.barcodeText.length; i++) {

                let fx2 = gc.barcodeObject.barcodeText[i]

                // let bx = Number(fx2.fontSize) / Number(fx2.scalingRatio);

                // let gx = bx * scale_f;
                //
                fx2.scalingRatio = scale_f;
                // fx2.fontSize = gx;

                let gx_mm = Number(fx2.fontSizeMM) * 0.352778
                fx2.fontSize = (Number(lbx_val[3]) * gx_mm)/Number(gc.label.height)

                var width = require('text-width');
                let wt = 'normal';
                if (fx2.bold) {
                    wt = 'bold';
                }
                var w = width(fx2.text, {
                    family: fx2.fontFamily,
                    size: fx2.fontSize,
                    weight: wt
                });
                let tx = Number(gc.label.width)/Number(fx2.x)
                let rw = (lbx_val[2] /tx )

                let t = Number(gc.label.height)/Number(fx2.y)
                let rx = (lbx_val[3] /t )

                if (fx2.textAlign === 'left'){
                    fx2.left =  lbx_val[0] + window.innerWidth * 0.25+rw;
                }
                if (fx2.textAlign === 'center'){
                    fx2.left =  lbx_val[0] + window.innerWidth * 0.25+rw;
                    // fx2.right = window.innerWidth - (fx2.left+w)
                    fx2.width = w

                }
                if (fx2.textAlign === 'right'){
                    let gy = (fx2.deltaPositionX*window.innerWidth)/fx2.oldWidth
                    let rx = (fx2.right*window.innerWidth)/fx2.oldWidth
                    fx2.right = rx - gy

                }
                if (fx2.questionText === 'Question Before Print'){
                    fx2.questionText = 'DATA'
                }
                if (fx2.multiline){

                    if (fx2.multilineReset === null){
                        fx2.width = (Number(fx2.widthMM) * lbx_val[2])/gc.label.width


                    }else {
                        fx2.width = (Number(fx2.multilineReset) * lbx_val[2])/gc.label.width
                    }

                }


                fx2.top =   lbx_val[1] +  window.innerWidth* 0.095 + rx
                fx2.deltaPositionX = 0
                fx2.deltaPositionY = 0
                fx2.oldWidth = window.innerWidth
                if (fx2.dateStamp === true){
                    fx2.question = false

                }

                if (!fx2.copyChildIndex){
                    fx2.copyChildIndex = []
                }
                if (!fx2.copyDataStatus){
                    fx2.copyDataStatus = false

                }
                if (!fx2.csvQTY){
                    fx2.csvQTY = 0
                }
                if (!fx2.copyDataLabel){
                    fx2.copyDataLabel = 'Copy data from Field'
                }
                if (!fx2.multilineHeight){
                    fx2.multilineHeight = 1
                }
                if (!fx2.widthMM) {
                    fx2.widthMM = 0
                }
                if (!fx2.transformValue){
                    fx2.transformValue = 90
                }
                if (!fx2.transformRotation){
                    fx2.transformRotation = 0
                }
                if (!fx2.zIndex){
                    fx2.zIndex = 2
                }
                if (!fx2.multilineFontSize){
                    fx2.multilineFontSize = 0
                }
                if (!fx2.multilineFirst){
                    fx2.multilineFirst = ''
                }
                if (!fx2.multilineFontFamily){
                    fx2.multilineFontFamily = ''
                }
                if (!fx2.multilineReset){
                    fx2.multilineReset = null
                }
                if (!fx2.dbConnectData){
                    fx2.dbConnectData = ''
                }
                if (!fx2.dbConnectDataIndex){
                    fx2.dbConnectDataIndex = ''
                }
                if (!fx2.qTextdeltaX && !fx2.qTextdeltaY){
                    fx2.qTextdeltaX = 0
                    fx2.qTextdeltaY = 0
                }
                if (!fx2.qInputdeltaX && !fx2.qInputdeltaY){
                    fx2.qInputdeltaX = 0
                    fx2.qInputdeltaY = 0
                } else {
                    if (Number.isNaN(Number(fx2.qInputdeltaX))){
                        fx2.qInputdeltaX = 0
                    }
                    if (Number.isNaN(Number(fx2.qInputdeltaY))){
                        fx2.qInputdeltaY = 0
                    }
                }
                if (!fx2.deleted){
                    fx2.deleted = false
                }
                if (!fx2.firstLoad && fx2.firstLoad !== false) {
                    fx2.firstLoad = true;
                }

                if (fx2.qTextdeltaX && fx2.qTextdeltaY && fx2.firstLoad){
                    fx2.qTextdeltaX = 0
                    fx2.qTextdeltaY = 0
                }
                if (fx2.qInputdeltaX && fx2.qInputdeltaY && fx2.firstLoad){
                    fx2.qInputdeltaX = 0
                    fx2.qInputdeltaY = 0
                }
            }

            for (let i = 0; i < gc.barcodeObject.barcodeText.length; i++) {
                let fx = gc.barcodeObject.barcodeText[i]
                if (fx.copyChildIndex){
                    if (fx.copyChildIndex.length > 0){
                        for (let j = 0; j < fx.copyChildIndex.length; j++) {
                            if (gc.text[fx.copyChildIndex[j]].copyDataLabel !== fx.pinText){
                                gc.text[fx.copyChildIndex[j]].copyDataLabel = fx.pinText
                            }
                        }
                    }
                }
                if(fx.copyDataStatus){

                    if (fx.copyDataStatus === true){
                        let crcCheck = false
                        for (let k = 0; k < gc.barcodeObject.barcodeText.length; k++) {
                            if (k !== i) {
                                let gx = gc.barcodeObject.barcodeText[k]
                                if (gx.copyChildIndex.length > 0 && gx.copyChildIndex.includes(i)) {
                                    let mn = gx.copyChildIndex.indexOf(i)
                                    if (mn > -1){
                                        crcCheck = true
                                    }
                                }
                            }
                        }
                        if(crcCheck === false){
                            let hj = false
                            for (let n = 0; n < gc.barcodeObject.barcodeText.length; n++) {
                                let nx = gc.barcodeObject.barcodeText[n]
                                if (nx.copyChildIndex.length > 0){
                                    if (nx.pinText === fx.copyDataLabel){
                                        nx.copyChildIndex.push(i)
                                        hj = true
                                    }

                                }
                            }

                            if (hj === false){
                                fx.copyDataLabel = false
                                fx.copyDataLabel = 'Copy data from Field'
                            }
                        }
                    }

                }

            }
            for (let i = 0; i < gc.image_array.length; i++) {
                let fx2 = gc.image_array[i]
                let t = Number(gc.label.height)/Number(fx2.y)
                let rx = (lbx_val[3] /t )
                let tx = Number(gc.label.width)/Number(fx2.x)
                let rw = (lbx_val[2] /tx )
                fx2.left =  lbx_val[0] + window.innerWidth * 0.25+rw;
                fx2.top = lbx_val[1] +  window.innerWidth* 0.095 + rx

                fx2.deltaPositionX = 0
                fx2.deltaPositionY = 0
                fx2.oldWidth = window.innerWidth

                // if (fx2.stretch){
                //     fx2.width = (Number(lbx_val[2]) * Number(fx2.widthMM))/Number(gc.label.width)
                //     fx2.height = (Number(lbx_val[3]) * Number(fx2.heightMM))/Number(gc.label.height)
                //
                // }
                fx2.width = (Number(lbx_val[2]) * Number(fx2.widthMM))/Number(gc.label.width)
                fx2.height = (Number(lbx_val[3]) * Number(fx2.heightMM))/Number(gc.label.height)

                if (!fx2.transformValue){
                    fx2.transformValue = 90
                }
                if (!fx2.transformRotation){
                    fx2.transformRotation = 0
                }
                if (!fx2.zIndex){
                    fx2.zIndex = 1
                }
                if (!fx2.resetTransform){
                    fx2.resetTransform = null
                }
                if (!fx2.transform){
                    fx2.transform = null
                }
                if (!fx2.widthMM){
                    fx2.widthMM = 0
                }
                if (!fx2.heightMM){
                    fx2.heightMM = 0
                }
                if (!fx2.deleted){
                    fx2.deleted = false
                }
            }
            for (let i = 0; i < gc.shape_array.length; i++) {
                let fx2 = gc.shape_array[i]
                let t = Number(gc.label.height)/Number(fx2.y)
                let rx = (lbx_val[3] /t )
                let tx = Number(gc.label.width)/Number(fx2.x)
                let rw = (lbx_val[2] /tx )
                fx2.left =  lbx_val[0] + window.innerWidth * 0.25+rw;
                fx2.top =  lbx_val[1] +  window.innerWidth* 0.095 + rx
                fx2.deltaPositionX = 0
                fx2.deltaPositionY = 0
                // fx2.oldWidth = window.innerWidth

                if (!fx2.transformValue){
                    fx2.transformValue = 90
                }
                if (!fx2.transformRotation){
                    fx2.transformRotation = 0
                }
                if (!fx2.zIndex){
                    fx2.zIndex = 1
                }
                if (!fx2.resetTransform){
                    fx2.resetTransform = null
                }
                if (!fx2.transform){
                    fx2.transform = null
                }
                if (!fx2.deleted){
                    fx2.deleted = false
                }
            }

            if (!gc.questionLock){
                gc.questionLock = false
            }
            if (!gc.fileLoadedRow){
                gc.fileLoadedRow = false
            }
            if (!gc.csvFileData){
                gc.csvFileData = []
            }

            if (!gc.csvFileName){
                gc.csvFileName = ''
            }
            if (!gc.csvQTYArray){
                gc.csvQTYArray = []
            }
             else {
               gc.csvQTYArray = gc.csvQTYArray.map(value => {
                  if (value !== "") {
                    return "";
                  }
                  return value;
                });
            }

            if(!gc.userSelectedColumn){
              gc.userSelectedColumn = ''
            }

            this.setState({
                label_dim: gc.label,
                loadLanguage:language_Options[gc.language],
                loadLanguageLabel:gc.language,
                text: gc.text,
                dbEnableStatus: gc.csvFileData.length > 0,
                barcodeObject: gc.barcodeObject,
                image_array: gc.image_array,
                shape_array: gc.shape_array,
                questionPrintLock:gc.questionLock,
                csvReadRow:gc.fileLoadedRow,
                truncateText:gc.truncateText,
                limitColumns:gc.limitColumns,
                csvFileData:gc.csvFileData,
                csvFileName:gc.csvFileName,
                csvQTYArr:gc.csvQTYArray,
                userSelectedColumn: gc.userSelectedColumn,

            },()=>{
               for (let i =0; i < this.state.barcodeObject.barcode.length; i++){
                   if (document.getElementById('bcd' + i)) {
                       let fb2x = this.state.barcodeObject.barcode[i];
                       let bz = Number(document.getElementById('bcd' + i).clientWidth)
                       let bw = Number(this.getBarWidthLoad(fb2x.barcodeType.toLowerCase(), fb2x.value,fb2x.barcodeXDIM,lbx_val[2],fb2x.barcodeDPI))
                       if (Math.ceil(bz) !== Math.ceil(bw)) {
                           const trueWidth = parseInt(bz, 10);
                           const scale = bw / trueWidth;
                           fb2x.barWidth = (fb2x.barWidth * scale);
                           this.state.barcodeObject.barcode.splice(i, 1, fb2x);
                           let cx1 = this.state.barcodeObject.barcode;
                           this.setState({
                               barcodeObject: {
                                   ...this.state.barcodeObject,
                                   barcode: cx1,
                               }
                           })
                       }
                   }
               }

                this.setState({fileLoading: false})
            })
        });

        fr.readAsText(blob);

    }
    loadOldFile = (File)=>{
        let a = File.name

        const blob = new Blob([File], {type: "application/xml"});
        let rt = null;

        const fr = new FileReader();

        fr.addEventListener("load", e => {
            var parseString = require('xml2js').parseString;
            parseString(fr.result, function (err, result) {
                if (result){
                    rt = result.x1;
                }

            });
            if (rt !== null) {
                this.setState({
                    label_dim: {
                        ...this.state.label_dim,
                        width: rt.pw[0],
                        height: rt.ph[0],
                        fileName: a.substr(0,a.length-3),
                        actualWidth: rt.tin[0],
                        actualHeight: rt.hint[0],
                        leftMargin: rt.lmargin[0],
                        rightMargin: rt.rmargin[0],
                        label_across: rt.labelacross[0],
                        labelCreate: false,
                        startLabel: true,
                        label_down: rt.labeldown[0],
                    }
                })
                let rsz_dz = 11.057374338624339 / this.state.label_dim.width ;

                let ratio_dz = this.props.label_Width * rsz_dz;

                let scale_f = ratio_dz/ 32.724609375;

                let n2x = rt.pw[0]
                let n2 = (n2x*72)/25.4
                let n4 = rt.lmargin[0]
                let n41 = (n4*72)/25.4
                let n5 = rt.rmargin[0]
                let n51 = (n5*72)/25.4
                let n7 = rt.labelacross[0]
                let n = (n2-(n41+n51))/n7;

                let n3x = rt.ph[0]
                let n3 = (n3x*72)/25.4

                let ld = rt.labeldown[0];
                let ld1 = (ld*72)/25.4

                let tm = rt.tmargin[0];
                let tm1 = (tm*72)/25.4

                let bm = rt.bmargin[0];
                let bm1 = (bm*72)/25.4

                let nhh = (n3-(tm1+bm1)/ld)

                if (rt.text) {
                    rt.text.map((item, i) => {
                        let txb = false
                        let txi = false
                        let txu = false
                        let txl = false
                        let tx_lock = false
                        let tx_suffix = ''
                        let tx_prefix = ''
                        let tx_noPrint = false
                        let tx_multiline = false
                        let txw = null
                        let tx_align = 'left'
                        let tx_txt = ''
                        let tx_question = false

                        if (item.question[0] === 'true' && item.questionboolean[0] === 'true'){
                            tx_question = true
                        }

                        let ffm = this.capitalizeFirstLetter(item.fontfamily[0]);

                        if (ffm.includes('bold') || ffm.includes('bolditalic')) {
                            const d = ffm.indexOf('bold');
                            ffm = ffm.slice(0, d);
                        } else {
                            if (ffm.includes('italic')) {
                                const dx = ffm.indexOf('italic');
                                ffm = ffm.slice(0, dx);
                            }
                        }
                        if (item.weight[0] === 'bold' && item.style[0] === 'italic') {
                            ffm = ffm + 'BoldItalic'
                        }
                        if (item.weight[0] === 'bold' && item.style[0] !== 'italic') {
                            ffm = ffm + 'Bold'
                        }
                        if (item.weight[0] !== 'bold' && item.style[0] === 'italic') {
                            ffm = ffm + 'Italic'
                        }

                        if (item.weight[0] === 'bold') {
                            txb = true
                        }
                        if (item.style[0] === 'italic') {
                            txi = true
                        }
                        if (item.underline[0] === 'underline') {
                            txu = true
                        }
                        if (item.linethrough[0] === 'true') {
                            txl = true
                        }
                        if (item.locked[0] === 'true') {
                            tx_lock = true
                        }
                        if (item.prefix[0] !== 'null') {
                            tx_prefix = item.prefix[0]
                        }
                        if (item.suffix[0] !== 'null') {
                            tx_suffix = item.suffix[0]
                        }
                        if (item.noprint[0] === 'true') {
                            tx_noPrint = true
                        }
                        tx_txt = item.tx[0].substr(tx_prefix.length, item.tx[0].length - tx_suffix.length - tx_prefix.length)


                        if (item.alignment[0] === 'Center') {
                            tx_align = 'center'
                        }
                        if (item.alignment[0] === 'Right') {
                            tx_align = 'right'
                        }
                        let gx = item.size[0] * scale_f;

                        let up_y = 1.33 * gx;

                        let offset_X = 0;
                        let offset_Y = 0;

                        let t_addX = Number(item.x[0])
                        let t_addY = Number(item.y[0])

                        if (n > nhh) {
                            let xz = t_addX - 10
                            let xz_z = xz / 470
                            offset_X = this.props.label_Width * xz_z

                            let x = n / nhh;
                            let x1 = 470 / x;
                            let xx2 = x1 / 2;
                            let offsetYx = 219 - (xx2);
                            let hz = x1;

                            let yz = t_addY - offsetYx
                            let yz_z = yz / hz
                            offset_Y = this.props.label_Height * yz_z

                            if (item.multiline[0] === 'true') {
                                tx_multiline = true
                                let txwx = Number(item.maxwidth[0]) / 470
                                txw = this.props.label_Width * txwx

                            }
                        }
                        if (n === nhh) {

                            let xpos = (245 - (415 / 2));
                            let xz = t_addX - xpos
                            let xz_z = xz / 480
                            offset_X = this.props.label_Width * xz_z

                            let ypos = 219 - (415 / 2);
                            let yz = t_addY - ypos
                            let yz_z = yz / 427
                            offset_Y = this.props.label_Height * yz_z

                            if (item.multiline[0] === 'true') {
                                tx_multiline = true
                                let txwx = Number(item.maxwidth[0]) / 480
                                txw = this.props.label_Width * txwx

                            }
                        }
                        if (nhh > n) {
                            let ss1 = nhh / n;
                            let ss2 = 425 / ss1;
                            let xx1 = ss2 / 2;
                            let xpos = (245 - (xx1));

                            let xz = t_addX - xpos
                            let xz_z = xz / ss2
                            offset_X = this.props.label_Width * xz_z

                            let yz = t_addY - 10
                            let yz_z = yz / 425
                            offset_Y = this.props.label_Height * yz_z

                            if (item.multiline[0] === 'true') {
                                tx_multiline = true
                                let txwx = Number(item.maxwidth[0]) / ss2
                                txw = this.props.label_Width * txwx

                            }
                        }
                        let tx_color = this.decTorgb(item.color[0])
                        let tx_bcolor = this.decTorgb(item.backgroundcolor[0])


                        this.setState({
                            text: [...this.state.text, {
                                text: tx_txt,
                                focus: false,
                                flipText: false,
                                textAlign: tx_align,
                                top: (window.innerWidth * 0.095) + this.props.label_Y + offset_Y,
                                noPrint: tx_noPrint,
                                overflow: 'hidden',
                                left: this.props.label_X + window.innerWidth * 0.25 + offset_X,
                                right: null,
                                deltaPositionX: 0,
                                transform: null,
                                multiline: tx_multiline,
                                deltaPositionY: 0,
                                fontFamily: ffm,
                                fontSize: gx,
                                lineThrough: txl,
                                bold: txb,
                                italic: txi,
                                underline: txu,
                                backgroundColor: tx_bcolor,
                                textColor: tx_color,
                                resetTransform: null,
                                outlineSelected: false,
                                pinText: item.tooltip[0],
                                width: txw,
                                lock: tx_lock,
                                suffix: tx_suffix,
                                prefix: tx_prefix,
                                xPos: 0,
                                yPos: 0,
                                scalingRatio: scale_f,
                                paraWidth: false,
                                paraHeight: false,
                                question: tx_question,
                                fontSizeMM:0, qWidth:40,qWidthNow:40, copyDataLabel:'Copy data from Field',copyDataStatus:false,
                                copyChildIndex:[],multilineHeight:1,widthMM:0, dateStamp:false,dateStampFormat:'',x:0,y:0,
                                oldWidth:window.innerWidth,questionText:'Question Before Print',
                                transformValue:90, transformRotation: 0,zIndex:2
                            }]
                        })
                    })
                }

                if (rt.barcode){
                    rt.barcode.map((item, i) => {

                        let bar_Width = (this.props.label_Width * Number(item.barcodezb[0].barcodezbxdim[0]))/this.state.label_dim.width

                        let b_type = item.barcodezb[0].barcodezbtype[0]
                        let txb = false
                        let txi = false
                        let txu = false
                        let txl = false
                        let tx_lock = false
                        let tx_noPrint = false
                        let b_lock = false
                        let b_noPrint = false
                        let tx_question = false
                        let b_height = 0
                        let tx_align = 'left'

                        if (item.barcodetext[0].barcodetextalignment[0] === 'Center') {
                            tx_align = 'center'
                        }
                        if (item.barcodetext[0].barcodetextalignment[0] === 'Right') {
                            tx_align = 'right'
                        }


                        if (item.barcodetext[0].barcodetextquestion[0] === 'true' && item.barcodetext[0].barcodetextquestionboolean[0] === 'true'){
                            tx_question = true
                        }

                        let tx_color = this.decTorgb(item.barcodetext[0].barcodetextcolor[0])
                        let tx_bcolor = this.decTorgb(item.barcodetext[0].barcodetextbackgroundcolor[0])

                        let b_color = this.decTorgb(item.barcodezb[0].barcodezbcolor[0])
                        let b_bcolor = this.decTorgb(item.barcodezb[0].barcodezbbackgroundcolor[0])

                        let b_name = item.bordercontainername[0]

                        if (b_name === 'null'){
                            b_name = 'Untitled Barcode'
                        }

                        if (b_type === 'code128A' || b_type === 'code128B' || b_type === 'code128C'){
                            b_type = 'CODE128'
                        }
                        if (item.barcodetext[0].barcodetextnoprint[0] === 'true') {
                            tx_noPrint = true
                        }
                        if (item.barcodezb[0].barcodezbnoprint[0] === 'true') {
                            b_noPrint = true
                        }
                        if (item.barcodezb[0].barcodezblocked[0] === 'true') {
                            b_lock = true
                        }

                        if (item.barcodetext[0].barcodetextweight[0] === 'bold') {
                            txb = true
                        }
                        if (item.barcodetext[0].barcodetextstyle[0] === 'italic') {
                            txi = true
                        }
                        if (item.barcodetext[0].barcodetextunderline[0] === 'underline') {
                            txu = true
                        }
                        if (item.barcodetext[0].barcodetextlinethrough[0] === 'true') {
                            txl = true
                        }
                        if (item.barcodetext[0].barcodetextlocked[0] === 'true') {
                            tx_lock = true
                        }


                        let ffm = this.capitalizeFirstLetter(item.barcodetext[0].barcodetextfontfamily[0]);

                        if (ffm.includes('bold') || ffm.includes('bolditalic')) {
                            const d = ffm.indexOf('bold');
                            ffm = ffm.slice(0, d);
                        } else {
                            if (ffm.includes('italic')) {
                                const dx = ffm.indexOf('italic');
                                ffm = ffm.slice(0, dx);
                            }
                        }
                        if (item.barcodetext[0].barcodetextweight[0] === 'bold' && item.barcodetext[0].barcodetextstyle[0] === 'italic') {
                            ffm = ffm + 'BoldItalic'
                        }
                        if (item.barcodetext[0].barcodetextweight[0] === 'bold' && item.barcodetext[0].barcodetextstyle[0] !== 'italic') {
                            ffm = ffm + 'Bold'
                        }
                        if (item.barcodetext[0].barcodetextweight[0] !== 'bold' && item.barcodetext[0].barcodetextstyle[0] === 'italic') {
                            ffm = ffm + 'Italic'
                        }

                        let rsz_dz = 11.057374338624339 / this.state.label_dim.width ;

                        let ratio_dz = this.props.label_Width * rsz_dz;

                        let scale_f = ratio_dz/ 32.724609375;

                        let gx = item.barcodetext[0].barcodetextsize[0] * scale_f;

                        let up_y = 1.33 * gx;

                        let offset_X = 0;
                        let offset_Y = 0;

                        let t_offset_X = 0;
                        let t_offset_Y = 0;

                        let b_addX = Number(item.bordercontainerx[0])+Number(item.barcodezb[0].barcodezbx[0])
                        let b_addY = Number(item.bordercontainery[0])+Number(item.barcodezb[0].barcodezby[0])

                        let t_addX = Number(item.bordercontainerx[0])+Number(item.barcodetext[0].barcodetextx[0])
                        let t_addY = Number(item.bordercontainery[0])+Number(item.barcodetext[0].barcodetexty[0])

                        let b_h = Number(item.barcodezb[0].barcodezbheight[0])


                        if (n > nhh) {
                            let xz = b_addX - 10

                            let xzt = t_addX -10

                            let xz_z = xz / 470
                            let xz_zt = xzt / 470

                            offset_X = this.props.label_Width * xz_z
                            t_offset_X = this.props.label_Width * xz_zt


                            let x = n / nhh;
                            let x1 = 470 / x;
                            let xx2 = x1 / 2;
                            let offsetYx = 219 - (xx2);
                            let hz = x1;

                            let yz = b_addY - offsetYx
                            let yz_z = yz / hz
                            offset_Y = this.props.label_Height * yz_z

                            let yzt = t_addY - offsetYx
                            let yz_zt = yzt / hz
                            t_offset_Y = this.props.label_Height * yz_zt

                            let bhx = b_h / hz
                            b_height = this.props.label_Height * bhx

                        }
                        if (n === nhh) {

                            let xpos = (245 - (415 / 2));
                            let xz = b_addX - xpos
                            let xz_z = xz / 480
                            offset_X = this.props.label_Width * xz_z

                            let xzt = t_addX - xpos
                            let xz_zt = xzt / 480
                            t_offset_X = this.props.label_Width * xz_zt

                            let ypos = 219 - (415 / 2);
                            let yz = b_addY - ypos
                            let yz_z = yz / 427
                            offset_Y = this.props.label_Height * yz_z

                            let yzt = t_addY - ypos
                            let yz_zt = yzt / 427
                            t_offset_Y = this.props.label_Height * yz_zt

                            let bhx = b_h / 427
                            b_height = this.props.label_Height * bhx

                        }
                        if (nhh > n) {
                            let ss1 = nhh / n;
                            let ss2 = 425 / ss1;
                            let xx1 = ss2 / 2;
                            let xpos = (245 - (xx1));

                            let xz = b_addX - xpos
                            let xz_z = xz / ss2
                            offset_X = this.props.label_Width * xz_z

                            let xzt = t_addX - xpos
                            let xz_zt = xzt / ss2
                            t_offset_X = this.props.label_Width * xz_zt

                            let yz = b_addY - 10
                            let yz_z = yz / 425
                            offset_Y = this.props.label_Height * yz_z

                            let yzt = t_addY - 10
                            let yz_zt = yzt / 425
                            t_offset_Y = this.props.label_Height * yz_zt

                            let bhx = b_h / 425
                            b_height = this.props.label_Height * bhx

                        }
                        let b_dpi = item.barcodezb[0].barcodezbdpi[0]+' dpi'

                        let barTop = (window.innerWidth * 0.095)+this.props.label_Y+offset_Y
                        let barTop_t = (window.innerWidth * 0.095)+this.props.label_Y+t_offset_Y


                        this.setState({ barcodeObject: {barcode: [...this.state.barcodeObject.barcode, {barcodeType: b_type,
                                    barcodeDPI: b_dpi,value: item.barcodezb[0].barcodezbtext[0],
                                    color:b_color,backgroundColor:b_bcolor,
                                    barWidth:bar_Width, barHeight: b_height,qrCode:false,barcodeXDIM: Number(item.barcodezb[0].barcodezbxdim[0]),
                                    deltaPositionX : 0,transformValue:90, transformRotation: 0,
                                    deltaPositionY : 0,xPos:0,yPos:0, left: this.props.label_X+window.innerWidth * 0.25+offset_X,lock:b_lock,
                                    top: barTop, focus: false,checkDigit: false, pinText: b_name,noPrint:b_noPrint
                                    ,barHeightMM:0,oldWidth:window.innerWidth,zIndex:2,resetTransform: null,transform: null, x:0,y:0}],
                                barcodeText: [...this.state.barcodeObject.barcodeText, {text:item.barcodezb[0].barcodezbtext[0], focus: false, flipText: false,
                                    textAlign: tx_align,
                                    top: barTop_t,paraWidth:false,paraHeight:false, question:tx_question,
                                    overflow:'hidden', left: this.props.label_X+window.innerWidth * 0.25+t_offset_X, right: null, deltaPositionX : 0, transform: null, multiline: false,
                                    deltaPositionY : 0,fontFamily: ffm,fontSize: gx, lineThrough:txl, bold: txb, italic: txi, underline: txu,
                                    backgroundColor: tx_bcolor, textColor: tx_color, resetTransform: null, outlineSelected: false,
                                    pinText: item.barcodetext[0].barcodetextFieldName[0],width: null,
                                    lock: tx_lock, suffix:'', prefix: '',xPos:0,yPos:0,scalingRatio: scale_f,noPrint:tx_noPrint
                                    ,qWidth:40,qWidthNow:40,
                                    fontSizeMM:0, questionText:'Question Before Print',copyDataLabel:'Copy data from Field',copyDataStatus:false,
                                    dateStamp:false,dateStampFormat:'', x:0,y:0, oldWidth:window.innerWidth,
                                    copyChildIndex:[],multilineHeight:1,widthMM:0,transformValue:90, transformRotation: 0, zIndex:2}]}
                        })
                    })
                }
            }
        })
        fr.readAsText(blob);
    }
    componentToHex =(c) => {
        let hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }
    decTorgb =(c) =>{
        var r = Math.floor(c / (256*256));
        var g = Math.floor(c / 256) % 256;
        var b = c % 256;
        return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);

    }

    capitalizeFirstLetter = (sg)=> {
        return sg.charAt(0).toUpperCase() + sg.slice(1);
    }
    onChangeFileName (e) {

            this.setState({
                label_dim: {
                    ...this.state.label_dim,
                    fileName: e.target.value
                }})

    }
    changeRightState(hz) {
        if (this.props.Right_State === "RH1") {

            return (
                <Data_Var clickedText={this.state.showText} changeClickedText={(e)=>this.changeSelectedText(e)} changeCheckDigit={()=>this.onChangeCheckDigit()}
                          dbData={this.state.csvFileData} changeDBEnable={()=>this.onChangeDBEnable()} dbStatus={this.state.dbEnableStatus} labelDB={this.state.dbDataLabel}
                          dbSelect={this.state.dbDataValue} popupCSV={()=>this.setState({csvPopup:true})}
                          dataLoad={this.state.label_dim.startLabel && this.state.label_dim.width.toString().length > 0 && this.state.label_dim.height.toString().length > 0}
                          onClickSelected={()=>this.onClickedSelectedText()}  onClickBarcodeSelected={()=>this.onClickedSelectedText()} onKeyUpSelected={(e)=>this.onKeyUpSelectedText(e)}
                          onKeyDownSelected={(e)=>this.onKeyDownSelectedText(e)} onKeyDownPrefix={(e)=>this.onKeyDownPrefixText(e)} onKeyUpPrefix={(e)=>this.onKeyUpPrefixText(e)}
                          prefixStatus={this.state.prefix} changebarcodeValue={(e)=>this.onchangebarcodeValue(e)} barcodeValueStatus={this.state.barcodeValue}
                          changeClickedPrefixText={(e)=>this.changePrefixText(e)}   changeClickedSuffixText={(e)=>this.changeSuffixText(e)}
                          onKeyDownSuffix={(e)=>this.onKeyDownSuffixText(e)} onKeyUpSuffix={(e)=>this.onKeyUpSuffixText(e)} checkDigitStatus={this.state.checkDigit}
                          suffixStatus={this.state.suffix} textIndex={this.state.chosenTextIndex} barcodeIndex={this.state.chosenBarcodeIndex}
                          questionStatus={this.state.textQuestion} incrementStatus={this.state.textIncrement} changeQuestion={()=>this.onChangeQuestion()}
                          changeIncrement={()=>this.onChangeIncrement()} mouseleaveText={()=>this.checkIncrement()}
                          dateStampStatus={this.state.dateStampOn} heightLayer={hz} questionTextStatus={this.state.questionPrintText}
                          changedateStamp={()=>this.onChangeDateStamp()} questionTextChange={(e)=>this.changeQuestionTextStatus(e)}
                          questionBarcodeTextChange={(e)=>this.changeQuestionBarcodeTextStatus(e)} copyStatus={this.state.copyDataStatus} dbDataChange={(v)=>this.onDBData(v)}
                          copySelect={this.state.copyData} copyStatusChangeEvent={()=>this.copyStatusChange()} copyDataChange={(v)=>this.onCopyData(v)}
                          copyDataValue={this.state.copyDataLabel} disableCopy={this.state.disableCopyData} keyDownBarcodeText={(e)=>this.onKeyDownSelectedBarcodeText(e)}
                          textBoxBlur={()=>this.offInputTextFocusBlur()} barcodeTextBoxBlur={()=>this.offInputBarcodeTextFocusBlur()}

                />
            )
        }
        if (this.props.Right_State === "RH3") {

            return (
                <Data_Excel onRowChange={()=>this.setState({csvReadRow: !this.state.csvReadRow})} fName={this.state.csvFileName} removeCSV={()=>this.csvReset()}
                            resetPopup={()=>this.setState({csvDataResetPopup:true})}
                            truncateState={this.state.truncateText} limitedColumnState={this.state.limitColumns}
                            onLimitedColumns={()=>this.setState({limitColumns: !this.state.limitColumns})}
                            onTruncateText={()=> this.setState({truncateText: !this.state.truncateText})}
                            loadFileRow={this.state.csvReadRow} heightLayer={hz} csv_onLoad={()=>this.readLoadFiles()} showCSV={()=>this.viewCSV()}/>
            )
        }
    }

    changeState(hz){
        if (this.props.Left_State === "LH1"){

            return(
                <Draw width={this.state.label_dim.width} height={this.state.label_dim.height} changeHeight={(e)=>{this.changeHeight(e)}}
                      labelStatus={this.state.label_dim.width && this.state.label_dim.height} saveFile={()=>this.onSaveFile()}
                      newpopupDecider={()=>this.onNewPopupDecider()} popupOpen={this.state.popupOpenStatus}
                      widthBlur ={()=>{this.widthBlur()}} fileNameStatus={this.state.label_dim.fileName} openFile={()=>this.onOpenFile()}
                      createLabels={()=>{this.createLabel()}} changeFileName={(e)=>this.onChangeFileName(e)} newFile={()=>this.onNewFile()}
                      actualWidth={this.state.label_dim.actualWidth} actualHeight={this.state.label_dim.height} popupStatus={this.state.popupNewStatus}
                      changelabelAcross={(e)=>{this.changelAcross(e)}}  changeWidth={(e)=>{this.changeWidth(e)}} savenew={()=>this.onSaveNew()}
                      lmargin={this.state.label_dim.leftMargin} rmargin={this.state.label_dim.rightMargin} lable_across={this.state.label_dim.label_across}
                      label_down={this.state.label_dim.label_down} changelmar={(e)=>{this.changelmar(e)}} changermar={(e)=>{this.changermar(e)}}
                      labelStart={this.state.label_dim.startLabel} heightLayer={hz} langData={this.state.loadLanguage.drawLabel}
                      />
            )

        }
        if (this.props.Left_State === "LH2"){
            return(
                <TextLayer boldStatus={this.state.bold} italicStatus={this.state.italic} lineThroughStatus={this.state.lineThrough} underlineStatus={this.state.underline} pinTextStatus={this.state.pinText}
                           changePinText={(e)=>this.changeSelectedPinText(e)} textLockedStatus={this.state.textLocked} onLock={()=>this.onLockChange()} textXposStatus={this.state.textXPos}
                           onKeyDownPrefix={(e)=>this.onKeyDownPrefixText(e)} onKeyUpPrefix={(e)=>this.onKeyUpPrefixText(e)} textYposStatus={this.state.textYPos} textXPosChange={(e)=>this.textXPosOnChange(e)}
                           prefixStatus={this.state.prefix} textYPosChange={(e)=>this.textYPosOnChange(e)} fontSizeChange={(e)=>this.fontSizeOnChange(e)}
                           changeClickedPrefixText={(e)=>this.changePrefixText(e)}   changeClickedSuffixText={(e)=>this.changeSuffixText(e)}
                           onKeyDownSuffix={(e)=>this.onKeyDownSuffixText(e)} onKeyUpSuffix={(e)=>this.onKeyUpSuffixText(e)}
                           suffixStatus={this.state.suffix} textNoPrintStatus={this.state.textNoPrint} onNoPrint={()=>this.onNoPrintChange()}
                           onParaWidth={()=>this.onParaWidthChange()} onParaHeight={()=>this.onParaHeightChange()}
                           onKeyDownSelected={(e)=>this.onKeyDownSelectedText(e)} onKeyUpSelected={(e)=>this.onKeyUpSelectedText(e)} onClickSelected={()=>this.onClickedSelectedText()}
                           changeClickedText={(e)=>this.changeSelectedText(e)} textColorStatus={this.state.textColor} leftAlignStatus={this.state.leftAlign} centerAlignStatus={this.state.centerAlign}
                           rightAlignStatus={this.state.rightAlign} multilineStatus={this.state.multiline} flipTextStatus={this.state.flipText} fontFamilyStatus={this.state.fontFamily}
                           fontFamilyStatusIndex={this.state.fontFamilyIndex} clickedText={this.state.showText} fontChange={(v)=>this.onfontChange(v)} numberInputChange={(v)=>this.onnumberInputChange(v)}
                           fontSizeValue={this.state.fontSize} paraWidthStatus={this.state.paraWidth} paraHeightStatus={this.state.paraHeight}
                           onTextColor={(c)=>this.onTextColorChangeHandler(c)} onBackgroundColor={(c)=>this.onBackgroundColorChangeHandler(c)} backgroundColorStatus={this.state.backgroundColor}
                           onBold={()=>this.onBoldChange()}  onItalic={()=>this.onItalicChange()} onlineThrough={()=>this.onlineThroughChange()} onUnderline={()=>this.onUnderlineChange()}
                           onMultiline={()=>this.onMultilineWidth()} onFlip={()=>this.onFlipChange()} addtoArray={()=>{this.addToText()}} onLeftTextAlign={()=>this.onLeftTextAlignChange()}
                           onCenterTextAlign={()=>this.onCenterTextAlignChange()} onRightTextAlign={()=>this.onRightTextAlignChange()}  onLeftMouseDown={()=>this.onLeftMouseDown11()} onLeftMouseClick={()=>this.onLeftMouseDownClick()}
                           onLeftMouseUp={()=>this.onLeftMouseUp11()} onRightMouseDown={()=>this.onRightMouseDown11()} onRightMouseUp={()=>this.onRightMouseUp11()} onUpMouseDown={()=>this.onUpMouseDown11()}
                           onUpMouseUp={()=>this.onUpMouseUp11()} onDownMouseDown={()=>this.onDownMouseDown11()} onDownMouseUp={()=>this.onDownMouseUp11()} heightLayer={hz} multilineWidthStatus={this.state.multilineWidth}
                           multilineHeightStatus={this.state.multilineHeight} changeMulti={(e)=>this.changeMultiWidth(e)} changeMultiLineHeight={(e)=>this.changeMultiHeight(e)}
                           numberChangeMultiWidth={(e)=>this.onNumberChangeMultiWidth(e)} numberChangeMultiHeight={(e)=>this.onNumberChangeMultiHeight(e)} clockTransform={()=>this.transformClock()}
                           clockAntiTransform={()=>this.transformAntiClock()} transformRotateValue={this.state.transformStatus} changeRotation={(e)=>this.changeRotationAngle(e)} numberChangeRotation={(e)=>this.numberChangeRotationAngle(e)}
                           bringForward={()=>this.textBringForward()} bringBackward={()=>this.textBringBackward()} statusTransparent={this.state.transparentStatus} changeTransparent={()=>this.onTransparencyChange()}
                           langData={this.state.loadLanguage.textLayer} />
            )
        }
        if (this.props.Left_State === "LH3"){
            return(
                <BarcodeLayer barcodeTypeStatus_Value={this.state.barcodeTypeValue} barcodeTypeStatus={this.state.barcodeType} qrcodeStatus={this.state.qrCode}
                              barcodeTypeChange={(v)=>this.onBarcodeTypeChange(v)} barcodeDPIStatus={this.state.barcodeDPI} barcodeDPIStatus_Value={this.state.barcodeDPIValue}
                              barcodeDPI_XDIM={this.state.barcodeDPIXDIM} barcodeDPIChange={(v)=>this.onBarcodeDPIChange(v)} barcodeValueStatus={this.state.barcodeValue}
                              barcodeDPI_XDIM_Change={(v)=>this.onBarcodeDPI_XDIM_Change(v)} barcodeDPI_XDIM_Value={this.state.barcodeDPIXDIM_Value} barcodeNoPrintStatus={this.state.barcodeNoPrint}
                              barcodeHeightStatus={this.state.barcodeHeight} changebarcodeHeight={(e)=>this.onbarcodeHeightChange(e)} barcodeXPosChange={(e)=>this.barcodeXPosOnChange(e)}
                              changebarcodeValue={(e)=>this.onchangebarcodeValue(e)} barcodeXposStatus={this.state.barcodeXPos} barcodeYposStatus={this.state.barcodeYPos}
                              barcodepinTextStatus={this.state.barcodepinText} barcodechangePinText={(e)=>this.changeSelectedBarcodePinText(e)} changeCheckDigit={()=>this.onChangeCheckDigit()}
                              addtoBarocdeArray={()=>{this.addToBarcode()}} barcodedpi_Options={this.state.barcodeDPI_Options} barcodeYPosChange={(e)=>this.barcodeYPosOnChange(e)}
                              onBarcodeLeftMouseDown={()=>this.onBarcodeLeftMouseDown11()} onBarcodeLeftMouseUp={()=>this.onBarcodeLeftMouseUp11()} onBarcodeRightMouseDown={()=>this.onBarcodeRightMouseDown11()}
                              onBarcodeRightMouseUp={()=>this.onBarcodeRightMouseUp11()} onBarcodeUpMouseDown={()=>this.onBarcodeUpMouseDown11()} onBarcodeUpMouseUp={()=>this.onBarcodeUpMouseUp11()}
                              onBarcodeDownMouseDown={()=>this.onBarcodeDownMouseDown11()} onBarcodeDownMouseUp={()=>this.onBarcodeDownMouseUp11()} checkDigitStatus={this.state.checkDigit}
                              barcodeLockedStatus={this.state.barcodeLocked} onBarcodeLock={()=>this.onBarcodeLockChange()} onBarcodeNoPrint={()=>this.onBarcodeNoPrintChange()}
                              changebarcodeHeightArrow={(e)=>this.onbarcodeHeightChangePress(e)} lh2_click={this.LH2_ClickBtx} lineColor={this.state.barcodeLineColor}
                              backgroundColor={this.state.barcodeBackgroundColor} onbarcodeBackgroundChange={(c)=>this.onBarcodeBackgroundColorChangeHandler(c)}
                              onbarcodeLineChange={(c)=>this.onBarcodeLineColorChangeHandler(c)} heightLayer={hz} clickedBarcode={this.state.chosenBarcodeIndex}
                              clockTransform={()=>this.transformBarcodeClock()} langData={this.state.loadLanguage.barcodeLayer}
                              clockAntiTransform={()=>this.transformBarcodeAntiClock()} transformRotateValue={this.state.transformStatus} changeRotation={(e)=>this.changeBarcodeRotationAngle(e)} numberChangeRotation={(e)=>this.numberChangeBarcodeRotationAngle(e)}
                              bringForward={()=>this.barcodeBringForward()} bringBackward={()=>this.barcodeBringBackward()} statusTransparent={this.state.transparentStatus} changeTransparent={()=>this.onTransparencyBarcodeChange()} />
            )
        }
        if (this.props.Left_State === "LH4"){
            return(
                <ImageLayer imageXPosStatus={this.state.imageXPos} imageYPosStatus={this.state.imageYPos} stretchStatus={this.state.imageStretch} imageLockStatus={this.state.imageLocked}
                            imageXPosChange={(e)=>this.imageXPosOnChange(e)} imageYPosChange={(e)=>this.imageYPosOnChange(e)} numberImageWidth={(v)=>this.onnumberImageWidth(v)}
                            image_onLoad={()=>this.getImageBase64()} langData={this.state.loadLanguage.imageLayer}
                            loadedName={this.state.loadImageName} langData1={this.state.loadLanguage.popup}
                            imageWidthStatus={this.state.imageWidth} numberImageHeight={(v)=>this.onnumberImageHeight(v)}
                            imageHeightStatus={this.state.imageHeight} onStretch={()=>this.onChangeStretch()} onAspectRatio={()=>this.onChangeAspectRatio()} onImageLock={()=>this.onImageLockChange()}
                            onImageLeftMouseDown={()=>this.onImageLeftMouseDown11()} onImageLeftMouseUp={()=>this.onImageLeftMouseUp11()} onImageRightMouseDown={()=>this.onImageRightMouseDown11()}
                            onImageRightMouseUp={()=>this.onImageRightMouseUp11()} onImageUpMouseDown={()=>this.onImageUpMouseDown11()} onImageUpMouseUp={()=>this.onImageUpMouseUp11()}
                            onImageDownMouseDown={()=>this.onImageDownMouseDown11()} onImageDownMouseUp={()=>this.onImageDownMouseUp11()} pinImageStatus={this.state.imagepinText}
                            changePinImage={(e)=>this.changeSelectedImagePinText(e)} clickedImage={this.state.chosenImageIndex} imageNoPrintStatus={this.state.imageNoPrint} onImageNoPrint={()=>this.onImageNoPrintChange()}
                            createShape={(e)=>this.addtoShapeNature(e)} shapeLine={this.state.shapeType} shapeThickness={this.state.shapeStrokeWidth} changeshapeThickness={(v)=>this.onChangeShapeThickness(v)}
                            changeshapeThicknessArrow={(v)=>this.onChangeShapeThicknessPress(v)} heightLayer={hz}
                            borderColor={this.state.shapeBorderColor} fillColor={this.state.shapeFillColor} changeShapeBorderColors={(c)=>this.onChangeShapeBorderColor(c)} changeShapeFillColor={(c)=>this.onChangeShapeFillColor(c)}
                            clickedShape={this.state.chosenShapeIndex} imageHeightMouseDown={()=>this.onnumberImageHeightPressMouseDown()} imageHeightMouseUp={()=>this.onnumberImageHeightPressMouseUp()}
                            imageHeightMouseDownLess={()=>this.onnumberImageHeightPressMouseDownLess()} imageHeightMouseUpLess={()=>this.onnumberImageHeightPressMouseUPLess()}
                            imageWidthMouseDown={()=>this.onnumberImageWidthPressMouseDown()} imageWidthMouseUp={()=>this.onnumberImageWidthPressMouseUp()}
                            imageWidthMouseDownLess={()=>this.onnumberImageWidthPressMouseDownLess()} imageWidthMouseUpLess={()=>this.onnumberImageWidthPressMouseUPLess()}
                            clockTransform={()=>this.transformImageClock()}
                            clockAntiTransform={()=>this.transformImageAntiClock()} transformRotateValue={this.state.transformStatus} changeRotation={(e)=>this.changeImageRotationAngle(e)} numberChangeRotation={(e)=>this.numberChangeImageRotationAngle(e)}
                            bringForward={()=>this.imageBringForward()} bringBackward={()=>this.imageBringBackward()}
                            addtoImageLoaded={this.state.imageLoaded} addtoImageArray={()=>this.addtoImage()}/>
            )
        }
        if (this.props.Left_State === "LH5"){
            return(
                <ShapeLayer imageXPosStatus={this.state.shapeXPos} imageYPosStatus={this.state.shapeYPos} stretchStatus={this.state.shapeStretch} imageLockStatus={this.state.shapeLocked}
                            imageXPosChange={(e)=>this.imageXPosOnChange(e)} imageYPosChange={(e)=>this.imageYPosOnChange(e)} numberImageWidth={(v)=>this.onnumberImageWidth(v)}
                            imageWidthStatus={this.state.shapeWidth} numberImageHeight={(v)=>this.onnumberImageHeight(v)}
                            imageHeightStatus={this.state.shapeHeight} onStretch={()=>this.onChangeStretch()} onAspectRatio={()=>this.onChangeAspectRatio()} onImageLock={()=>this.onImageLockChange()}
                            onImageLeftMouseDown={()=>this.onImageLeftMouseDown11()} onImageLeftMouseUp={()=>this.onImageLeftMouseUp11()} onImageRightMouseDown={()=>this.onImageRightMouseDown11()}
                            onImageRightMouseUp={()=>this.onImageRightMouseUp11()} onImageUpMouseDown={()=>this.onImageUpMouseDown11()} onImageUpMouseUp={()=>this.onImageUpMouseUp11()}
                            onImageDownMouseDown={()=>this.onImageDownMouseDown11()} onImageDownMouseUp={()=>this.onImageDownMouseUp11()} pinImageStatus={this.state.shapepinText}
                            changePinImage={(e)=>this.changeSelectedImagePinText(e)} clickedImage={this.state.chosenShapeIndex} imageNoPrintStatus={this.state.shapeNoPrint} onImageNoPrint={()=>this.onImageNoPrintChange()}
                            createShape={(e)=>this.addtoShapeNature(e)} shapeLine={this.state.shapeType} shapeThickness={this.state.shapeStrokeWidth} changeshapeThickness={(v)=>this.onChangeShapeThickness(v)}
                            changeshapeThicknessArrow={(v)=>this.onChangeShapeThicknessPress(v)} heightLayer={hz}
                            borderColor={this.state.shapeBorderColor} fillColor={this.state.shapeFillColor} changeShapeBorderColor={(c)=>this.onChangeShapeBorderColor(c)} changeShapeFillColor={(c)=>this.onChangeShapeFillColor(c)}
                            clickedShape={this.state.chosenShapeIndex} imageHeightMouseDown={()=>this.onnumberImageHeightPressMouseDown()} imageHeightMouseUp={()=>this.onnumberImageHeightPressMouseUp()}
                            imageHeightMouseDownLess={()=>this.onnumberImageHeightPressMouseDownLess()} imageHeightMouseUpLess={()=>this.onnumberImageHeightPressMouseUPLess()}
                            imageWidthMouseDown={()=>this.onnumberImageWidthPressMouseDown()} imageWidthMouseUp={()=>this.onnumberImageWidthPressMouseUp()}
                            imageWidthMouseDownLess={()=>this.onnumberImageWidthPressMouseDownLess()} imageWidthMouseUpLess={()=>this.onnumberImageWidthPressMouseUPLess()}
                            clockTransform={()=>this.transformShapeClock()} langData={this.state.loadLanguage.shapeLayer}
                            clockAntiTransform={()=>this.transformShapeAntiClock()} transformRotateValue={this.state.transformStatus} changeRotation={(e)=>this.changeShapeRotationAngle(e)} numberChangeRotation={(e)=>this.numberChangeShapeRotationAngle(e)}
                            bringForward={()=>this.shapeBringForward()} bringBackward={()=>this.shapeBringBackward()}
                            addtoShapeArray={()=>this.addtoShape()} addtoShapeSelected={this.state.shapeNature}/>
            )
        }
        else {
            return null;
        }
    }

    onChangeQuestion(){

        if (this.state.chosenTextIndex >= 0) {

            let fx = this.state.text[this.state.chosenTextIndex];
            this.setState({textQuestion: !fx.question})
            fx.question = !fx.question;
            fx.qWidth = 40
            fx.qWidthNow = 40

            if (!fx.question){
                fx.questionText = 'Question Before Print'
                this.setState({
                    questionPrintText: fx.questionText,
                })

            }
            fx.copyDataStatus = false
            fx.copyDataLabel = 'Copy data from Field'
            this.setState({
                copyDataStatus: false,
            })
            fx.dbConnectData = ''

            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx = this.state.text;
            this.setState({
                text: cx,
                dbEnableStatus:false,

            })

        }
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            this.setState({textQuestion: !fx.question})
            fx.question = !fx.question;
            fx.qWidth = 40
            fx.qWidthNow = 40
            if (!fx.question){
                fx.questionText = 'Question Before Print'
                this.setState({
                    questionPrintText: fx.questionText,
                })
            }
            fx.copyDataStatus = false
            fx.dbConnectData = ''

            fx.copyDataLabel = 'Copy data from Field'
            this.setState({
                copyDataStatus: false,
            })
            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                },
                dbEnableStatus:false,

            })

        }

    }
    onChangeIncrement(){

        if (this.state.chosenTextIndex >= 0) {

            let fx = this.state.text[this.state.chosenTextIndex];
            this.setState({textIncrement: !fx.increment})
            fx.increment = !fx.increment;
            fx.copyDataStatus = false
            fx.copyDataLabel = 'Copy data from Field'
            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx = this.state.text;
            this.setState({
                text: cx,
                copyDataStatus: false,
            })

        }
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            this.setState({textIncrement: !fx.increment})
            fx.increment = !fx.increment;
            fx.copyDataStatus = false
            fx.copyDataLabel = 'Copy data from Field'
            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                },
                copyDataStatus: false,
            })

        }

    }
    onChangeDateStamp(){

        if (this.state.chosenTextIndex >= 0) {

            let fx = this.state.text[this.state.chosenTextIndex];
            this.setState({dateStampOn: !fx.dateStamp,dateStampPopup:!fx.dateStamp})
            fx.dateStamp = !fx.dateStamp;
            fx.copyDataStatus = false
            fx.copyDataLabel = 'Copy data from Field'
            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx = this.state.text;
            this.setState({
                text: cx,
                copyDataStatus: false,
            })

        }
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            this.setState({dateStampOn: !fx.dateStamp,dateStampPopup:!fx.dateStamp})
            fx.dateStamp = !fx.dateStamp;
            fx.copyDataStatus = false
            fx.copyDataLabel = 'Copy data from Field'
            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                },
                copyDataStatus: false,
            })

        }

    }
    onChangeDateStampStatus (){
        if (this.state.chosenTextIndex >= 0) {

            let fx = this.state.text[this.state.chosenTextIndex];
            this.setState({dateStampOn: !fx.dateStamp,dateStampPopup:!fx.dateStamp})
            fx.dateStamp = !fx.dateStamp;
            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx = this.state.text;
            this.setState({
                text: cx,
            })

        }
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            this.setState({dateStampOn: !fx.dateStamp,dateStampPopup:!fx.dateStamp})
            fx.dateStamp = !fx.dateStamp;
            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                }
            })

        }
        this.setState({
            dateStampPopup: false
        })
    }

    onApplyDateStamp(e){
        let d = new Date();
        let dx = d.getDate()
        let mx = d.getMonth()+1
        let yx = d.getFullYear().toString()
        let l_yx = yx.slice(-2);
        let sx = ''
        if (dx.toString().length === 1){
            dx = '0'+dx
        }
        if (mx.toString().length === 1){
            mx = '0'+mx
        }
        if (e.currentTarget.id === 'DDMMYY'){
            sx = dx.toString()+mx.toString()+l_yx.toString()

        }
        if (e.currentTarget.id === 'DDMMYYYY'){
            sx = dx.toString()+mx.toString()+yx.toString()
        }
        if (e.currentTarget.id === 'YYMMDD'){
            sx = l_yx.toString()+mx.toString()+dx.toString()
        }
        if (e.currentTarget.id === 'YYYYMMDD'){
            sx = yx.toString()+mx.toString()+dx.toString()
        }

        if (this.state.chosenTextIndex >= 0) {

            let fx = this.state.text[this.state.chosenTextIndex];
            fx.dateStampFormat = e.currentTarget.id;
            this.setState({showText: sx})

            fx.text = sx
            fx.prefix = ''
            fx.suffix = ''
            fx.question = false
            fx.increment = false

            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx = this.state.text;
            this.setState({
                text: cx,
                dateStampPopup: false,
                textQuestion:false,
                textIncrement: false,
            })

        }
        if (this.state.chosenBarcodeIndex >= 0) {

            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            let fb2 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

            this.setState({showText: sx})

            this.setState({barcodeValue: sx})
            fb2.value = sx
            fx.dateStampFormat = e.currentTarget.id;
            fx.text = sx
            fx.prefix = ''
            fx.suffix = ''
            fx.question = false
            fx.increment = false

            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx,

                },
                dateStampPopup: false,
                textQuestion:false,
                textIncrement: false,
            })

        }
    }
    checkQuestionPrint(){
        let rv = false
        let tx_q = []
        let btx_q = []
        this.state.text.map((tx,index)=>{
            if (tx.question && !this.state.questionPrint && !tx.deleted){
                tx.qWidthNow = tx.qWidth
                if (tx.multiline) {
                    tx.widthMM = (tx.width * this.state.label_dim.width / this.props.label_Width).toFixed(1)
                    tx.fontSizeMM = tx.fontSizeMM*0.96
                }
                tx_q.push({'id':index,'txt':tx})
                rv = true
            }
        })

        this.setState({
            questionText: tx_q,

        })
        this.state.barcodeObject.barcodeText.map((fx, index) => {
            if (fx.question && !this.state.questionPrint&& !fx.deleted){
                fx.qWidthNow = fx.qWidth

                btx_q.push({'id':index,'txt':fx})
                rv = true
            }
        })
        this.setState({
            questionBtx: btx_q,
        })
        if (tx_q.length > 0 || btx_q.length > 0){
            this.setState({
                questionPrint: true
            })
        }

        // questionPrint: true
        return rv

    }
    checkCSVPopup(){

        for (let i =0;i < this.state.text.length;i++){
            if (this.state.text[i].dbConnectData.length > 0 && this.state.text[i].deleted === false){
                return true
            }
        }
        for (let i =0;i < this.state.barcodeObject.barcodeText.length;i++){
            if (this.state.barcodeObject.barcodeText[i].dbConnectData.length > 0 && this.state.barcodeObject.barcodeText[i].deleted === false){
                return true
            }
        }
        return  false

    }

    includeItemsByIndexes = (arrayOfArrays, indexesToAdd) => {
           return arrayOfArrays.map((innerArray) => {
        // Include only the items at the specified indexes for all arrays
        return innerArray.filter((_, innerIndex) => indexesToAdd.includes(innerIndex));
      });
    };

    getDBIndexAll(){
      let indexArray = new Set()
      for (let i =0;i < this.state.text.length;i++){
           if(this.state.text[i].dbConnectData !== ''){
              indexArray.add(Number(this.state.text[i].dbConnectDataIndex+1))
           }
      }
      for (let g = 0; g < this.state.barcodeObject.barcodeText.length;g++){
          if(this.state.barcodeObject.barcodeText[g].dbConnectData !== ''){
              indexArray.add(Number(this.state.barcodeObject.barcodeText[g].dbConnectDataIndex+1))
           }
      }
      return Array.from(indexArray)
    }

    getDBValues(indexesToGet){
        let vals = new Map()

        for(let i = 0; i < indexesToGet.length; i++){
           vals.set(indexesToGet[i],i+1)

        }
        return vals

    }

    onPrintLabel(){
        if (this.checkCSVPopup()){
            if (this.state.csvFileData.length > 0){
                var data = Papa.parse(this.state.csvFileData);
                let db = []

                for (let i=0;i<data.data.length;i++){
                    let rs = []
                    for (let k=0;k < data.data[i].length;k++){
                        // if (data.data[i][k].length > 0){
                            rs.push(data.data[i][k])
                        // }
                    }
                    // if (rs.length > 0){
                        rs.splice(0,0,'QTY')

                    // }
                    // if (rs.length > 0){
                        db.push(rs)
                    // }

                }
                let fx = this.state.csvQTYArr
                if (this.state.csvQTYArr.length === 0){
                    for (let i =1; i < db.length;i++){
                        fx.push('')
                    }
                }
                let dbb = db

                if(this.state.limitColumns){

                let indexed = this.getDBIndexAll()
                indexed.push(0)
                const gcc = indexed.sort()
                dbb = this.includeItemsByIndexes(db,gcc)

                const dbFetch = this.getDBIndexAll()
                const getCols = this.getDBValues(dbFetch)
                // Sort the Map by keys
                let sortedMap = new Map([...getCols.entries()].sort((a, b) => a[0] - b[0]));

                // Extract only the values and sort them
                let sortedValues = [...sortedMap.values()].sort((a, b) => a - b);

                // Create a new Map with sorted keys and updated sorted values
                let finalMap = new Map();
                let keys = [...sortedMap.keys()];
                keys.forEach((key, index) => {
                  finalMap.set(key, sortedValues[index]);
                });
                this.setState({csvFileDataTableQBPMap: finalMap})
                }
                this.setState({csvFileDataTableQBP:dbb,csvQTYArr:fx},()=>this.setState({viewCSVQBPPopup: !this.state.viewCSVQBPPopup}))
            }

        }else {
            if (!this.checkQuestionPrint()) {
                if (this.checkBarcodeValidity() === null) {
                    this.printLabelData()
                } else {
                    this.setState({bcdValidateError: true})
                    console.log('barcode invalid')
                }
            }
        }
    }
    checkBarcodeValidity(){
        let rx = null
        this.state.barcodeObject.barcode.map((fb) => {

            if(fb.barcodeType === 'EAN13' || fb.barcodeType === 'EAN8') {
                if (validbarcode(fb.value)){
                    if ((fb.value.length !== 13 && fb.barcodeType === 'EAN13') || (fb.value.length !== 8 && fb.barcodeType === 'EAN8')){
                        rx =  false
                    }
                }
            }
        })
        return rx

    }
    printPreviousLabel(){
        if(this.state.loadPreviousReprint.label && this.checkBarcodeValidity() === null){
            this.setState({
                loadGrid: true,
                questionPrint: false,
            })
            var self = this
            const axios = require('axios');
            let url_p = 'https://backendbeta.zetags.com/barcode'

            // url_p = 'http://localhost:3000/barcode'
            axios.post(url_p,this.state.loadPreviousReprint, {
                responseType: 'arraybuffer'

            })
                .then(function (response) {
                    self.checkAddToList()
                    self.setState({
                        loadGrid: false,

                    })
                    const file = new Blob([response.data], {type: 'application/pdf'});
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);




                })
                .catch(function (error) {
                    self.setState({
                        loadGrid: false,
                        addToList:[],

                    })
                    console.log(error);
                });


        }

    }
    printLabelData(){

        this.setState({
            loadGrid: true
        })
        if (this.state.chosenTextIndex >= 0) {

            let fx1 = this.state.text[this.state.chosenTextIndex];
            fx1.focus = false;

            this.state.text.splice(this.state.chosenTextIndex, 1, fx1);

            let cx1 = this.state.text;

            this.setState({
                text: cx1,
                chosenTextIndex: -1,
                showText: null,
                textXPos: null,
                textYPos: null,
            })
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx1 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            fx1.focus = false;
            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx1);
            let cx1 = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcode: cx1,
                },
                chosenBarcodeIndex: -1,
                barcodeValue: null,
            })

            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if (fx2) {
                fx2.focus = false;
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
                let cx2 = this.state.barcodeObject.barcodeText;

                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: cx2,

                    },
                    chosenBarcodeIndex: -1,
                    barcodeValue: null,
                })
            }
        }
        if (this.state.chosenImageIndex >= 0) {
            this.setState({
                chosenImageIndex: -1,
            })
        }
        if (this.state.chosenShapeIndex >= 0) {
            this.setState({
                chosenShapeIndex: -1,
            })
        }

        this.state.text.map((fx, index) => {

            if (!fx.deleted) {
                let y_range = fx.top + fx.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

                let y_resize = (this.state.label_dim.height / this.props.label_Height);
                let y_pos_real = y_range * y_resize
                let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
                fx.y = Number(y_pos)

                // let bx = Number(fx.fontSize) / Number(fx.scalingRatio);
                // fx.fontSizeMM =Number(bx)

                let rf = fx.fontSize;
                let rf_mm = (Number(rf) * this.state.label_dim.height) / this.props.label_Height
                fx.fontSizeMM = rf_mm / 0.352778;


                if (fx.multiline) {
                    fx.widthMM = (fx.width * this.state.label_dim.width / this.props.label_Width)
                    // fx.fontSizeMM = fx.fontSizeMM*0.96
                    //
                    // let fxg = Number(fx.fontSize) /fx.multilineHeight
                    //
                    // let bgx = Number(fxg) / Number(fx.scalingRatio);
                    // fx.fontSizeMM = Number(bgx)

                }

                let fl = fx.left
                let fdx = fx.deltaPositionX
                let fr = fx.right


                if (fx.textAlign === 'left') {

                    let x_range = fl + fdx - this.props.label_X - window.innerWidth * 0.25;

                    let x_resize = (this.state.label_dim.width / this.props.label_Width);
                    let x_pos_real = x_range * x_resize
                    let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                    fx.x = Number(x_pos)


                }
                // if (fx.textAlign === 'center') {
                //
                //     let x_range = fl + fdx - this.props.label_X - window.innerWidth * 0.25;
                //     let x_resize = (this.state.label_dim.width / this.props.label_Width);
                //     let x_pos_real = x_range * x_resize
                //     let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                //     fx.x = Number(x_pos)
                //
                // }
                if (fx.textAlign === 'right') {
                    let dxx = document.getElementById('text' + index)
                    if (dxx) {
                        let ry = window.innerWidth - fr - dxx.offsetWidth;
                        let x_range = ry + fdx - this.props.label_X - window.innerWidth * 0.25;
                        let x_resize = (this.state.label_dim.width / this.props.label_Width);
                        let x_pos_real = x_range * x_resize
                        let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                        fx.x = Number(x_pos)
                    }

                }
            }

        })

        let copiedObjectBcd = JSON.parse(JSON.stringify(this.state.barcodeObject.barcode))

        copiedObjectBcd.map((fb, index) => {
            if (!fb.deleted){
            let x_range = fb.left + fb.deltaPositionX - this.props.label_X - window.innerWidth * 0.25 + 1;

            let bbh = (fb.barHeight * this.state.label_dim.height / this.props.label_Height).toFixed(2)
            fb.barHeightMM = bbh


            let x_resize = (this.state.label_dim.width / this.props.label_Width);
            let x_pos_real = x_range * x_resize
            let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                let gbnx =  1.8 * x_resize
                let gbx = (Math.round(gbnx * 100) / 100).toFixed(1)

            fb.x = Number(x_pos)+Number(gbx)

            let y_range = fb.top + fb.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

            let y_resize = (this.state.label_dim.height / this.props.label_Height);
            let y_pos_real = y_range * y_resize
            let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)

            let gbn =  3 * y_resize
            let gby = (Math.round(gbn * 100) / 100).toFixed(1)

            fb.y = Number(y_pos)+Number(gby)

        }

        })


        this.state.barcodeObject.barcodeText.map((fx, index) => {

            if (!fx.deleted){

            if (fx.textAlign === 'left') {
                let x_range = fx.left + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_pos_real = x_range * x_resize
                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                fx.x = Number(x_pos)
            }
            // if (fx.textAlign === 'center') {
            //     let x_range = fx.left + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
            //     let x_resize = (this.state.label_dim.width / this.props.label_Width);
            //     let x_pos_real = x_range * x_resize
            //     let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
            //     fx.x = Number(x_pos)
            // }
            if (fx.textAlign === 'right') {

                let dxx = document.getElementById('btext' + index)
                if (dxx) {
                    let ry = window.innerWidth - fx.right - dxx.offsetWidth;
                    let x_range = ry + fx.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
                    let x_resize = (this.state.label_dim.width / this.props.label_Width);
                    let x_pos_real = x_range * x_resize
                    let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
                    fx.x = Number(x_pos)
                }


            }
            let y_range = fx.top + fx.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;
            let y_resize = (this.state.label_dim.height / this.props.label_Height);
            let y_pos_real = y_range * y_resize
            let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
            fx.y = Number(y_pos)

            // let bx = Number(fx.fontSize) / Number(fx.scalingRatio);
            // fx.fontSizeMM = Number(bx)

            let rf = fx.fontSize;
            let rf_mm = (Number(rf) * this.state.label_dim.height) / this.props.label_Height
            fx.fontSizeMM = rf_mm / 0.352778;

            if (fx.multiline) {
                fx.widthMM = (fx.width * this.state.label_dim.width / this.props.label_Width)

            }
        }
        })
        this.forceUpdate(()=>this.state.text)

        this.state.image_array.map((fb, index) => {
            if (!fb.deleted){
            let x_range = fb.left + fb.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
            let x_resize = (this.state.label_dim.width / this.props.label_Width);
            let x_pos_real = x_range * x_resize
            let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
            fb.x = Number(x_pos)

            let y_range = fb.top + fb.deltaPositionY - ((window.innerWidth * 0.095) + this.props.label_Y);
            let y_resize = (this.state.label_dim.height / this.props.label_Height);
            let y_pos_real = y_range * y_resize
            let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
            fb.y = Number(y_pos)

            let img_width = (this.state.label_dim.width * fb.width) / this.props.label_Width
            fb.widthMM = img_width

            let img_height = (this.state.label_dim.height * fb.height) / this.props.label_Height
            fb.heightMM = img_height
        }
        })

        this.state.shape_array.map((fb, index) => {
            if (!fb.deleted){
            let x_range = fb.left + fb.deltaPositionX - this.props.label_X - window.innerWidth * 0.25;
            let x_resize = (this.state.label_dim.width / this.props.label_Width);
            let x_pos_real = x_range * x_resize
            let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)
            fb.x = Number(x_pos)
            let y_range = fb.top + fb.deltaPositionY - ((window.innerWidth * 0.095) + this.props.label_Y);
            let y_resize = (this.state.label_dim.height / this.props.label_Height);
            let y_pos_real = y_range * y_resize
            let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)
            fb.y = Number(y_pos)

            let shp_width = (this.state.label_dim.width * fb.width) / this.props.label_Width
            fb.widthMM = shp_width

            let shp_height = (this.state.label_dim.height * fb.height) / this.props.label_Height
            fb.heightMM = shp_height
        }

        })

        const axios = require('axios');

        let gb = []
        for (let h=0;h < this.state.csvPrintData.length;h++){
            if(this.state.csvPrintData[h]['pages'] > 0){
                gb.push(this.state.csvPrintData[h])
            }
        }
        let rsz_dz = 11.057374338624339 / this.state.label_dim.width;
        let ratio_dz = this.props.label_Width * rsz_dz;
        let scale_f = ratio_dz / 32.724609375;
        for (let q = 0; q < gb.length; q++){
            let gbx = gb[q]
            for (let qt =0; qt < gbx?.text?.length;qt++){
              let gbx_text = gbx.text[qt]
              let bx = gbx_text.fontSize / gbx_text.scalingRatio;
              let gx = bx * scale_f;

              var width = require('text-width');
              let wt = 'normal';
              var w = width(gbx_text.prefix + gbx_text.text + gbx_text.suffix, {
                    family: gbx_text.fontFamily,
                    size: gx,
                    weight: wt
                    })
              let w_resize = (this.state.label_dim.width / this.props.label_Width);
              let t_pos_real = w * w_resize
              let t_pos = (Math.round(t_pos_real * 100) / 100).toFixed(1)

              if (gbx_text.textAlign === 'right'){
                 for (let tt = 0; tt < this.state.text.length;tt++){
                    let ttx = this.state.text[tt]
                    if (ttx.dbConnectDataIndex === gbx_text.dbConnectDataIndex){
                        let tbx = ttx.fontSize / ttx.scalingRatio
                        let tgx = tbx * scale_f
                        var tw = width(ttx.prefix + ttx.text + ttx.suffix, {
                            family: ttx.fontFamily,
                            size: tgx,
                            weight: wt
                        })
                        let tx_pos_real = tw * w_resize
                        let tx_pos = (Math.round(tx_pos_real * 100) / 100).toFixed(1)
                        let x_post = Number(ttx.x)
                        if (Number(t_pos) > Number(tx_pos)){
                           let cvt = t_pos - tx_pos
                           let zs = x_post - cvt
                           gbx.text[qt].x = String(zs)
                        } else {
                            let cvt = tx_pos - t_pos
                           let zs = x_post + cvt
                           gbx.text[qt].x = String(zs)
                        }
                         }
                    }

                    }
                 if (gbx_text.textAlign === 'center'){
                 for (let tt = 0; tt < this.state.text.length;tt++){
                    let ttx = this.state.text[tt]
                    if (ttx.dbConnectDataIndex === gbx_text.dbConnectDataIndex){
                        let tbx = ttx.fontSize / ttx.scalingRatio
                        let tgx = tbx * scale_f
                        var width = require('text-width');
                        let wt = 'normal';
                        var tw = width(ttx.prefix + ttx.text + ttx.suffix, {
                            family: ttx.fontFamily,
                            size: tgx,
                            weight: wt
                        })

                        let tx_pos_real = tw * w_resize
                        let tx_pos = (Math.round(tx_pos_real * 100) / 100).toFixed(1)
                        let x_post = Number(ttx.x)
                        if (Number(t_pos) > Number(tx_pos)){
                           let cvt = t_pos - tx_pos
                           let zs = x_post - (cvt/2)
                           gbx.text[qt].x = String(zs)
                        } else {
                            let cvt = tx_pos - t_pos
                           let zs = x_post + (cvt)/2
                           gbx.text[qt].x = String(zs)
                        }
                         }
                    }
                    }
                 }
              for (let qt =0; qt < gbx?.barcodeObject?.barcodeText?.length;qt++){
              let gbx_text = gbx.barcodeObject.barcodeText[qt]
              let bx = gbx_text.fontSize / gbx_text.scalingRatio;
              let gx = bx * scale_f;

              var width = require('text-width');
              let wt = 'normal';
              var w = width(gbx_text.prefix + gbx_text.text + gbx_text.suffix, {
                    family: gbx_text.fontFamily,
                    size: gx,
                    weight: wt
                    })
              let w_resize = (this.state.label_dim.width / this.props.label_Width);
              let t_pos_real = w * w_resize
              let t_pos = (Math.round(t_pos_real * 100) / 100).toFixed(1)

              if (gbx_text.textAlign === 'right'){
                 for (let tt = 0; tt < this.state.barcodeObject.barcodeText.length;tt++){
                    let ttx = this.state.barcodeObject.barcodeText[tt]
                    if (ttx.dbConnectDataIndex === gbx_text.dbConnectDataIndex){
                        let tbx = ttx.fontSize / ttx.scalingRatio
                        let tgx = tbx * scale_f
                        var tw = width(ttx.prefix + ttx.text + ttx.suffix, {
                            family: ttx.fontFamily,
                            size: tgx,
                            weight: wt
                        })
                        let tx_pos_real = tw * w_resize
                        let tx_pos = (Math.round(tx_pos_real * 100) / 100).toFixed(1)
                        let x_post = Number(ttx.x)
                        if (Number(t_pos) > Number(tx_pos)){
                           let cvt = t_pos - tx_pos
                           let zs = x_post - cvt
                           gbx.barcodeObject.barcodeText[qt].x = String(zs)
                        } else {
                            let cvt = tx_pos - t_pos
                           let zs = x_post + cvt
                           gbx.barcodeObject.barcodeText[qt].x = String(zs)
                        }
                         }
                    }

                    }
                 if (gbx_text.textAlign === 'center'){
                 for (let tt = 0; tt < this.state.barcodeObject.barcodeText.length;tt++){
                    let ttx = this.state.barcodeObject.barcodeText[tt]
                    if (ttx.dbConnectDataIndex === gbx_text.dbConnectDataIndex){
                        let tbx = ttx.fontSize / ttx.scalingRatio
                        let tgx = tbx * scale_f
                        var width = require('text-width');
                        let wt = 'normal';
                        var tw = width(ttx.prefix + ttx.text + ttx.suffix, {
                            family: ttx.fontFamily,
                            size: tgx,
                            weight: wt
                        })

                        let tx_pos_real = tw * w_resize
                        let tx_pos = (Math.round(tx_pos_real * 100) / 100).toFixed(1)
                        let x_post = Number(ttx.x)
                        console.log(tx_pos,t_pos)
                        if (Number(t_pos) > Number(tx_pos)){
                           let cvt = t_pos - tx_pos
                           let zs = x_post - (cvt/2)
                           gbx.barcodeObject.barcodeText[qt].x = String(zs)
                        } else {
                            let cvt = tx_pos - t_pos
                           let zs = x_post + (cvt)/2
                           gbx.barcodeObject.barcodeText[qt].x = String(zs)
                        }
                         }
                    }
                    }
                 }



            }

        let datas =
            {
                printList: this.state.addToList,
                label: this.state.label_dim,
                text: this.state.text.filter(item => item.deleted !== true),
                barcodeObject: {barcode:copiedObjectBcd.filter(item => item.deleted !== true),
                        barcodeText:this.state.barcodeObject.barcodeText.filter(item => item.deleted !== true)},
                image_array: this.state.image_array.filter(item => item.deleted !== true),
                shape_array: this.state.shape_array.filter(item => item.deleted !== true),
                csvData:gb,

            }

        // var FileSaver = require('file-saver');
        //
        // var json = JSON.stringify(datas);
        // var file = new File([json], this.state.label_dim.fileName+".zet", {type: "application/json"});
        // FileSaver.saveAs(file);


        var self = this
        let url_p = 'https://backendbeta.zetags.com/barcode'

        // url_p = 'http://localhost:3000/barcode'
        axios.post(url_p, datas, {
            responseType: 'arraybuffer'

        })
            .then(function (response) {
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                self.checkAddToList()
                self.setState({
                    loadPreviousReprint:datas,

                })



            })
            .catch(function (error) {
                self.setState({
                    loadGrid: false,
                    addToList:[],
                })
                console.log(error);
            });

    }
    hasNumber(s) {
        return /\d/.test(s);
    }
    checkIncrement(){
        if (this.state.chosenTextIndex >= 0) {
            let fx1 = this.state.text[this.state.chosenTextIndex];

            if (fx1.increment && !this.hasNumber(fx1.prefix + fx1.text + fx1.suffix)) {

                fx1.increment = false
                this.state.text.splice(this.state.chosenTextIndex, 1, fx1);
                let cx1 = this.state.text;
                this.setState({
                    text: cx1,
                    textIncrement: false,
                })

            }
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

            if (fx.increment && !this.hasNumber(fx.prefix + fx.text + fx.suffix)) {

                this.setState({textIncrement: false})
                fx.increment = false;
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
                let cx = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: cx,
                    }
                })
            }

        }

    }
    resetFocusAll(){
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx1 = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

            fx1.focus = false;
            fx2.focus = false;

            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx1);
            let cx1 = this.state.barcodeObject.barcode;

            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx2);
            let cx2 = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcode: cx1,
                    barcodeText:cx2,
                },
                chosenBarcodeIndex: -1,
                barcodeValue:null,
                barcodeXPos: null,
                barcodeYPos: null,
                barcodeHeight: null,
            })
        }
        if (this.state.chosenTextIndex >= 0) {
            let fx1 = this.state.text[this.state.chosenTextIndex];
            fx1.focus = false;

            this.state.text.splice(this.state.chosenTextIndex, 1, fx1);
            let cx1 = this.state.text;
            this.setState({
                text: cx1,
                chosenTextIndex:-1,
                showText: null,
                textXPos:null,
                textYPos:null,

            })
        }
        if (this.state.chosenImageIndex >= 0) {
            let fb = this.state.image_array[this.state.chosenImageIndex];
            fb.focus = false;
            this.state.image_array.splice(this.state.chosenImageIndex, 1, fb);
            let cx1 = this.state.image_array;

            this.setState({
                chosenImageIndex: -1,
                image_array: cx1,
            })

        }
        if (this.state.chosenShapeIndex >= 0) {
            let fb = this.state.shape_array[this.state.chosenShapeIndex];
            fb.focus = false;
            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fb);
            let cx1 = this.state.shape_array;

            this.setState({
                chosenShapeIndex: -1,
                shape_array: cx1,
            })
        }
    }
    changeQuestiontxt(s,e){

        let fx = this.state.text[s];
        let dx = e
        if (dx.includes('(') && dx.includes('E') && dx.includes('M') && dx.includes('P') && dx.includes('T') && dx.includes('Y') && dx.includes(')')){
            let a = dx.replace('(','')
            let b = a.replace('E','')
            let c = b.replace('M','')
            let d = c.replace('P','')
            let e = d.replace('T','')
            let f = e.replace('Y','')
            let gv = f.replace(')','')
            fx.text = gv

            this.state.text.splice(s, 1, fx);
            let cx = this.state.text;
            this.setState({
                    text: cx
                },
                //     ()=>{
                //     var el = document.getElementById("txtx"+s)
                //     var range = document.createRange()
                //     var sel = window.getSelection()
                //     if (el && fx.text.length > 0){
                //         range.setStart(el.childNodes[0], 1)
                //         range.collapse(true)
                //         sel.removeAllRanges()
                //         sel.addRange(range)
                //     }
                // }
            )


        } else {
            fx.text = e;
            fx.prefix = '';
            fx.suffix = '';

            if (this.state.csvPrintData.length > 0){
                let gb = [...this.state.csvPrintData]
                for (let j=0;j < gb.length;j++){
                    gb[j].text[s].text = e
                    gb[j].text[s].prefix = '';
                    gb[j].text[s].suffix = '';
                }

                this.setState({
                    csvPrintData: gb
                })
            }

            this.state.text.splice(s, 1, fx);
            var width = require('text-width');
            let wt = 'normal';
            if (fx.bold){
                wt = 'bold';
            }

            var wz = width(e, {
                family: fx.fontFamily,
                size: fx.fontSize,
                weight: wt,

            });
            let w = document.getElementById('txtx'+s).offsetWidth




            let tb = [...this.state.text]
            for (let t = 0 ; t < fx.copyChildIndex.length; t++){
                let tv = tb[fx.copyChildIndex[t]]
                tv.text = e
                tv.prefix = ''
                tv.suffix = ''

                this.setState({
                    text: tb,
                })
            }

            let cx = this.state.text;
            this.setState({
                text: cx,
            },()=>{
                let rx = document.getElementById('text'+s)
                const rect = rx.getBoundingClientRect();

                let fxx = this.state.text[s];

                let wx = wz - rect.width;


                if (fxx.textAlign === 'center' && !fxx.multiline && wx < 0) {
                    let z = rect.width-w
                    fxx.width = wz;

                    fxx.left = fxx.left + z / 2+((rect.width-wz)/2);
                    this.setState({
                        objectPosition: {
                            ...this.state.objectPosition,
                            x: this.state.objectPosition.x+z/2,
                            left: this.state.objectPosition.x + z/2,
                            right: this.state.objectPosition.right - z/2,
                            width: w

                        }
                    });
                }

                if (fxx.textAlign === 'center' && wx > 0 && !fxx.multiline){
                    fxx.width = wz;
                    fxx.left = fxx.left - wx / 2;
                    this.setState({
                        objectPosition: {
                            ...this.state.objectPosition,
                            x: this.state.objectPosition.x-wx/2,
                            left: this.state.objectPosition.x - wx/2,
                            right: this.state.objectPosition.right + wx/2,
                            width: w

                        }
                    });

                }

                let tb = [...this.state.text]
                for (let t = 0 ; t < fxx.copyChildIndex.length; t++){
                    let tv = tb[fxx.copyChildIndex[t]]
                    let hh = document.getElementById('text'+fxx.copyChildIndex[t])

                    let rect1 = hh.getBoundingClientRect();
                    let wxx = wz - rect1.width;


                    if (tv.textAlign === 'center' && wxx < 0 && !tv.multiline) {
                        let zx = rect1.width-w
                        tv.width = wz;
                        tv.left = tv.left + zx / 2+((rect.width - wz) / 2);
                    }
                    if (tv.textAlign === 'center' && wxx > 0 && !tv.multiline){
                        tv.width = wz;
                        tv.left = tv.left - wxx / 2;
                    }
                    this.setState({
                        text: tb,
                    })
                }
            })
        }

    }
    changeQuestionWidth(s,e){
        let fx = this.state.text[s];
        fx.qWidth = (e/window.innerWidth)*100;
        this.state.text.splice(s, 1, fx);
        let cx = this.state.text;
        this.setState({
            text: cx,
        })
    }
    changeQuestionWidthBtx(s,e){
        let fx = this.state.barcodeObject.barcodeText[s];
        fx.qWidth = (e/window.innerWidth)*100;
        this.state.barcodeObject.barcodeText.splice(s, 1, fx);
        let cx = this.state.barcodeObject.barcodeText;
        this.setState({
            barcodeObject:{
                ...this.state.barcodeObject,
                barcodeText: cx,
            }
        })
    }
    changeQuestionbtx(s,e){

        let fx = this.state.barcodeObject.barcodeText[s];
        let fb2 = this.state.barcodeObject.barcode[s];
        let dx = e
        if (dx.includes('(') && dx.includes('E') && dx.includes('M') && dx.includes('P') && dx.includes('T') && dx.includes('Y') && dx.includes(')')){
            let a = dx.replace('(','')
            let b = a.replace('E','')
            let c = b.replace('M','')
            let d = c.replace('P','')
            let e = d.replace('T','')
            let f = e.replace('Y','')
            let gv = f.replace(')','')
            fx.text = gv

            this.state.barcodeObject.barcode.splice(s, 1, fb2);
            let cx1 = this.state.barcodeObject.barcode;

            this.state.barcodeObject.barcodeText.splice(s, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: cx,
                        barcode: cx1,
                    }
                },
                //     ()=>{
                //     var el = document.getElementById("txtx"+s)
                //     var range = document.createRange()
                //     var sel = window.getSelection()
                //     if (el && fx.text.length > 0){
                //         range.setStart(el.childNodes[0], 1)
                //         range.collapse(true)
                //         sel.removeAllRanges()
                //         sel.addRange(range)
                //     }
                // }
            )


        } else {
            fx.text = e;
            fx.prefix = '';
            fx.suffix = '';
            fb2.value = e;



            if (this.state.csvPrintData.length > 0){
                let gb = [...this.state.csvPrintData]

                for (let j=0;j < gb.length;j++){
                    gb[j].barcodeObject.barcodeText[s].text = e
                    gb[j].barcodeObject.barcode[s].value = e
                    gb[j].barcodeObject.barcodeText[s].prefix = '';
                    gb[j].barcodeObject.barcodeText[s].suffix = '';
                }



                this.setState({
                    csvPrintData: gb
                })
            }

            var width = require('text-width');
            let wt = 'normal';
            if (fx.bold){
                wt = 'bold';
            }

            var wz = width(e, {
                family: fx.fontFamily,
                size: fx.fontSize,
                weight: wt,

            });


            let tb = [...this.state.barcodeObject.barcodeText]
            let tbb = [...this.state.barcodeObject.barcode]

            for (let t = 0 ; t < fx.copyChildIndex.length; t++){
                let tv = tb[fx.copyChildIndex[t]]
                let tvb = tbb[fx.copyChildIndex[t]]

                tv.text = e
                tvb.value = dx


                this.setState({
                    barcodeObject:{
                        ...this.state.barcodeObject,
                        barcode: tvb,
                        barcodeText: tb,
                    }
                })
            }
            this.state.barcodeObject.barcode.splice(s, 1, fb2);
            let cx1 = this.state.barcodeObject.barcode;

            this.state.barcodeObject.barcodeText.splice(s, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                    barcode: cx1,
                }
            },()=>{

                let rx = document.getElementById('btext'+s)
                let w = document.getElementById('btxtx'+s).offsetWidth

                const rect = rx.getBoundingClientRect();

                let wx = wz - rect.width;

                let fxx = this.state.barcodeObject.barcodeText[s];

                if (fxx.textAlign === 'center' && wx < 0 && !fxx.multiline) {
                    let z = rect.width-w
                    fxx.width = wz;

                    fxx.left = fxx.left + z / 2+((rect.width-wz)/2);
                    this.setState({
                        objectPosition_barcodeText: {
                            ...this.state.objectPosition_barcodeText,
                            x: this.state.objectPosition_barcodeText.x+z/2,
                            left: this.state.objectPosition_barcodeText.x + z/2,
                            right: this.state.objectPosition_barcodeText.right - z/2,
                            width: w

                        }
                    });
                }

                if (fxx.textAlign === 'center' && wx > 0 && !fxx.multiline){
                    fxx.width = wz;

                    fxx.left = fxx.left - wx / 2;
                    this.setState({
                        objectPosition_barcodeText: {
                            ...this.state.objectPosition_barcodeText,
                            x: this.state.objectPosition_barcodeText.x-wx/2,
                            left: this.state.objectPosition_barcodeText.x - wx/2,
                            right: this.state.objectPosition_barcodeText.right + wx/2,
                            width: w

                        }
                    });

                }

                let tb = [...this.state.barcodeObject.barcodeText]
                for (let t = 0 ; t < fxx.copyChildIndex.length; t++){
                    let tv = tb[fxx.copyChildIndex[t]]
                    let hh = document.getElementById('btext'+fxx.copyChildIndex[t])

                    let rect1 = hh.getBoundingClientRect();
                    let wxx = w - rect1.width;

                    if (tv.textAlign === 'center' && wxx < 0 && !tv.multiline) {
                        let zx = rect1.width-w
                        tv.width = wz;
                        tv.left = tv.left + zx / 2+((rect.width - wz) / 2);
                    }
                    if (tv.textAlign === 'center' && wxx > 0 && !tv.multiline){
                        tv.width = wz;
                        tv.left = tv.left - wxx / 2;
                    }
                    this.setState({
                        barcodeObject:{
                            ...this.state.barcodeObject,
                            barcodeText: tb,
                        }
                    })
                }

            })
        }


    }
    onPrintQuestion(p){

        if (this.state.csvPrintData.length > 0){
            this.setState({
                    label_dim:{
                        ...this.state.label_dim,
                        pageNumber: p,

                    },
                    questionPrint: !this.state.questionPrint},
                ()=>{
                    this.printLabelData()
                })
        }else {
            this.setState({
                    label_dim:{
                        ...this.state.label_dim,
                        pageNumber: p,

                    },
                    questionPrint: !this.state.questionPrint},
                ()=>{
                    this.printLabelData()
                })

        }


    }
    changeQuestionTextStatus(e){
        if (this.state.chosenTextIndex > -1){
            let fx = this.state.text[this.state.chosenTextIndex];
            fx.questionText =  e.target.value;
            fx.qWidth = 40
            fx.qWidthNow = 40
            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx = this.state.text;
            this.setState({
                text: cx,
                questionPrintText: e.target.value
            })
        }
    }
    changeQuestionBarcodeTextStatus(e){
        if (this.state.chosenBarcodeIndex > -1){
            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            fx.questionText =  e.target.value;
            fx.qWidth = 40
            fx.qWidthNow = 40
            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            let cx = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcodeText: cx,
                },
                questionPrintText: e.target.value
            })

        }
    }

    rememberQPChanges(){

        let copiedObject = JSON.parse(JSON.stringify(this.state.text))
        let copiedObjectBcd = JSON.parse(JSON.stringify(this.state.barcodeObject))
        let copiedObjectBcdd = JSON.parse(JSON.stringify(this.state.barcodeObject.barcode))

        copiedObjectBcdd.map((fb, index) => {
            if (!fb.deleted){
                let x_range = fb.left + fb.deltaPositionX - this.props.label_X - window.innerWidth * 0.25 + 1;

                let bbh = (fb.barHeight * this.state.label_dim.height / this.props.label_Height).toFixed(2)
                fb.barHeightMM = bbh


                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_pos_real = x_range * x_resize
                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                let gbnx =  1.8 * x_resize
                let gbx = (Math.round(gbnx * 100) / 100).toFixed(1)

                fb.x = Number(x_pos)+Number(gbx)

                let y_range = fb.top + fb.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

                let y_resize = (this.state.label_dim.height / this.props.label_Height);
                let y_pos_real = y_range * y_resize
                let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)

                let gbn =  3 * y_resize
                let gby = (Math.round(gbn * 100) / 100).toFixed(1)

                fb.y = Number(y_pos)+Number(gby)

            }

        })

           this.setState({
                text: copiedObject.filter(item => item.deleted !== true),
                barcode: {barcode:copiedObjectBcdd.filter(item => item.deleted !== true),
                        barcodeText:copiedObjectBcd.barcodeText.filter(item => item.deleted !== true)},
            }

        )
    }

    addListData(p){
        if(this.state.loadPreviousReprint.label){
            this.setState({
                loadPreviousReprint:[]
            })
        }


        let copiedObject = JSON.parse(JSON.stringify(this.state.text))
        let copiedObjectBcd = JSON.parse(JSON.stringify(this.state.barcodeObject))
        let copiedObjectBcdd = JSON.parse(JSON.stringify(this.state.barcodeObject.barcode))

        copiedObjectBcdd.map((fb, index) => {
            if (!fb.deleted){
                let x_range = fb.left + fb.deltaPositionX - this.props.label_X - window.innerWidth * 0.25 + 1;

                let bbh = (fb.barHeight * this.state.label_dim.height / this.props.label_Height).toFixed(2)
                fb.barHeightMM = bbh


                let x_resize = (this.state.label_dim.width / this.props.label_Width);
                let x_pos_real = x_range * x_resize
                let x_pos = (Math.round(x_pos_real * 100) / 100).toFixed(1)

                let gbnx =  1.8 * x_resize
                let gbx = (Math.round(gbnx * 100) / 100).toFixed(1)

                fb.x = Number(x_pos)+Number(gbx)

                let y_range = fb.top + fb.deltaPositionY - this.props.label_Y - window.innerWidth * 0.095;

                let y_resize = (this.state.label_dim.height / this.props.label_Height);
                let y_pos_real = y_range * y_resize
                let y_pos = (Math.round(y_pos_real * 100) / 100).toFixed(1)

                let gbn =  3 * y_resize
                let gby = (Math.round(gbn * 100) / 100).toFixed(1)

                fb.y = Number(y_pos)+Number(gby)

            }

        })



        this.setState({
            addToList: [...this.state.addToList,{
                pageNumber:p,
                text: copiedObject.filter(item => item.deleted !== true),
                barcode: {barcode:copiedObjectBcdd.filter(item => item.deleted !== true),
                        barcodeText:copiedObjectBcd.barcodeText.filter(item => item.deleted !== true)},
            }]
        })
    }
    checkAddToList(){

        let cnt = this.state.addToList.length
        let txtArray = [...this.state.text]
        let bxtArray = [...this.state.barcodeObject.barcodeText]

        for (let g=0; g< txtArray.length; g ++){
            if (txtArray[g].increment){
                let yp = (this.checkListAutoIncrement(txtArray[g].text))
                if (yp[1]+1 === txtArray[g].text.length){
                    if (yp[0] === 0){
                        let rxt = Number(txtArray[g].text)
                        let yl = rxt+cnt-1
                        if (cnt === 1){
                            yl = rxt+1
                        }
                        txtArray[g].text = yl.toString()

                    }else {
                        let rxt = Number(txtArray[g].text.substring(yp[0],yp[1]+1))
                        let txt = txtArray[g].text
                        let yl = rxt+cnt-1
                        if (cnt === 1){
                            yl = rxt+1
                        }
                        txtArray[g].text = txt.substring(0,yp[0])+yl.toString()
                    }

                }
                 else{
                    if (yp[0] === 0){
                        let rxt = Number(txtArray[g].text.substring(yp[0],yp[1]+1))
                        let txt = txtArray[g].text
                        let yl = rxt+cnt-1
                        if (cnt === 1){
                            yl = rxt+1
                        }
                        txtArray[g].text = yl.toString()+txt.substring(yp[1]+1, txt.length)
                    }else {
                        let rxt = Number(txtArray[g].text.substring(yp[0],yp[1]+1))
                        let txt = txtArray[g].text
                        let yl = rxt+cnt-1
                        if (cnt === 1){
                            yl = rxt+1
                        }
                        txtArray[g].text = txt.substring(0,yp[0])+yl.toString()+txt.substring(yp[1]+1, txt.length)
                    }
                }
            }
        }

        for (let g=0; g< bxtArray.length; g ++){
            if (bxtArray[g].increment){
                let yp = (this.checkListAutoIncrement(bxtArray[g].text))

                if (yp[1]+1 === bxtArray[g].text.length){
                    if (yp[0] === 0){
                        let rxt = Number(bxtArray[g].text)
                        let yl = rxt+cnt-1
                        if (cnt === 1){
                            yl = rxt+1
                        }
                        bxtArray[g].text = yl.toString()

                    }else {
                        let rxt = Number(bxtArray[g].text.substring(yp[0],yp[1]+1))
                        let txt = bxtArray[g].text
                        let yl = rxt+cnt-1
                        if (cnt === 1){
                            yl = rxt+1
                        }
                        bxtArray[g].text = txt.substring(0,yp[0])+yl.toString()
                    }

                }
                else{
                    if (yp[0] === 0){
                        let rxt = Number(bxtArray[g].text.substring(yp[0],yp[1]+1))
                        let txt = bxtArray[g].text
                        let yl = rxt+cnt-1
                        if (cnt === 1){
                            yl = rxt+1
                        }
                        bxtArray[g].text = yl.toString()+txt.substring(yp[1]+1, txt.length)
                    }else {
                        let rxt = Number(bxtArray[g].text.substring(yp[0],yp[1]+1))
                        let txt = bxtArray[g].text
                        let yl = rxt+cnt-1
                        if (cnt === 1){
                            yl = rxt+1
                        }
                        bxtArray[g].text = txt.substring(0,yp[0])+yl.toString()+txt.substring(yp[1]+1, txt.length)
                    }
                }
            }
        }

        this.setState({
            barcodeObject: {
                ...this.state.barcodeObject,
                barcodeText: bxtArray,
            },
            text: txtArray,
            loadGrid: false,
            addToList:[],
        })
    }

    checkListAutoIncrement(r){
        let gx = false
        let px = 0
        for (var i = r.length ; i >= 0; i -- ){
            let isnum = /^\d+$/.test(r[i]);
            if ((gx && !isnum)){
                return [i+1,px]
            }
            if((gx && i === 0)){
                return [i,px]
            }

            if (!gx && isnum){
                px = i
                gx = true
            }

        }
    }
    copyStatusChange(){
        let rt = []
            if (this.state.chosenTextIndex > -1){

                for (var i = 0 ; i  < this.state.text.length; i ++){
                    if (i !== this.state.chosenTextIndex){
                        rt.push(  {
                            label: this.state.text[i].pinText,
                            value: 't'+i,
                            data: false,
                        },)
                    }

                }

                let rb = this.state.text[this.state.chosenTextIndex];
                let g = [...this.state.text]
                if (!rb.copyDataStatus === false && rb.copyDataLabel !== 'Copy data from Field'){
                    for (let p = 0 ; p < g.length;p++){
                        if (p !== this.state.chosenTextIndex){
                            if (g[p].pinText === rb.copyDataLabel){
                                for (let j = 0; j < g[p].copyChildIndex.length; j++){
                                    if (g[p].copyChildIndex[j] === this.state.chosenTextIndex){
                                        g[p].copyChildIndex.splice(j, 1);
                                    }
                                }
                            }
                        }
                    }
                    this.setState({
                        text: g
                    })


                }

                rb.question = false
                rb.increment = false
                rb.dateStamp = false
                rb.questionText = 'Question Before Print'
                rb.dbConnectData = ''
                rb.copyDataStatus = !rb.copyDataStatus
                let xc = this.state.text
                this.setState({
                    text: xc,
                    questionPrintText: 'Question Before Print',
                    textQuestion: false,
                    textIncrement: false,
                    dateStampOn: false,
                    copyDataStatus: rb.copyDataStatus,
                    copyDataLabel: rb.copyDataLabel,
                    dbEnableStatus: false
                })
            }
            if (this.state.chosenBarcodeIndex > -1){
                for (let i = 0 ; i  < this.state.barcodeObject.barcodeText.length; i ++) {
                    if (i !== this.state.chosenBarcodeIndex) {
                        rt.push({
                            label: this.state.barcodeObject.barcodeText[i].pinText,
                            value: 'b' + i,
                            data: false,
                        },)
                    }
                }

                let rb = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

                let g = [...this.state.barcodeObject.barcodeText]
                if (!rb.copyDataStatus === false && rb.copyDataLabel !== 'Copy data from Field'){
                    for (let p = 0 ; p < g.length;p++){
                        if (p !== this.state.chosenBarcodeIndex){
                            if (g[p].pinText === rb.copyDataLabel){
                                for (let j = 0; j < g[p].copyChildIndex.length; j++){
                                    if (g[p].copyChildIndex[j] === this.state.chosenBarcodeIndex){
                                        g[p].copyChildIndex.splice(j, 1);

                                    }
                                }

                            }
                        }
                    }
                    this.setState({
                        barcodeObject: {
                            ...this.state.barcodeObject,
                            barcodeText: g,
                        },
                    })


                }
                rb.question = false
                rb.increment = false
                rb.dateStamp = false
                rb.questionText = 'Question Before Print'
                rb.dbConnectData = ''
                rb.copyDataStatus = !rb.copyDataStatus
                let xc = this.state.barcodeObject.barcodeText
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: xc,
                    },
                    questionPrintText: 'Question Before Print',
                    textQuestion: false,
                    textIncrement: false,
                    dateStampOn: false,
                    copyDataStatus: rb.copyDataStatus,
                    copyDataLabel: rb.copyDataLabel,
                    dbEnableStatus: false

                })
            }


        this.setState({

            copyData: rt,
        })
    }
    onCopyData(f){

        this.setState({
            copyDataLabel: f.label,
        })

        if (f.value.substring(0,1) === 't'){
            let vb = [...this.state.text]
            let nmb = Number(f.value.substring(1,f.value.length))

            if (nmb === this.state.chosenTextIndex){
                vb[this.state.chosenTextIndex].copyDataLabel = f.label
            }
        else {
             vb[nmb].copyChildIndex.push(this.state.chosenTextIndex)
             vb[this.state.chosenTextIndex].prefix = vb[nmb].prefix;
             vb[this.state.chosenTextIndex].suffix = vb[nmb].suffix;
             vb[this.state.chosenTextIndex].text = vb[nmb].text;
             vb[this.state.chosenTextIndex].increment = vb[nmb].increment;
             vb[this.state.chosenTextIndex].copyDataLabel = f.label

        }
            this.setState({
                text: vb
            })

        }
        if (f.value.substring(0,1) === 'b'){
            let nmb = Number(f.value.substring(1,f.value.length))
            let vb = [...this.state.barcodeObject.barcodeText]
            if (nmb === this.state.chosenBarcodeIndex){
                vb[this.state.chosenBarcodeIndex].copyDataLabel = f.label
            }
            else {

                let vbb = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
                vb[this.state.chosenBarcodeIndex].prefix = vb[nmb].prefix;
                vb[this.state.chosenBarcodeIndex].suffix = vb[nmb].suffix;
                vb[this.state.chosenBarcodeIndex].text = vb[nmb].text;
                vb[this.state.chosenBarcodeIndex].increment = vb[nmb].increment;
                vb[this.state.chosenBarcodeIndex].copyDataLabel = f.label
                vb[nmb].copyChildIndex.push(this.state.chosenBarcodeIndex)

                vbb.value = vb[nmb].prefix+vb[nmb].text+vb[nmb].suffix
                this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, vbb);

            }
            let cx1 = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcode: cx1,
                    barcodeText: vb,
                },
            })

        }

    }
    onCopyClick(){
        if (this.state.chosenTextIndex > -1){

            let b = JSON.parse(JSON.stringify(this.state.text))
            let up_y = 1.33 * Number(b[this.state.chosenTextIndex].fontSize)
            b[this.state.chosenTextIndex].focus = false
            b[this.state.chosenTextIndex].top = (window.innerWidth * 0.095)+this.props.label_Y+this.props.label_Height+up_y/2
            b[this.state.chosenTextIndex].left =  this.props.label_X+window.innerWidth * 0.25
            b[this.state.chosenTextIndex].right = null
            b[this.state.chosenTextIndex].deltaPositionX = 0
            b[this.state.chosenTextIndex].deltaPositionY = 0
            b[this.state.chosenTextIndex].xPos = 0
            b[this.state.chosenTextIndex].yPos = 0
            b[this.state.chosenTextIndex].x = 0
            b[this.state.chosenTextIndex].y = 0
            b[this.state.chosenTextIndex].copyChildIndex = []

            let g = [...this.state.text]
            let parentId = null

            if (b[this.state.chosenTextIndex].copyDataStatus === true && b[this.state.chosenTextIndex].copyDataLabel !== 'Copy data from Field'){
                for (let p = 0 ; p < g.length;p++){
                    if (p !== this.state.chosenTextIndex){
                        if (g[p].pinText === g[this.state.chosenTextIndex].copyDataLabel){
                            parentId = p
                        }
                    }
                }
            }
            this.setState({
                toolBarCopy: {
                    type: 'Text',
                    dataNode:b[this.state.chosenTextIndex],
                    parentIndex: parentId

                }
            })
        }
        if (this.state.chosenBarcodeIndex > -1){
            let b = JSON.parse(JSON.stringify(this.state.barcodeObject))

            let up_y = 1.33 * Number(b.barcodeText[this.state.chosenBarcodeIndex].fontSize)
            let barTop = (window.innerWidth * 0.095)+this.props.label_Y+this.props.label_Height+up_y/2
            let bar_Height = b.barcode[this.state.chosenBarcodeIndex].barHeight

            b.barcode[this.state.chosenBarcodeIndex].focus = false
            b.barcode[this.state.chosenBarcodeIndex].top = barTop
            b.barcode[this.state.chosenBarcodeIndex].left =  this.props.label_X+window.innerWidth * 0.25
            b.barcode[this.state.chosenBarcodeIndex].deltaPositionX = 0
            b.barcode[this.state.chosenBarcodeIndex].deltaPositionY = 0
            b.barcode[this.state.chosenBarcodeIndex].xPos = 0
            b.barcode[this.state.chosenBarcodeIndex].yPos = 0
            b.barcode[this.state.chosenBarcodeIndex].x = 0
            b.barcode[this.state.chosenBarcodeIndex].y = 0

            b.barcodeText[this.state.chosenBarcodeIndex].focus = false
            b.barcodeText[this.state.chosenBarcodeIndex].top = barTop+bar_Height+up_y/2
            b.barcodeText[this.state.chosenBarcodeIndex].left =  this.props.label_X+window.innerWidth * 0.25
            b.barcodeText[this.state.chosenBarcodeIndex].right = null
            b.barcodeText[this.state.chosenBarcodeIndex].deltaPositionX = 0
            b.barcodeText[this.state.chosenBarcodeIndex].deltaPositionY = 0
            b.barcodeText[this.state.chosenBarcodeIndex].xPos = 0
            b.barcodeText[this.state.chosenBarcodeIndex].yPos = 0
            b.barcodeText[this.state.chosenBarcodeIndex].x = 0
            b.barcodeText[this.state.chosenBarcodeIndex].y = 0
            b.barcodeText[this.state.chosenBarcodeIndex].copyChildIndex = []

            let g = [...this.state.barcodeObject.barcodeText]
            let parentId = null

            if (b.barcodeText[this.state.chosenBarcodeIndex].copyDataStatus === true && b.barcodeText[this.state.chosenBarcodeIndex].copyDataLabel !== 'Copy data from Field'){
                for (let p = 0 ; p < g.length;p++){
                    if (p !== this.state.chosenBarcodeIndex){
                        if (g[p].pinText === g[this.state.chosenBarcodeIndex].copyDataLabel){
                            parentId = p
                        }
                    }
                }
            }

            this.setState({
                toolBarCopy: {
                    type: 'Barcode',
                    dataNode: [b.barcode[this.state.chosenBarcodeIndex],b.barcodeText[this.state.chosenBarcodeIndex]],
                    parentIndex: parentId
                }
            })
        }
        if (this.state.chosenImageIndex > -1){
            let b = JSON.parse(JSON.stringify(this.state.image_array))

            b[this.state.chosenImageIndex].focus = false
            b[this.state.chosenImageIndex].top = (window.innerWidth * 0.095)+this.props.label_Y
            b[this.state.chosenImageIndex].left =  this.props.label_X+window.innerWidth * 0.25
            b[this.state.chosenImageIndex].right = null
            b[this.state.chosenImageIndex].deltaPositionX = 0
            b[this.state.chosenImageIndex].deltaPositionY = 0
            b[this.state.chosenImageIndex].xPos = 0
            b[this.state.chosenImageIndex].yPos = 0
            b[this.state.chosenImageIndex].x = 0
            b[this.state.chosenImageIndex].y = 0

            this.setState({
                toolBarCopy: {
                    type: 'Image',
                    dataNode:b[this.state.chosenImageIndex],
                }
            })

        }
        if (this.state.chosenShapeIndex > -1){
            let b = JSON.parse(JSON.stringify(this.state.shape_array))

            let shapeTop = (window.innerWidth * 0.095)+this.props.label_Y

            if (b[this.state.chosenShapeIndex].type === 'line'){
                shapeTop = (window.innerWidth* 0.095)+this.props.label_Y
            }

            b[this.state.chosenShapeIndex].focus = false
            b[this.state.chosenShapeIndex].top = shapeTop
            b[this.state.chosenShapeIndex].left = this.props.label_X+window.innerWidth * 0.25
            b[this.state.chosenShapeIndex].right = null
            b[this.state.chosenShapeIndex].deltaPositionX = 0
            b[this.state.chosenShapeIndex].deltaPositionY = 0
            b[this.state.chosenShapeIndex].xPos = 0
            b[this.state.chosenShapeIndex].yPos = 0
            b[this.state.chosenShapeIndex].x = 0
            b[this.state.chosenShapeIndex].y = 0

            this.setState({
                toolBarCopy: {
                    type: 'Shape',
                    dataNode:b[this.state.chosenShapeIndex],
                }
            })

        }

    }
    onPasteClick(d){
        this.setState({copyPasteStatus: false})

        if (this.state.toolBarCopy.type){
            this.resetFocusAll()
            if (this.state.toolBarCopy.type === 'Text'){
                let b = JSON.parse(JSON.stringify(this.state.toolBarCopy))
                b.dataNode.pinText = d
                if (b.parentIndex !== null){
                    b.dataNode.prefix = this.state.text[b.parentIndex].prefix
                    b.dataNode.text = this.state.text[b.parentIndex].text
                    b.dataNode.suffix = this.state.text[b.parentIndex].suffix
                }
                this.setState({
                    text: [...this.state.text,b.dataNode]
                },()=>{
                    if (b.parentIndex !== null) {
                        let r = [...this.state.text]
                        r[b.parentIndex].copyChildIndex.push(this.state.text.length - 1)
                        this.setState({
                            text: r
                        })
                    }
                })
                this.setState({toolBarCopy:{}})
            }
            if (this.state.toolBarCopy.type === 'Barcode'){
                let b = JSON.parse(JSON.stringify(this.state.toolBarCopy))
                b.dataNode[1].pinText = d
                b.dataNode[0].pinText = d+' Barcode'
                if (b.parentIndex !== null){
                    b.dataNode[1].prefix = this.state.barcodeObject.barcodeText[b.parentIndex].prefix
                    b.dataNode[1].text = this.state.barcodeObject.barcodeText[b.parentIndex].text
                    b.dataNode[1].suffix = this.state.barcodeObject.barcodeText[b.parentIndex].suffix

                    b.dataNode[0].value = this.state.barcodeObject.barcodeText[b.parentIndex].prefix+this.state.barcodeObject.barcodeText[b.parentIndex].text+
                        this.state.barcodeObject.barcodeText[b.parentIndex].suffix
                }
                this.setState({ barcodeObject: {barcode: [...this.state.barcodeObject.barcode, b.dataNode[0]],
                        barcodeText: [...this.state.barcodeObject.barcodeText, b.dataNode[1]]}
                }
                    ,()=>{
                        if (b.parentIndex !== null) {
                            let r = [...this.state.barcodeObject.barcodeText]
                            r[b.parentIndex].copyChildIndex.push(this.state.barcodeObject.barcodeText.length-1)
                            this.setState({
                                barcodeObject: {
                                    ...this.state.barcodeObject,
                                    barcodeText: r,
                                }
                            })
                        }
                })
                this.setState({toolBarCopy:{}})

            }

            if (this.state.toolBarCopy.type === 'Image'){
                let b = JSON.parse(JSON.stringify(this.state.toolBarCopy))
                b.dataNode.pinText = d
                this.setState({
                    image_array: [...this.state.image_array,b.dataNode]
                })
                this.setState({toolBarCopy:{}})

            }
            if (this.state.toolBarCopy.type === 'Shape'){
                let b = JSON.parse(JSON.stringify(this.state.toolBarCopy))
                b.dataNode.pinText = d
                this.setState({
                    shape_array: [...this.state.shape_array,b.dataNode]
                })
                this.setState({toolBarCopy:{}})
            }
        }
    }

    onPasteCheck(){
        this.setState({copyPasteStatus: true})
    }
    checkPasteField(d){
        if (this.state.toolBarCopy.type){
            let bb = false
            if (this.state.toolBarCopy.type === 'Text'){
                for (let r =0; r < this.state.text.length; r ++){
                    if (this.state.text[r].pinText === d){
                        bb = true
                    }
                }

                return bb
            }
            if (this.state.toolBarCopy.type === 'Barcode'){
                for (let r =0; r < this.state.barcodeObject.barcode.length; r ++){
                    if (this.state.barcodeObject.barcode[r].pinText === d){
                        bb = true
                    }
                }
                for (let r =0; r < this.state.barcodeObject.barcodeText.length; r ++){
                    if (this.state.barcodeObject.barcodeText[r].pinText === d){
                        bb = true
                    }
                }

                return  bb

            }
            if (this.state.toolBarCopy.type === 'Image'){
                for (let r =0; r < this.state.image_array.length; r ++){
                    if (this.state.image_array[r].pinText === d){
                        bb = true
                    }
                }

                return bb
            }
            if (this.state.toolBarCopy.type === 'Shape'){
                for (let r =0; r < this.state.shape_array.length; r ++){
                    if (this.state.shape_array[r].pinText === d){
                        bb = true
                    }
                }

                return bb
            }

        }
    }
   beginsWithFloat(val) {
        val = parseFloat(val);
        return ! isNaN(val);
    }
    changeMultiWidth(e){
        if (isNaN(e.target.value) === false || (e.target.value.length > 0 && this.beginsWithFloat(Number(e.target.value)))) {
            if (this.state.chosenTextIndex > -1){
                let fx = this.state.text[this.state.chosenTextIndex]
                fx.multilineReset = Number(e.target.value)
                let bbx = (Number(e.target.value) * this.props.label_Width / this.state.label_dim.width).toFixed(0)

                fx.width = Number(bbx)
                this.state.text.splice(this.state.chosenTextIndex, 1, fx);
                let cx1 = this.state.text;
                this.setState({text: cx1})
                this.setState({multilineWidth:(e.target.value)})
                this.forceUpdate()
            }
            if (this.state.chosenBarcodeIndex >= 0) {
                let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                fx2.multilineReset = Number(e.target.value)
                let bbx = (Number(e.target.value) * this.props.label_Width / this.state.label_dim.width).toFixed(0)
                fx2.width = Number(bbx)
                this.setState({multilineWidth:(e.target.value)})
            }

        }
    }
    onNumberChangeMultiWidth(n){
        if (this.state.chosenTextIndex > -1){
            let fx = this.state.text[this.state.chosenTextIndex]
            fx.multilineReset = Number(n)

            let bbx = (Number(n) * this.props.label_Width / this.state.label_dim.width).toFixed(0)
            fx.width = Number(bbx)
            this.setState({multilineWidth:n})
        }
        if (this.state.chosenBarcodeIndex >= 0) {
            let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            fx2.multilineReset = Number(n)
            let bbx = (Number(n) * this.props.label_Width / this.state.label_dim.width).toFixed(0)
            fx2.width = Number(bbx)
            this.setState({multilineWidth:n})
        }

    }

    changeMultiHeight(e){

        if (isNaN(e.target.value) === false || (e.target.value.length > 0 && this.beginsWithFloat(Number(e.target.value)))) {
            if (this.state.chosenTextIndex > -1){
                let fx = this.state.text[this.state.chosenTextIndex]
                fx.multilineHeight = (e.target.value)
                this.setState({multilineHeight:(e.target.value)})

            }
            if (this.state.chosenBarcodeIndex >= 0) {
                let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                fx2.multilineHeight = Number(e.target.value)
                this.setState({multilineHeight:(e.target.value)})
            }

        }
    }
    onNumberChangeMultiHeight(n){
            if (this.state.chosenTextIndex > -1){
                let tb = Math.round(n * 10) / 10
                let fx = this.state.text[this.state.chosenTextIndex]
                fx.multilineHeight = tb
                this.setState({multilineHeight:tb})

            }
            if (this.state.chosenBarcodeIndex >= 0) {
                let tb = Math.round(n * 10) / 10
                let fx2 = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                fx2.multilineHeight = tb
                this.setState({multilineHeight:tb})
            }
    }
    transformClock(){
        if (this.state.chosenTextIndex > -1) {
            let fx = this.state.text[this.state.chosenTextIndex]
            if (fx.textAlign === 'right' && fx.right !== null){
                this.onLeftTextAlignChange()

            }
            let rt = fx.transformValue+fx.transformRotation

            if (rt <= 360){
                if (fx.transform === null || fx.transform === 'rotate(0deg)' ){
                    fx.left = fx.left+fx.deltaPositionX
                    fx.top = fx.top+fx.deltaPositionY
                    fx.deltaPositionX  = 0
                    fx.deltaPositionY = 0
                }
                if(rt === 360){
                    fx.transform = null
                    fx.transformRotation = 0

                    if (fx.resetTransform === null){
                        fx.resetTransform = true
                    }else {
                        fx.resetTransform = null
                    }
                }
                if (rt < 360){
                    fx.transform = 'rotate('+rt+'deg)'
                    fx.transformRotation = rt
                }
                this.state.text.splice(this.state.chosenTextIndex, 1, fx);
                let cx1 = this.state.text;
                this.setState({text: cx1})

            }

        }
        if (this.state.chosenBarcodeIndex > -1) {
            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            if (fx.textAlign === 'right' && fx.right !== null){
                this.onLeftTextAlignChange()

            }

            let rt = fx.transformValue+fx.transformRotation

            if (rt <= 360){
                if (fx.transform === null || fx.transform === 'rotate(0deg)' ){
                    fx.left = fx.left+fx.deltaPositionX
                    fx.top = fx.top+fx.deltaPositionY
                    fx.deltaPositionX  = 0
                    fx.deltaPositionY = 0

                }
                if(rt === 360){
                    fx.transform = null
                    fx.transformRotation = 0

                    if (fx.resetTransform === null){
                        fx.resetTransform = true
                    }else {
                        fx.resetTransform = null
                    }
                }
                if (rt < 360){
                    fx.transform = 'rotate('+rt+'deg)'
                    fx.transformRotation = rt
                }

                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);

                let cx1 = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: cx1,
                    }
                })
            }
        }
    }
    transformBarcodeClock(){

        if (this.state.chosenBarcodeIndex > -1) {
            let fx = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex]

            let rt = fx.transformValue+fx.transformRotation


            if (rt <= 360){
                if (fx.transform === null || fx.transform === 'rotate(0deg)' ){
                    fx.left = fx.left+fx.deltaPositionX
                    fx.top = fx.top+fx.deltaPositionY
                    fx.deltaPositionX  = 0
                    fx.deltaPositionY = 0
                }
                if(rt === 360){
                    fx.transform = null
                    fx.transformRotation = 0

                    if (fx.resetTransform === null){
                        fx.resetTransform = true
                    }else {
                        fx.resetTransform = null
                    }
                }
                if (rt < 360){
                    fx.transform = 'rotate('+rt+'deg)'
                    fx.transformRotation = rt
                }
                this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx);
                let cx1 = this.state.barcodeObject.barcode;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcode: cx1,
                    }
                })

            }

        }
    }

    transformImageClock(){

        if (this.state.chosenImageIndex > -1) {
            let fx = this.state.image_array[this.state.chosenImageIndex]
            let rt = fx.transformValue+fx.transformRotation


            if (rt <= 360){
                if (fx.transform === null || fx.transform === 'rotate(0deg)' ){
                    fx.left = fx.left+fx.deltaPositionX
                    fx.top = fx.top+fx.deltaPositionY
                    fx.deltaPositionX  = 0
                    fx.deltaPositionY = 0
                }
                if(rt === 360){
                    fx.transform = null
                    fx.transformRotation = 0

                    if (fx.resetTransform === null){
                        fx.resetTransform = true
                    }else {
                        fx.resetTransform = null
                    }
                }
                if (rt < 360){
                    fx.transform = 'rotate('+rt+'deg)'
                    fx.transformRotation = rt
                }
                this.state.image_array.splice(this.state.chosenImageIndex, 1, fx);
                let cx1 = this.state.image_array;
                this.setState({
                   image_array: cx1
                })

            }

        }
    }

    transformShapeClock(){

        if (this.state.chosenShapeIndex > -1) {
            let fx = this.state.shape_array[this.state.chosenShapeIndex]
            let rt = fx.transformValue+fx.transformRotation


            if (rt <= 360){
                if (fx.transform === null || fx.transform === 'rotate(0deg)' ){
                    fx.left = fx.left+fx.deltaPositionX
                    fx.top = fx.top+fx.deltaPositionY
                    fx.deltaPositionX  = 0
                    fx.deltaPositionY = 0
                }
                if(rt === 360){
                    fx.transform = null
                    fx.transformRotation = 0

                    if (fx.resetTransform === null){
                        fx.resetTransform = true
                    }else {
                        fx.resetTransform = null
                    }
                }
                if (rt < 360){
                    fx.transform = 'rotate('+rt+'deg)'
                    fx.transformRotation = rt
                }
                this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx);
                let cx1 = this.state.shape_array;
                this.setState({
                    shape_array: cx1
                })

            }

        }
    }

    transformAntiClock(){
        if (this.state.chosenTextIndex > -1) {
            let fx = this.state.text[this.state.chosenTextIndex]
            if (fx.textAlign === 'right' && fx.right !== null){
                this.onLeftTextAlignChange()

            }
            let rt = fx.transformRotation-fx.transformValue
            if (fx.transformRotation === 0){
                rt = 360 - fx.transformValue
            }

            if (rt >= 0){
                if (fx.transform === null || fx.transform === 'rotate(0deg)' ){
                    fx.left = fx.left+fx.deltaPositionX
                    fx.top = fx.top+fx.deltaPositionY
                    fx.deltaPositionX  = 0
                    fx.deltaPositionY = 0
                }
                if(rt === 0){
                    fx.transform = null
                    fx.transformRotation = 0

                    if (fx.resetTransform === null){
                        fx.resetTransform = true
                    }else {
                        fx.resetTransform = null
                    }
                }
                if (rt > 0){
                    fx.transform = 'rotate('+rt+'deg)'
                    fx.transformRotation = rt

                }
                this.state.text.splice(this.state.chosenTextIndex, 1, fx);
                let cx1 = this.state.text;
                this.setState({text: cx1})

            }

        }
        if (this.state.chosenBarcodeIndex > -1) {
            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

            if (fx.textAlign === 'right' && fx.right !== null){
                this.onLeftTextAlignChange()

            }
            let rt = fx.transformRotation - fx.transformValue
            if (fx.transformRotation === 0) {
                rt = 360 - fx.transformValue
            }

            if (rt >= 0) {
                if (fx.transform === null || fx.transform === 'rotate(0deg)') {
                    fx.left = fx.left + fx.deltaPositionX
                    fx.top = fx.top + fx.deltaPositionY
                    fx.deltaPositionX = 0
                    fx.deltaPositionY = 0
                }
                if (rt === 0) {
                    fx.transform = null
                    fx.transformRotation = 0

                    if (fx.resetTransform === null) {
                        fx.resetTransform = true
                    } else {
                        fx.resetTransform = null
                    }
                }
                if (rt > 0) {
                    fx.transform = 'rotate(' + rt + 'deg)'
                    fx.transformRotation = rt
                }

                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);

                let cx1 = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: cx1,
                    }
                })
            }
        }
    }
    transformBarcodeAntiClock(){

        if (this.state.chosenBarcodeIndex > -1) {
            let fx = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

            if (fx.textAlign === 'right' && fx.right !== null){
                let dxx = document.getElementById('btext'+this.state.chosenBarcodeIndex)
                if (dxx) {
                    this.setState({textAlign: 'left', leftAlign: true, centerAlign: false, rightAlign: false})
                    fx.textAlign = 'left';
                    fx.left = dxx.offsetLeft;
                    fx.width = null;
                    fx.outlineSelected = false;
                    fx.right = null;
                    this.state.text.splice(this.state.chosenTextIndex, 1, fx);
                    let cx = this.state.text;
                    this.setState({
                        barcodeObject: {
                            ...this.state.barcodeObject,
                            barcodeText: cx,
                        }
                    })
                }
            }

            let rt = fx.transformRotation - fx.transformValue
            if (fx.transformRotation === 0) {
                rt = 360 - fx.transformValue
            }

            if (rt >= 0) {
                if (fx.transform === null || fx.transform === 'rotate(0deg)') {
                    fx.left = fx.left + fx.deltaPositionX
                    fx.top = fx.top + fx.deltaPositionY

                    fx.deltaPositionX = 0
                    fx.deltaPositionY = 0
                }
                if (rt === 0) {
                    fx.transform = null
                    fx.transformRotation = 0

                    if (fx.resetTransform === null) {
                        fx.resetTransform = true
                    } else {
                        fx.resetTransform = null
                    }
                }
                if (rt > 0) {
                    fx.transform = 'rotate(' + rt + 'deg)'
                    fx.transformRotation = rt
                }

                this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx);

                let cx1 = this.state.barcodeObject.barcode;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcode: cx1,
                    }
                })
            }
        }

    }
    transformImageAntiClock(){

        if (this.state.chosenImageIndex > -1) {
            let fx = this.state.image_array[this.state.chosenImageIndex];

            let rt = fx.transformRotation - fx.transformValue
            if (fx.transformRotation === 0) {
                rt = 360 - fx.transformValue
            }

            if (rt >= 0) {
                if (fx.transform === null || fx.transform === 'rotate(0deg)') {
                    fx.left = fx.left + fx.deltaPositionX
                    fx.top = fx.top + fx.deltaPositionY
                    fx.deltaPositionX = 0
                    fx.deltaPositionY = 0
                }
                if (rt === 0) {
                    fx.transform = null
                    fx.transformRotation = 0

                    if (fx.resetTransform === null) {
                        fx.resetTransform = true
                    } else {
                        fx.resetTransform = null
                    }
                }
                if (rt > 0) {
                    fx.transform = 'rotate(' + rt + 'deg)'
                    fx.transformRotation = rt
                }


                this.state.image_array.splice(this.state.chosenImageIndex, 1, fx);
                let cx1 = this.state.image_array;
                this.setState({
                    image_array: cx1
                })
            }
        }

    }

    transformShapeAntiClock(){

        if (this.state.chosenShapeIndex > -1) {
            let fx = this.state.shape_array[this.state.chosenShapeIndex];

            let rt = fx.transformRotation - fx.transformValue
            if (fx.transformRotation === 0) {
                rt = 360 - fx.transformValue
            }

            if (rt >= 0) {
                if (fx.transform === null || fx.transform === 'rotate(0deg)') {
                    fx.left = fx.left + fx.deltaPositionX
                    fx.top = fx.top + fx.deltaPositionY
                    fx.deltaPositionX = 0
                    fx.deltaPositionY = 0
                }
                if (rt === 0) {
                    fx.transform = null
                    fx.transformRotation = 0

                    if (fx.resetTransform === null) {
                        fx.resetTransform = true
                    } else {
                        fx.resetTransform = null
                    }
                }
                if (rt > 0) {
                    fx.transform = 'rotate(' + rt + 'deg)'
                    fx.transformRotation = rt
                }


                this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx);
                let cx1 = this.state.shape_array;
                this.setState({
                    shape_array: cx1
                })
            }
        }

    }
    changeRotationAngle(e){
        if(Number(e.target.value) <= 360 && Number(e.target.value) >= 0 ){
            if (this.state.chosenTextIndex > -1) {
                let fx = this.state.text[this.state.chosenTextIndex]
                if (fx.textAlign === 'right' && fx.right !== null){
                    this.onLeftTextAlignChange()

                }
                fx.transformValue = Number(e.target.value)
                this.state.text.splice(this.state.chosenTextIndex, 1, fx);
                let cx1 = this.state.text;
                this.setState({text: cx1, transformStatus: Number(e.target.value)})
            }
            if (this.state.chosenBarcodeIndex > -1) {
                let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                if (fx.textAlign === 'right' && fx.right !== null){
                    this.onLeftTextAlignChange()

                }
                fx.transformValue = Number(e.target.value)
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);

                let cx1 = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: cx1,
                    },
                    transformStatus: Number(e.target.value)

                })
            }
        }
    }
    changeBarcodeRotationAngle(e){
        if(Number(e.target.value) <= 360 && Number(e.target.value) >= 0 ){

            if (this.state.chosenBarcodeIndex > -1) {
                let fx = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
                fx.transformValue = Number(e.target.value)
                this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx);

                let cx1 = this.state.barcodeObject.barcode;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcode: cx1,
                    },
                    transformStatus: Number(e.target.value)

                })
            }
        }
    }
    changeImageRotationAngle(e){
        if(Number(e.target.value) <= 360 && Number(e.target.value) >= 0 ){

            if (this.state.chosenImageIndex > -1) {
                let fx = this.state.image_array[this.state.chosenImageIndex];
                fx.transformValue = Number(e.target.value)
                this.state.image_array.splice(this.state.chosenImageIndex, 1, fx);

                let cx1 = this.state.image_array;
                this.setState({
                    image_array: cx1,
                    transformStatus: Number(e.target.value)

                })
            }
        }
    }
    changeShapeRotationAngle(e){
        if(Number(e.target.value) <= 360 && Number(e.target.value) >= 0 ){

            if (this.state.chosenShapeIndex > -1) {
                let fx = this.state.shape_array[this.state.chosenShapeIndex];
                fx.transformValue = Number(e.target.value)
                this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx);

                let cx1 = this.state.shape_array;
                this.setState({
                    shape_array: cx1,
                    transformStatus: Number(e.target.value)

                })
            }
        }
    }
    numberChangeRotationAngle(r){
        if(Number(r) <= 360 && Number(r) >= 0 ){
            if (this.state.chosenTextIndex > -1) {
                let fx = this.state.text[this.state.chosenTextIndex]
                if (fx.textAlign === 'right' && fx.right !== null){
                    this.onLeftTextAlignChange()

                }
                fx.transformValue = Number(r)
                this.state.text.splice(this.state.chosenTextIndex, 1, fx);
                let cx1 = this.state.text;
                this.setState({text: cx1, transformStatus: Number(r)})
            }
            if (this.state.chosenBarcodeIndex > -1) {
                let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                if (fx.textAlign === 'right' && fx.right !== null){
                    this.onLeftTextAlignChange()

                }
                fx.transformValue = Number(r)
                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);

                let cx1 = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcodeText: cx1,
                    },
                    transformStatus: Number(r)

                })
            }
        }
    }

    numberChangeBarcodeRotationAngle(r){
        if(Number(r) <= 360 && Number(r) >= 0 ){

            if (this.state.chosenBarcodeIndex > -1) {
                let fx = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];
                fx.transformValue = Number(r)
                this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx);

                let cx1 = this.state.barcodeObject.barcode;
                this.setState({
                    barcodeObject: {
                        ...this.state.barcodeObject,
                        barcode: cx1,
                    },
                    transformStatus: Number(r)

                })
            }
        }
    }

    numberChangeImageRotationAngle(r){
        if(Number(r) <= 360 && Number(r) >= 0 ){

            if (this.state.chosenImageIndex > -1) {
                let fx = this.state.image_array[this.state.chosenImageIndex];
                fx.transformValue = Number(r)
                this.state.image_array.splice(this.state.chosenImageIndex, 1, fx);

                let cx1 = this.state.image_array;
                this.setState({
                    image_array: cx1,
                    transformStatus: Number(r)

                })
            }
        }
    }
    numberChangeShapeRotationAngle(r){
        if(Number(r) <= 360 && Number(r) >= 0 ){

            if (this.state.chosenShapeIndex > -1) {
                let fx = this.state.shape_array[this.state.chosenShapeIndex];
                fx.transformValue = Number(r)
                this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx);

                let cx1 = this.state.shape_array;
                this.setState({
                    shape_array: cx1,
                    transformStatus: Number(r)

                })
            }
        }
    }


    textBringForward(){
        if (this.state.chosenTextIndex > -1) {
            let fx = this.state.text[this.state.chosenTextIndex]
            for (let g = 0; g < this.state.text.length;g++){
                if (g !== this.state.chosenTextIndex){
                    let gx = this.state.text[g]
                    let fxx = fx.left+fx.deltaPositionX
                    let fxw = document.getElementById('text'+this.state.chosenTextIndex).clientWidth
                    let gxx = gx.left+gx.deltaPositionX
                    let gxw = document.getElementById('text'+this.state.chosenTextIndex).clientWidth

                    let fyy = fx.top+fx.deltaPositionY
                    let fyh = document.getElementById('text'+this.state.chosenTextIndex).clientHeight
                    let gyy = gx.top+gx.deltaPositionY
                    let gyh = document.getElementById('text'+this.state.chosenTextIndex).clientHeight

                    if (((gxx === fxx) || (fxx < gxx < fxx+fxw < gxx+gxw) || (fxx < gxx < gxx+gxw < fxx+fxw)
                        || (gxx < fxx < gxx+gxw < fxx+fxw) || (gxx < fxx < fxx+fxw < gxx+gxw)) && (
                        (gyy === fyy) || (gyy < fyy < gyy+gyh < fyy+fyh) || (gyy < fyy < fyy+fyh < gyy+gyh)
                        || (fyy < gyy < gyy+gyh < fyy+fyh) || (fyy < gyy < fyy+fyh < gyy+gyh) )){
                        gx.zIndex = 2
                        fx.zIndex = 3
                    }
                }
            }
            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx1 = this.state.text;
            this.setState({text: cx1})

        }
        if (this.state.chosenBarcodeIndex > -1) {
            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

            for (let g = 0; g < this.state.barcodeObject.barcodeText.length;g++){
                if (g !== this.state.chosenBarcodeIndex){
                    let gx = this.state.barcodeObject.barcodeText[g]
                    let fxx = fx.left+fx.deltaPositionX
                    let fxw = document.getElementById('btext'+this.state.chosenBarcodeIndex).clientWidth
                    let gxx = gx.left+gx.deltaPositionX
                    let gxw = document.getElementById('btext'+this.state.chosenBarcodeIndex).clientWidth

                    let fyy = fx.top+fx.deltaPositionY
                    let fyh = document.getElementById('btext'+this.state.chosenBarcodeIndex).clientHeight
                    let gyy = gx.top+gx.deltaPositionY
                    let gyh = document.getElementById('btext'+this.state.chosenBarcodeIndex).clientHeight

                    if (((gxx === fxx) || (fxx < gxx < fxx+fxw < gxx+gxw) || (fxx < gxx < gxx+gxw < fxx+fxw)
                        || (gxx < fxx < gxx+gxw < fxx+fxw) || (gxx < fxx < fxx+fxw < gxx+gxw)) && (
                        (gyy === fyy) || (gyy < fyy < gyy+gyh < fyy+fyh) || (gyy < fyy < fyy+fyh < gyy+gyh)
                        || (fyy < gyy < gyy+gyh < fyy+fyh) || (fyy < gyy < fyy+fyh < gyy+gyh) )){
                        gx.zIndex = 2
                        fx.zIndex = 3
                    }
                }
            }

            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);

            let cx1 = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcodeText: cx1,
                }
            })
        }

    }

    barcodeBringForward(){

        if (this.state.chosenBarcodeIndex > -1) {
            let fx = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

            for (let g = 0; g < this.state.barcodeObject.barcode.length;g++){
                if (g !== this.state.chosenBarcodeIndex){
                    let gx = this.state.barcodeObject.barcode[g]
                    let fxx = fx.left+fx.deltaPositionX
                    let fxw = document.getElementById('bcd'+this.state.chosenBarcodeIndex).clientWidth
                    let gxx = gx.left+gx.deltaPositionX
                    let gxw = document.getElementById('bcd'+this.state.chosenBarcodeIndex).clientWidth

                    let fyy = fx.top+fx.deltaPositionY
                    let fyh = document.getElementById('bcd'+this.state.chosenBarcodeIndex).clientHeight
                    let gyy = gx.top+gx.deltaPositionY
                    let gyh = document.getElementById('bcd'+this.state.chosenBarcodeIndex).clientHeight

                    if (((gxx === fxx) || (fxx < gxx < fxx+fxw < gxx+gxw) || (fxx < gxx < gxx+gxw < fxx+fxw)
                        || (gxx < fxx < gxx+gxw < fxx+fxw) || (gxx < fxx < fxx+fxw < gxx+gxw)) && (
                        (gyy === fyy) || (gyy < fyy < gyy+gyh < fyy+fyh) || (gyy < fyy < fyy+fyh < gyy+gyh)
                        || (fyy < gyy < gyy+gyh < fyy+fyh) || (fyy < gyy < fyy+fyh < gyy+gyh) )){
                        gx.zIndex = 2
                        fx.zIndex = 3
                    }
                }
            }

            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx);

            let cx1 = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcode: cx1,
                }
            })
        }

    }
    imageBringForward(){

        if (this.state.chosenImageIndex > -1) {
            let fx = this.state.image_array[this.state.chosenImageIndex];

            for (let g = 0; g < this.state.image_array.length;g++){
                if (g !== this.state.chosenImageIndex){
                    let gx = this.state.image_array[g]
                    let fxx = fx.left+fx.deltaPositionX
                    let fxw = document.getElementById('img'+this.state.chosenImageIndex).clientWidth
                    let gxx = gx.left+gx.deltaPositionX
                    let gxw = document.getElementById('img'+this.state.chosenImageIndex).clientWidth

                    let fyy = fx.top+fx.deltaPositionY
                    let fyh = document.getElementById('img'+this.state.chosenImageIndex).clientHeight
                    let gyy = gx.top+gx.deltaPositionY
                    let gyh = document.getElementById('img'+this.state.chosenImageIndex).clientHeight

                    if (((gxx === fxx) || (fxx < gxx < fxx+fxw < gxx+gxw) || (fxx < gxx < gxx+gxw < fxx+fxw)
                        || (gxx < fxx < gxx+gxw < fxx+fxw) || (gxx < fxx < fxx+fxw < gxx+gxw)) && (
                        (gyy === fyy) || (gyy < fyy < gyy+gyh < fyy+fyh) || (gyy < fyy < fyy+fyh < gyy+gyh)
                        || (fyy < gyy < gyy+gyh < fyy+fyh) || (fyy < gyy < fyy+fyh < gyy+gyh) )){
                        gx.zIndex = 1
                        fx.zIndex = 2
                    }
                }
            }

            this.state.image_array.splice(this.state.chosenImageIndex, 1, fx);
            let cx1 = this.state.image_array;
            this.setState({
                image_array: cx1
            })
        }

    }
    shapeBringForward(){

        if (this.state.chosenShapeIndex > -1) {
            let fx = this.state.shape_array[this.state.chosenShapeIndex];

            for (let g = 0; g < this.state.shape_array.length;g++){
                if (g !== this.state.chosenShapeIndex){
                    let gx = this.state.shape_array[g]
                    let fxx = fx.left+fx.deltaPositionX
                    let fxw = document.getElementById('shp'+this.state.chosenShapeIndex).clientWidth
                    let gxx = gx.left+gx.deltaPositionX
                    let gxw = document.getElementById('shp'+this.state.chosenShapeIndex).clientWidth

                    let fyy = fx.top+fx.deltaPositionY
                    let fyh = document.getElementById('shp'+this.state.chosenShapeIndex).clientHeight
                    let gyy = gx.top+gx.deltaPositionY
                    let gyh = document.getElementById('shp'+this.state.chosenShapeIndex).clientHeight

                    if (((gxx === fxx) || (fxx < gxx < fxx+fxw < gxx+gxw) || (fxx < gxx < gxx+gxw < fxx+fxw)
                        || (gxx < fxx < gxx+gxw < fxx+fxw) || (gxx < fxx < fxx+fxw < gxx+gxw)) && (
                        (gyy === fyy) || (gyy < fyy < gyy+gyh < fyy+fyh) || (gyy < fyy < fyy+fyh < gyy+gyh)
                        || (fyy < gyy < gyy+gyh < fyy+fyh) || (fyy < gyy < fyy+fyh < gyy+gyh) )){
                        gx.zIndex = 1
                        fx.zIndex = 2
                    }
                }
            }

            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx);
            let cx1 = this.state.shape_array;
            this.setState({
                shape_array: cx1
            })
        }

    }

    textBringBackward(){
        if (this.state.chosenTextIndex > -1) {
            let fx = this.state.text[this.state.chosenTextIndex]
            for (let g = 0; g < this.state.text.length;g++){
                if (g !== this.state.chosenTextIndex){
                    let gx = this.state.text[g]
                    let fxx = fx.left+fx.deltaPositionX
                    let fxw = document.getElementById('text'+this.state.chosenTextIndex).clientWidth
                    let gxx = gx.left+gx.deltaPositionX
                    let gxw = document.getElementById('text'+this.state.chosenTextIndex).clientWidth

                    let fyy = fx.top+fx.deltaPositionY
                    let fyh = document.getElementById('text'+this.state.chosenTextIndex).clientHeight
                    let gyy = gx.top+gx.deltaPositionY
                    let gyh = document.getElementById('text'+this.state.chosenTextIndex).clientHeight

                    if (((gxx === fxx) || (fxx < gxx < fxx+fxw < gxx+gxw) || (fxx < gxx < gxx+gxw < fxx+fxw)
                        || (gxx < fxx < gxx+gxw < fxx+fxw) || (gxx < fxx < fxx+fxw < gxx+gxw)) && (
                        (gyy === fyy) || (gyy < fyy < gyy+gyh < fyy+fyh) || (gyy < fyy < fyy+fyh < gyy+gyh)
                        || (fyy < gyy < gyy+gyh < fyy+fyh) || (fyy < gyy < fyy+fyh < gyy+gyh) )){

                        gx.zIndex = 3
                        fx.zIndex = 2
                    }
                }

            }

            this.state.text.splice(this.state.chosenTextIndex, 1, fx);
            let cx1 = this.state.text;
            this.setState({text: cx1})

        }

        if (this.state.chosenBarcodeIndex > -1) {
            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];

            for (let g = 0; g < this.state.barcodeObject.barcodeText.length;g++){
                if (g !== this.state.chosenBarcodeIndex){
                    let gx = this.state.barcodeObject.barcodeText[g]
                    let fxx = fx.left+fx.deltaPositionX
                    let fxw = document.getElementById('btext'+this.state.chosenBarcodeIndex).clientWidth
                    let gxx = gx.left+gx.deltaPositionX
                    let gxw = document.getElementById('btext'+this.state.chosenBarcodeIndex).clientWidth

                    let fyy = fx.top+fx.deltaPositionY
                    let fyh = document.getElementById('btext'+this.state.chosenBarcodeIndex).clientHeight
                    let gyy = gx.top+gx.deltaPositionY
                    let gyh = document.getElementById('btext'+this.state.chosenBarcodeIndex).clientHeight

                    if (((gxx === fxx) || (fxx < gxx < fxx+fxw < gxx+gxw) || (fxx < gxx < gxx+gxw < fxx+fxw)
                        || (gxx < fxx < gxx+gxw < fxx+fxw) || (gxx < fxx < fxx+fxw < gxx+gxw)) && (
                        (gyy === fyy) || (gyy < fyy < gyy+gyh < fyy+fyh) || (gyy < fyy < fyy+fyh < gyy+gyh)
                        || (fyy < gyy < gyy+gyh < fyy+fyh) || (fyy < gyy < fyy+fyh < gyy+gyh) )){
                        gx.zIndex = 3
                        fx.zIndex = 2
                    }
                }
            }

            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);

            let cx1 = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcodeText: cx1,
                }
            })
        }
    }

    barcodeBringBackward(){

        if (this.state.chosenBarcodeIndex > -1) {
            let fx = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

            for (let g = 0; g < this.state.barcodeObject.barcode.length;g++){
                if (g !== this.state.chosenBarcodeIndex){
                    let gx = this.state.barcodeObject.barcode[g]
                    let fxx = fx.left+fx.deltaPositionX
                    let fxw = document.getElementById('bcd'+this.state.chosenBarcodeIndex).clientWidth
                    let gxx = gx.left+gx.deltaPositionX
                    let gxw = document.getElementById('bcd'+this.state.chosenBarcodeIndex).clientWidth

                    let fyy = fx.top+fx.deltaPositionY
                    let fyh = document.getElementById('bcd'+this.state.chosenBarcodeIndex).clientHeight
                    let gyy = gx.top+gx.deltaPositionY
                    let gyh = document.getElementById('bcd'+this.state.chosenBarcodeIndex).clientHeight

                    if (((gxx === fxx) || (fxx < gxx < fxx+fxw < gxx+gxw) || (fxx < gxx < gxx+gxw < fxx+fxw)
                        || (gxx < fxx < gxx+gxw < fxx+fxw) || (gxx < fxx < fxx+fxw < gxx+gxw)) && (
                        (gyy === fyy) || (gyy < fyy < gyy+gyh < fyy+fyh) || (gyy < fyy < fyy+fyh < gyy+gyh)
                        || (fyy < gyy < gyy+gyh < fyy+fyh) || (fyy < gyy < fyy+fyh < gyy+gyh) )){
                        gx.zIndex = 3
                        fx.zIndex = 2
                    }
                }
            }

            this.state.barcodeObject.barcode.splice(this.state.chosenBarcodeIndex, 1, fx);

            let cx1 = this.state.barcodeObject.barcode;
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcode: cx1,
                }
            })
        }

    }
    imageBringBackward(){

        if (this.state.chosenImageIndex > -1) {
            let fx = this.state.image_array[this.state.chosenImageIndex];

            for (let g = 0; g < this.state.image_array.length;g++){
                if (g !== this.state.chosenImageIndex){
                    let gx = this.state.image_array[g]
                    let fxx = fx.left+fx.deltaPositionX
                    let fxw = document.getElementById('img'+this.state.chosenImageIndex).clientWidth
                    let gxx = gx.left+gx.deltaPositionX
                    let gxw = document.getElementById('img'+this.state.chosenImageIndex).clientWidth

                    let fyy = fx.top+fx.deltaPositionY
                    let fyh = document.getElementById('img'+this.state.chosenImageIndex).clientHeight
                    let gyy = gx.top+gx.deltaPositionY
                    let gyh = document.getElementById('img'+this.state.chosenImageIndex).clientHeight

                    if (((gxx === fxx) || (fxx < gxx < fxx+fxw < gxx+gxw) || (fxx < gxx < gxx+gxw < fxx+fxw)
                        || (gxx < fxx < gxx+gxw < fxx+fxw) || (gxx < fxx < fxx+fxw < gxx+gxw)) && (
                        (gyy === fyy) || (gyy < fyy < gyy+gyh < fyy+fyh) || (gyy < fyy < fyy+fyh < gyy+gyh)
                        || (fyy < gyy < gyy+gyh < fyy+fyh) || (fyy < gyy < fyy+fyh < gyy+gyh) )){
                        gx.zIndex = 2
                        fx.zIndex = 1
                    }
                }
            }

            this.state.image_array.splice(this.state.chosenImageIndex, 1, fx);
            let cx1 = this.state.image_array;
            this.setState({
                image_array: cx1
            })
        }

    }
    shapeBringBackward(){

        if (this.state.chosenShapeIndex > -1) {
            let fx = this.state.shape_array[this.state.chosenShapeIndex];

            for (let g = 0; g < this.state.shape_array.length;g++){
                if (g !== this.state.chosenShapeIndex){
                    let gx = this.state.shape_array[g]
                    let fxx = fx.left+fx.deltaPositionX
                    let fxw = document.getElementById('shp'+this.state.chosenShapeIndex).clientWidth
                    let gxx = gx.left+gx.deltaPositionX
                    let gxw = document.getElementById('shp'+this.state.chosenShapeIndex).clientWidth

                    let fyy = fx.top+fx.deltaPositionY
                    let fyh = document.getElementById('shp'+this.state.chosenShapeIndex).clientHeight
                    let gyy = gx.top+gx.deltaPositionY
                    let gyh = document.getElementById('shp'+this.state.chosenShapeIndex).clientHeight

                    if (((gxx === fxx) || (fxx < gxx < fxx+fxw < gxx+gxw) || (fxx < gxx < gxx+gxw < fxx+fxw)
                        || (gxx < fxx < gxx+gxw < fxx+fxw) || (gxx < fxx < fxx+fxw < gxx+gxw)) && (
                        (gyy === fyy) || (gyy < fyy < gyy+gyh < fyy+fyh) || (gyy < fyy < fyy+fyh < gyy+gyh)
                        || (fyy < gyy < gyy+gyh < fyy+fyh) || (fyy < gyy < fyy+fyh < gyy+gyh) )){
                        gx.zIndex = 2
                        fx.zIndex = 1
                    }
                }
            }

            this.state.shape_array.splice(this.state.chosenShapeIndex, 1, fx);
            let cx1 = this.state.shape_array;
            this.setState({
                shape_array: cx1
            })
        }

    }
    getImageBase64() {
        var F  = document.querySelector('input[type=file]').files[0];
        var fullP = document.getElementById('loadimgFile').value;

        if (F && fullP){
            var reader = new FileReader();
            reader.readAsDataURL(F);
            var self = this
            reader.onload = function () {
                self.setState({
                    imageSrcCode: reader.result,
                    imageLoaded: true,
                    loadImageName:fullP.split(/(\\|\/)/g).pop()
                },()=>{
                    document.getElementById('loadimgFile').value = null
                })
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }

    }
    readLoadFiles(){
        // var F  = document.querySelector('input[type=file]').files[0];
        var F = document.getElementById("loadcsvFile").files[0]
        var fullP = document.getElementById('loadcsvFile').value;
        let csvReadData = [];
        let areFilesIdentical = true;

        if (F && fullP){
            var reader = new FileReader();
            reader.readAsText(F);
            reader.fileName = F.name
            var self = this
            reader.onload = function (readerEvt) {
                csvReadData = reader.result;
                for (let i = 0; i < self.state.csvFileData.length; i++) {
                  if (JSON.stringify(self.state.csvFileData[i]) !== JSON.stringify(csvReadData[i])) {
                    areFilesIdentical = false;
                    break;
                  }
                }
                if (!areFilesIdentical) {
                    self.setState({csvResetLoadData: reader.result,
                    csvResetLoadFilename: readerEvt.target.fileName},()=>{
                       document.getElementById('loadcsvFile').value = null
                       self.setState({csvResetSave: true})

                    })
                } else {
                  self.setState({csvResetLoadData: reader.result,
                    csvResetLoadFilename: readerEvt.target.fileName},()=>{
                       document.getElementById('loadcsvFile').value = null
                       self.setState({csvAppendSave: true})

                    })
                  // self.setState({
                  //   csvFileData: reader.result,
                  //   csvFileName:readerEvt.target.fileName,
                  //   },()=>{
                  //       document.getElementById('loadcsvFile').value = null
                  //       console.log("loaded csvfileData: ",reader.result)
                  //       self.setState({csvAppendSave: true})
                  //   })
                }

            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }

    reloadCSVData(){
        this.csvReset();
        this.setState({
             csvFileData: this.state.csvResetLoadData,
             csvFileName: this.state.csvResetLoadFilename,
        },()=> {
            this.onSaveFile()
            this.setState({csvResetSave: false})
            setTimeout(() => {
                        this.setState({csvResetInfo: true})
            },3000)
        })
    }

    appendCSVData(){
        this.setState({
             csvFileData: this.state.csvResetLoadData,
             csvFileName: this.state.csvResetLoadFilename,
        },()=> {
            this.onSaveFile()
            this.setState({csvAppendSave: false})
        })
    }

    clicktextCopyStatus(){
        let rt = []
        if (this.state.chosenTextIndex > -1) {

            for (var i = 0; i < this.state.text.length; i++) {
                if (i !== this.state.chosenTextIndex && this.state.text[i].copyDataStatus === false) {
                    rt.push({
                        label: this.state.text[i].pinText,
                        value: 't' + i,
                        data: false,
                    },)
                }
            }
        }
        if (this.state.chosenBarcodeIndex > -1) {
            for (let i = 0; i < this.state.barcodeObject.barcodeText.length; i++) {
                if (i !== this.state.chosenBarcodeIndex && this.state.barcodeObject.barcodeText[i].copyDataStatus === false) {
                    rt.push({
                        label: this.state.barcodeObject.barcodeText[i].pinText,
                        value: 'b' + i,
                        data: false,
                    },)
                }
            }
        }
        this.setState({
            copyData: rt,
        })

    }
    offInputTextFocusBlur = () => {
        const rect = document.getElementById('text'+this.state.chosenTextIndex).getBoundingClientRect();

        let fx = this.state.text[this.state.chosenTextIndex];


        if (document.getElementById('text'+this.state.chosenTextIndex).textContent.length === 0){
            fx.text = '(EMPTY)'
            this.setState({showText:'(EMPTY)'})

        }

        var width = require('text-width');
        let wt = 'normal';
        if (fx.bold){
            wt = 'bold';
        }

        var w = width(fx.prefix+fx.text+fx.suffix, {
            family: fx.fontFamily,
            size: fx.fontSize,
            weight: wt,

        });

        let wx = w - rect.width;
        if ((fx.prefix.length > 0 || fx.suffix.length > 0)){
            let f = fx.prefix+fx.text+fx.suffix;
            if (this.state.prefix.length > 0 && fx.prefix !== this.state.prefix){
                fx.prefix = this.state.prefix;

            }
            if (this.state.suffix.length > 0 && fx.suffix !== this.state.suffix){
                fx.suffix = this.state.suffix;

            }
            let fx_text = f.substring(fx.prefix.length, f.length-fx.suffix.length);
            if (fx.text !== fx_text){
                fx.text = fx_text;

            }
        }

        if (fx.textAlign === 'center' && wx < -10 && !fx.multiline) {
            let z = rect.width-w

            fx.outlineSelected = false;
            fx.width = w;

            fx.left = fx.left + z / 2;
            this.setState({
                objectPosition: {
                    ...this.state.objectPosition,
                    x: this.state.objectPosition.x+z/2,
                    left: this.state.objectPosition.x + z/2,
                    right: this.state.objectPosition.right - z/2,
                    width: w

                }
            });
        }


        if (fx.textAlign === 'center' && wx > 0 && !fx.multiline){
            fx.outlineSelected = false;
            fx.width = w;

            fx.left = fx.left - wx / 2;
            this.setState({
                objectPosition: {
                    ...this.state.objectPosition,
                    x: this.state.objectPosition.x-wx/2,
                    left: this.state.objectPosition.x - wx/2,
                    right: this.state.objectPosition.right + wx/2,
                    width: w

                }
            });

        }
        let tb = [...this.state.text]
        for (let t = 0 ; t < fx.copyChildIndex.length; t++){
            let tv = tb[fx.copyChildIndex[t]]
            let hh = document.getElementById('text'+fx.copyChildIndex[t])

            let rect1 = hh.getBoundingClientRect();
            let wxx = w - rect1.width;

            if (tv.textAlign === 'center' && wxx < -10 && !tv.multiline) {
                let zx = rect1.width-w
                tv.width = w;
                tv.left = tv.left + zx / 2;
            }
            if (tv.textAlign === 'center' && wxx > 0 && !tv.multiline){
                tv.width = w;
                tv.left = tv.left - wxx / 2;
            }
            this.setState({
                text: tb,
            })
        }

        this.state.text.splice(this.state.chosenTextIndex, 1, fx);
        let cx = this.state.text;
        this.setState({
            text: cx
        })

    };

    offInputBarcodeTextFocusBlur = () => {
        const rect = document.getElementById('btext'+this.state.chosenBarcodeIndex).getBoundingClientRect();

        let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
        let fg = this.state.barcodeObject.barcode[this.state.chosenBarcodeIndex];

        if (document.getElementById('btext'+this.state.chosenBarcodeIndex).textContent.length === 0){
            fx.text = '(EMPTY)'
            fg.emptyPrint = true
            this.setState({
                barcodeObject: {
                    ...this.state.barcodeObject,
                    barcode: fg,
                },
                showText:'(EMPTY)'

            })

        }


            var width = require('text-width');
            let wt = 'normal';
            if (fx.bold){
                wt = 'bold';
            }

            var w = width(fx.prefix+fx.text+fx.suffix, {
                family: fx.fontFamily,
                size: fx.fontSize,
                weight: wt,

            });

            let wx = w - rect.width;
            if ((fx.prefix.length > 0 || fx.suffix.length > 0)){
                let f = fx.prefix+fx.text+fx.suffix;
                if (this.state.prefix.length > 0 && fx.prefix !== this.state.prefix){
                    fx.prefix = this.state.prefix;

                }
                if (this.state.suffix.length > 0 && fx.suffix !== this.state.suffix){
                    fx.suffix = this.state.suffix;

                }
                let fx_text = f.substring(fx.prefix.length, f.length-fx.suffix.length);
                if (fx.text !== fx_text){
                    fx.text = fx_text;

                }
            }

            if (fx.textAlign === 'center' && wx < -10 && !fx.multiline) {
                let z = rect.width-w

                fx.outlineSelected = false;
                fx.width = w;

                fx.left = fx.left + z / 2;
                this.setState({
                    objectPosition_barcodeText: {
                        ...this.state.objectPosition_barcodeText,
                        x: this.state.objectPosition_barcodeText.x+z/2,
                        left: this.state.objectPosition_barcodeText.x + z/2,
                        right: this.state.objectPosition_barcodeText.right - z/2,
                        width: w

                    }
                });
            }


            if (fx.textAlign === 'center' && wx > 0 && !fx.multiline){
                fx.outlineSelected = false;
                fx.width = w;

                fx.left = fx.left - wx / 2;
                this.setState({
                    objectPosition_barcodeText: {
                        ...this.state.objectPosition_barcodeText,
                        x: this.state.objectPosition_barcodeText.x-wx/2,
                        left: this.state.objectPosition_barcodeText.x - wx/2,
                        right: this.state.objectPosition_barcodeText.right + wx/2,
                        width: w

                    }
                });

            }
            let tb = [...this.state.barcodeObject.barcodeText]
            for (let t = 0 ; t < fx.copyChildIndex.length; t++){
                let tv = tb[fx.copyChildIndex[t]]
                let hh = document.getElementById('btext'+fx.copyChildIndex[t])

                let rect1 = hh.getBoundingClientRect();
                let wxx = w - rect1.width;

                if (tv.textAlign === 'center' && wxx < -10 && !tv.multiline) {
                    let zx = rect1.width-w
                    tv.width = w;
                    tv.left = tv.left + zx / 2;
                }
                if (tv.textAlign === 'center' && wxx > 0 && !tv.multiline){
                    tv.width = w;
                    tv.left = tv.left - wxx / 2;
                }
                this.setState({
                    barcodeObject:{
                        ...this.state.barcodeObject,
                        barcodeText: tb,
                    }
                })
            }

        this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
        let cx = this.state.barcodeObject.barcodeText;
        this.setState({
            barcodeObject:{
                ...this.state.barcodeObject,
                barcodeText: cx,
            }
        })




    };

    updateLanguage = (c) =>{

        this.setState({
            loadLanguageLabel: c,
            loadLanguage:language_Options[c]
        })
    }
    getPosition = ( element ) => {
        let rect = element.getBoundingClientRect();
        return rect.top

    }
    dragQText = ()=>{


        let r = []
        let txtr = JSON.parse(JSON.stringify(this.state.text));

        txtr.map((tx,index)=>{
            if (tx.question){
                tx.qWidthNow = tx.qWidth
                if (tx.multiline) {
                    tx.widthMM = (tx.width * this.state.label_dim.width / this.props.label_Width).toFixed(1)
                    tx.fontSizeMM = tx.fontSizeMM*0.96
                }
                r.push({'id':index,'txt':tx})
            }
        })
        let rb = []
        let txtb = JSON.parse(JSON.stringify(this.state.barcodeObject.barcodeText));

        txtb.map((tx,index)=>{
            if (tx.question){
                tx.qWidthNow = tx.qWidth
                if (tx.multiline) {
                    tx.widthMM = (tx.width * this.state.label_dim.width / this.props.label_Width).toFixed(1)
                    tx.fontSizeMM = tx.fontSizeMM*0.96
                }
                rb.push({'id':index,'txt':tx})
            }
        })


        let num_Array = []
        let p = []
        for (let i =0;i < r.length;i++){
            p.push(['txt',r[i].id,this.getPosition(document.getElementById('txtData'+i))])

        }
        for (let i =0;i < rb.length;i++){
            p.push(['btxt',rb[i].id,this.getPosition(document.getElementById('btxtData'+i))])

        }

        num_Array = p.sort((a, b) => a[2] - b[2]);
        this.setState({
            qInputSortArray:num_Array
        },()=>{
            this.setState({
                modalClose:true,
                questionPrint:false

            },()=>{
                this.setState({
                    modalClose:false,
                    questionPrint:true

                })
            })
        })
    }

    viewCSV = ()=>{
        if (this.state.csvFileData.length > 0){
            var data = Papa.parse(this.state.csvFileData);
            let db = []

            for (let i=0;i<data.data.length;i++){
                let rs = []
                for (let k=0;k < data.data[i].length;k++){
                    // if (data.data[i][k].length > 0){
                        rs.push(data.data[i][k])
                    // }
                }
                db.push(rs)
            }
            this.setState({csvFileDataTable:db},()=>this.setState({viewCSVPopup: !this.state.viewCSVPopup}))


        }
    }

    onChangeDBEnable(){
        if (this.state.csvFileData.length > 0) {

            if (this.state.chosenBarcodeIndex > -1) {
                let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
                fx.dbConnectData = ''
                fx.copyDataStatus = false
                fx.copyDataLabel = 'Copy data from Field'
                fx.question = false
                fx.increment = false
                fx.dateStamp = false
                fx.questionText = 'DATA'

                this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
                let cx1 = this.state.barcodeObject.barcodeText;
                this.setState({
                    barcodeObject:{
                        ...this.state.barcodeObject,
                        barcodeText: cx1,
                    },
                    copyDataStatus: false,
                    questionPrintText: 'DATA',
                    textQuestion: false,
                    textIncrement: false,
                    dateStampOn: false,
                })


            }
            else {
                if (this.state.chosenTextIndex > -1){
                    let fx = this.state.text[this.state.chosenTextIndex];

                    fx.dbConnectData = ''
                    fx.copyDataStatus = false
                    fx.copyDataLabel = 'Copy data from Field'
                    fx.question = false
                    fx.increment = false
                    fx.dateStamp = false
                    fx.questionText = 'DATA'
                    this.state.text.splice(this.state.chosenTextIndex, 1, fx);

                    let cx1 = this.state.text;

                    this.setState({
                        text: cx1,
                        copyDataStatus: false,
                        questionPrintText: 'DATA',
                        textQuestion: false,
                        textIncrement: false,
                        dateStampOn: false,
                    });

                }
            }
            if (this.state.dbEnableStatus) {
                this.setState({dbEnableStatus: false})


            } else {


                let bn = []
                var data = Papa.parse(this.state.csvFileData);
                if (this.state.csvReadRow) {
                    for (let j = 0; j < data.data[0].length; j++) {
                        bn.push({value: j, label: data.data[0][j]})
                    }
                } else {
                    for (let j = 0; j < data.data[0].length; j++) {
                        bn.push({value: j, label: 'Column ' + [j + 1]})
                    }
                }


                this.setState({dbDataValue: bn}, () => {
                    this.setState({dbEnableStatus: true, dbDataLabel: 'Select Data Field'})
                })
            }
        }

    }

    onDBData(f){

        if (this.state.chosenBarcodeIndex > -1) {
            let fx = this.state.barcodeObject.barcodeText[this.state.chosenBarcodeIndex];
            fx.dbConnectData = f.label
            fx.dbConnectDataIndex = f.value
            this.state.barcodeObject.barcodeText.splice(this.state.chosenBarcodeIndex, 1, fx);
            let cx1 = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx1,
                }
            })


        }
        else {
            if (this.state.chosenTextIndex > -1){
                let fx = this.state.text[this.state.chosenTextIndex];
                fx.dbConnectData = f.label
                fx.dbConnectDataIndex = f.value
                this.state.text.splice(this.state.chosenTextIndex, 1, fx);

                let cx1 = this.state.text;

                this.setState({
                    text: cx1,
                });

            }
        }


            this.setState({
            dbDataLabel: f.label,
        })




    }
    onSetQtyAll(s){
        let gb = []
        for (let i =0;i < this.state.csvQTYArr.length;i++){
            gb.push(s)

        }
        this.setState({csvQTYArr:gb})

    }
    matchText(){

        for (let i =0;i < this.state.text.length;i++) {
            let y = false
            if (this.state.text[i].dbConnectData.length > 0){
                for (let j=0;j < this.state.csvFileDataTableQBP[0].length;j++){
                    if (this.state.csvFileDataTableQBP[0][j] === this.state.text[i].dbConnectData){
                        y = true
                    }
                }

            }
            return y
        }

    }

    sortArrayByDbConnectDataIndex(array) {
      return array.sort((a, b) => {
        // Handle cases where dbConnectDataIndex is empty or invalid
        const aIndex = a.dbConnectDataIndex || Infinity; // Assign Infinity for empty/invalid values
        const bIndex = b.dbConnectDataIndex || Infinity;

        // Sort in ascending order, keeping empty/invalid values last
        return aIndex - bIndex;
      });
    }

    filterHeader(headers, searchKey) {
      // Create key-value map
      const headerIndexMap = headers.reduce((acc, header, index) => {
        acc[header.trim()] = index;
        return acc;
      }, {});

      // Return the index of the search key
      return headerIndexMap[searchKey.trim()] ?? -1;  // Return -1 if not found
    }




    csvNext(){
        let rs = []
        for (let i =1;i < this.state.csvFileDataTableQBP.length;i++){
            if (this.state.csvQTYArr[i-1]) {
                if (this.state.csvQTYArr[i - 1].length > 0) {
                    let r = this.state.csvFileDataTableQBP[i]
                    r.push(this.state.csvQTYArr[i - 1])
                    rs.push(r)
                }
            }
        }

        let vb = []

        for (let i =0;i<rs.length;i++){
            let bx = JSON.parse(JSON.stringify(this.state.text));
            let bbx = JSON.parse(JSON.stringify(this.state.barcodeObject.barcodeText));
            let bbz = JSON.parse(JSON.stringify(this.state.barcodeObject.barcode));

             if(this.state.limitColumns){
                for (let j=0;j < bx.length;j++){
                 if (bx[j].dbConnectData.length > 0){
                   const ix = this.state.csvFileDataTableQBPMap.get(bx[j].dbConnectDataIndex+1)
                    bx[j].text = rs[i][ix]
                    // bx[j].prefix = ''
                    // bx[j].suffix = ''
                    }
                }

                // for (let j=0;j < bbx.length;j++){
                //      if (bbx[j].dbConnectData.length > 0){
                //         const ix = this.state.csvFileDataTableQBPMap.get(bbx[j].dbConnectDataIndex+1)
                //            console.log('ix vals:',rs[i][ix])
                //             bbx[j].text = rs[i][ix]
                //             bbz[j].value = rs[i][ix]
                //
                //
                //         // bbx[j].prefix = ''
                //         // bbx[j].suffix = ''
                //     }
                // }

                const ix = this.state.csvFileDataTableQBP[0]

                for (let j=0;j < bbx.length;j++){
                     if (bbx[j].dbConnectData.length > 0){
                        const idx = this.state.csvFileDataTableQBPMap.get(bbx[j].dbConnectDataIndex+1)
                        if(rs[i][idx] !== undefined && rs[i][idx]?.length > 0){
                          bbx[j].text = rs[i][idx]
                          bbz[j].value = rs[i][idx]
                        } else {
                           const index = this.filterHeader(ix,bbx[j].dbConnectData );
                           if (index > -1 && rs[i][index]?.length > 0){
                              bbx[j].text = rs[i][index]
                              bbz[j].value = rs[i][index]
                           }
                        }
                        // bbx[j].prefix = ''
                        // bbx[j].suffix = ''
                    }
                }

             } else {
                for (let j=0;j < bx.length;j++){
                 if (bx[j].dbConnectData.length > 0){
                    bx[j].text = rs[i][bx[j].dbConnectDataIndex+1]
                    // bx[j].prefix = ''
                    // bx[j].suffix = ''
                    }
                }

                const ix = this.state.csvFileDataTableQBP[0]

                for (let j=0;j < bbx.length;j++){
                     if (bbx[j].dbConnectData.length > 0){
                        if(rs[i][bbx[j].dbConnectDataIndex+1] !== undefined && rs[i][bbx[j].dbConnectDataIndex+1]?.length > 0){
                          bbx[j].text = rs[i][bbx[j].dbConnectDataIndex+1]
                          bbz[j].value = rs[i][bbx[j].dbConnectDataIndex+1]
                        } else {
                           const index = this.filterHeader(ix,bbx[j].dbConnectData );
                           if (index > -1 && rs[i][index]?.length > 0){
                              bbx[j].text = rs[i][index]
                              bbz[j].value = rs[i][index]
                           }
                        }
                    }
                }
             }

            let ty = {barcode:bbz,barcodeText:bbx}
            vb.push({text:bx, barcodeObject:ty,pages:rs[i][rs[0].length-1]})
        }
        this.setState({csvPrintData: vb,viewCSVQBPPopup:false},()=>{
            if (!this.checkQuestionPrint()) {
                    this.printLabelData()
                }
        })

    }
    csvReset(){
        this.setState({
            csvFileData:[],
            csvFileName:'',
            csvQTYArray:[],
            csvReadRow:false,
            truncateText: false,
            limitColumns: false,
        })
        if (this.state.barcodeObject.barcodeText.length > 0) {
            for (let i =0; i < this.state.barcodeObject.barcodeText.length;i++){
                let fx = this.state.barcodeObject.barcodeText[i];
                fx.dbConnectData = ''
                fx.dbConnectDataIndex = ''
            }

            let cx1 = this.state.barcodeObject.barcodeText;
            this.setState({
                barcodeObject:{
                    ...this.state.barcodeObject,
                    barcodeText: cx1,
                }
            })
        }

            if (this.state.text.length > 0){

                for (let i =0; i < this.state.text.length;i++){
                    let fx = this.state.text[i];
                    fx.dbConnectData = ''
                    fx.dbConnectDataIndex = ''
                }

                let cx1 = this.state.text;

                this.setState({
                    text: cx1,
                });
            }

        this.setState({
            dbDataLabel: '',
        })



    }


    render() {

        let rb = document.documentElement.clientWidth
        let rsz = rb * 0.095
        let h_z = document.documentElement.clientHeight - rsz;
        if (h_z < window.innerWidth*0.45){
            h_z = window.innerWidth*0.45
        }

        return(
            <div style={{
            'WebkitTouchCallout': 'none',
                'WebkitUserSelect': 'none',
                'KhtmlUserSelect': 'none',
                'MozUserSelect': 'none',
                'msUserSelect': 'none',
                'userSelect': 'none',
            }}>

                <Toolbar onUpMouseUp={()=>this.onUpMouseUpAll()} onUpMouseDown={()=>this.upMoveAll()}
                         onDownMouseUp={()=>this.onDownMouseUpAll()} onDownMouseDown={()=>this.downMoveAll()}
                         objectCopy={this.state.toolBarCopy} settingsOn={()=>this.setState({settingsPopup:true})}
                         onPrint={()=>this.onPrintLabel()} copyClick={()=>this.onCopyClick()} pasteClick={()=>this.onPasteCheck()}
                         onLeftMouseUp={()=>this.onLeftMouseUpAll()} onLeftMouseDown={()=>this.leftMoveAll()}
                         langData={this.state.loadLanguage.Toolbar}
                         onRightMouseUp={()=>this.onRightMouseUpAll()} onRightMouseDown={()=>this.rightMoveAll()}/>


            <div style={{width:'100vw',  display:'flex',flexDirection: 'row'}}>

                <div style={{margin:0,padding:0,width:'25vw',flexDirection: 'column',zIndex:5}}>

                    {
                        this.props.activator_Bool ? (
                                <div style={{width:'25vw',margin:0,padding:0}}>
                                    <div style={{display:'flex',margin:0,padding:0,width:'25vw',flexDirection: 'row',

                                    }}>

                                        <div  style={{  padding: 0,width : "4vw",
                                            height:h_z,
                                            minHeight:h_z,
                                            backgroundColor: '#EEEEEE'}}>
                                            <div style={{...imgStyleBtn}} >

                                            </div>

                                            <div  data-offset="{'left': 0,'right':0}"  data-place={'right'}  data-tip={this.state.loadLanguage.leftTab.label}  style={imgStyleBtn} onClick={this.LH1_Click}>
                                                <img  src={this.props.Left_State === 'LH1' ? doc_e : doc_d} alt="doc" style={imgStyle}/>

                                            </div>

                                                <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.state.loadLanguage.leftTab.text} style={imgStyleBtn}  onClick={this.LH2_Click}>
                                                    <img   src={this.props.Left_State === 'LH2' ? txt_e: txt_d} alt="text" style={imgStyle}/>

                                                </div>
                                            <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.state.loadLanguage.leftTab.barcode}  style={imgStyleBtn} onClick={this.LH3_Click}>
                                                <img   src={this.props.Left_State === 'LH3' ? bar_e:bar_d} alt="barcode" style={imgStyle}/>

                                            </div>
                                            <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.state.loadLanguage.leftTab.image} style={imgStyleBtn} onClick={this.LH4_Click}>
                                                <img   src={this.props.Left_State === 'LH4' ? img_e:img_d} alt="image" style={imgStyle}/>

                                            </div>
                                            <div data-place={'right'} data-offset="{'left': 0,'right':0}" data-tip={this.state.loadLanguage.leftTab.shape} style={imgStyleBtn} onClick={this.LH5_Click}>
                                                <img   src={this.props.Left_State === 'LH5' ? shp_e:shp_d} alt="shape" style={imgStyle}/>

                                            </div>

                                        </div>

                                        { this.state.popupNewStatus &&
                                        <NewFile_Popup newFile={()=>this.onNewFile()} savenew={()=>this.onSaveNew()}
                                                       status={this.state.popupNewStatus} langData={this.state.loadLanguage.popup}
                                                       changeStatus={()=>this.onNewPopupDecider()}/>
                                        }

                                        { this.state.csvAppendSave &&
                                         <CsvAppendSave_Popup savenew={()=>this.appendCSVData()}
                                                       changeStatus={()=>this.setState({csvAppendSave:false})}
                                                       status={this.state.csvAppendSave} langData={this.state.loadLanguage.popup}
                                                       />
                                        }

                                        { this.state.csvResetSave &&
                                         <CsvResetSave_Popup savenew={()=>this.reloadCSVData()}
                                                       changeStatus={()=>this.setState({csvResetSave:false})}
                                                       status={this.state.csvResetSave} langData={this.state.loadLanguage.popup}
                                                       />
                                        }

                                        { this.state.csvPopup &&
                                        <CSVLoad status={this.state.csvPopup} changeStatus={()=>this.setState({csvPopup:false})}/>
                                        }

                                        { this.state.xPosError &&
                                         <DimError status={this.state.xPosError} changeStatus={()=>this.setState({xPosError:false})}/>
                                        }

                                        { this.state.bcdValidateError &&
                                          <BarcodeError status={this.state.bcdValidateError} changeStatus={()=>this.setState({bcdValidateError:false})}/>
                                        }

                                        {this.state.csvResetInfo &&
                                         <CsvResetInfo langData={this.state.loadLanguage.popup} status={this.state.csvResetInfo}
                                        changeStatus={()=>this.setState({csvResetInfo:false})}/>
                                        }

                                        { this.state.popupOpenStatus &&
                                                <OpenFile_Popup openFile={()=>this.onFileOpen()} saveopen={()=>this.onSaveOpen()}
                                                        status={this.state.popupOpenStatus} langData={this.state.loadLanguage.popup}
                                                        changeStatus={()=>this.onOpenPopupDecider()}/>
                                        }

                                        { this.state.csvDataResetPopup &&
                                            <CsvDataReset status={this.state.csvDataResetPopup} changeStatus={()=>this.setState({csvDataResetPopup:false})}
                                            onYes={()=>{
                                            this.setState({csvDataResetPopup:false})
                                            this.csvReset()
                                        }}/>
                                        }

                                        { this.state.transformPopup &&
                                          <TransformNotice status={this.state.transformPopup} langData={this.state.loadLanguage.popup}
                                                        changeStatus={()=>this.setState({transformPopup:false})}/>
                                        }

                                        { this.state.dateStampPopup &&
                                          <DateStamp_Popup
                                            changeStatus={()=>this.onChangeDateStampStatus()} langData={this.state.loadLanguage.popup}
                                            applydateStamp={(e)=>this.onApplyDateStamp(e)} status={this.state.dateStampPopup}/>
                                        }

                                        {this.state.questionPrint &&
                                            <QuestionPrint status={this.state.questionPrint}
                                                           txtData={this.state.questionText}
                                                           btxtData={this.state.questionBtx}
                                                           onChangeTxt={(s, e) => this.changeQuestiontxt(s, e)}
                                                           onChangeBtx={(s, e) => this.changeQuestionbtx(s, e)}
                                                           addList={(p) => this.addListData(p)}
                                                           addListCount={this.state.addToList.length}
                                                           onChangeQWidth={(s, e) => this.changeQuestionWidth(s, e)}
                                                           onChangeQWidthBtx={(s, e) => this.changeQuestionWidthBtx(s, e)}
                                                           printQuestion={(p) => this.onPrintQuestion(p)}
                                                           changeStatus={() => {
                                                               // this.rememberQPChanges()
                                                               this.setState({questionPrint: false})
                                                           }}
                                                           reprint={this.state.loadPreviousReprint}
                                                           reprintData={() => this.printPreviousLabel()}
                                                           langData={this.state.loadLanguage.popup}
                                                           handleDragQtext={(e, ui, r) => this.onHandleDragQText(e, ui, r)}
                                                           handleDragQBtext={(e, ui, r) => this.onHandleDragQBText(e, ui, r)}
                                                           handleDragQInputtext={(e, ui, r) => this.onHandleDragQInputText(e, ui, r)}
                                                           handleDragQInputBtext={(e, ui, r) => this.onHandleDragQInputBText(e, ui, r)}
                                                           sortArray={this.state.qInputSortArray}
                                                           checkCSVData={this.state.csvPrintData.length > 0}
                                                           closingIcon={() => {
                                                               if (this.state.modalClose) {
                                                                   this.dragQText()
                                                               }
                                                           }}
                                                           onFlip={() => this.dragQText()}
                                                           lockQPStatus={this.state.questionPrintLock}
                                                           onChangeLockP={() => this.setState({questionPrintLock: !this.state.questionPrintLock})}

                                            />
                                        }
                                        { this.state.viewCSVPopup &&
                                           <ViewCSV status={this.state.viewCSVPopup} tableData={this.state.csvFileDataTable}
                                                 changeStatus={()=>this.setState({viewCSVPopup: !this.state.viewCSVPopup})}/>

                                        }

                                        {
                                        this.state.viewCSVQBPPopup &&
                                        <ViewCSVQBP setQTYAll={(s)=>this.onSetQtyAll(s)} qtyData={this.state.csvQTYArr} status={this.state.viewCSVQBPPopup}
                                                    tableData={this.state.csvFileDataTableQBP} setQTYSelect={(b)=>this.setState({csvQTYArr:b})}
                                                    truncated={this.state.truncateText} setSelectedColumnCSV={(v)=>this.setState({userSelectedColumn:v})}
                                                    selectedColumnCSV={this.state.userSelectedColumn} limitedColumns={this.state.limitColumns}
                                                 changeStatus={()=> this.setState({viewCSVQBPPopup: !this.state.viewCSVQBPPopup})} nextCSV={()=>this.csvNext()}/>

                                        }

                                        {  this.state.settingsPopup &&
                                            <Settings setLanguage={(c)=>this.updateLanguage(c)} status={this.state.settingsPopup} labelData={this.state.loadLanguageLabel}
                                                      changeStatus={()=>this.setState({settingsPopup:false})} langData={this.state.loadLanguage.popup}/>

                                        }

                                        { this.state.copyPasteStatus &&
                                           <CopyPastePopup status={this.state.copyPasteStatus}  onPaste={(d)=>this.onPasteClick(d)} langData={this.state.loadLanguage.popup}
                                                            changeStatus={()=>this.setState({copyPasteStatus: false})} checkPaste={(d)=>this.checkPasteField(d)}/>
                                        }

                                        {
                                            this.changeState(h_z)
                                        }
                                    </div>
                                </div>

                            ):
                            null
                    }
                </div>


                <div style={{display:'flex',margin:0,padding:0,width:'50vw', flexDirection: 'column',

                    }}>

                            <div style={{marginLeft:'20vw',marginTop:'25vh',position:'absolute'
                            }}>
                                <GridLoader
                                    css={override}
                                    size={30}
                                    color={'#838383'}
                                    loading={this.state.loadGrid}
                                />
                            </div>

                    <div id={'objectContainer'}>

                        {
                            this.state.text.map((item, index) =>

                                this.textStart(item,index, this.props.scale_factor)

                            )
                        }
                        {
                            this.state.barcodeObject.barcode.map((item, index) =>

                                this.barcodeStart(item,index, this.props.scale_factor)

                            )
                        }
                        {
                            this.state.barcodeObject.barcodeText.map((item, index) =>

                                this.barcodetextStart(item,index, this.props.scale_factor)

                            )
                        }
                        {
                            this.state.image_array.map((item, index) =>

                                this.imageStart(item,index, this.props.scale_factor)

                            )
                        }
                        {
                            this.state.shape_array.map((item, index) =>

                                this.shapeStart(item,index, this.props.scale_factor)

                            )
                        }


                        {this.state.label_dim.startLabel && this.state.label_dim.width.toString().length > 0 && this.state.label_dim.height.toString().length > 0 ?
                            <DrawLabel layerHeight={h_z} textArray={this.state.label_dim.text} width={this.state.label_dim.width}
                                       height={this.state.label_dim.height} />

                            : null
                        }
                    </div>
                </div>

                <div style={{margin:0,padding:0,width:'25vw',flexDirection: 'column',zIndex:2}}>

                    {
                        this.props.data_Bool ? (
                                <div style={{display:'flex',margin:0,padding:0,width:'25vw',flexDirection: 'row'}}>

                                <div  style={{  padding: 0,width : "21vw",
                                    height:h_z, backgroundColor: '#EEEEEE'}}>

                                    {
                                        this.changeRightState(h_z)
                                    }
                                </div>
                                    {/*{this.state.showText ?*/}
                                        <div style={{
                                            padding: 0, width: "4vw",
                                            height: h_z,
                                            backgroundColor: '#EEEEEE'
                                        }}>
                                            <div style={{...imgStyleBtn}} >

                                            </div>
                                            <ReactTooltip  place="left" type="dark" effect="float"/>


                                            <div data-place={'left'} data-offset="{'left': 0,'right':0}" data-tip={this.state.loadLanguage.leftTab.dataVar} style={imgStyleBtn}
                                                 onClick={this.RH1_Click}>
                                                <img src={this.props.Right_State === 'RH1' ? data_var_e : data_var_d}
                                                     alt="data_var" style={imgStyle}/>

                                            </div>

                                            <div data-place={'left'} data-offset="{'left': 0,'right':0}"  data-tip={this.state.loadLanguage.leftTab.excel}
                                                 style={imgStyleBtn} onClick={this.RH3_Click}>
                                                <img
                                                    src={this.props.Right_State === 'RH3' ? data_excel_e_new : data_excel_d_new}
                                                    alt="data_excel" style={imgStyle}/>

                                            </div>


                                        </div>
                                    {/*    :*/}
                                    {/*    <div style={{*/}
                                    {/*        padding: 0, width: "4vw",*/}
                                    {/*        height: h_z,*/}
                                    {/*        backgroundColor: '#EEEEEE'*/}
                                    {/*    }}/>*/}
                                    {/*}*/}
                                </div>
                        ):null
                    }
                </div>



            </div>

            </div>
        )
    }

}
function mapStateToProps(state) {
    return {
        Left_State : state.counter.LH_State,
        Right_State : state.counter.RH_State,
        Tool_Bool : state.counter.Toolbar_Boolean,
        scale_factor: state.counter.scale_factor,
        label_X: state.counter.Label_X,
        label_Y: state.counter.Label_Y,
        label_Width: state.counter.Label_Width,
        label_Height: state.counter.Label_Height,
        delete_On: state.counter.Delete_Objects,
        activator_Bool: state.counter.activator_Boolean,
        data_Bool: state.counter.data_Boolean,
        toolIcons_bool: state.counter.toolbarIcons_On,

    };

}
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  position:absolute;
  z-index:999
`;


const imgStyle = {
    width:'100%',
    height:'100%',
    objectFit:'contain',padding:0,margin:0

};
const imgStyleBtn = {
    height : "4vw",
    padding: 0,
    border: 0,
    margin: 0,
    "width": "100%",
    display: 'flex',
    outline: 'none',
    cursor:'pointer',
    backgroundColor:'#ececec'
};

export default connect(mapStateToProps)(Activator);
